import {
    ADD_UNIT_ALTERNATIVE, CLEAR_ERRORS,
    GET_UNIT_ALTERNATIVE,
    GET_UNIT_ALTERNATIVES,
    GET_UNITS_DATA,
    GET_UNITS_DATA_ERROR,
    REMOVE_DELETE_UNIT_ALTERNATIVE,
    REMOVE_UPDATE_UNIT_ALTERNATIVE,
    SET_DELETE_UNIT_ALTERNATIVE_ID,
    SET_UPDATE_UNIT_ALTERNATIVE_ID,
    UNIT_ALTERNATIVE_ERROR,
} from '../actions/types';

const initialState = {
    unitAlternatives: [],
    unitAlternative: {},
    unitsData: [],
    update_unitAlternative: null,
    delete_unitAlternative: null,
    loading: false,
    error: null,
};

// eslint-disable-next-line

export default function (state = initialState, action) {
    const {type, payload} = action;

    switch (type) {

        case GET_UNITS_DATA:
            return {
                ...state,
                unitsData: payload.data,
                loading: false,
            };

        case GET_UNITS_DATA_ERROR:
            return {
                ...state,
                error: payload,
                loading: false,
            };

        case GET_UNIT_ALTERNATIVE:
            return {
                ...state,
                unitAlternative: payload.data,
                loading: false,
            };

        case GET_UNIT_ALTERNATIVES:
            return {
                ...state,
                unitAlternatives: payload.data,
                error: null,
                loading: false,
            };

        case ADD_UNIT_ALTERNATIVE:
            return {
                ...state,
                unitAlternatives: [payload],
                error: null,
                loading: false,
            };

        case REMOVE_DELETE_UNIT_ALTERNATIVE:
            return {
                ...state,
                delete_unitAlternative: null,
            };

        case REMOVE_UPDATE_UNIT_ALTERNATIVE:
            return {
                ...state,
                update_unitAlternative: null,
            };

        case SET_DELETE_UNIT_ALTERNATIVE_ID:

            return {
                ...state,
                delete_unitAlternative: state.unitAlternatives.find((item) => item.id === action.payload.unitAlternativeID)
            };

        case SET_UPDATE_UNIT_ALTERNATIVE_ID:
            return {
                ...state,
                update_unitAlternative: state.unitAlternatives.find((item) => item.id === action.payload.unitAlternativeID)
            };

        case UNIT_ALTERNATIVE_ERROR:
            return {
                ...state,
                error: payload,
                loading: false,
            };

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
                loading: false,
            };

        default:
            return state;
    }
}
