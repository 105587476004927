import React, {Fragment, useEffect, useRef, useState} from "react";
import moment from 'moment'
import {useSelector} from "react-redux";
import SearchForm from "../layout/SearchForm";
import FilterByMonth from "../layout/FilterByMonth";
import FilterByFirm from "../filters/FilterByFirm";
import ProductDataRow from "./ProductDataRow";
import {getProductsTransactions, downloadProductReport} from "../../actions/products_transaction";
import ProductReport from "./ProductReport";
import useDates from "../../utils/useDates";
import CustomPaginationWithManyPages from "../layout/CustomPaginationWithManyPages";

const ProductCalculationsNew = () => {

    const {
        auth
    } = useSelector(state => state);

    const {role_name} = auth?.user || {};

    const {dates, updateDates} = useDates();
    const {
        products,
        loading,
        numberOfPages,
        current_page,
    } = useSelector(state => state.products_transaction);

    const scrollableContainer = useRef();
    const [isFirst, setIsFirst] = useState(true);

    useEffect(() => {
        if (products && products.length > 0 && products[0].hasOwnProperty('transactions')) {
            if (isFirst === true && scrollableContainer.current) {
                scrollableContainer.current.scrollLeft += scrollableContainer.current.scrollHeight * 30;
                setIsFirst(false);
            }
        }
    }, [products])

    const onMonthChange = (newYearMonth) => {
        updateDates(newYearMonth);
    }

    return (
        <div className={'px-4'}>
            <div className="row mt-4 mb-2 justify-content-between">
                <div className="col-md-3">
                    <SearchForm getPageItems={getProductsTransactions}/>
                </div>
                {
                    role_name !== 'manager' && role_name !== 'storekeeper' ? (
                        <div className="col-md-2">
                            <FilterByFirm getPageItems={getProductsTransactions}/>
                        </div>
                    ) : ''
                }
                <div className="col-md-3 align-self-end ">
                    <FilterByMonth change={onMonthChange} getPageItems={getProductsTransactions}/>
                </div>
            </div>

            <div className="row mt-3">

                <div className={`loader-container ${loading ? 'loading' : ''} `}></div>

                <div ref={scrollableContainer} className={`tableFixHead ${loading ? 'data_loader' : ''}`}>

                    {
                        products && products.length > 0 ? (
                            <table className={'table-hover custom-hover'}>
                                <thead>
                                <tr>
                                    <th className="sticky-col" style={{width: "2em"}}>#</th>
                                    <th className="sticky-col2">Mahsulot</th>
                                    {
                                        dates?.map((item, index, items) => (
                                            items.length - 1 === index ? (
                                                <th key={`date${index}`} className="sticky-col"
                                                    style={{textAlign: "center"}}
                                                    colSpan="2">
                                                    {item}
                                                    {moment(new Date()).format('YYYY-MM-DD') === item ? '(bugun)' : ''}
                                                </th>
                                            ) : (
                                                <th key={`date${index}`} className="sticky-col"
                                                    style={{textAlign: "center"}}
                                                    colSpan="2">{item}
                                                </th>
                                            )
                                        ))
                                    }
                                    <th className="sticky-col" style={{textAlign: "center", right: 0}}
                                        colSpan="5">Oy bo'yicha umumiy hisob
                                    </th>
                                </tr>
                                <tr>
                                    <th className="sticky-col">-</th>
                                    <th className="sticky-col2"></th>
                                    {
                                        dates.map((subitem, index) => (
                                            <Fragment key={`th${index}`}>
                                                <th className="sticky-col"
                                                    style={{textAlign: "center"}}>Chiqarilgan
                                                </th>
                                                <th className="sticky-col"
                                                    style={{textAlign: "center"}}>Sotilgan
                                                </th>
                                            </Fragment>
                                        ))
                                    }
                                    <th className="sticky-col"
                                        style={{textAlign: "center", minWidth: "100px", right: "400px"}}>
                                        Ishlab chiqarilgan
                                    </th>
                                    <th className="sticky-col"
                                        style={{textAlign: "center", minWidth: "100px", right: "300px"}}>
                                        Sotilgan
                                    </th>
                                    <th className="sticky-col"
                                        style={{textAlign: "center", minWidth: "100px", right: "200px"}}>
                                        Umumiy chiqarilgan
                                    </th>
                                    <th className="sticky-col"
                                        style={{textAlign: "center", minWidth: "100px", right: "100px"}}>
                                        Umumiy sotilgan
                                    </th>
                                    <th className="sticky-col"
                                        style={{textAlign: "center", minWidth: "100px", right: "0px"}}>
                                        Qoldiq
                                    </th>
                                </tr>
                                </thead>

                                <tbody>
                                {products?.map((item, index) => (
                                    <ProductDataRow item={item}
                                                    key={index}
                                                    index={index}
                                                    dates={dates}
                                                    />
                                ))}
                                </tbody>
                            </table>
                        ) : ''
                    }

                </div>

            </div>

            <div className="row mt-3">
                <CustomPaginationWithManyPages
                    numberOfPages={numberOfPages}
                    getPageItems={getProductsTransactions}
                    current_page={current_page}
                />
            </div>

            <ProductReport downloadProductReport={downloadProductReport}/>
        </div>

    )
}

export default ProductCalculationsNew;

