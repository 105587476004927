import axios from 'axios';
import {clearAlerts, setAlert} from './alert';
import {
    ADD_TRANSPORT,
    CLEAR_ERRORS,
    GET_AVAILABLE_DRIVER_EMPLOYEE,
    GET_FILTERED_TRANSPORT_PRODUCT_DELIVERIES,
    GET_TRANSPORT,
    GET_TRANSPORT_PRODUCT_DELIVERIES,
    GET_TRANSPORTS,
    REMOVE_DELETE_TRANSPORT,
    REMOVE_DELETE_TRANSPORT_PRODUCT_DELIVERY,
    REMOVE_UPDATE_TRANSPORT,
    REMOVE_UPDATE_TRANSPORT_PRODUCT_DELIVERY,
    SET_DELETE_TRANSPORT_ID,
    SET_DELETE_TRANSPORT_PRODUCT_DELIVERY_ID, SET_LOADING,
    SET_UPDATE_TRANSPORT_ID,
    SET_UPDATE_TRANSPORT_PRODUCT_DELIVERY_ID,
    TRANSPORT_ERROR
} from './types';


export const getAvailableDriverEmployees = () => async (dispatch) => {
    try {
        const res = await axios.get('/employees');
        const items = res.data.data.map(item => ({name: item.first_name + " " + item.last_name, id: item.id}))
        dispatch({
            type: GET_AVAILABLE_DRIVER_EMPLOYEE,
            payload: items ?? [],
        });

    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
    }
};

export const getTransport = (transportID) => async (dispatch) => {
    try {
        const res = await axios.get(`/transports/${transportID}`);
        dispatch({
            type: GET_TRANSPORT,
            payload: res.data,
        });

    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
    }
};

export const getTransports = (page = 1, filters = {}) => async (dispatch) => {
    dispatch({type: SET_LOADING, payload: true})
    try {
        let queryParams = '';
        if (filters) {
            Object.keys(filters).map((key, index) => queryParams += `&${key}=${filters[key]}`);
        }
        const res = await axios.get(`/transports?page=${page}${queryParams}`);

        dispatch({
            type: GET_TRANSPORTS,
            payload: res.data,
        });
        dispatch({type: SET_LOADING, payload: false})
    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
    }
};

export const addTransport = (formData) => async (dispatch) => {
    dispatch(clearAlerts());

    const config = {
        headers: {
            'Content-Type': 'application/json',
        },
    };

    try {
        const res = await axios.post('/transports/', formData, config);

        dispatch(getTransports());

        dispatch({
            type: ADD_TRANSPORT,
            payload: res.data,
        });

        dispatch(setAlert('Transport yaratildi !', 'success'));

    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));

        if (err?.response?.data?.errors) {
            dispatch({
                type: TRANSPORT_ERROR,
                payload: err.response.data,
            });
        } else {
            dispatch(setAlert("Transport yaratilmadi !", 'danger'));
        }
    }
};

export const deleteTransport = (deleteTransportID, getPage) => async (dispatch) => {
    dispatch(clearAlerts());

    const config = {
        headers: {
            'Content-Type': 'application/json',
        },
    };

    try {
        const res = await axios.delete(`/transports/${deleteTransportID}`, config);

        dispatch({
            type: REMOVE_DELETE_TRANSPORT
        });

        dispatch(getTransports(getPage));

        dispatch(setAlert("Transport o'chirildi !", 'success'));

        dispatch(clearErrors());

    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
    }
};

export const updateTransport = (updateTransportID, updateformData, getPage) => async (dispatch) => {
    dispatch(clearAlerts());

    const config = {
        headers: {
            'Content-Type': 'application/json',
        },
    };

    try {
        const res = await axios.put(`/transports/${updateTransportID}`, updateformData, config);

        dispatch({type: REMOVE_UPDATE_TRANSPORT});

        dispatch(getTransports(getPage));

        dispatch(setAlert('Transport tahrirlandi !', 'success'));
    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
    }
};

export const setDeleteTransportId = (transportID) => async (dispatch) => {

    try {
        if (transportID !== null) {
            dispatch({
                type: SET_DELETE_TRANSPORT_ID,
                payload: {
                    transportID
                }
            });

        } else {
            dispatch({
                type: REMOVE_DELETE_TRANSPORT
            });
        }

    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
    }
};

export const setUpdateTransportId = (transportID) => async (dispatch) => {

    try {
        if (transportID !== null) {
            dispatch({
                type: SET_UPDATE_TRANSPORT_ID,
                payload: {
                    transportID
                }
            });
        } else dispatch({type: REMOVE_UPDATE_TRANSPORT});

    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
    }
};

export const clearErrors = () => async (dispatch) => {
    dispatch({
        type: CLEAR_ERRORS,
    });
};


export const getFilteredTransportProductDelivery = (id, from_date = '', to_date = '') => async (dispatch) => {
    try {
        let queryParams = '';

        if (from_date !== '' && to_date !== '') {
            queryParams = `?from_date=${from_date}&to_date=${to_date}`;
        } else {
            if (from_date !== '') {
                queryParams = `?from_date=${from_date}`;
            } else {
                queryParams = `?to_date=${to_date}`;
            }
        }

        const res = await axios.get(`/product-deliveries${queryParams}&transport_id=${id}`);
        dispatch({
            type: GET_FILTERED_TRANSPORT_PRODUCT_DELIVERIES,
            payload: res.data,
        });

    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
    }
};

export const getForTransportProductDeliveries = (transport_ID) => async (dispatch) => {
    try {

        const res = await axios.get(`/product-deliveries/?transport_id=${transport_ID}`);
        dispatch({
            type: GET_TRANSPORT_PRODUCT_DELIVERIES,
            payload: res.data,
        });

    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
    }
};

export const addForTransportProductDelivery = (formData, transport_ID) => async (dispatch) => {
    dispatch(clearAlerts());

    const config = {
        headers: {
            'Content-Type': 'application/json',
        },
    };

    try {
        const res = await axios.post(`/product-deliveries/`, formData, config);

        dispatch(setAlert('Yetkazib berish yaratildi !', 'success'));
        return true;
    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));

        if (err?.response?.data?.errors) {
            dispatch({
                type: TRANSPORT_ERROR,
                payload: err.response.data,
            });
        } else {
            dispatch(setAlert("Yetkazib berish yaratilmadi !", 'danger'));
        }
        return false;
    }
};

export const updateForTransportProductDelivery = (update_transport_product_delivery_ID, transport_ID, updateformData) => async (dispatch) => {
    dispatch(clearAlerts());

    const config = {
        headers: {
            'Content-Type': 'application/json',
        },
    };

    try {
        const res = await axios.put(`/product-deliveries/${update_transport_product_delivery_ID}`, updateformData, config);

        dispatch(setAlert('Yetkazib berish tahrirlandi !', 'success'));
        return true;
    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
        if (err?.response?.data?.errors) {
            dispatch({
                type: TRANSPORT_ERROR,
                payload: err.response.data,
            });
        } else {
            dispatch(setAlert("Yetkazib berish tahrirlanmadi!", 'danger'));
        }
    }
};

export const deleteForTransportProductDelivery = (deleteProductDeliveryID, transport_ID) => async (dispatch) => {

    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        };
        const res = await axios.delete(`/product-deliveries/${deleteProductDeliveryID}`, config);

        dispatch({
            type: REMOVE_DELETE_TRANSPORT_PRODUCT_DELIVERY
        });
        dispatch(setAlert("Yetkazib berish o'chirildi!", 'success'));

        dispatch(getForTransportProductDeliveries(transport_ID));

        dispatch(clearErrors())

    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
        if (err?.response?.data?.errors) {
            dispatch({
                type: TRANSPORT_ERROR,
                payload: err.response.data,
            });
        } else {
            dispatch(setAlert("Yetkazib berish o'chirilmadi!", 'danger'));
        }
    }
};

export const setUpdateTransportProductDeliveryId = (transport_product_delivery_ID, productDeliveryItems) => async (dispatch) => {

    try {
        if (transport_product_delivery_ID !== null) {

            let update_product_delivery = productDeliveryItems.find((item) => item.id === transport_product_delivery_ID);

            dispatch({
                type: SET_UPDATE_TRANSPORT_PRODUCT_DELIVERY_ID,
                payload: update_product_delivery
            });
        } else dispatch({type: REMOVE_UPDATE_TRANSPORT_PRODUCT_DELIVERY});

    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
    }
};

export const setDeleteTransportProductDeliveryId = (transport_product_delivery_ID, productDeliveryItems) => async (dispatch) => {

    try {
        if (transport_product_delivery_ID !== null) {

            let delete_product_delivery = productDeliveryItems.find((item) => item.id === transport_product_delivery_ID);

            dispatch({
                type: SET_DELETE_TRANSPORT_PRODUCT_DELIVERY_ID,
                payload: delete_product_delivery
            })

        } else dispatch({type: SET_DELETE_TRANSPORT_PRODUCT_DELIVERY_ID, payload: null});

    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
    }
};









