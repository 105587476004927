import React, {Fragment, useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Button from 'react-bootstrap/Button';
import {updateProduction, getProductOptions, setUpdateProductionId} from "../../actions/production";
import Select from 'react-select'
import {Form} from "react-bootstrap";
import {getParams, getSelectedOption} from "../../utils/hooks/getParams";
import {getFirmOptions} from "../../actions/user";
import moment from "moment";
import ButtonLoader from "../layout/ButtonLoader";
import Modal from "react-bootstrap/Modal";
import {clearErrors} from "../../actions/client";

const ProductionUpdateForm = ({
                                  firm_options,
                                  error,
                                  update_production,
                                  handleClose,
                                  loading,
                                  product_options,
                                  updateProduction,
                                  getProductOptions,
                              }) => {

    const formRef = useRef();

    const [firmOptions, setFirmOptions] = useState([]);
    const [selectedFirm, setSelectedFirm] = useState({label: 'Tanlang', value: ''});
    const [productOptions, setProductOptions] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState({label: 'Tanlang', value: ''});

    const defaultValues = {
        firm_id: '',
        product_id: '',
        quantity: '',
        date: '',
    };
    const [inputs, setInputs] = useState(defaultValues);
    const [errors, setErrors] = useState({});


    useEffect(() => {
        if (update_production){

            setInputs({
                product_id: update_production.product_id,
                firm_id: update_production.warehouse?.firm_id,
                quantity: parseInt(update_production.quantity),
                date: update_production.date,
            });
        }

    }, [update_production]);

    useEffect(() => {
        getFirmOptions();
    }, [getFirmOptions]);

    useEffect(() => {
        let items = [];
        firm_options.forEach((item) => items.push({
            label: `${item.name}`,
            value: item.id
        }));
        setFirmOptions(items);

    }, [firm_options]);

    useEffect(() => {
        if (update_production && inputs.hasOwnProperty('firm_id')){
            setSelectedFirm(getSelectedOption(inputs?.firm_id, firmOptions))
            getProductOptions({firm_id: update_production?.warehouse?.firm_id});
        }
    }, [inputs]);

    useEffect(() => {
        if (update_production && update_production.hasOwnProperty('product_id')){
            setSelectedProduct(getSelectedOption(update_production.product_id, productOptions))
        }
    }, [productOptions]);


    useEffect(() => {
        let options = [];
        product_options.forEach((item) => options.push({
            label: item.name,
            value: item.id
        }));
        setProductOptions(options);
    }, [product_options]);

    const handleChange = e => {
        if (e.target.name === 'quantity' && parseFloat(e.target.value) < 0) {
            e.target.value = Number(e.target.value) * -1;
        }
        setErrors({});
        setInputs(prevState => ({...prevState, [e.target.name]: e.target.value}));
    }
    const handleSelect = (data, type) => {
        if (type.name === 'firm_id') {
            getProductOptions({firm_id: data.value});
        }
        setErrors({});
        setInputs(prevState => ({...prevState, [type.name]: data.value}))
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (await updateProduction(update_production.id, inputs, getParams(true))) {
            setInputs(defaultValues);
            onClose();
        }
    };
    useEffect(() => {

        if (loading === false) {
            if (error) {
                const errorsItems = error?.errors;
                let errorObject = {};
                if (error.hasOwnProperty('errors')) {
                    Object.keys(error.errors).forEach((item) => errorObject[item] = errorsItems[item][0]);
                }
                setErrors(errorObject);

            } else {
                setErrors({});
                setInputs(defaultValues);
            }
        }
    }, [error]);

    const onClose = () => {
        setUpdateProductionId(null);
        clearErrors();
        setInputs(defaultValues);
        setErrors({});
        handleClose();
    };


    return (
        <Fragment>
            <Modal show={true} onHide={onClose}>
                <Modal.Header closeButton className="bg-light">
                    <Modal.Title>ishlab chiqarish ni tahrirlash</Modal.Title>
                </Modal.Header>
                <Modal.Body className="pt-3 pb-4 mx-3">

                    <Form onSubmit={handleSubmit} ref={formRef}>

                        <div className="mb-3">
                            <label htmlFor="firm_id">Firma</label>
                            <Select
                                name={'firm_id'}
                                options={firmOptions}
                                value={selectedFirm}
                                onChange={handleSelect}
                                defaultValue={{label: 'Tanlang', value: ''}}
                            />
                            <div className="error">
                                {errors?.firm_id}
                            </div>
                        </div>

                        <div className="mb-2">
                            <label htmlFor="product_id">Mahsulot</label>
                            <Select
                                name={'product_id'}
                                options={productOptions}
                                value={selectedProduct}
                                onChange={handleSelect}
                                defaultValue={{label: 'Tanlang', value: ''}}
                            />
                            <div className="error">
                                {errors?.product_id}
                            </div>
                        </div>

                        {/*<div className="mb-2">*/}
                        {/*    <label htmlFor="warehouse_id">Mahsulot sklad</label>*/}
                        {/*    <Select*/}
                        {/*        name={'warehouse_id'}*/}
                        {/*        options={warehouseOptions}*/}
                        {/*        value={getSelectedOption(inputs?.warehouse_id || '', warehouseOptions)}*/}
                        {/*        onChange={handleSelect}*/}
                        {/*        defaultValue={{label: 'Tanlang', value: ''}}*/}
                        {/*    />*/}
                        {/*    <div className="error">*/}
                        {/*        {errors?.warehouse_id}*/}
                        {/*    </div>*/}
                        {/*</div>*/}

                        <div className="mb-2">
                            <label htmlFor="quantity">Miqdori</label>
                            <input
                                name="quantity"
                                className={'form-control'}
                                value={inputs?.quantity || ''}
                                type="text"
                                min={1}
                                placeholder={1000}
                                onChange={handleChange}
                                required
                            />
                            <div className="error">
                                {errors?.quantity}
                            </div>
                        </div>

                        <div className="mb-3">
                            <label htmlFor="date">Sana</label>
                            <input
                                name="date"
                                className={'form-control'}
                                type="date"
                                value={inputs?.date || ''}
                                placeholder={'kiriting'}
                                onChange={handleChange}
                                max={moment().format('YYYY-MM-DD')}
                                // onKeyUp={e => e.target.oldValue = e.target.value}
                            />

                            <div className="error">
                                {errors?.date}
                            </div>
                        </div>


                        <div className="d-flex gap-2">
                            <Button className="w-50" variant="secondary" type="button" onClick={onClose}>Bekor
                                qilish</Button>
                            <Button className="w-50" variant="primary" type="submit">
                                Saqlash
                                {loading && <ButtonLoader/>}
                            </Button>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>
        </Fragment>
    );
};

ProductionUpdateForm.propTypes = {
    updateProduction: PropTypes.func.isRequired,
    getProductOptions: PropTypes.func.isRequired,
    getFirmOptions: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    production: state.production,
    update_production: state.production.update_production,
    firm_options: state.user.firm_options,
    error: state.production.error,
    loading: state.production.loading,
    product_units: state.production.product_units,
    product_options: state.production.product_options,
    warehouse_options: state.production.warehouse_options,
});


export default connect(mapStateToProps, {
    updateProduction,
    getProductOptions,
    getFirmOptions
})(ProductionUpdateForm);
