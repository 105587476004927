import React, {Fragment, useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import {addTransport, clearErrors, getAvailableDriverEmployees} from "../../actions/transport";

const TransportCreateForm = ({
                                 transport: {available_drivers, error, loading, transports},
                                 addTransport,
                                 getAvailableDriverEmployees,
                                 handleShow,
                                 handleClose,
                                 show
                             }) => {


    useEffect(() => {
        getAvailableDriverEmployees();
    }, [getAvailableDriverEmployees]);


    const formRef = useRef();

    const defaultValues = {
        name: '',
        plate: '',
        driver_id: '',
    };

    const [inputs, setInputs] = useState(defaultValues);
    const [errors, setErrors] = useState({});

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = {
            name: inputs.name,
            plate: inputs.plate,
            driver_id: inputs.driver_id,
        };
        addTransport(data);
    };

    const handleChange = e => {
        setErrors({});
        e.target.oldValue = e.target.value;
        setInputs(prevState => ({...prevState, [e.target.name]: e.target.value}));
    };

    useEffect(() => {
        if (error && loading === false) {
            const errorsItems = error?.errors;
            let errorObject = {};
            if (error && error?.errors) {
                Object.keys(error?.errors).forEach((item, index) => errorObject[item] = errorsItems[item][0]);
            }
            setErrors(errorObject);

        }
    }, [error]);

    useEffect(() => {
        clearErrors();
        setErrors({});
        setInputs(defaultValues);
    }, [transports]);

    const onClose = () => {
        clearErrors();
        setInputs(defaultValues);
        setErrors({});
        handleClose();
    };

    return (
        <Fragment>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Yangi mashina qo'shish</Modal.Title>
                </Modal.Header>

                <Modal.Body className="pt-3 pb-4 mx-3">


                    <form onSubmit={handleSubmit} ref={formRef}>

                        <div className="mb-3">
                            <label htmlFor="name">Nomi</label>
                            <input
                                name="name"
                                className={'form-control'}
                                type="text"
                                value={inputs?.name || ''}
                                placeholder={'kiriting'}
                                onChange={handleChange}
                                onKeyUp={e => e.target.oldValue = e.target.value}
                            />

                            <div className="error">
                                {errors?.name}
                            </div>
                        </div>

                        <div className="mb-3">
                            <label htmlFor="plate">Mashina raqami</label>
                            <input
                                name="plate"
                                className={'form-control'}
                                type="text"
                                value={inputs?.plate || ''}
                                placeholder={'kiriting'}
                                onChange={handleChange}
                                onKeyUp={e => e.target.oldValue = e.target.value}
                            />

                            <div className="error">
                                {errors?.plate}
                            </div>
                        </div>

                        <div className="mb-3">
                            <label htmlFor="driver_id">Haydovchi</label>
                            <select name="driver_id"
                                    value={inputs?.driver_id || ''}
                                    id="driver_id"
                                    className="form-control"
                                    onChange={handleChange}
                                    onKeyUp={e => e.target.oldValue = e.target.value}
                            >
                                <option value="">Tanlang</option>
                                {
                                    available_drivers.map((item, index) => (
                                        <option key={index} value={item.id}>{item.name}</option>
                                    ))
                                }

                            </select>
                            <div className="error">
                                {errors?.driver_id}
                            </div>
                        </div>


                        <div className="d-flex gap-2">
                            <Button className="w-50" variant="secondary" type="button" onClick={onClose}>Bekor
                                qilish</Button>
                            <Button className="w-50" variant="primary" type="submit">Saqlash</Button>
                        </div>
                    </form>


                </Modal.Body>

            </Modal>
        </Fragment>
    );
};

TransportCreateForm.propTypes = {
    addTransport: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    transport: state.transport,
});


export default connect(mapStateToProps, {addTransport, getAvailableDriverEmployees, clearErrors})(TransportCreateForm);
