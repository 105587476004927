const getApiUrl = () => {
    const mode = process.env.REACT_APP_MODE;
    
    switch (mode) {
        case 'stage':
            return process.env.REACT_APP_STAGING_APP_API_URL;
        case 'production':
            return process.env.REACT_APP_PRODUCTION_APP_API_URL;
        case 'local':
        default:
            return process.env.REACT_APP_API_URL || '/api';
    }
};

export default getApiUrl;
