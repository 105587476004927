import React, {useEffect, useState} from 'react';
import useQueryParams from "../../utils/hooks/useQueryParams";
import {getParams} from "../../utils/hooks/getParams";
import {useDispatch} from "react-redux";
import ReactPaginate from "react-paginate";

const scrollToTop = (x = 0, y = 0) => {
    window.scroll(x, y);
};

const CustomPaginationWithManyPages = props => {
    const dispatch = useDispatch();
    const {page} = useQueryParams();
    const {getPageItems, numberOfPages, current_page, items, custom_params} = props;
    const [innerCurrentPage, setInnerCurrentPage] = useState(Number(current_page));


    useEffect(() => {
        const params = getParams();
        const new_params = {...custom_params, ...params};
        dispatch(getPageItems(page, new_params));
        // if (page && params) {
        //     setInnerCurrentPage(Number(page));
        // } else {
        //     setInnerCurrentPage(1);
        // }
    }, [])

    useEffect(() => {
        if (Number(page) !== Number(innerCurrentPage)) {
            setInnerCurrentPage(Number(page))
        }
    }, [page])

    const handlePageClick = (event) => {
        const newPage = event.selected + 1;
        const url = new URL(window.location);
        url.searchParams.set('page', newPage);
        window.history.pushState({}, '', url);
        const params = getParams();
        setInnerCurrentPage(Number(newPage));
        if (delete params['page']) ;
        const new_params = {...custom_params, ...params};
        dispatch(getPageItems(newPage, new_params));
        scrollToTop();
    }


    return numberOfPages > 1 ?
        <ReactPaginate
            className={'pagination'}
            breakClassName={'page-item'}
            nextClassName={'page-item'}
            activeClassName={'active'}
            activeLinkClassName={'active'}
            nextLinkClassName={'page-link'}
            previousClassName={'page-item'}
            previousLinkClassName={'page-link'}
            breakLinkClassName={'page-link'}
            pageLinkClassName={'page-link'}
            pageClassName={'page-item'}
            breakLabel="..."
            nextLabel="Keyingi >"
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={numberOfPages}
            forcePage={current_page-1}
            previousLabel="< Ortga"
            renderOnZeroPageCount={null}
        />
        : '';
}


export default CustomPaginationWithManyPages;
