import React, {useEffect, useState} from 'react';
import {Link, useLocation} from 'react-router-dom';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import "./Sidebar.css";
import {toggleSidebar, setActiveItem, hideSidebar, collapseSidebar} from '../../actions/sidebar';

import {MAX_MOBILE_WINDOW_WIDTH} from '../../constants';
import useLocalStorage from "../../utils/hooks/useLocalStorage";


const Sidebar = ({
                     isShown,
                     user,
                     role,
                     isExpanded,
                     menu,
                     setActiveItem,
                     toggleSidebar,
                     collapseSidebar
                 }) => {

    const [isSidebarExpanded, setIsSidebarExpanded] = useLocalStorage("isSidebarExpanded");

    const {pathname} = useLocation();
    const [width, setWidth] = useState(window.innerWidth);

    const {role_name, manager_firm} = user || {};

    useEffect(() => {

        if (isExpanded === true && isSidebarExpanded === false) {
            collapseSidebar()
        }
    }, [])

    useEffect(() => {
        if (isExpanded && width <= MAX_MOBILE_WINDOW_WIDTH) {
            collapseSidebar();
        }
        if (pathname) {
            if (pathname !== '') {
                setActiveItem(pathname);
            }
        }
    }, [pathname, width, setActiveItem, toggleSidebar]);

    if (!isShown) {
        return (
            ''
        );
    }

    return (
        <nav id="sidebar" data-include="sidebar"
             className={`sidebar js-sidebar ${!isExpanded ? 'sidebar-hidden' : ''} `}>
            <div className="sidebar-content js-simplebar">
                <div className="sidebar-brand">
                    <span className="align-middle">Boshqaruv menyu</span>
                </div>

                {
                    role_name === 'manager' && manager_firm && Object.keys(manager_firm).length > 0 ? (
                        <ul className="sidebar-nav">
                            {
                                menu?.map((item, key) => {
                                        if (item.belongs_to.includes(role?.name)) {
                                            return (
                                                <li key={key}
                                                    className={`${item.is_section ? 'sidebar-header' : 'sidebar-item'} ${item.is_active ? 'active' : ''} `}>
                                                    {item.is_section ? (
                                                        <span className="align-middle">
                                                                {item.name}
                                                            </span>
                                                    ) : (
                                                        <Link className="sidebar-link" to={item.route}>
                                                            {item.icon_tag}
                                                            <span className="align-middle">
                                                                    {item.name}
                                                                </span>
                                                        </Link>
                                                    )}

                                                </li>
                                            )
                                        }
                                    }
                                )
                            }

                        </ul>
                    ) : (
                        role_name === 'superadmin' || role_name === 'director' || role_name === 'accountant' || role_name === 'storekeeper' ? (
                            <ul className="sidebar-nav">
                                {
                                    menu.map((item, key) => {
                                            if (item.belongs_to.includes(role?.name)) {
                                                return (
                                                    <li key={key}
                                                        className={`${item.is_section ? 'sidebar-header' : 'sidebar-item'} ${item.is_active ? 'active' : ''} `}>
                                                        {item.is_section ? (
                                                            <span className="align-middle">
                    {item.name}
                        </span>
                                                        ) : (
                                                            <Link className="sidebar-link" to={item.route}>
                                                                {item.icon_tag}
                                                                <span className="align-middle">
                    {item.name}
                        </span>
                                                            </Link>
                                                        )}

                                                    </li>
                                                )
                                            }
                                        }
                                    )}

                            </ul>
                        ) :
                            <div className="sidebar-brand">
                                <div className="spinner-grow text-light" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            </div>

                    )
                }


            </div>
        </nav>
    )
}

Sidebar.propTypes = {
    isShown: PropTypes.bool,
    setActiveItem: PropTypes.func.isRequired,
    toggleSidebar: PropTypes.func.isRequired,
    hideSidebar: PropTypes.func.isRequired,
    collapseSidebar: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
    isShown: state.sidebar.isShown,
    user: state.auth.user,
    role: state.auth.user?.role,
    isExpanded: state.sidebar.isExpanded,
    menu: state.sidebar.menu
});


export default connect(mapStateToProps, {toggleSidebar, hideSidebar, setActiveItem, collapseSidebar})(Sidebar);
