import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {setDeleteSparePartId, setUpdateSparePartId} from "../../actions/spare_part";
import {Link, useHistory} from 'react-router-dom'
import MoneyFormat from "../layout/MoneyFormat";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEdit, faTrash} from '@fortawesome/free-solid-svg-icons';
import {Button} from 'react-bootstrap';

const SparePartItem = ({
                           auth,
                           setDeleteSparePartId,
                           setUpdateSparePartId,
                           spare_part: {
                               id, name, quantity, price, warehouse_id, warehouse, unit, unit_id,
                               date: created_at
                           },
                           number
                       }) => {
    const {role_name} = auth?.user || {};
    const setDeleteSparePart = (id) => {
        setDeleteSparePartId(id);
    };

    const setUpdateSparePart = (id) => {
        setUpdateSparePartId(id);
    };

    const history = useHistory();
    return (
        <Fragment>
            <tr>
                <th scope="row">{number}</th>
                <td>{name}</td>
                <td>{quantity}</td>
                <td><MoneyFormat value={price}/> so'm</td>
                <td>{unit?.name}</td>
                <td><Link to={`warehouses/${warehouse?.id}`}>{warehouse?.name}</Link></td>
                {
                    role_name !== 'accountant' && (
                        <td>
                            <div className="d-flex gap-2">
                                {
                                    role_name === 'superadmin' || role_name === 'director' || role_name === 'manager' ? (
                                        <Button
                                            variant="outline-primary"
                                            size="sm"
                                            className="d-flex align-items-center justify-content-center"
                                            style={{width: '32px', height: '32px', padding: 0}}
                                            onClick={() => setUpdateSparePart(id)}
                                        >
                                            <FontAwesomeIcon icon={faEdit}/>
                                        </Button>
                                    ) : ''
                                }
                                {
                                    role_name === 'superadmin' || role_name === 'director' ? (
                                        <Button
                                            variant="outline-danger"
                                            size="sm"
                                            className="d-flex align-items-center justify-content-center"
                                            style={{width: '32px', height: '32px', padding: 0}}
                                            onClick={() => setDeleteSparePart(id)}
                                        >
                                            <FontAwesomeIcon icon={faTrash}/>
                                        </Button>
                                    ) : ''
                                }
                            </div>
                        </td>
                    )
                }
            </tr>
        </Fragment>
    );
};

SparePartItem.defaultProps = {
    showActions: true,
};

SparePartItem.propTypes = {
    spare_part: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
});

export default connect(mapStateToProps, {setDeleteSparePartId, setUpdateSparePartId})(
    SparePartItem
);
