import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useParams} from 'react-router-dom';
import GoBackButton from "../layout/GoBackButton";
import {
    getFilteredTransportProductDelivery,
    getForTransportProductDeliveries,
    getTransport,
    setDeleteTransportProductDeliveryId,
    setUpdateTransportProductDeliveryId
} from "../../actions/transport";
import {Col, Nav, Row, Tab} from "react-bootstrap";
import CurrencyFormat from "react-currency-format";
import moment from "moment";
import TransportProductDeliveryCreateForm from "./TransportProductDeliveryCreateForm";
import TransportProductDeliveryUpdateForm from "./TransportProductDeliveryUpdateForm";
import TransportProductDeliveryDeleteForm from "./TransportProductDeliveryDeleteForm";
import {Edit2, Trash2} from "react-feather";

const TransportPage = () => {

    const {id} = useParams();

    useEffect(() => {
        dispatch(getTransport(id));
        dispatch(getForTransportProductDeliveries(id));
    }, [getTransport, getForTransportProductDeliveries]);

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const dispatch = useDispatch();


    const {
        transport,
        transport_product_deliveries,
        filtered_transport_product_delivery_data_date
    } = useSelector(state => state.transport);


    const [productDeliveryItems, setProductDeliveryItems] = useState([]);

    const [from_date, setFromDate] = useState('');
    const [to_date, setToDate] = useState('');


    const onChangeFromDate = (event) => {
        setFromDate(state => event.target.value);
        dispatch(getFilteredTransportProductDelivery(transport.id, event.target.value, to_date))
    };

    const onChangeToDate = (event) => {
        setToDate(state => event.target.value);
        dispatch(getFilteredTransportProductDelivery(transport.id, from_date, event.target.value))
    };


    useEffect(() => {

        if (filtered_transport_product_delivery_data_date?.length >= 0) {
            setProductDeliveryItems(filtered_transport_product_delivery_data_date);
        } else {
            setProductDeliveryItems(transport_product_deliveries);
        }
    }, [filtered_transport_product_delivery_data_date, transport_product_deliveries, from_date, to_date]);


    const setUpdateDelivery = (id) => {
        dispatch(setUpdateTransportProductDeliveryId(id, transport_product_deliveries));
    };

    const setDeleteDelivery = (id) => {
        dispatch(setDeleteTransportProductDeliveryId(id, transport_product_deliveries));
    };


    return (
        <div>
            <main className="content mt-0 pt-0">
                <div className="container-fluid p-0">

                    <div className="row mb-2">
                        <div className="col-md-12 col-lg-8">
                            <h1 className="h3 mb-3"><strong>Transport</strong> sahifasi </h1>
                        </div>
                        <div className="col-lg-4">
                            <div className="crud_actions text-end">
                                <GoBackButton/>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12 col-lg-12">
                            <div className="card px-4 py-3">
                                <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                                    <Row>
                                        <Col sm={3} md={3}>
                                            <Nav variant="pills" className="flex-column">
                                                <Nav.Item>
                                                    <Nav.Link eventKey="first"
                                                              className="text-center fw-bold">Haqida</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="second" className="text-center fw-bold">
                                                        Transport yetkazmalari
                                                    </Nav.Link>
                                                </Nav.Item>
                                            </Nav>
                                        </Col>
                                        <Col sm={9} md={9}>
                                            <Tab.Content>
                                                <Tab.Pane eventKey="first">

                                                    <div className="card mb-3">
                                                        <div className="card-header">
                                                            <h5 className="card-title mb-0">Transport
                                                                ma'lumotlari</h5>
                                                        </div>
                                                        <div className="card-body text-center">
                                                            <div className="row">

                                                                <div className="col-md-12">

                                                                    <div className="d-flex align-items-center">
                                                                        <div className="card-title m-2">Nomi:</div>
                                                                        <div
                                                                            className="text-muted m-0">{transport?.name}</div>
                                                                    </div>

                                                                    <div className="d-flex align-items-center">
                                                                        <div className="card-title m-2">Raqami:</div>
                                                                        <div
                                                                            className="text-muted m-0">{transport?.plate}</div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="card-header border-top">
                                                            <h5 className="card-title mb-0">
                                                                Haydovchi ma'lumotlari
                                                            </h5>
                                                        </div>
                                                        <div className="card-body">
                                                            <div className="row">

                                                                <div className="col-md-12">

                                                                    <div className="d-flex align-items-center">
                                                                        <div className="card-title m-2">Ism familiya:
                                                                        </div>
                                                                        <div
                                                                            className="text-muted m-0">{transport?.driver?.first_name} {transport?.driver?.last_name}</div>
                                                                    </div>

                                                                    <div className="d-flex align-items-center">
                                                                        <div className="card-title m-2">INN:</div>
                                                                        <div
                                                                            className="text-muted m-0">{transport?.driver?.inn}</div>
                                                                    </div>

                                                                    <div className="d-flex align-items-center">
                                                                        <div className="card-title m-2">Telefon:</div>
                                                                        <div
                                                                            className="text-muted m-0">{transport?.driver?.phone}</div>
                                                                    </div>

                                                                    <div className="d-flex align-items-center">
                                                                        <div className="card-title m-2">Manzil:</div>
                                                                        <div
                                                                            className="text-muted m-0">{transport?.driver?.address}</div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </Tab.Pane>

                                                <Tab.Pane eventKey="second">

                                                    <div className="w-100 table-responsive px-2">

                                                        <div className="row mb-3">
                                                            <div className="col-md-6"></div>
                                                            <div className="col-md-6">
                                                                <div className="crud_actions text-end">
                                                                    <button className="btn btn-primary"
                                                                            onClick={() => handleShow()}>
                                                                        Yetkazib berish
                                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                                             width="24" height="24" viewBox="0 0 24 24"
                                                                             fill="none" stroke="currentColor"
                                                                             strokeWidth="2" strokeLinecap="round"
                                                                             strokeLinejoin="round"
                                                                             className="feather feather-plus align-middle mr-3">
                                                                            <line x1="12" y1="5" x2="12" y2="19"></line>
                                                                            <line x1="5" y1="12" x2="19" y2="12"></line>
                                                                        </svg>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div
                                                            className="mb-2 align-items-center d-flex justify-content-between">

                                                            <div className="mb-3">
                                                                <label htmlFor="date1" className="mb-2">dan</label>
                                                                <input onChange={onChangeFromDate}
                                                                       className="form-control"
                                                                       id="date1" type="date"/>
                                                            </div>

                                                            <div className="mb-3">
                                                                <label htmlFor="date2" className="mb-2">gacha</label>
                                                                <input onChange={onChangeToDate}
                                                                       className="form-control"
                                                                       id="date2" type="date"/>
                                                            </div>
                                                        </div>

                                                        <table className="table custom-table table-hover">
                                                            <thead className="table-dark">
                                                            <tr>
                                                                <th scope="col">#</th>
                                                                <th scope="col">ID</th>
                                                                <th scope="col">Mijoz</th>
                                                                <th scope="col">Haydovchi</th>
                                                                <th scope="col">Qatnov(reys)</th>
                                                                <th scope="col">Narxi</th>
                                                                <th scope="col">Sana</th>
                                                                <th scope="col">Tahrirlash</th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            {
                                                                productDeliveryItems?.map((item, index) => (
                                                                    <tr key={index}>
                                                                        <th scope="row">{index + 1}</th>
                                                                        <th>{item.id}</th>
                                                                        <td>
                                                                            {item.client?.name}
                                                                        </td>
                                                                        <td>
                                                                            {item.driver?.first_name} {item.driver?.last_name}
                                                                        </td>
                                                                        <td>
                                                                            {item.round}
                                                                        </td>
                                                                        <td>
                                                                            <CurrencyFormat
                                                                                value={item.price}
                                                                                displayType={'text'}
                                                                                thousandSeparator={true}
                                                                                suffix={"  so'm"}/>
                                                                        </td>
                                                                        <td>{moment(item.created_at).format('YYYY-MM-DD')}</td>
                                                                        <td>
                                                                            <div className="actions">
                                                                                <Edit2
                                                                                    onClick={() => setUpdateDelivery(item.id)}
                                                                                />

                                                                                <Trash2
                                                                                    onClick={() => setDeleteDelivery(item.id)}
                                                                                />
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                ))
                                                            }
                                                            </tbody>
                                                        </table>

                                                    </div>

                                                </Tab.Pane>

                                            </Tab.Content>
                                        </Col>
                                    </Row>
                                </Tab.Container>
                            </div>
                        </div>
                    </div>

                </div>


                <TransportProductDeliveryCreateForm
                    transport_id={transport?.id}
                    show={show}
                    handleShow={handleShow}
                    handleClose={handleClose}
                />

                <TransportProductDeliveryUpdateForm transport_id={transport?.id}/>

                <TransportProductDeliveryDeleteForm transport_id={transport?.id}/>

            </main>
        </div>
    );
};

export default TransportPage;