import React, {Fragment, useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import {addSparePart, clearErrors, getUnitOptions, getWarehouseOptions} from "../../actions/spare_part";
import Select from "react-select";
import {getSelectedOption} from "../../utils/hooks/getParams";
import {getFirmOptions} from "../../actions/user";

const SparePartCreateForm = ({
                                 spare_part: {error, loading, spare_parts, unit_options},
                                 addSparePart,
                                 firm_options,
                                 warehouse_options,
                                 handleShow,
                                 getWarehouseOptions,
                                 getUnitOptions,
                                 handleClose,
                                 clearErrors,
                                 show
                             }) => {

    useEffect(() => {
        getFirmOptions();
        getUnitOptions();
    }, [getFirmOptions, getUnitOptions]);

    const formRef = useRef();


    const [firmOptions, setFirmOptions] = useState([]);
    const [warehouseOptions, setWarehouseOptions] = useState([]);

    useEffect(() => {
        let items = [];
        firm_options.forEach((item) => items.push({
            label: `${item.name}`,
            value: item.id
        }));
        setFirmOptions(items);

    }, [firm_options]);


    useEffect(() => {
        let options = [];
        warehouse_options.forEach((item) => options.push({
            label: item.name,
            value: item.id
        }));
        setWarehouseOptions(options);
    }, [warehouse_options]);


    const defaultValues = {
        name: '',
        quantity: '',
        price: '',
        firm_id: '',
        warehouse_id: '',
        unit_id: '',
    };

    const [inputs, setInputs] = useState(defaultValues);
    const [errors, setErrors] = useState({});


    const handleChange = e => {
        setErrors({});
        e.target.oldValue = e.target.value;
        setInputs(prevState => ({...prevState, [e.target.name]: e.target.value}));
    };


    const handleSelect = (data, type) => {
        setErrors({});

        if (type.name === 'firm_id') {
            getWarehouseOptions({firm_id: data.value});
        }
        setInputs(prevState => ({...prevState, [type.name]: data.value}))
    };


    useEffect(() => {
        if (error && loading === false) {
            const errorsItems = error?.errors;
            let errorObject = {};
            if (error?.errors) {
                Object.keys(error?.errors)?.forEach((item, index) => errorObject[item] = errorsItems[item][0]);
                setErrors(errorObject);
            }
        }
    }, [error]);


    const handleSubmit = (event) => {
        event.preventDefault();
        const data = {
            name: inputs.name,
            firm_id: Number(inputs.firm_id),
            warehouse_id: Number(inputs.warehouse_id),
            quantity: inputs.quantity,
            price: inputs.price,
            unit_id: inputs.unit_id,
        };
        addSparePart(data);
    };

    useEffect(() => {
        clearErrors();
        setErrors({});
        setInputs(defaultValues);
    }, [spare_parts]);

    const onClose = () => {
        clearErrors();
        setInputs(defaultValues);
        setErrors({});
        handleClose();
    };

    return (
        <Fragment>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Yangi ehtiyot qismi qo'shish</Modal.Title>
                </Modal.Header>

                <Modal.Body className="pt-3 pb-4 mx-3">

                    <form onSubmit={handleSubmit} ref={formRef}>


                        <div className="mb-3">
                            <label htmlFor="name">Nomi</label>
                            <input
                                name="name"
                                className={'form-control'}
                                type="text"
                                value={inputs?.name || ''}
                                placeholder={'kiriting'}
                                onChange={handleChange}
                                onKeyUp={e => e.target.oldValue = e.target.value}
                            />

                            <div className="error">
                                {errors?.name}
                            </div>
                        </div>


                        <div className="mb-3">
                            <label htmlFor="firm_id">Firma</label>
                            <Select
                                name={'firm_id'}
                                options={firmOptions}
                                value={getSelectedOption(inputs?.firm_id, firmOptions)}
                                onChange={handleSelect}
                                defaultValue={{label: 'Tanlang', value: ''}}
                            />
                            <div className="error">
                                {errors?.firm_id}
                            </div>
                        </div>

                        <div className="mb-3">
                            <label htmlFor="firm_id">Sklad</label>
                            <Select
                                name={'warehouse_id'}
                                options={warehouseOptions}
                                value={getSelectedOption(inputs?.warehouse_id, warehouseOptions)}
                                onChange={handleSelect}
                                defaultValue={{label: 'Tanlang', value: ''}}
                            />
                            <div className="error">
                                {errors?.warehouse_id}
                            </div>
                        </div>


                        <div className="mb-3">
                            <label htmlFor="quantity">Miqdori</label>
                            <input
                                name="quantity"
                                className={'form-control'}
                                type="number"
                                value={inputs?.quantity || ''}
                                placeholder={'kiriting'}
                                onChange={handleChange}
                                onKeyUp={e => e.target.oldValue = e.target.value}
                            />

                            <div className="error">
                                {errors?.quantity}
                            </div>
                        </div>

                        <div className="mb-3">
                            <label htmlFor="price">Narxi</label>
                            <input
                                name="price"
                                className={'form-control'}
                                type="number"
                                value={inputs?.price || ''}
                                placeholder={'kiriting'}
                                onChange={handleChange}
                                onKeyUp={e => e.target.oldValue = e.target.value}
                            />

                            <div className="error">
                                {errors?.price}
                            </div>
                        </div>

                        <div className="mb-3">
                            <label htmlFor="unit_id">Kirim o'lchov birligi</label>
                            <select name="unit_id"
                                    value={inputs?.unit_id || ''}
                                    id="unit_id"
                                    className="form-control"
                                    onChange={handleChange}
                                    onKeyUp={e => e.target.oldValue = e.target.value}
                            >
                                <option value="">Tanlang</option>
                                {
                                    unit_options.map((item, index) => (
                                        <option key={index} value={item.id}>{item.name}</option>
                                    ))
                                }
                            </select>
                            <div className="error">
                                {errors?.unit_id}
                            </div>
                        </div>


                        <div className="d-flex gap-2">
                            <Button className="w-50" variant="secondary" type="button" onClick={onClose}>Bekor
                                qilish</Button>
                            <Button className="w-50" variant="primary" type="submit">Saqlash</Button>
                        </div>
                    </form>
                </Modal.Body>

            </Modal>
        </Fragment>
    );
};

SparePartCreateForm.propTypes = {
    addSparePart: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    spare_part: state.spare_part,
    unit_options: state.spare_part.unit_options,
    firm_options: state.user.firm_options,
    warehouse_options: state.spare_part.warehouse_options,
});


export default connect(mapStateToProps, {
    addSparePart,
    clearErrors,
    getFirmOptions,
    getUnitOptions,
    getWarehouseOptions
})(SparePartCreateForm);
