import axios from 'axios';
import {clearAlerts, setAlert} from './alert';
import {
    ADD_MATERIAL_SALE,
    CLEAR_ERRORS, GET_CLIENT_OPTIONS, GET_MATERIAL_SALE_OPTIONS, GET_MATERIAL_SALES,
    GET_PRODUCT_CLIENT_AND_SELLER_ID_DATA,
    GET_SALE,
    GET_SPECIAL_CLIENT, REMOVE_DELETE_MATERIAL_SALE,
    REMOVE_UPDATE_MATERIAL_SALE,
    REMOVE_UPDATE_SALE,
    SALE_ERROR, SET_DELETE_MATERIAL_SALE_ID,
    SET_LOADING, SET_UPDATE_MATERIAL_SALE_ID,

} from './types';

export const getProductClientSellerIdData = () => async (dispatch) => {

    try {
        const res = await axios.get(`/products?is_all=true`);
        const res2 = await axios.get(`/clients?is_all=true`);
        const res3 = await axios.get(`/users?is_all=true`);

        dispatch({
            type: GET_PRODUCT_CLIENT_AND_SELLER_ID_DATA,
            payload: {
                product_ID_DATA: res.data.data,
                client_ID_DATA: res2.data.data,
                seller_ID_DATA: res3.data.data,
            },
        });

    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
    }
};

export const getMaterialOptions = (filters = {}) => async (dispatch) => {
    let queryParams = '';
    if (filters) {
        Object.keys(filters).map((key => queryParams += `&${key}=${filters[key]}`));
    }
    try {
        const res = await axios.get(`/materials?is_all=true${queryParams}`);
        dispatch({
            type: GET_MATERIAL_SALE_OPTIONS,
            payload: res.data,
        });

    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
    }
};

export const getClientOptions = (filters = {}) => async (dispatch) => {
    try {
        let queryParams = '';
        if (filters) {
            Object.keys(filters).map((key) => queryParams += `&${key}=${filters[key]}`);
        }
        const res = await axios.get(`/clients?is_all=true${queryParams}`);
        dispatch({
            type: GET_CLIENT_OPTIONS,
            payload: res.data,
        });

    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
    }
};

export const getSale = (saleID) => async (dispatch) => {

    try {
        const res = await axios.get(`/sales/${saleID}`);
        dispatch({
            type: GET_SALE,
            payload: res.data,
        });

    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
    }
};

export const getSpecialClientMaterialTransactions = (id) => async (dispatch) => {

    try {
        const res = await axios.get(`/clients/${id}?is_special_client=true`);
        dispatch({
            type: GET_SPECIAL_CLIENT,
            payload: res.data,
        });

    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
    }
};

export const getMaterialSales = (page = 1, filters = {}) => async (dispatch) => {
    dispatch({type: SET_LOADING, payload: true})
    try {

        let queryParams = '';
        if (filters) {
            Object.keys(filters).map((key) => queryParams += `&${key}=${filters[key]}`);
        }
        const res = await axios.get(`/material-sales?page=${page}${queryParams}`);

        dispatch({
            type: GET_MATERIAL_SALES,
            payload: res.data,
        });
        dispatch({type: SET_LOADING, payload: false})
    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
    }
};

export const addMaterialSale = (formData) => async (dispatch) => {
    dispatch(clearAlerts());
    dispatch({type: SET_LOADING, payload: true});

    const config = {
        headers: {
            'Content-Type': 'application/json',
        },
    };

    try {
        let url = '/material-sales/';

        const res = await axios.post(url, formData, config);
        console.log(res)


        dispatch({
            type: ADD_MATERIAL_SALE,
            payload: res.data,
        });

        dispatch(getMaterialSales());

        dispatch(setAlert('Sotish yaratildi !', 'success'));

        return true;
    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));

        if (err?.response?.data?.errors) {
            dispatch({
                type: SALE_ERROR,
                payload: err.response.data,
            });
            return err.response.data;
        } else {
            dispatch(setAlert("Sotish yaratilmadi !", 'danger'));
        }
    }
};

export const deleteMaterialSale = (deleteSaleID, getPage) => async (dispatch) => {
    dispatch(clearAlerts());

    const config = {
        headers: {
            'Content-Type': 'application/json',
        },
    };

    try {
        const res = await axios.delete(`/material-sales/${deleteSaleID}`, config);

        if (res.status === 200) {
            dispatch({
                type: REMOVE_DELETE_MATERIAL_SALE
            });

            dispatch(getMaterialSales(getPage));

            dispatch(setAlert("Sotish o'chirildi !", 'success'));

            dispatch(clearErrors());
            return true;
        }
        return false;
    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
        return false;
    }
};

export const updateMaterialSale = (updateSaleID, updateformData, getPage) => async (dispatch) => {
    dispatch(clearAlerts());
    dispatch({type: SET_LOADING, payload: true});

    const config = {
        headers: {
            'Content-Type': 'application/json',
        },
    };

    try {
        const res = await axios.put(`/material-sales/${updateSaleID}`, updateformData, config);
        if (res.status === 200) {
            dispatch({type: REMOVE_UPDATE_MATERIAL_SALE});

            dispatch(getMaterialSales(getPage));

            dispatch(setAlert('Material sotish tahrirlandi !', 'success'));
        }

        return true;
    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
        dispatch({
            type: SALE_ERROR,
            payload: {msg: err.response.statusText, status: err.response.status},
        });
    }
};

export const setDeleteMaterialSaleId = (saleID) => async (dispatch) => {

    try {
        if (saleID !== null) {
            dispatch({
                type: SET_DELETE_MATERIAL_SALE_ID,
                payload: {
                    saleID
                }
            });

        } else {
            dispatch({
                type: REMOVE_DELETE_MATERIAL_SALE
            });
        }

    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
    }
};

export const setUpdateMaterialSaleId = (saleID) => async (dispatch) => {

    try {
        if (saleID !== null) {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                },
            };
            const res = await axios.get(`/material-sales/${saleID}`, config);

            dispatch({
                type: SET_UPDATE_MATERIAL_SALE_ID,
                payload: res.data.data
            });
        } else dispatch({type: REMOVE_UPDATE_SALE});

    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
    }
};


export const clearErrors = () => async (dispatch) => {
    dispatch({
        type: CLEAR_ERRORS,
    });
};







