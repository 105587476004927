import axios from 'axios';
import {clearAlerts, setAlert} from './alert';
import {
    ADD_WAREHOUSE,
    CLEAR_ERRORS,
    GET_AVAILABLE_STROKEEPER_USER,
    GET_FILTERED_WAREHOUSE_THINGS,
    GET_WAREHOUSE,
    GET_WAREHOUSES,
    REMOVE_DELETE_WAREHOUSE,
    REMOVE_UPDATE_WAREHOUSE,
    SET_DELETE_WAREHOUSE_ID, SET_LOADING,
    SET_UPDATE_WAREHOUSE_ID,
    WAREHOUSE_ERROR
} from './types';
import {request} from "../utils/service";

export const getAvailableStorekeeperUsers = (filters = {}) => async (dispatch) => {
    let queryParams = '';
    if (filters) {
        Object.keys(filters).map((key, index) => queryParams += `&${key}=${filters[key]}`);
    }
    try {

        const res = await axios.get(`/users/?${queryParams}`);
        const res2 = await axios.get('/firms/');

        dispatch({
            type: GET_AVAILABLE_STROKEEPER_USER,
            payload: {
                available_warehouse_users: res.data.data,
                available_warehouse_firms: res2.data.data,
            },
        });

    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
    }
};

export const getWarehouse = (warehouseID) => async (dispatch) => {
    try {
        const res = await axios.get(`/warehouses/${warehouseID}`);
        dispatch({
            type: GET_WAREHOUSE,
            payload: res.data,
        });
    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
    }
};

export const getWarehouses = (page = 1, filters = {}) => async (dispatch) => {
    dispatch({type: SET_LOADING, payload: true});
    let queryParams = '';
    if (filters) {
        Object.keys(filters).map((key, index) => queryParams += `&${key}=${filters[key]}`);
    }

    const res = await request(`/warehouses?page=${page}${queryParams}`, {METHOD: 'GET'}, dispatch);


    if (res?.hasOwnProperty('data')) {

        dispatch({
            type: GET_WAREHOUSES,
            payload: res,
        });
        dispatch({type: SET_LOADING, payload: false});
    } else {
        dispatch(setAlert(res?.response.statusText, 'danger'));
    }
};

export const getFilteredWarehouseThings = (id, type, from_date = '', to_date = '') => async (dispatch) => {
    try {
        let queryParams = '';

        if (from_date !== '' && to_date !== '') {
            queryParams = `?${type}s_from_date=${from_date}&transactions_to_date=${to_date}`;
        } else {
            if (from_date !== '') {
                queryParams = `?${type}s_from_date=${from_date}`;
            } else {
                queryParams = `?${type}s_to_date=${to_date}`;
            }
        }

        const res = await axios.get(`/warehouses/${id}/${queryParams}`);
        dispatch({
            type: GET_FILTERED_WAREHOUSE_THINGS,
            payload: res.data,
        });

    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
    }
};

export const addWarehouse = (formData, filters = {}) => async (dispatch) => {
    dispatch(clearAlerts());

    const config = {
        headers: {
            'Content-Type': 'application/json',
        },
    };

    try {
        const res = await axios.post('/warehouses/', formData, config);

        dispatch(getWarehouses(filters.page, filters));

        dispatch({
            type: ADD_WAREHOUSE,
            payload: res.data,
        });

        dispatch(setAlert('Sklad yaratildi !', 'success'));
        dispatch(getAvailableStorekeeperUsers())
    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));

        if (err?.response?.data?.errors) {
            dispatch({
                type: WAREHOUSE_ERROR,
                payload: err.response.data,
            });
        } else {
            dispatch(setAlert("Sklad yaratilmadi !", 'danger'));
        }
    }
};

export const deleteWarehouse = (deleteWarehouseID, getPage) => async (dispatch) => {
    dispatch(clearAlerts());

    const config = {
        headers: {
            'Content-Type': 'application/json',
        },
    };

    try {
        const res = await axios.delete(`/warehouses/${deleteWarehouseID}`, config);

        dispatch({
            type: REMOVE_DELETE_WAREHOUSE
        });

        dispatch(getWarehouses(getPage));

        dispatch(setAlert("Sklad o'chirildi !", 'success'));

        dispatch(clearErrors());

    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
    }
};

export const updateWarehouse = (updateWarehouseID, updateformData, getPage) => async (dispatch) => {
    dispatch(clearAlerts());

    const config = {
        headers: {
            'Content-Type': 'application/json',
        },
    };

    try {
        const res = await axios.put(`/warehouses/${updateWarehouseID}`, updateformData, config);

        dispatch({type: REMOVE_UPDATE_WAREHOUSE});

        dispatch(getWarehouses(getPage));

        dispatch(setAlert('Sklad tahrirlandi !', 'success'));
    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
    }
};

export const setDeleteWarehouseId = (warehouseID) => async (dispatch) => {

    try {
        if (warehouseID !== null) {
            dispatch({
                type: SET_DELETE_WAREHOUSE_ID,
                payload: {
                    warehouseID
                }
            });

        } else {
            dispatch({
                type: REMOVE_DELETE_WAREHOUSE
            });
        }

    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
    }
};

export const setUpdateWarehouseId = (warehouseID) => async (dispatch) => {

    try {
        if (warehouseID !== null) {
            dispatch({
                type: SET_UPDATE_WAREHOUSE_ID,
                payload: {
                    warehouseID
                }
            });
        } else dispatch({type: REMOVE_UPDATE_WAREHOUSE});

    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
    }
};


export const clearErrors = () => async (dispatch) => {
    dispatch({
        type: CLEAR_ERRORS,
    });
};








