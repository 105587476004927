import React, { useEffect, useRef, useState} from 'react';
import Chart from 'chart.js/auto';

const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
];

const MonthlyBarChart = (props) => {

    const {metrics} = props;
    const chartRef = useRef();
    const [chartObject, setChartObject] = useState(null);
    const [labels, setLabels] = useState(months);

    useEffect(() => {

        const ctx = chartRef.current.getContext("2d");

        if (!chartObject) {
            setChartObject(new Chart(ctx, {
                type: "bar",
                data: {
                },
                options: {
                    maintainAspectRatio: false,
                    legend: {
                        display: false
                    },
                    scales: {
                    }
                }
            }));
        }


    }, [])


    useEffect(() => {
        if (metrics) {
            const dataItems = props.metrics?.map((metric) => metric.sale_quantity);
            setLabels(months);
            const warningColor = '#fcb92c';
            if (chartObject) {
                chartObject.data = {
                    labels: labels,
                    datasets: [{
                        data: dataItems,
                        label: (new Date()).getFullYear(),
                        backgroundColor: warningColor,
                        borderWidth: 5
                    }]
                };
                chartObject.update();
            }
        }
    }, [metrics, chartObject])

    return (
        <div className="monthly_statistics">
            <canvas
                style={styles}
                id="monthly_statistics"
                ref={chartRef}
            />
        </div>
    )

}

const styles = {
    display: 'block', width: '450px', height: '350px'
}
export default MonthlyBarChart;