import {
    CLEAR_ERRORS,
    GET_SUPPLIER_TRANSACTIONS, GET_SUPPLIERS_TRANSACTIONS, SET_LOADING,
    SUPPLIER_TRANSACTION_ERROR, UNSET_LOADING
} from '../actions/types';

const initialState = {
    supplier_transactions: [],
    dates: [],

    suppliers_transactions: [],
    suppliers_transaction: {},

    suppliers_DATA: [],
    filtered_suppliers_transactions: [],
    update_suppliers_transaction: null,
    update_suppliers_transaction_for_supplier: null,
    delete_suppliers_transaction: null,
    loading: true,
    numberOfPages: 1,
    total_suppliers_transactions: null,
    total_suppliers: null,
    error: null,
};

// eslint-disable-next-line

export default function (state = initialState, action) {
    const {type, payload} = action;

    switch (type) {
        case SET_LOADING:
            return {
                ...state,
                loading: true,
            }
        case UNSET_LOADING:
            return {
                ...state,
                loading: false,
            }
        case GET_SUPPLIERS_TRANSACTIONS:

            return {
                ...state,
                dates: payload.dates,
                supplier_transactions: payload.suppliers.data,
                numberOfPages: payload.suppliers.last_page,
                current_page: payload.suppliers.current_page,
                suppliers_data: payload,
                error: null,
                loading: false,
            };

        case GET_SUPPLIER_TRANSACTIONS:
            let new_state = [...state.supplier_transactions];
            const {id} = payload;
            const item = state.supplier_transactions.find(item => item.id === id);
            if (!item) {
                new_state.push(payload)
            }else{
                new_state = state.supplier_transactions.map(item => item.id === id ? payload : item)
            }
            return {
                ...state,
                supplier_transactions: new_state,
                error: null,
                loading: false,
            };
        case SUPPLIER_TRANSACTION_ERROR:
            console.log(payload)
            return {
                ...state,
                error: payload,
                loading: false,
            };

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
                loading: false,
            };

        default:
            return state;
    }
}
