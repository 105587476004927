import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {
    setDeleteExpenseTemplateId,
    setUpdateExpenseTemplateId
} from "../../actions/expense_template";

import {getExpenseTemplateType} from "../../utils/hooks/getParams";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'react-bootstrap';

const ExpenseTemplateItem = ({
                                 auth,
                                 setDeleteExpenseTemplateId,
                                 setUpdateExpenseTemplateId,
                                 index,
                                 expense_template: {
                                     id, name, category, type,color,
                                 },
                             }) => {

    const {role_name} = auth?.user || {};

    const setDeleteExpenseTemplate = (id) => {
        setDeleteExpenseTemplateId(id);
    };

    const setUpdateExpenseTemplate = (id) => {
        setUpdateExpenseTemplateId(id);
    };

    return (
        <Fragment>
            <tr>
                <th scope="row">{index}</th>
                <td>{name}</td>
                <td>{category}</td>
                <td style={{backgroundColor: color ?? '#000'}}>{color}</td>
                <td>{getExpenseTemplateType(type)}</td>
                <td>
                    <div className="d-flex gap-2">
                        {
                            role_name === 'superadmin' || role_name === 'director' || role_name === 'accountant' || role_name === 'manager' ? (
                                <Button
                                    variant="outline-primary"
                                    size="sm"
                                    className="d-flex align-items-center justify-content-center"
                                    style={{ width: '32px', height: '32px', padding: 0 }}
                                    onClick={() => setUpdateExpenseTemplate(id)}
                                >
                                    <FontAwesomeIcon icon={faEdit} />
                                </Button>
                            ) : ''
                        }
                        {
                            role_name === 'superadmin' || role_name === 'director' || role_name === 'accountant' ? (
                                <Button
                                    variant="outline-danger"
                                    size="sm"
                                    className="d-flex align-items-center justify-content-center"
                                    style={{ width: '32px', height: '32px', padding: 0 }}
                                    onClick={() => setDeleteExpenseTemplate(id)}
                                >
                                    <FontAwesomeIcon icon={faTrash} />
                                </Button>
                            ) : ''
                        }
                    </div>
                </td>
            </tr>
        </Fragment>
    );
};

ExpenseTemplateItem.defaultProps = {
    showActions: true,
};

ExpenseTemplateItem.propTypes = {
    expense_template: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
});

export default connect(mapStateToProps, {setDeleteExpenseTemplateId, setUpdateExpenseTemplateId})(
    ExpenseTemplateItem
);
