import React, {Fragment, useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Spinner from '../layout/Spinner';
import Table from 'react-bootstrap/Table';
import CustomPagination from "../layout/CustomPagination";
import ProductDeliveryDeleteForm from "./ProductDeliveryDeleteForm";
import ProductDeliveryUpdateForm from "./ProductDeliveryUpdateForm";
import {getProductDeliveries} from "../../actions/product-delivery";
import ProductDeliveryCreateForm from "./ProductDeliveryCreateForm";
import ProductDeliveryItem from "./ProductDeliveryItem";
import SearchForm from "../layout/SearchForm";

const ProductDeliveries = ({getProductDeliveries, product_delivery}) => {

    const {product_deliveries, loading, numberOfPages, currentPage, total_product_deliveries} = product_delivery;

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return loading ? (
        <Spinner/>
    ) : (
        <Fragment>
            <div className="container-fluid p-0">

                <div className="row mb-2">
                    <div className="col-md-12 col-lg-8">
                        <h1 className="h3 mb-3"><strong></strong> mahsulotlarni yetkazib berish
                            ({total_product_deliveries}) </h1>
                    </div>
                </div>

                <div className="row">
                    <SearchForm getPageItems={getProductDeliveries}/>
                    <div className="col-md-6">
                        <div className="crud_actions text-end">
                            <button className="btn btn-primary" onClick={handleShow}>
                                Delivery
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                     fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                     strokeLinejoin="round" className="feather feather-plus align-middle mr-3">
                                    <line x1="12" y1="5" x2="12" y2="19"></line>
                                    <line x1="5" y1="12" x2="19" y2="12"></line>
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>

                <div className="row mt-2">
                    <div className="col-xl-12 col-xxl-12 d-flex">

                        <div className="card w-100 table-responsive">
                            <Table responsive="sm">

                            </Table>
                            <table className="table custom-table table-hover">
                                <thead className="table-light">
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Transport</th>
                                    <th scope="col">Mijoz</th>
                                    <th scope="col">Haydovchi</th>
                                    <th scope="col">Qatnov(reys)</th>
                                    <th scope="col">Narxi</th>
                                    <th scope="col">Amallar</th>
                                </tr>
                                </thead>
                                <tbody>
                                {product_deliveries.map((item, index) => (
                                    <ProductDeliveryItem key={`productdelivery${item.id}`} product_delivery={item}
                                                         index={index}/>
                                ))}
                                </tbody>
                            </table>

                        </div>

                    </div>

                </div>

                <div className="row">
                    <div className="col-xl-12 col-xxl-12 d-flex">
                        <CustomPagination
                            numberOfPages={numberOfPages}
                            getPageItems={getProductDeliveries}
                            current_page={currentPage}
                        />
                    </div>
                </div>

            </div>


            <ProductDeliveryCreateForm show={show} handleShow={handleShow} handleClose={handleClose}/>

            <ProductDeliveryDeleteForm/>

            <ProductDeliveryUpdateForm/>


        </Fragment>
    );
};


ProductDeliveries.propTypes = {
    getProductDeliveries: PropTypes.func.isRequired,
    product_delivery: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    product_delivery: state.product_delivery,
});

export default connect(mapStateToProps, {getProductDeliveries})(ProductDeliveries);
