import React from 'react';
import Modal from 'react-bootstrap/Modal'
import {useDispatch, useSelector} from "react-redux";
import {deleteUnitAlternative, setDeleteUnitAlternativeId} from "../../actions/unitalternative";

const UnitAlternativeDeleteForm = () => {

    const dispatch = useDispatch();

    const {delete_unitAlternative} = useSelector(state => state.unitalternative);

    const closeModal = () =>{
        // removing unitAlternative from redux state by sending null setDeleteUnitAlternativeId
        dispatch(setDeleteUnitAlternativeId(null))
    };

    const onConfirm = () =>{
        dispatch(deleteUnitAlternative(delete_unitAlternative.id))
    };

    let condition = false;
    if(delete_unitAlternative !== null)
        condition = true;

    return (
        <div>
            <Modal show={condition} onHide={closeModal}>
                <Modal.Header className="bg-danger" closeButton>
                    <Modal.Title className="text-white" >O'lchov birligini o'chirish</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Siz haqiqatdan ham ushbu o'lchov birligini o'chirmoqchimisiz ?
                </Modal.Body>
                <Modal.Footer>
                    <button onClick={closeModal} className="btn btn-secondary">YO'Q</button>
                    <button onClick={onConfirm} className="btn btn-primary">HA</button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default UnitAlternativeDeleteForm;