import React, {useEffect, useRef, useState} from 'react';
import Modal from "react-bootstrap/Modal";
import {useDispatch, useSelector} from "react-redux";

import Button from "react-bootstrap/Button";
import {
    setUpdateProductId,
    updateProduct,
    clearErrors,
    getUnitOptions,
    getMaterialOptions,
    getWarehouseOptions
} from "../../actions/product";
import {Multiselect} from "multiselect-react-dropdown";
import Select from "react-select";
import {Accordion} from "react-bootstrap";
import {ArrowLeft, Save, X} from "react-feather";
import {getParams, getSelectedOption} from "../../utils/hooks/getParams";
import {Input} from "reactstrap";
import {isArray} from "chart.js/helpers";
import ButtonLoader from "../layout/ButtonLoader";

const ProductUpdateForm = () => {

    const dispatch = useDispatch();

    const {
        loading,
        error,
        products,
        update_product,
        warehouse_options,
        material_options,
        unit_options
    } = useSelector(state => state.product);
    const {
        firm_options
    } = useSelector(state => state.user);


    useEffect(() => {
        dispatch(getUnitOptions());
        dispatch(getWarehouseOptions())
    }, [getUnitOptions, getMaterialOptions, getWarehouseOptions]);


    useEffect(() => {
        if (update_product) {
            setErrors({});

            if (material_options.length === 0) {
                dispatch(getMaterialOptions({firm_id: update_product.firm_id}))
                dispatch(getWarehouseOptions({firm_id: update_product.firm_id}))
                return;
            }

            setInputs({
                ...update_product,
                description: update_product.description ?? ""
            });

            if (update_product.hasOwnProperty('materials')) {

                setSelectedFirm(getSelectedOption(update_product.firm_id, firmOptions))
                setSelectedWarehouse(getSelectedOption(update_product.warehouse_id, warehouseOptions))

                let ids = update_product.materials.map(item => item.material_id);
                let items = [];
                material_options.filter(item => {
                    if (ids.includes(item.id)) {
                        const selectedSubitem = update_product.materials.find(subItem => subItem.material_id === item.id);
                        if (selectedSubitem)
                            item.unit_value = selectedSubitem.unit_value;
                        else
                            item.unit_value = 0;
                        items.push(item);
                        return item;
                    } else {
                        return item.unit_value = 0;
                    }
                });
                const leftItems = material_options.filter(item => !ids.includes(item.id))
                setMaterialOptions(leftItems);
                setSelectedMaterialOptions(items);
            }

        }
    }, [update_product, material_options])

    const formRef = useRef();

    const defaultValues = {
        name: '',
        is_listed_in_report: true,
        is_listed_in_products_table: true,
        description: '',
        unit_id: '',
        warehouse_id: '',
        materials: []
    };

    const handleSelect = (data, type) => {
        setErrors({});
        if (type.name === 'firm_id') {
            dispatch(getWarehouseOptions({firm_id: data.value}));
            dispatch(getMaterialOptions({firm_id: data.value}))
        }
        setInputs(prevState => ({...prevState, [type.name]: data.value}))
    };

    const [selectedFirm, setSelectedFirm] = useState({label: 'Tanlang', value: ''});
    const [firmOptions, setFirmOptions] = useState([]);
    const [selectedWarehouse, setSelectedWarehouse] = useState({label: 'Tanlang', value: ''});
    const [warehouseOptions, setWarehouseOptions] = useState([]);

    useEffect(() => {
        let items = [];
        firm_options.forEach((item) => items.push({
            label: `${item.name}`,
            value: item.id
        }));
        setFirmOptions(items);

    }, [firm_options]);


    useEffect(() => {
        let options = [];
        warehouse_options.forEach((item) => options.push({
            label: item.name,
            value: item.id
        }));
        setWarehouseOptions(options);
    }, [warehouse_options]);


    const [inputs, setInputs] = useState(defaultValues);
    const [errors, setErrors] = useState({});

    const handleChange = e => {
        setErrors({});
        e.target.oldValue = e.target.value;
        if (e.target.name === 'is_listed_in_report' || e.target.name === 'is_listed_in_products_table'){
            setInputs(prevState => ({...prevState, [e.target.name]: !inputs[e.target.name]}));
        }else{
            setInputs(prevState => ({...prevState, [e.target.name]: e.target.value}));
        }
    };

    const onClose = () => {
        clearErrors();
        setInputs(defaultValues);
        setErrors({});
        closeModal();
    };

    const [materialOptions, setMaterialOptions] = useState(material_options);
    const [selectedDynamicMaterialOptions, setSelectedDynamicMaterialOptions] = useState([]);
    const [selectedMaterialOptions, setSelectedMaterialOptions] = useState(update_product?.materials || []);

    const onSelectMaterial = (data) => {
        setSelectedDynamicMaterialOptions(data);
    };

    const onRemoveMaterial = (data) => {
        setSelectedDynamicMaterialOptions(data)
    };

    const onClickMoveSelectedMaterials = () => {
        if (selectedDynamicMaterialOptions.length > 0) {
            setSelectedMaterialOptions([...selectedMaterialOptions, ...selectedDynamicMaterialOptions]);
            let ids = selectedDynamicMaterialOptions.map(({id}) => id);
            const filteredMaterials = materialOptions.filter((item) => !ids.includes(item.id));
            setMaterialOptions(filteredMaterials);
            setSelectedDynamicMaterialOptions([]);
        }
    };


    const onChangeInput = (e) => {
        let material_id = parseInt(e.target.dataset.id);
        const cleanItems = [];
        const items = selectedMaterialOptions.map((item) => {
            if (item.id === material_id) {
                item[e.target.getAttribute('name')] = e.target.value;
            }
            cleanItems.push({
                id: item.id,
                unit_value: item.unit_value
            });
            return item;
        });
        setSelectedMaterialOptions(items);
        setErrors({});
        setInputs(prevState => ({...prevState, materials: [...cleanItems]}));
    };
    const onClickRemoveFromSelectedMaterials = (id) => {
        const filteredSelectedMaterials = selectedMaterialOptions.filter(item => item.id !== Number(id));
        setSelectedMaterialOptions([...filteredSelectedMaterials]);
        let ids = filteredSelectedMaterials.map(({id}) => id);
        const filteredMaterials = material_options.filter((item) => !ids.includes(item.id));
        setMaterialOptions(filteredMaterials);
        setSelectedDynamicMaterialOptions([]);
    }


    const handleSubmit = async (event) => {
        event.preventDefault();
        const cleanItems = [];

        selectedMaterialOptions.map((item) => {
            cleanItems.push({
                id: item.material_id || item?.id,
                unit_value: parseFloat(item.unit_value)
            });
            return item;
        });
        const data = {
            name: inputs.name,
            is_listed_in_report: inputs.is_listed_in_report,
            is_listed_in_products_table: inputs.is_listed_in_products_table,
            warehouse_id: inputs.warehouse_id,
            unit_id: inputs.unit_id,
            description: inputs.description,
        };
        if (cleanItems.length > 0){
            data.materials = cleanItems;
        }
        const params = getParams(true)
        const is_updated = await dispatch(updateProduct(update_product.id, data, params));
        console.log(is_updated)
        if (is_updated){
            onClose()
            setSelectedDynamicMaterialOptions([]);
        }
    };

    useEffect(() => {
        if (loading === false) {
            if (error) {
                const errorsItems = error?.errors;
                let errorObject = {};
                if (error?.errors) {
                    Object.keys(error?.errors).forEach((item) => {
                        if (item.includes('materials')) {
                            errorObject.unit_value = []
                            errorObject.unit_value[Number(item.replace(/[^0-9]/g, ''))] = errorsItems[item][0];
                        } else {
                            errorObject[item] = errorsItems[item][0];
                        }
                    });
                    setErrors(errorObject);
                }

            } else {
                setSelectedDynamicMaterialOptions([]);
                setSelectedMaterialOptions([]);
                setMaterialOptions([]);
                setMaterialOptions(material_options);
            }
        }
    }, [error, loading, products]);


    const closeModal = () => {
        // removing update_product from redux by sending null to setUpdateProductId
        dispatch(setUpdateProductId(null));
    };

    let condition = false;
    if (update_product !== null)
        condition = true;

    return (
        <div>
            <Modal  size={'lg'} show={condition} onHide={closeModal}>
                <Modal.Header closeButton className="bg-light">
                    <Modal.Title>Mahsulotni tahrirlash</Modal.Title>
                </Modal.Header>
                <Modal.Body className="pt-3 pb-4 mx-3">


                    <form onSubmit={handleSubmit} ref={formRef}>

                        <div className="mb-3">
                            <label htmlFor="name">Mahsulot nomi</label>
                            <input
                                name="name"
                                className={'form-control'}
                                type="text"
                                value={inputs?.name || ''}
                                placeholder={'kiriting'}
                                onChange={handleChange}
                                onKeyUp={e => e.target.oldValue = e.target.value}
                            />

                            <div className="error">
                                <span className="error">
                                    {errors?.name}
                                </span>
                            </div>
                        </div>


                        <div className="mb-3">
                            <label htmlFor="firm_id">Firma</label>
                            <Select
                                name={'firm_id'}
                                options={firmOptions}
                                value={selectedFirm}
                                onChange={handleSelect}
                                defaultValue={{label: 'Tanlang', value: ''}}
                            />
                            <div className="error">
                                {errors?.firm_id}
                            </div>
                        </div>

                        <div className="mb-3">
                            <label htmlFor="firm_id">Sklad</label>
                            <Select
                                name={'warehouse_id'}
                                options={warehouseOptions}
                                value={selectedWarehouse}
                                onChange={handleSelect}
                                defaultValue={{label: 'Tanlang', value: ''}}
                            />
                            <div className="error">
                                {errors?.warehouse_id}
                            </div>
                        </div>


                        <div className="mb-3">
                            <div className="row materials__mover">
                                <div className="col-lg-5 col-md-12">
                                    <label className="form-label mb-2">Tarkibi</label>

                                    <div className="card p-2">
                                        <Accordion>
                                            {
                                                isArray(selectedMaterialOptions) && selectedMaterialOptions.length > 0 ?
                                                    selectedMaterialOptions?.map((item, index) => (
                                                        <Accordion.Item key={index} eventKey={item.id}>
                                                            <Accordion.Header>{item.name} ({item?.quantity} {item?.unit?.symbol})</Accordion.Header>
                                                            <Accordion.Body>

                                                                <div
                                                                    className="input-group mb-1 justify-content-between">
                                                                    <label htmlFor="ID">Hozirgi tan narxi</label>
                                                                    <X className={'pointered-icon'}
                                                                       onClick={() => onClickRemoveFromSelectedMaterials(item.id)}
                                                                       color={'red'}
                                                                    />
                                                                </div>
                                                                <input
                                                                    className="form-control my-2"
                                                                    type="text"
                                                                    name="id"
                                                                    id="net_price"
                                                                    value={item?.net_price || 0}
                                                                    required
                                                                    disabled
                                                                />

                                                                <div className="input-group mb-3">
                                                                    <input type="text" className="form-control"
                                                                           placeholder="Miqdori"
                                                                           name='unit_value'
                                                                           value={item.unit_value}
                                                                           data-id={item?.id}
                                                                           id={`unit_value${item?.id}`}
                                                                           required
                                                                           onChange={onChangeInput}
                                                                    />
                                                                    <span className="input-group-text" id="unit_value">
                                                                        {item.unit_alternative?.symbol}
                                                                    </span>
                                                                </div>
                                                                <span className="error">
                                                                    {errors?.unit_value && errors?.unit_value[index] !== undefined ? errors?.unit_value[index] : ''}
                                                                </span>

                                                            </Accordion.Body>
                                                        </Accordion.Item>
                                                    ))

                                                    :
                                                    <h6 className="text-secondary">
                                                        O'ng tomondan material tanlab qo'shing!
                                                    </h6>
                                            }
                                        </Accordion>
                                    </div>


                                </div>

                                <div className="col-md-12 justify-content-center col-lg-2 text-center">
                                    <br/>
                                    <br/>
                                    <button onClick={onClickMoveSelectedMaterials} type={'button'}
                                            className="btn-primary py-2 px-3">
                                        <ArrowLeft/>
                                    </button>
                                </div>

                                <div className="col-lg-5 col-md-12">

                                    <div className="mt-2">
                                        <label htmlFor="" className="mb-2">Materiallar</label>
                                        <Multiselect
                                            className="form-control mt-2"
                                            options={materialOptions}
                                            displayValue="name"
                                            onSelect={onSelectMaterial}
                                            onRemove={onRemoveMaterial}
                                            selectedValues={selectedDynamicMaterialOptions}
                                            placeholder="Materiallarni tanlang"
                                        />

                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className="mb-3">
                            <label htmlFor="exampleFormControlInput1" className="form-label">Qisqa
                                ma'lumot </label>
                            <textarea className="form-control" value={inputs?.description}
                                      onChange={handleChange} name="description"
                                      id="description"
                                      rows="4"></textarea>
                            <span className="error">
                                {errors?.description}
                            </span>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="unit_id" className="form-label">
                                Mahsulot sarflash o'lchov birligi
                            </label>
                            <select className="form-control" value={inputs?.unit_id}
                                    onChange={handleChange} name="unit_id"
                                    id="unit_id">
                                <option defaultValue={''}>Tanlang</option>
                                {
                                    unit_options && unit_options.map((item, key) => (
                                        <option key={key}
                                                defaultValue={key === 0}
                                                value={item.id}>{item.name}</option>
                                    ))
                                }
                            </select>
                            <span className="error">{errors?.unit_id}</span>
                        </div>


                        <div className="mb-3 form-check">
                            <Input
                                type="checkbox"
                                id={'is_listed_in_report'}
                                value={inputs.is_listed_in_report}
                                checked={inputs.is_listed_in_report}
                                name={"is_listed_in_report"}
                                onChange={handleChange}
                            />
                            <label htmlFor="is_listed_in_report" className="form-label">
                                Otchet jadvalida ko'rsatilsin
                            </label>
                        </div>

                        <div className="mb-3 form-check">
                            <Input
                                type="checkbox"
                                id={'is_listed_in_products_table'}
                                value={inputs?.is_listed_in_products_table}
                                checked={inputs.is_listed_in_products_table}
                                name={"is_listed_in_products_table"}
                                onChange={handleChange}
                            />
                            <label htmlFor="is_listed_in_products_table" className="form-label pl-4 ">
                                Elektron Mahsulotlar jadvalida ko'rinsin
                            </label>
                        </div>


                        <div className="d-flex gap-2">
                            <Button className="w-50" variant="secondary" type="button" onClick={onClose}>Bekor
                                qilish</Button>
                            <Button className="w-50" variant="primary" type="submit">
                                <Save/> Saqlash
                                {loading && <ButtonLoader/>}
                            </Button>
                        </div>

                    </form>

                </Modal.Body>
            </Modal>
        </div>
    );
};

export default ProductUpdateForm;