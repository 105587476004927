// import axios from 'axios';
// import { setAlert } from './alert';
import {
    SHOW_NAVBAR,
    HIDE_NAVBAR,
    HIDE_PROFILE,
    TOGGLE_PROFILE
} from './types';

export const showNavbar = () => ({
    type: SHOW_NAVBAR
});

export const hideNavbar = () => ({
    type: HIDE_NAVBAR
});

export const toggleProfile = () => ({
    type: TOGGLE_PROFILE,
});

export const closeProfile = () => ({
    type: HIDE_PROFILE,
});
