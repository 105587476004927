import {
    ADD_MATERIAL, GET_MATERIALS_OPTIONS,
    MATERIAL_ERROR,
    CLEAR_ERRORS,
    CLEAR_MATERIAL_TRANSACTIONS,
    GET_SUPPLIERS_MATERIALS_TRANSACTIONS,
    SET_LOADING,
    UNSET_LOADING, GET_SUPPLIER_MATERIALS_TRANSACTIONS
} from '../actions/types';

const initialState = {
    suppliers: [],
    material: {},
    material_options: [],
    loading: true,
    numberOfPages: 1,
    total_materials: null,
    error: null,
};

// eslint-disable-next-line
export default function (state = initialState, action) {
    const {type, payload} = action;

    switch (type) {
        case SET_LOADING:
            return {
                ...state,
                loading: true,
            }
        case UNSET_LOADING:
            return {
                ...state,
                loading: false,
            }
        case GET_MATERIALS_OPTIONS:

            return {
                ...state,
                material_options: payload.data,
                loading: false,
            };

        case CLEAR_MATERIAL_TRANSACTIONS:

            return {
                ...state,
                material_options: [],
                loading: false,
            };

        case GET_SUPPLIERS_MATERIALS_TRANSACTIONS:
            return {
                ...state,
                dates: payload.dates,
                suppliers: [...payload.suppliers.data],
                numberOfPages: payload.suppliers.last_page,
                current_page: payload.suppliers.current_page,
                suppliers_data: payload,
                error: null,
                loading: false,
            };

        case GET_SUPPLIER_MATERIALS_TRANSACTIONS:

            return {
                ...state,
                suppliers: state.suppliers.map((item) => item.id === payload.id ? payload : item),
                loading: false,
            };

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            };

        case ADD_MATERIAL:
            return {
                ...state,
                materials: [payload],
                error: null,
                loading: false,
            };

        case MATERIAL_ERROR:
            return {
                ...state,
                error: payload,
                loading: false,
            };

        default:
            return state;
    }
}


