import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {setDeleteMaterialId, setUpdateMaterialId} from "../../actions/material";
import {Link} from 'react-router-dom';
import MoneyFormat from "../layout/MoneyFormat";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash, faEye } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'react-bootstrap';

const MaterialItem = ({
                          auth,
                          setDeleteMaterialId,
                          setUpdateMaterialId,
                          material: {
                              id, name, unit_alternative, unit, in_stock,
                              multiplier,
                              price,
                              firm,
                              warehouse
                          },
                          number
                      }) => {

    const {role_name} = auth?.user || {};

    const setDeleteMaterial = (id) => {
        setDeleteMaterialId(id);
    };

    const setUpdateMaterial = (id) => {
        setUpdateMaterialId(id);
    };

    return (
        <Fragment>
            <tr>
                <th scope="row">{number}</th>
                <td>{name}</td>
                <td>
                    {firm?.name}
                </td>
                <td>
                    <Link to={`/warehouses/${warehouse?.id}`}>{warehouse?.name}</Link>
                </td>
                <td>{in_stock}</td>
                <td>{unit?.name} </td>
                <td>{multiplier} x</td>
                <td> {unit_alternative?.name}</td>
                <td><MoneyFormat value={price}/> so'm</td>
                <td>
                    <div className="d-flex gap-2">
                        <Link to={`materials/${id}`}>
                            <Button
                                variant="outline-secondary"
                                size="sm"
                                className="d-flex align-items-center justify-content-center"
                                style={{ width: '32px', height: '32px', padding: 0 }}
                            >
                                <FontAwesomeIcon icon={faEye} />
                            </Button>
                        </Link>
                        {
                            role_name === 'superadmin' || role_name === 'director' || role_name === 'manager' || role_name === 'storekeeper' ? (
                                <Button
                                    variant="outline-primary"
                                    size="sm"
                                    className="d-flex align-items-center justify-content-center"
                                    style={{ width: '32px', height: '32px', padding: 0 }}
                                    onClick={() => setUpdateMaterial(id)}
                                >
                                    <FontAwesomeIcon icon={faEdit} />
                                </Button>
                            ) : ''
                        }
                        {
                            role_name === 'superadmin' || role_name === 'director' ? (
                                <Button
                                    variant="outline-danger"
                                    size="sm"
                                    className="d-flex align-items-center justify-content-center"
                                    style={{ width: '32px', height: '32px', padding: 0 }}
                                    onClick={() => setDeleteMaterial(id)}
                                >
                                    <FontAwesomeIcon icon={faTrash} />
                                </Button>
                            ) : ''
                        }
                    </div>
                </td>
            </tr>
        </Fragment>
    );
};

MaterialItem.defaultProps = {
    showActions: true,
};

MaterialItem.propTypes = {
    material: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
});

export default connect(mapStateToProps, {setDeleteMaterialId, setUpdateMaterialId})(
    MaterialItem
);
