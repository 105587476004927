import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {getUser, setDeleteUserId, setUpdateUserId} from "../../actions/user";
import {Link} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash, faEye } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'react-bootstrap';

const UserItem = ({
    auth,
    setDeleteUserId,
    setUpdateUserId,
    getUser,
    user,
    showActions,
    number
}) => {
    // Early return if user is not valid
    if (!user || typeof user !== 'object') {
        console.warn('Invalid user data received:', user);
        return null;
    }

    // Check if user is an empty object
    if (Object.keys(user).length === 0) {
        console.warn('Empty user object received');
        return null;
    }

    // Safely destructure user object with default values
    const { 
        id = null,
        name = '',
        email = '',
        role = null,
        firm = null 
    } = user;

    // Early return if required fields are missing
    if (!id) {
        console.warn('User ID is missing:', user);
        return null;
    }

    const role_name = auth?.user?.role_name || '';

    const hasUpdatePermission = ['director', 'superadmin', 'manager'].includes(role_name);
    const hasDeletePermission = ['director', 'superadmin'].includes(role_name);

    const missingDataPlaceholder = 'N/A';

    const renderRoleTitle = () => {
        try {
            // If role is an empty object, return placeholder
            if (role && Object.keys(role).length === 0) {
                return missingDataPlaceholder;
            }

            if (role?.title) {
                return role.title;
            }
            if (typeof role === 'string') {
                return role;
            }
            return missingDataPlaceholder;
        } catch (error) {
            console.error('Error rendering role title:', error);
            return missingDataPlaceholder;
        }
    };

    const renderFirmName = () => {
        try {
            // If firm is an empty object, return placeholder
            if (firm && Object.keys(firm).length === 0) {
                return missingDataPlaceholder;
            }

            if (firm?.name) {
                return firm.name;
            }
            if (typeof firm === 'string') {
                return firm;
            }
            return missingDataPlaceholder;
        } catch (error) {
            console.error('Error rendering firm name:', error);
            return missingDataPlaceholder;
        }
    };

    return (
        <tr>
            <th scope="row">{number || 0}</th>
            <td>{name || missingDataPlaceholder}</td>
            <td>{email || missingDataPlaceholder}</td>
            <td>{renderFirmName()}</td>
            <td>{renderRoleTitle()}</td>
            <td>
                {showActions && (
                    <div className="d-flex gap-2">
                        <Link to={`/users/${id}`}>
                            <Button
                                variant="outline-secondary"
                                size="sm"
                                className="d-flex align-items-center justify-content-center"
                                style={{ width: '32px', height: '32px', padding: 0 }}
                                data-testid="view-icon"
                            >
                                <FontAwesomeIcon icon={faEye} />
                            </Button>
                        </Link>
                        {hasUpdatePermission && (
                            <Button
                                variant="outline-primary"
                                size="sm"
                                className="d-flex align-items-center justify-content-center"
                                style={{ width: '32px', height: '32px', padding: 0 }}
                                onClick={() => setUpdateUserId(id)}
                                data-testid="edit-icon"
                            >
                                <FontAwesomeIcon icon={faEdit} />
                            </Button>
                        )}
                        {hasDeletePermission && (
                            <Button
                                variant="outline-danger"
                                size="sm"
                                className="d-flex align-items-center justify-content-center"
                                style={{ width: '32px', height: '32px', padding: 0 }}
                                onClick={() => setDeleteUserId(id)}
                                data-testid="delete-icon"
                            >
                                <FontAwesomeIcon icon={faTrash} />
                            </Button>
                        )}
                    </div>
                )}
            </td>
        </tr>
    );
};

UserItem.propTypes = {
    user: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        email: PropTypes.string,
        role: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.shape({
                title: PropTypes.string
            })
        ]),
        firm: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.shape({
                name: PropTypes.string
            })
        ])
    }).isRequired,
    number: PropTypes.number,
    showActions: PropTypes.bool,
    auth: PropTypes.object,
    setDeleteUserId: PropTypes.func.isRequired,
    setUpdateUserId: PropTypes.func.isRequired,
    getUser: PropTypes.func.isRequired
};

UserItem.defaultProps = {
    showActions: true
};

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps, {
    setDeleteUserId,
    setUpdateUserId,
    getUser
})(UserItem);
