import React from 'react';
import Modal from 'react-bootstrap/Modal'
import {useSelector, useDispatch} from "react-redux";
import {setDeleteEmployeeId, deleteEmployee} from "../../actions/employee";
import {getParams} from "../../utils/hooks/getParams";

const EmployeeDeleteForm = () => {

    const dispatch = useDispatch();

    const {delete_employee, currentPage, numberOfPages, employees} = useSelector(state => state.employee);

    const closeModal = () => {
        // removing employee user from redux state by sending null setDeleteEmployeeId
        dispatch(setDeleteEmployeeId(null))
    };

    let getPage = null;

    const onConfirm = () => {
        dispatch(deleteEmployee(delete_employee.id, getParams()))
    };

    let condition = false;
    if (delete_employee !== null)
        condition = true;

    return (
        <div>
            <Modal show={condition} onHide={closeModal}>
                <Modal.Header className="bg-danger" closeButton>
                    <Modal.Title className="text-white">Xodimni o'chirish</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Siz haqiqatdan ham ushbu xodimni o'chirmoqchimisiz ?
                </Modal.Body>
                <Modal.Footer>
                    <button onClick={closeModal} className="btn btn-secondary">YO'Q</button>
                    <button onClick={onConfirm} className="btn btn-primary">HA</button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default EmployeeDeleteForm;