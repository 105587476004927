import {
    ADD_EXPENSE_TEMPLATE, CLEAR_ERRORS,
    EXPENSE_TEMPLATE_ERROR,
    GET_EXPENSE_TEMPLATE,
    GET_EXPENSE_TEMPLATES,
    REMOVE_DELETE_EXPENSE_TEMPLATE,
    REMOVE_UPDATE_EXPENSE_TEMPLATE,
    SET_DELETE_EXPENSE_TEMPLATE_ID, SET_LOADING,
    SET_UPDATE_EXPENSE_TEMPLATE_ID
} from '../actions/types';

const initialState = {
    expense_templates: [],
    expense_template: {},
    update_expense_template: null,
    delete_expense_template: null,
    loading: true,
    total_expense_templates: 0,
    numberOfPages: 1,
    error: null,
};

// eslint-disable-next-line

export default function (state = initialState, action) {
    const {type, payload} = action;

    switch (type) {

        case SET_LOADING:
            return {
                ...state,
                loading: payload,
            };
        case GET_EXPENSE_TEMPLATE:
            return {
                ...state,
                expense_template: payload.data,
                loading: false,
            };

        case GET_EXPENSE_TEMPLATES:
            return {
                ...state,
                expense_templates: payload.data,
                numberOfPages: payload.meta.last_page,
                currentPage: payload.meta.current_page,
                total_expense_templates: payload.meta.total,
                error: null,
                loading: false,
            };

        case ADD_EXPENSE_TEMPLATE:
            return {
                ...state,
                expense_templates: [payload],
                error: null,
                loading: false,
            };

        case REMOVE_DELETE_EXPENSE_TEMPLATE:
            return {
                ...state,
                delete_expense_template: null,
            };

        case REMOVE_UPDATE_EXPENSE_TEMPLATE:
            return {
                ...state,
                update_expense_template: null,
            };

        case SET_DELETE_EXPENSE_TEMPLATE_ID:

            return {
                ...state,
                delete_expense_template: state.expense_templates.find((item) => item.id === action.payload.expense_template_ID)
            };

        case SET_UPDATE_EXPENSE_TEMPLATE_ID:
            return {
                ...state,
                update_expense_template: state.expense_templates.find((item) => item.id === action.payload.expense_template_ID)
            };

        case EXPENSE_TEMPLATE_ERROR:
            return {
                ...state,
                error: payload,
                loading: false,
            };

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
                loading: false,
            };

        default:
            return state;
    }
}
