import React, {Fragment, useEffect, useState} from "react";
import {getParams, getSelectedOption} from "../../utils/hooks/getParams";

import {useDispatch} from "react-redux";
import Select from "react-select";
import {getFirmOptions} from "../../actions/user";

const FilterByType = (props) => {

    const dispatch = useDispatch();
    const {getPageItems} = props;

    useEffect(() => {
        dispatch(getFirmOptions());
    }, [])

    const [currentType, setCurrentType] = useState(Number(getParams().type) || '');
    const [isFirst, setIsFirst] = useState(true);

    useEffect(() => {
        const params = getParams();
        if (!isFirst) {
            dispatch(getPageItems(1, params))
        }
        setIsFirst(false);
    }, [currentType])

    const [typeItems] = useState([
        {
            label: 'Hammasi',
            value: ''
        },
        {
            label: "Mahsulot",
            value: "product",
        },
        {
            label: "Material",
            value: "material",
        },
        {
            label: "Zapchast",
            value: "part",
        }
    ]);
    const handleSelect = (data) => {
        setCurrentType(data.value);
        if (data.value !== '') {
            const params = getParams();
            params.firm_id = data.value;
            const url = new URL(window.location);
            url.searchParams.set('page', 1);
            url.searchParams.set('type', data.value);
            window.history.pushState({}, '', url);
        } else {
            const url = new URL(window.location);
            url.searchParams.delete('type');
            window.history.pushState({}, '', url);
        }
    };
    return (
        <Fragment>

            <Select styles={customStyles}
                    value={getSelectedOption(currentType, typeItems)}
                    options={typeItems}
                    onChange={handleSelect}
                    menuPortalTarget={document.body}
                    menuPosition={'fixed'}
            />

        </Fragment>
    )
}
const customStyles = {
    ///.....
    menuPortal: provided => ({...provided, zIndex: 9999}),
    menu: provided => ({...provided, zIndex: 9999})
    ///.....
}
export default FilterByType;