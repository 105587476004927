import React, { useEffect, useRef, useState} from 'react';
import Chart from 'chart.js/auto';

function getExpenseColorByPreset(metrics) {
    const found = metrics.find(item => item.color)
    if (found){
        return found?.color ?? '#000000';
    }
    return '#ffff00';
}


const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
];

const ExpenseMonthlyBarChart = (props) => {

    const { metrics } = props;
    const chartRef = useRef();
    const [chartObject, setChartObject] = useState(null);
    const [labels] = useState(months); // Assuming months is defined elsewhere

    // Function to update the chart with new data
    const updateChart = (chart, datasets) => {
        // Only update if datasets are different or new data is provided
        if (chart.data.datasets !== datasets) {
            chart.data = {
                labels: labels, // X-axis labels
                datasets: datasets
            };
            chart.update(); // Trigger the chart update
        }
    };

    useEffect(() => {
        const ctx = chartRef.current.getContext("2d");

        // Only initialize the chart once
        if (!chartObject) {
            const newChart = new Chart(ctx, {
                type: "bar",
                data: {
                    labels: labels, // Initial empty or static labels
                    datasets: []    // Initial empty datasets
                },
                options: {
                    maintainAspectRatio: false,
                }
            });
            setChartObject(newChart); // Set chart object after initialization
        }
    }, []); // Only run this effect on component mount

    useEffect(() => {
        if (metrics && chartObject) {
            // Ensure we have actual metrics and a valid chartObject
            const datasetItems = [];

            // Iterate over the metrics and build datasets
            Object.keys(metrics).forEach((expense) => {
                const expenseItems = metrics[expense];
                const dataItems = expenseItems?.map((metric) => metric.sum);
                const colors = getExpenseColorByPreset(expenseItems); // Assuming this function exists

                datasetItems.push({
                    data: dataItems,
                    label: expense,
                    backgroundColor: colors,
                    borderWidth: 5
                });
            });

            // Update chart only if datasetItems changes
            updateChart(chartObject, datasetItems);
        }
    }, [metrics, chartObject]); // Only run this effect when metrics or chartObject changes

    return (
        <div className="expense_monthly_statistics">
            <canvas
                style={styles}
                id="expense_monthly_statistics"
                ref={chartRef}
            />
        </div>
    )

}

const styles = {
    display: 'block', width: '450px', height: '420px'
}
export default ExpenseMonthlyBarChart;