import React from 'react';
import Modal from 'react-bootstrap/Modal'
import {useDispatch, useSelector} from "react-redux";
import {deleteSparePart, setDeleteSparePartId} from "../../actions/spare_part";

const SparePartDeleteForm = () => {

    const dispatch = useDispatch();

    const {delete_spare_part, currentPage, numberOfPages, spare_parts} = useSelector(state => state.spare_part);

    const closeModal = () => {
        dispatch(setDeleteSparePartId(null))
    };

    let getPage = null;

    const onConfirm = () => {

        if ((currentPage === numberOfPages) && (spare_parts.length === 1)) {
            getPage = (currentPage - 1);
        } else {
            getPage = currentPage;
        }
        dispatch(deleteSparePart(delete_spare_part.id, getPage))
    };

    let condition = false;
    if (delete_spare_part !== null)
        condition = true;

    return (
        <div>
            <Modal show={condition} onHide={closeModal}>
                <Modal.Header className="bg-danger" closeButton>
                    <Modal.Title className="text-white">Ehtiyot qismini o'chirish</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Siz haqiqatdan ham ushbu ehtiyot qismini o'chirmoqchimisiz ?
                </Modal.Body>
                <Modal.Footer>
                    <button onClick={closeModal} className="btn btn-secondary">YO'Q</button>
                    <button onClick={onConfirm} className="btn btn-primary">HA</button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default SparePartDeleteForm;