import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {setDeleteUnitAlternativeId, setUpdateUnitAlternativeId} from "../../actions/unitalternative";
import {useHistory} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash, faEye } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'react-bootstrap';

const UnitAlternativeItem = ({
                                 auth,
                                 setDeleteUnitAlternativeId,
                                 setUpdateUnitAlternativeId,
                                 unitAlternative: {
                                     id, unit, multiplier,
                                     date: created_at
                                 },
                                 showActions,
                             }) => {

    const history = useHistory();

    const setDeleteUnitAlternative = (id) => {
        setDeleteUnitAlternativeId(id);
    };

    const setUpdateUnitAlternative = (id) => {
        setUpdateUnitAlternativeId(id);
    };

    return (
        <Fragment>
            <tr>
                <th scope="row">{id}</th>
                <td>{unit?.name}</td>
                <td> <strong className="text-danger">x</strong> {multiplier}</td>
                <td>
                    <div className="d-flex gap-2">
                        <Button
                            variant="outline-secondary"
                            size="sm"
                            className="d-flex align-items-center justify-content-center"
                            style={{ width: '32px', height: '32px', padding: 0 }}
                            onClick={() => history.push(`unitalternatives/${id}`)}
                        >
                            <FontAwesomeIcon icon={faEye} />
                        </Button>
                        <Button
                            variant="outline-primary"
                            size="sm"
                            className="d-flex align-items-center justify-content-center"
                            style={{ width: '32px', height: '32px', padding: 0 }}
                            onClick={() => setUpdateUnitAlternative(id)}
                        >
                            <FontAwesomeIcon icon={faEdit} />
                        </Button>
                        <Button
                            variant="outline-danger"
                            size="sm"
                            className="d-flex align-items-center justify-content-center"
                            style={{ width: '32px', height: '32px', padding: 0 }}
                            onClick={() => setDeleteUnitAlternative(id)}
                        >
                            <FontAwesomeIcon icon={faTrash} />
                        </Button>
                    </div>
                </td>
            </tr>
        </Fragment>
    );
};

UnitAlternativeItem.defaultProps = {
    showActions: true,
};

UnitAlternativeItem.propTypes = {
    unitAlternative: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
});

export default connect(mapStateToProps, {setDeleteUnitAlternativeId, setUpdateUnitAlternativeId})(
    UnitAlternativeItem
);
