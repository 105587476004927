import React, {useEffect, useRef, useState} from 'react';
import Modal from "react-bootstrap/Modal";
import {useDispatch, useSelector} from "react-redux";
import {setUpdateWarehouseId, updateWarehouse, clearErrors} from "../../actions/warehouse";
import Select from "react-select";
import Button from "react-bootstrap/Button";

const WarehouseUpdateForm = () => {

    const dispatch = useDispatch();

    const {
        update_warehouse,
        currentPage,
        available_warehouse_users,
        available_warehouse_firms,
    } = useSelector(state => state.warehouse);

    const defaultItem = {label: 'Tanlang', value: '-'}
    const [firmOptions, setFirmOptions] = useState([]);
    const [storekeeperOptions, setStorekeeperOptions] = useState([]);
    const [defaultFirm, setDefaultFirm] = useState({label: 'Tanlang', value: '-'});
    const [defaultStorekeeper, setDefaultStorekeeper] = useState({label: 'Tanlang', value: '-'});

    useEffect(() => {
        let items = [];
        available_warehouse_firms.forEach((item) => items.push({
                label: `${item.name}`,
                value: item.id
            })
        );
        setFirmOptions(items);
    }, [available_warehouse_firms]);

    useEffect(() => {
        let items = [];
        available_warehouse_users.forEach((item) => items.push({
                label: `${item.name}`,
                value: item.id
            })
        );
        setStorekeeperOptions(items);
    }, [available_warehouse_users]);


    useEffect(() => {
        if (update_warehouse) {
            setInputs((prevState) => ({...prevState,
                name: update_warehouse.name,
                firm_id: update_warehouse.firm_id,
                type: update_warehouse.type,
                storekeeper_id: update_warehouse.storekeeper_id
            }));
            if (!update_warehouse.firm_id){
                setDefaultFirm(defaultItem);
            }
            const found_firm = firmOptions.find(item => item.value === update_warehouse.firm_id);
            if (found_firm)
                setDefaultFirm(found_firm);
            if (!update_warehouse.storekeeper_id){
                setDefaultStorekeeper(defaultItem);
            }
            const found_storekeeper = storekeeperOptions.find(item => item.value === update_warehouse.storekeeper_id);
            if (found_storekeeper)
                setDefaultStorekeeper(found_storekeeper);
        }
    }, [update_warehouse]);

    const closeModal = () => {
        dispatch(setUpdateWarehouseId(null));
    };
    const handleChange = e => {
        setErrors({});
        e.target.oldValue = e.target.value;
        setInputs(prevState => ({...prevState, [e.target.name]: e.target.value}));
    };
    const handleSelect = (name, item) => {
        setErrors({});
        if (name === 'storekeeper_id'){
            setDefaultStorekeeper(item);
            setInputs(prevState => ({...prevState, storekeeper_id: item.value}));
        }
        if (name === 'firm_id'){
            setDefaultFirm(item);
            setInputs(prevState => ({...prevState, firm_id: item.value}));
        }
    };
    const onClose = () => {
        clearErrors();
        setInputs(defaultValues);
        setErrors({});
        closeModal();
    };

    const formRef = useRef();

    const defaultValues = {
        name: null,
        firm_id: null,
        storekeeper_id: null,
        type: null
    };

    const [inputs, setInputs] = useState(defaultValues);
    const [errors, setErrors] = useState({});

    const handleSubmit = (event) => {
        event.preventDefault();
        dispatch(updateWarehouse(update_warehouse.id, inputs, currentPage));
    };

    let condition = false;
    if (update_warehouse !== null)
        condition = true;


    return (
        <div>
            <Modal show={condition} onHide={closeModal}>
                <Modal.Header closeButton className="bg-light">
                    <Modal.Title>Skladni tahrirlash</Modal.Title>
                </Modal.Header>
                <Modal.Body className="pt-3 pb-4 mx-3">
                    <form onSubmit={handleSubmit} ref={formRef}>
                        <div className="mb-3">
                            <label htmlFor="name">Nomi</label>
                            <input
                                name="name"
                                className={'form-control'}
                                type="text"
                                value={inputs?.name || ''}
                                placeholder={'kiriting'}
                                onChange={handleChange}
                                onKeyUp={e => e.target.oldValue = e.target.value}
                            />

                            <div className="error">
                                {errors?.name}
                            </div>
                        </div>

                        <div className="mb-2">
                            <label htmlFor="firm_id">Firma</label>
                            <Select
                                name={'storekeeper_id'}
                                options={firmOptions}
                                onChange={(item) => handleSelect('firm_id', item)}
                                value={defaultFirm}
                                defaultValue={{label: 'Tanlang', value: '-'}}
                            />
                        </div>

                        <div className="mb-2">
                            <label htmlFor="storekeeper_id">Skladchi</label>
                            <Select
                                name={'storekeeper_id'}
                                options={storekeeperOptions}
                                onChange={(item) => handleSelect('storekeeper_id', item)}
                                value={defaultStorekeeper}
                                defaultValue={{label: 'Tanlang', value: '-'}}
                            />
                        </div>


                        <div className="d-flex gap-2">
                            <Button className="w-50" variant="secondary" type="button" onClick={onClose}>Bekor
                                qilish</Button>
                            <Button className="w-50" variant="primary" type="submit">Saqlash</Button>
                        </div>
                    </form>


                </Modal.Body>
            </Modal>
        </div>
);
};

export default WarehouseUpdateForm;