import {
    ADD_TRANSPORT,
    AVAILABLE_DRIVER_EMPLOYEE_ERROR,
    CLEAR_ERRORS,
    GET_AVAILABLE_DRIVER_EMPLOYEE,
    GET_FILTERED_TRANSPORT_PRODUCT_DELIVERIES,
    GET_TRANSPORT,
    GET_TRANSPORT_PRODUCT_DELIVERIES,
    GET_TRANSPORTS,
    REMOVE_DELETE_TRANSPORT,
    REMOVE_DELETE_TRANSPORT_PRODUCT_DELIVERY,
    REMOVE_UPDATE_TRANSPORT,
    REMOVE_UPDATE_TRANSPORT_PRODUCT_DELIVERY,
    SET_DELETE_TRANSPORT_ID,
    SET_DELETE_TRANSPORT_PRODUCT_DELIVERY_ID, SET_LOADING,
    SET_UPDATE_TRANSPORT_ID,
    SET_UPDATE_TRANSPORT_PRODUCT_DELIVERY_ID,
    TRANSPORT_ERROR
} from '../actions/types';

const initialState = {
    transports: [],
    client_options: [],
    driver_options: [],
    transport_options: [],
    available_drivers: [],
    update_transport: null,
    delete_transport: null,
    loading: true,
    numberOfPages: 1,
    total_transports: null,
    error: null,
    transport: {},
    filtered_transport_product_delivery_data_date: null,
    transport_product_deliveries: [],
    update_transport_product_delivery: null,
    delete_transport_product_delivery: null
};

// eslint-disable-next-line

export default function (state = initialState, action) {
    const {type, payload} = action;

    switch (type) {

        case SET_LOADING:

            return {
                ...state,
                loading: payload
            };

        case GET_AVAILABLE_DRIVER_EMPLOYEE:

            return {
                ...state,
                available_drivers: payload,
                loading: false
            };

        case AVAILABLE_DRIVER_EMPLOYEE_ERROR:
            return {
                ...state,
                error: payload,
                loading: false,
            };

        case GET_TRANSPORT:
            return {
                ...state,
                transport: payload.data,
                error: null,
                loading: false,
            };

        case GET_TRANSPORTS:
            return {
                ...state,
                transports: payload.data,
                numberOfPages: payload.meta.last_page,
                currentPage: payload.meta.current_page,
                total_transports: payload.meta.total,
                error: null,
                loading: false,
            };

        case ADD_TRANSPORT:
            return {
                ...state,
                transports: [payload],
                error: null,
                loading: false,
            };

        case REMOVE_DELETE_TRANSPORT:
            return {
                ...state,
                delete_transport: null,
            };

        case REMOVE_UPDATE_TRANSPORT:
            return {
                ...state,
                update_transport: null,
            };

        case SET_DELETE_TRANSPORT_ID:

            return {
                ...state,
                delete_transport: state.transports.find((item) => item.id === action.payload.transportID)
            };

        case SET_UPDATE_TRANSPORT_ID:
            return {
                ...state,
                update_transport: state.transports.find((item) => item.id === action.payload.transportID)
            };

        case TRANSPORT_ERROR:
            return {
                ...state,
                error: payload,
                loading: false,
            };

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
                loading: false,
            };


        case GET_TRANSPORT_PRODUCT_DELIVERIES:
            return {
                ...state,
                transport_product_deliveries: payload.data,
                error: null,
                loading: false,
            };

        case GET_FILTERED_TRANSPORT_PRODUCT_DELIVERIES:
            return {
                ...state,
                filtered_transport_product_delivery_data_date: payload.data,
                loading: false,
            };

        case REMOVE_UPDATE_TRANSPORT_PRODUCT_DELIVERY:
            return {
                ...state,
                update_transport_product_delivery: null,
            };

        case REMOVE_DELETE_TRANSPORT_PRODUCT_DELIVERY:
            return {
                ...state,
                delete_transport_product_delivery: null,
            };

        case SET_UPDATE_TRANSPORT_PRODUCT_DELIVERY_ID:
            return {
                ...state,
                update_transport_product_delivery: payload
            };

        case SET_DELETE_TRANSPORT_PRODUCT_DELIVERY_ID:
            return {
                ...state,
                delete_transport_product_delivery: payload
            };

        default:
            return state;
    }
}
