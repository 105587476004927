import React, {Fragment, useEffect, useState} from "react";
import {getParams, formatMoney} from "../../utils/hooks/getParams";
import {useDispatch, useSelector} from "react-redux";
import { updateTransaction} from "../../actions/suppliers_transaction";

const SupplierDataCell = ({index, date, object_id, cellData, refreshRow, rowData}) => {
    const dispatch = useDispatch();
    const [itemData, setDateData] = useState(cellData);
    useEffect(() => {
        setDateData(cellData);
    }, [cellData])
    const {
        auth
    } = useSelector(state => state);
    const {role_name} = auth?.user || {};

    const editTransaction = async (transaction) => {
        return await dispatch(
            updateTransaction(
                transaction, getParams(true)
            )
        );
    };

    const handleKeyDown = async (e, updatedValue) => {

        // if (parseFloat(updatedValue) < 0) {
        //     e.target.classList.add('invalid_cell_value');
        //     return false;
        // } else {
        //     e.target.classList.remove('invalid_cell_value');
        // }
        //
        // if (e.key === 'Enter' || e.key === 'Tab' || e.nativeEvent.key === "NumpadEnter") {
        //     e.preventDefault();
        //     if (e.target.getAttribute('contenteditable') === 'plaintext-only') {
        //
        //         const cell = rowData.find((item) => item.id === e.target.dataset.cell_id);
        //         const edited_cell = {...cell};
        //         if (edited_cell) {
        //             if (e.target.classList.contains('paid_amount')) {
        //                 edited_cell.paid_amount = unformatMoney(updatedValue);
        //             } else {
        //                 edited_cell.received_amount = unformatMoney(updatedValue);
        //             }
        //         }
        //         if (await editTransaction(edited_cell)) {
        //             const params = getParams();
        //             dispatch(getSupplierTransactions(e.target.dataset.row_id, params));
        //             e.target.setAttribute('contenteditable', false);
        //             e.target.classList.remove('editing_cell');
        //             e.target.classList.remove('invalid_cell');
        //         } else {
        //             e.target.setAttribute('contenteditable', 'plaintext-only');
        //             e.target.classList.add('invalid_cell');
        //             e.target.classList.remove('editing_cell');
        //             setTimeout(function () {
        //                 e.target.focus();
        //             }, 500);
        //         }
        //     } else {
        //         e.target.setAttribute('contenteditable', 'plaintext-only');
        //         e.target.classList.remove('invalid_cell');
        //         e.target.classList.add('editing_cell');
        //     }
        // }
        // if (e.key === 'Escape') {
        //     e.preventDefault();
        //     if (e.target.getAttribute('contenteditable') === 'plaintext-only') {
        //         e.target.setAttribute('contenteditable', false);
        //         e.target.classList.remove('editing_cell');
        //         e.target.classList.remove('invalid_cell');
        //         const cell = rowData.find((item) => item.id === e.target.dataset.cell_id);
        //         e.target.textContent = formatMoney(e.target.dataset.old_value)
        //     }
        // }
    }
    //
    const onDoubleClick = async (e, updatedValue) => {

        // if (e.target.getAttribute('contenteditable') === 'plaintext-only') {
        //     const cell = rowData.find((item) => item.id === e.target.dataset.cell_id);
        //     if (cell) {
        //         if (e.target.classList.contains('paid_amount')) {
        //             cell.paid_amount = unformatMoney(updatedValue);
        //         } else {
        //             cell.received_amount = unformatMoney(updatedValue);
        //         }
        //     }
        //
        //     if (await editTransaction(cell)) {
        //         const params = getParams();
        //         dispatch(getSupplierTransactions(e.target.dataset.row_id, params));
        //         e.target.setAttribute('contenteditable', false);
        //         e.target.classList.remove('editing_cell');
        //         e.target.classList.remove('invalid_cell');
        //     } else {
        //         e.target.setAttribute('contenteditable', 'plaintext-only');
        //         e.target.classList.add('invalid_cell');
        //         e.target.classList.remove('editing_cell');
        //         setTimeout(function () {
        //             e.target.focus();
        //         }, 500);
        //     }
        // } else {
        //     e.target.setAttribute('contenteditable', 'plaintext-only');
        //     e.target.classList.remove('invalid_cell');
        //     e.target.classList.add('editing_cell');
        // }
    }

    return (
        <Fragment key={`td${index}`}>
            <td
                className={'cell received_amount'}
            onDoubleClick={(e) => onDoubleClick(e, e.target.textContent)}
            onKeyDown={(e) => handleKeyDown(e, e.target.textContent)}
                data-row_id={object_id} data-cell_id={date}
            >{formatMoney(itemData.received_amount)}</td>
            <td
                className={'cell paid_amount'}
                data-row_id={object_id} data-cell_id={date}
                onDoubleClick={(e) => onDoubleClick(e, e.target.textContent)}
                onKeyDown={(e) => handleKeyDown(e, e.target.textContent)}
            >
                {formatMoney(itemData.paid_amount)}
            </td>
        </Fragment>
    )
}

export default SupplierDataCell;