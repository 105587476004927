import React from "react";
import MoneyFormat from "../../layout/MoneyFormat";
import Moment from "react-moment";
import SingleMaterialReport from "../SingleMaterialReport";

const MaterialAbout = ({material}) => {
    return (
        <>
            <div className="card mb-3">
                <div className="card-header">
                    <h5 className="card-title mb-0">
                        Material ma'lumotlari
                    </h5>
                </div>
                <div className="card-body text-center">
                    <div className="row">
                        <div className="col-md-12">

                            <div className="d-flex align-items-center">
                                <div className="card-title m-2">
                                    Material nomi:
                                </div>
                                <div
                                    className="text-muted m-0">{material?.name}</div>
                            </div>

                            <div className="d-flex align-items-center">
                                <div className="card-title m-2">
                                    Kirim o'lchovi:
                                </div>
                                <div
                                    className="text-muted m-0">{material?.unit?.name}({material?.unit?.symbol})
                                </div>
                            </div>

                            <div className="d-flex align-items-center">
                                <div className="card-title m-2">
                                    Chiqim o'lchovi:
                                </div>
                                <div
                                    className="text-muted m-0">{material?.unit_alternative?.name}({material?.unit_alternative?.symbol})
                                </div>
                            </div>

                            <div className="d-flex align-items-center">
                                <div className="card-title m-2">
                                    Hozirgi holati:
                                </div>
                                <div
                                    className="text-muted m-0">{material?.quantity} {material?.unit?.symbol}</div>
                            </div>

                            <div className="d-flex align-items-center">
                                <div className="card-title m-2">
                                    Kirim va chiqim o'lchov farqi:
                                </div>
                                <div
                                    className="text-muted m-0">
                                    1 {material?.unit?.symbol} = {material?.multiplier} {material?.unit_alternative?.symbol}
                                </div>
                            </div>

                            <div className="d-flex align-items-center">
                                <div className="card-title m-2">
                                    So'ngi narxi:
                                </div>
                                <div
                                    className="text-muted m-0">
                                    1 {material?.unit?.symbol} =
                                    <MoneyFormat
                                        value={` ${material?.price}`}/> so'm
                                </div>
                            </div>

                            <div className="d-flex align-items-center">
                                <div className="card-title m-2">
                                    Oxirgi yangilanish:
                                </div>
                                <div
                                    className="text-muted m-0">
                                    <Moment date={material?.updated_at}
                                            format={'YYYY-MM-DD HH:mm'}/>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div className="card">
                <div className="card-header">Hisobot chiqarish(<b>import va sarf</b>)</div>
                <div className="card-body">
                    <SingleMaterialReport id={material?.id}/>
                </div>
            </div>
        </>
    )
}

export default MaterialAbout;