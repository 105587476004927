import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useParams} from 'react-router-dom';
import {getMaterial} from "../../actions/material";
import GoBackButton from "../layout/GoBackButton";
import {Col, Nav, Row, Tab} from "react-bootstrap";
import MaterialImports from "./tabs/MaterialImports";
import MaterialSpends from "./tabs/MaterialSpends";
import MaterialAbout from "./tabs/MaterialAbout";

const MaterialPage = () => {

    const dispatch = useDispatch();

    const {id} = useParams();

    useEffect(() => {
        let params = {material_id: id};
        dispatch(getMaterial(id));
    }, [getMaterial]);

    const {material} = useSelector(state => state.material);


    return (
        <div>
            <main className="content mt-0 pt-0">
                <div className="container-fluid p-0">

                    <div className="row mb-2">
                        <div className="col-md-12 col-lg-8">
                            <h1 className="h3 mb-3"><strong>Material</strong> sahifasi </h1>
                        </div>
                        <div className="col-lg-4">
                            <div className="crud_actions text-end">
                                <GoBackButton/>
                            </div>
                        </div>
                    </div>

                    {/*<div className="row">*/}

                    {/*    <div className="card p-0">*/}
                    {/*        <div className="card-header">*/}
                    {/*            <h5 className="card-title mb-0">Material ma'umotlari</h5>*/}
                    {/*        </div>*/}
                    {/*        <div className="card-body text-center">*/}
                    {/*            <div className="d-flex align-items-center border-bottom py-2">*/}
                    {/*                <div className="card-title m-0">Nomi:</div>*/}
                    {/*                <div className="text-muted m-0 ms-2">{material.name}</div>*/}
                    {/*            </div>*/}

                    {/*            <div className="d-flex align-items-center border-bottom py-2">*/}
                    {/*                <div className="card-title m-0">Miqdori:</div>*/}
                    {/*                <div className="text-muted m-0 ms-2">{material.quantity}</div>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}

                    {/*</div>*/}

                    {
                        material ? (
                            <div className="row">
                                <div className="col-md-12 col-lg-12">
                                    <div className="card px-4 py-3">
                                        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                                            <Row>
                                                <Col sm={3} md={3}>
                                                    <Nav variant="pills" className="flex-column">
                                                        <Nav.Item>
                                                            <Nav.Link eventKey="first"
                                                                      className="text-center fw-bold">Haqida</Nav.Link>
                                                        </Nav.Item>
                                                        <Nav.Item>
                                                            <Nav.Link eventKey="second"
                                                                      className="text-center fw-bold">
                                                                Kirim
                                                            </Nav.Link>
                                                        </Nav.Item>
                                                        <Nav.Item>
                                                            <Nav.Link eventKey="third"
                                                                      className="text-center fw-bold">
                                                                Chiqim
                                                            </Nav.Link>
                                                        </Nav.Item>
                                                    </Nav>
                                                </Col>
                                                <Col sm={9} md={9}>
                                                    <Tab.Content>
                                                        <Tab.Pane eventKey="first">
                                                            <MaterialAbout material={material}/>
                                                        </Tab.Pane>

                                                        <Tab.Pane eventKey="second">

                                                            <MaterialImports />

                                                        </Tab.Pane>

                                                        <Tab.Pane eventKey="third">

                                                            <MaterialSpends />

                                                        </Tab.Pane>


                                                    </Tab.Content>
                                                </Col>
                                            </Row>
                                        </Tab.Container>
                                    </div>
                                </div>
                            </div>

                        ) : ''
                    }

                </div>
            </main>

        </div>
    );
};

export default MaterialPage;