import React, {Fragment, useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import {addExpense, getExpenseTemplateIdData} from "../../actions/expense";
import Select from "react-select";
import {clearErrors} from "../../actions/expense";
import {getUnitOptions} from "../../actions/product" ;

const ExpenseCreateForm = ({
                               expense: {expense_template_id_data, error, loading, expenses},
                               product: {unit_options},
                               addExpense,
                               getExpenseTemplateIdData,
                               getUnitOptions,
                               handleShow,
                               handleClose,
                               show
                           }) => {

    const [expense_template_id, setExpenseTemplateId] = useState(null);
    const [templateOptions, setTemplateOptions] = useState([]);
    const [unitOptions, setUnitOptions] = useState([]);

    useEffect(() => {
        expense_template_id_data.forEach((item) => setTemplateOptions(templateOptions => [...templateOptions, {
            label: `${item.name}`,
            value: item.id
        }]));
    }, [expense_template_id_data]);

    useEffect(() => {
        unit_options.forEach((item) => setUnitOptions(unitOptions => [...unitOptions, {
            label: `${item.name}`,
            value: item.id
        }]));
    }, [unit_options]);

    useEffect(() => {
        getUnitOptions();
        getExpenseTemplateIdData();
    }, [getUnitOptions, getExpenseTemplateIdData]);

    const formRef = useRef();

    const defaultValues = {
        expense_template_id: '',
        unit_id: '',
        quantity: '',
        price: '',
        description: ''
    };

    const [inputs, setInputs] = useState(defaultValues);
    const [errors, setErrors] = useState({});

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = {
            expense_template_id: expense_template_id,
            amount: inputs.amount,
            description: inputs.description,
        };
        addExpense(inputs);
    };

    const handleChange = e => {
        setErrors({});
        e.target.oldValue = e.target.value;
        setInputs(prevState => ({...prevState, [e.target.name]: e.target.value}));
    };

    const handleSelect = (data, type) => {
        setErrors({});
        setInputs(prevState => ({...prevState, [type.name]: data.value}))
    };

    useEffect(() => {
        if (error && loading === false) {
            const errorsItems = error?.errors;
            let errorObject = {};
            if (error && error?.errors) {
                Object.keys(error?.errors).forEach((item, index) => errorObject[item] = errorsItems[item][0]);
            }
            setErrors(errorObject);

        }
    }, [error]);

    useEffect(() => {
        clearErrors();
        setErrors({});
        setInputs(defaultValues);
        setExpenseTemplateId(prevSate => null);
    }, [expenses]);

    const onClose = () => {
        clearErrors();
        setInputs(defaultValues);
        setErrors({});
        handleClose();
        setExpenseTemplateId(prevState => (null));
    };

    return (
        <Fragment>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Yangi harajat qo'shish</Modal.Title>
                </Modal.Header>

                <Modal.Body className="pt-3 pb-4 mx-3">

                    <form onSubmit={handleSubmit} ref={formRef}>

                        <div className="mb-3">
                            <label htmlFor="expense_template_id">Harajat shabloni</label>
                            <Select
                                name={'expense_template_id'}
                                options={templateOptions}
                                onChange={handleSelect}
                                defaultValue={{label: 'Tanlang', value: '-'}}
                            />
                            <div className="error">
                                {errors?.expense_template_id}
                            </div>

                        </div>
                        <div className="mb-3">
                            <label htmlFor="amount">Narxi</label>
                            <input
                                name="price"
                                className={'form-control'}
                                type="number"
                                value={inputs?.price || ''}
                                placeholder={'kiriting'}
                                onChange={handleChange}
                                onKeyUp={e => e.target.oldValue = e.target.value}
                            />

                            <div className="error">
                                {errors?.price}
                            </div>
                        </div>

                        <div className="mb-3">
                            <label htmlFor="quantity">Miqdori</label>
                            <input
                                name="quantity"
                                className={'form-control'}
                                type="number"
                                value={inputs?.quantity || ''}
                                placeholder={'kiriting'}
                                onChange={handleChange}
                                onKeyUp={e => e.target.oldValue = e.target.value}
                            />

                            <div className="error">
                                {errors?.quantity}
                            </div>
                        </div>

                        <div className="mb-3">
                            <label htmlFor="unit_id">O'lchov birligi</label>
                            <Select
                                name={'unit_id'}
                                options={unitOptions}
                                onChange={handleSelect}
                                defaultValue={{label: 'Tanlang', value: '-'}}
                            />
                            <div className="error">
                                {errors?.unit_id}
                            </div>

                        </div>

                        <div className="mb-3">
                            <label htmlFor="description">Tavsifi</label>
                            <textarea
                                name="description"
                                className={'form-control'}
                                type="text"
                                value={inputs?.description || ''}
                                placeholder={'kiriting'}
                                onChange={handleChange}
                                onKeyUp={e => e.target.oldValue = e.target.value}
                            />

                            <div className="error">
                                {errors?.description}
                            </div>
                        </div>


                        <div className="d-flex gap-2">
                            <Button className="w-50" variant="secondary" type="button" onClick={onClose}>Bekor
                                qilish</Button>
                            <Button className="w-50" variant="primary" type="submit">Saqlash</Button>
                        </div>

                    </form>


                </Modal.Body>

            </Modal>
        </Fragment>
    );
};

ExpenseCreateForm.propTypes = {
    addExpense: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    expense: state.expense,
    product: state.product,
});


export default connect(mapStateToProps, {
    addExpense,
    getExpenseTemplateIdData,
    clearErrors,
    getUnitOptions
})(ExpenseCreateForm);
