import React, {Fragment, useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Button from 'react-bootstrap/Button';
import Select from 'react-select';
import {FormGroup, Input, Label} from "reactstrap";
import { Form} from "react-bootstrap";
import {getParams, getSelectedOption} from "../../utils/hooks/getParams";
import {getFirmOptions} from "../../actions/user";
import {
    getClientOptions
} from "../../actions/sale";
import {
    addOrUpdateClientTransaction,
    clearErrors
} from "../../actions/clients_transaction";
import moment from "moment";

const AddClientTransactionForm = ({
                                      loading,
                                      error,
                                      client_options,
                                      clearErrors,
                                      getClientOptions,
                                      addOrUpdateClientTransaction,
                                      getMaterialOptions,
                                  }) => {

    useEffect(() => {
        getClientOptions();
    }, [getClientOptions]);



    const [clientOptions, setClientOptions] = useState([]);

    const formRef = useRef();
    const defaultValues = {
        client_id: '',
        type: 'received',
        amount: '',
        date: '',
    };
    const [inputs, setInputs] = useState(defaultValues);
    const [errors, setErrors] = useState({});
    const [isReceivedChecked, setIsReceivedChecked] = useState(false);
    const [isPaidChecked, setIsPaidChecked] = useState(false);
    useEffect(() => {
        if (inputs.type === 'received')
            setIsReceivedChecked(true);
        else{
            setIsReceivedChecked(false);
        }
        if (inputs.type === 'paid')
            setIsPaidChecked(true);
        else
            setIsPaidChecked(false);
    }, [inputs])
    useEffect(() => {
        let options = [];
        client_options?.forEach((item) => options.push({
            label: item?.name,
            value: item?.id
        }));
        setClientOptions(options);
    }, [client_options])

    const handleChange = e => {
        setErrors({});
        setInputs(prevState => ({...prevState, [e.target.name]: e.target.value}));
    }
    const handleSelect = (data, type) => {
        if (type.name === 'firm_id') {
            getMaterialOptions({firm_id: data.value});
        }
        setErrors({});
        setInputs(prevState => ({...prevState, [type.name]: data.value}))
    };
    const handleRadio = (e) => {
        setErrors({});
        setInputs(prevState => ({...prevState, [e.target.name]: e.target.value}));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const params = getParams(true)
        if (await addOrUpdateClientTransaction(inputs, params)) {
            setErrors({});
            setInputs({type: 'received'});
        } else {
            const errorsItems = error?.errors;
            let errorObject = {};
            if (error && error?.errors) {
                Object.keys(error?.errors).forEach((item) => errorObject[item] = errorsItems[item][0]);
            }
            console.log(errorObject)
            setErrors(errorObject);
        }
    };

    useEffect(() => {
        if (loading === false) {
            if (error) {
                const errorsItems = error?.errors;
                let errorObject = {};
                if (error.hasOwnProperty('errors')) {
                    Object.keys(error.errors).forEach((item) => errorObject[item] = errorsItems[item][0]);
                }
                setErrors(errorObject);

            } else {
                clearErrors();
                setErrors({});
                setInputs(defaultValues);
                setInputs({type: 'received'});
            }
        }
    }, [error, loading]);

    return (
        <Fragment>

            <div className="col-12">
                <Form onSubmit={handleSubmit} ref={formRef}>

                    <FormGroup>
                        <Label for="client_id">
                            Klient(mijoz)
                        </Label>
                        <Select
                            name={'client_id'}
                            options={clientOptions}
                            onChange={handleSelect}
                            value={getSelectedOption(inputs?.client_id, clientOptions)}
                            required
                            defaultValue={{label: 'Tanlang', value: ''}}
                        />
                        <div className="error">
                            {errors?.client_id}
                        </div>
                    </FormGroup>

                    <div className="mb-2 form-group">
                        <div className="form-check form-check-inline">
                            <input className="form-check-input"
                                   type="radio"
                                   id="type1"
                                   value="received"
                                   onChange={handleRadio}
                                   name={'type'}
                                   checked={isReceivedChecked}
                            />
                            <label className="form-check-label" htmlFor="type1">Kirim</label>
                        </div>
                        <div className="form-check form-check-inline text-danger rounded-5 px-2 mx-3 " title={"Sotuv uchun qo'lda qo'shish tavsiya qilinmaydi!"}>
                            <input
                                className="form-check-input"
                                type="radio"
                                id="type2"
                                name={'type'}
                                onChange={handleRadio}
                                value="paid"
                                checked={isPaidChecked}
                            />
                            <label className="form-check-label " htmlFor="type2">Chiqim</label>

                        </div>
                        <div className="error">
                            {errors?.type}
                        </div>
                    </div>

                    <div className="mb-2">
                        <label htmlFor="amount">Summa</label>
                        <Input
                            name="amount"
                            value={inputs.amount || ''}
                            type="number"
                            placeholder={1000}
                            onChange={handleChange}
                            required
                        />
                        <div className="error">
                            {errors?.amount}
                        </div>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="date">Sana</label>
                        <input
                            name="date"
                            className={'form-control'}
                            type="date"
                            value={inputs?.date || ''}
                            placeholder={'kiriting'}
                            onChange={handleChange}
                            onKeyUp={e => e.target.oldValue = e.target.value}
                            max={moment().format('YYYY-MM-DD')}
                        />

                        <div className="error">
                            {errors?.date}
                        </div>
                    </div>

                    <div className="mb-2">
                        <Button className="" variant="primary" type="submit">Qo'shish</Button>
                    </div>
                </Form>

            </div>
        </Fragment>
    );
};

AddClientTransactionForm.propTypes = {
    clearErrors: PropTypes.func.isRequired,
    getClientOptions: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    material: state.material,
    materials: state.material.materials,
    error: state.clients_transaction.error,
    loading: state.clients_transaction.loading,
    client_options: state.sale.client_options,
    firm_options: state.user.firm_options,
});


export default connect(mapStateToProps, {
    addOrUpdateClientTransaction,
    clearErrors,
    getClientOptions,
    getFirmOptions,
})(AddClientTransactionForm);
