import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import Spinner from '../layout/Spinner';
import FirmDeleteModal from "./FirmDeleteModal";
import FirmUpdateForm from "./FirmUpdateForm";
import FirmCreateForm from "./FirmCreateForm";
import { getFirms } from "../../actions/firms";
import FirmCard from "./FirmCard";
import CustomPagination from "../layout/CustomPagination";
import { Plus } from "react-feather";
import { hasPermission } from '../../utils/permissions';

const Firms = ({ getFirms }) => {
    const {
        auth
    } = useSelector(state => state);
    const {role_name} = auth?.user || {};

    const {firms: firmItems, loading, numberOfPages, currentPage} = useSelector(state => state.firm);

    const [show, setShow] = useState(false);
    const [page, setPage] = useState(1);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handlePageChange = (pageNum, params = {}) => {
        return getFirms(pageNum, params);
    };

    useEffect(() => {
        const fetchFirms = async () => {
            await getFirms(page);
        };
        fetchFirms();
    }, [getFirms, page]);

    const canViewFirms = hasPermission(role_name, 'view_firms');
    const canCreateFirms = hasPermission(role_name, 'create_firms');

    if (!canViewFirms) {
        return <div>You don't have permission to view firms.</div>;
    }

    return loading ? (
        <Spinner/>
    ) : (
        <Fragment>
            <div className="container-fluid p-0">
                <div className="row mb-2">
                    <div className="col-md-12 col-lg-8">
                        <h1 className="h3 mb-3"><strong>Firmalar</strong>({firmItems?.length || 0}) </h1>
                    </div>
                    <div className="col-lg-4">
                        <div className="crud_actions text-end">
                            {canCreateFirms && (
                                <button className="btn btn-primary" onClick={handleShow}>
                                    Firma <Plus/>
                                </button>
                            )}
                        </div>
                    </div>
                </div>

                <div className="row mt-3">
                    {firmItems?.map((item, index) => (
                        <div key={`firm${item.id}`} className="col-md-4 col-lg-4">
                            <FirmCard firm={item} index={index}/>
                        </div>
                    ))}
                </div>

                <div className="row">
                    <div className="col-xl-12 col-xxl-12 d-flex">
                        <CustomPagination
                            numberOfPages={numberOfPages}
                            getPageItems={handlePageChange}
                            current_page={currentPage}
                        />
                    </div>
                </div>
            </div>

            {canCreateFirms && (
                <FirmCreateForm show={show} handleShow={handleShow} handleClose={handleClose}/>
            )}

            <FirmDeleteModal/>
            <FirmUpdateForm/>
        </Fragment>
    );
};

Firms.propTypes = {
    getFirms: PropTypes.func.isRequired
};

export default connect(null, { getFirms })(Firms);
