import React, {Fragment, useState} from 'react';
import {useSelector} from 'react-redux';
import MaterialSaleDeleteForm from "./MaterialSaleDeleteForm";
import MaterialSaleUpdateForm from "./MaterialSaleUpdateForm";
import MaterialSaleCreateForm from "./MaterialSaleCreateForm";
import {getMaterialSales} from "../../actions/material_sale";
import SearchForm from "../layout/SearchForm";
import {Plus} from "react-feather";
import FilterByFirm from "../filters/FilterByFirm";
import FilterByClient from "./FilterByClient";
// import FilterByProduct from "./FilterByProduct";
import TableSpinner from "../layout/TableSpinner";
import CustomPaginationWithManyPages from "../layout/CustomPaginationWithManyPages";
import FilterByDateRange from "../layout/FilterByDateRange";
import MaterialSaleItem from "./MaterialSaleItem";

const MaterialSales = () => {

    const {material_sales, loading, numberOfPages, currentPage, total_sales} = useSelector(state => state.material_sale);

    const [showCreate, setShowCreate] = useState(false);

    const handleCreateClose = () => setShowCreate(false);
    const handleCreateShow = () => setShowCreate(true);

    const [showUpdate, setShowUpdate] = useState(false);

    const handleUpdateClose = () => setShowUpdate(false);
    const handleUpdateShow = () => {
        showLoader()
        setShowUpdate(true)
    };

    const [isLoading, setIsLoading] = useState(loading ?? false);

    const showLoader = () => setIsLoading(true)
    const hideLoader = () => setIsLoading(false)
    return (
        <Fragment>
            <div className="container-fluid p-0">

                <div className="row mb-2">
                    <div className="col-md-9 ">
                        <h1 className="h3 mb-3"><strong></strong> sotuv bo'limi ({total_sales}) </h1>
                    </div>

                    <div className="col-md-3 justify-content-end">
                        <div className="crud_actions text-end">
                            <button className="btn btn-primary" onClick={handleCreateShow}>
                                Sotuv <Plus/>
                            </button>
                        </div>
                    </div>
                </div>

                <div className="row">

                    <div className="col-md-3">
                        <FilterByFirm getPageItems={getMaterialSales}/>
                    </div>

                    <div className="col-md-3">
                        <FilterByClient getPageItems={getMaterialSales}/>
                    </div>
                    <div className="col-md-3">
                        <SearchForm getPageItems={getMaterialSales}/>
                    </div>

                    <div className="col-md-3">
                        <FilterByDateRange getPageItems={getMaterialSales}/>
                    </div>

                </div>
                <div className="row mt-2">


                </div>

                <div className="row mt-2">
                    <div className="col-xl-12 col-xxl-12 d-flex">

                        <div className="card w-100 table-responsive">
                        {loading && (<TableSpinner/>)}
                            <table className="table custom-table table-hover">
                                <thead className="table-light">
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Material</th>
                                    <th scope="col">Client</th>
                                    <th scope="col">Sotuvchi</th>
                                    <th scope="col">Narxi</th>
                                    <th scope="col">Soni</th>
                                    <th scope="col">Sana</th>
                                    <th scope="col">Amallar</th>
                                </tr>
                                </thead>
                                <tbody>
                                {material_sales.map((item, index) => (
                                    <MaterialSaleItem key={`sale${item.id}`} material_sale={item} number={index + 1} handleShow={handleUpdateShow}/>
                                ))}
                                </tbody>
                            </table>

                        </div>

                    </div>

                </div>

                <div className="row">
                    <div className="col-xl-12 col-xxl-12 d-flex">
                        <CustomPaginationWithManyPages
                            numberOfPages={numberOfPages}
                            getPageItems={getMaterialSales}
                            current_page={currentPage}
                        />
                    </div>
                </div>

            </div>


            <MaterialSaleCreateForm show={showCreate} handleShow={handleCreateShow} handleClose={handleCreateClose}/>

            <MaterialSaleDeleteForm/>

            <MaterialSaleUpdateForm show={showUpdate}
                                    handleShow={handleUpdateShow}
                                    handleClose={handleUpdateClose}
                                    isLoading={isLoading} showLoader={showLoader} hideLoader={hideLoader}/>


        </Fragment>
    );
};

export default MaterialSales;
