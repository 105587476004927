import React, {useEffect, useState} from "react";
import useQueryParams from "../../utils/hooks/useQueryParams";
import {getParams} from "../../utils/hooks/getParams";
import {useDispatch} from "react-redux";

const SearchForm = ({getPageItems, search_text}) => {
    const dispatch = useDispatch();

    const {quantity, page} = useQueryParams();
    const [quantityValue, setQuantityValue] = useState(quantity ? quantity : '');
    const [isFirst, setIsFirst] = useState(true);

    useEffect(() => {
        setIsFirst(false);
    }, [])
    useEffect(() => {
        if (!isFirst) {
            const timeOutId = setTimeout(() => onChangeQuantity(quantityValue), 1000);
            return () => clearTimeout(timeOutId);
        }
    }, [quantityValue]);

    const onChangeQuantity = (quantityInput) => {
        if (quantityInput !== '') {
            setQuantityValue(quantityInput);
            const url = new URL(window.location);
            url.searchParams.set('quantity', quantityInput);
            url.searchParams.set('page', 1);
            window.history.pushState({}, '', url);
            const params = getParams();
            if (params['quantity']) delete params['page'];
            dispatch(getPageItems(1, params));

        } else {
            setQuantityValue('');
            const url = new URL(window.location);
            url.searchParams.delete('quantity');
            url.searchParams.set('page', 1);
            window.history.pushState({}, '', url);
            const params = getParams();
            if (params['quantity']) delete params['quantity'];
            dispatch(getPageItems(1, params));
        }
    };

    return (
        <input type="search" placeholder="Miqdori gacha" value={quantityValue}
               className="form-control"
               id="inputSearch"
               onChange={e => setQuantityValue(e.target.value)}/>
    )
};

export default SearchForm;