import React from 'react';
import Modal from 'react-bootstrap/Modal'
import {useDispatch, useSelector} from "react-redux";
import {deleteTransport, setDeleteTransportId} from "../../actions/transport";

const TransportDeleteForm = () => {

    const dispatch = useDispatch();

    const {delete_transport, currentPage, numberOfPages, transports} = useSelector(state => state.transport);

    const closeModal = () =>{
        // removing transport user from redux state by sending null setDeleteTransportId
        dispatch(setDeleteTransportId(null))
    };

    let getPage = null;

    const onConfirm = () =>{


        if ((currentPage === numberOfPages) && (transports.length === 1) ){
            getPage = (currentPage - 1);
        }
        else{
            getPage = currentPage;
        }
        dispatch(deleteTransport(delete_transport.id, getPage))
    };

    let condition = false;
    if(delete_transport !== null)
        condition = true;

    return (
        <div>
            <Modal show={condition} onHide={closeModal}>
                <Modal.Header className="bg-danger" closeButton>
                    <Modal.Title className="text-white" >Transportni o'chirish</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Siz haqiqatdan ham ushbu mashinani o'chirmoqchimisiz ?
                </Modal.Body>
                <Modal.Footer>
                    <button onClick={closeModal} className="btn btn-secondary">YO'Q</button>
                    <button onClick={onConfirm} className="btn btn-primary">HA</button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default TransportDeleteForm;