import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {getEmployee} from "../../actions/employee";
import {useParams} from 'react-router-dom';
import {Col, Nav, Row, Tab} from 'react-bootstrap'
import GoBackButton from "../layout/GoBackButton";
import EmployeeAbout from "./tabs/EmployeeAbout";
import EmployeeTransactions from "./tabs/EmployeeTransactions";

const EmployeePage = (props) => {

    useEffect(() => {
        dispatch(getEmployee(id));
    }, [getEmployee]);

    const dispatch = useDispatch();

    const {id} = useParams();

    const {employee, employee_transactions} = useSelector(state => state.employee);

    return (
        <div>
            <div className="content mt-0 pt-0">
                <div className="container-fluid p-0">

                    <div className="row mb-2">
                        <div className="col-md-12 col-lg-8">
                            <h1 className="h3 mb-3">
                                <strong>{employee?.first_name} {employee?.last_name}</strong> sahifasi </h1>
                        </div>
                        <div className="col-lg-4">
                            <div className="crud_actions text-end">
                                <GoBackButton/>
                            </div>
                        </div>
                    </div>


                    <div className="row">

                        <div className="card px-4 py-3">
                            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                                <Row>
                                    <Col sm={3} md={3}>
                                        <Nav variant="pills" className="flex-column">
                                            <Nav.Item>
                                                <Nav.Link eventKey="first"
                                                          className="text-center fw-bold">Haqida</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="second"
                                                          className="text-center fw-bold">Hisob-kitob</Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                    </Col>
                                    <Col sm={9} md={9}>
                                        <Tab.Content>
                                            <Tab.Pane eventKey="first">

                                                <EmployeeAbout employee={employee}/>

                                            </Tab.Pane>

                                            <Tab.Pane eventKey="second">

                                                <EmployeeTransactions employee={employee}
                                                                      employee_transactions={employee_transactions}/>

                                            </Tab.Pane>
                                        </Tab.Content>
                                    </Col>
                                </Row>
                            </Tab.Container>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    );
};

export default EmployeePage;