import React, {Fragment, useEffect} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {toast} from 'react-toastify';

const notify = (text, type, seconds) => (type === 'success')
    ?
    toast.success(text, {
        role: 'alert',
        pauseOnFocusLoss: false,
        seconds: seconds
    })
    :
    toast.error(text, {
        role: 'alert',
        pauseOnFocusLoss: true,
        seconds: seconds
    });
const dismissAll = () => toast.dismiss();
const Alert = ({alerts}) => {

    dismissAll();

    useEffect(() => {
        if (alerts !== null && alerts.length > 0) {
            const alert = alerts[0];
            notify(alert.msg, alert.type, alert?.seconds)
            return <Fragment key={alert.id}/>
        }
    }, [alerts])

    return null;
}


Alert.propTypes = {
    alerts: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
    alerts: state.alert,
});

export default connect(mapStateToProps)(Alert);
