import axios from 'axios';
import {clearAlerts, setAlert} from './alert';
import {
    ADD_SPARE_PART, CLEAR_ERRORS,
    GET_SPARE_PART, GET_SPARE_PART_OPTIONS,
    GET_SPARE_PARTS, GET_WAREHOUSE_OPTIONS, MATERIAL_ERROR,
    REMOVE_DELETE_SPARE_PART,
    REMOVE_UPDATE_SPARE_PART,
    SET_DELETE_SPARE_PART_ID, SET_LOADING,
    SET_UPDATE_SPARE_PART_ID,
    SPARE_PART_ERROR, GET_UNIT_OPTIONS
} from './types';

export const getUnitOptions = () => async (dispatch) => {
    try {
        const res = await axios.get('/units');
        dispatch({
            type: GET_UNIT_OPTIONS,
            payload: res.data,
        });

    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
    }
};

export const getWarehouseOptions = (filters = {}) => async (dispatch) => {
    try {
        let queryParams = '';
        if (filters) {
            Object.keys(filters).map((key, index) => queryParams += `&${key}=${filters[key]}`);
        }
        console.log(queryParams)
        const res = await axios.get(`/warehouses?is_all=true&type=part${queryParams}`);

        dispatch({
            type: GET_WAREHOUSE_OPTIONS,
            payload: res.data,
        });

    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
    }
};

export const getSparePart = (sparePartID) => async (dispatch) => {
    try {

        const res = await axios.get(`/spare-parts/${sparePartID}`);
        dispatch({
            type: GET_SPARE_PART,
            payload: res.data,
        });

    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
    }
};

export const getSparepartOptions = (filters = {}) => async (dispatch) => {
    let queryParams = '';
    if (filters) {
        Object.keys(filters).map((key, index) => queryParams += `&${key}=${filters[key]}`);
    }
    try {

        const res = await axios.get(`/spare-parts?is_all=true${queryParams}`);

        dispatch({
            type: GET_SPARE_PART_OPTIONS,
            payload: res.data,
        });

    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
    }
};

export const getSpareParts = (page = 1, filters = {}) => async (dispatch) => {
    try {

        let queryParams = '';
        if (filters) {
            Object.keys(filters).map((key, index) => queryParams += `&${key}=${filters[key]}`);
        }
        const res = await axios.get(`/spare-parts?page=${page}${queryParams}`);

        dispatch({
            type: GET_SPARE_PARTS,
            payload: res.data,
        });

    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
    }
};

export const addSparepartImport = (formData) => async (dispatch) => {


    dispatch({type: SET_LOADING});
    dispatch(clearAlerts());

    const config = {
        headers: {
            'Content-Type': 'application/json',
        },
    };

    try {
        const res = await axios.post(`/sparepart-imports/`, formData, config);
        dispatch(getSpareParts());
        dispatch(setAlert("Zapchast skladga qo'shildi!", 'success'));

        return true;
    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
        if (err?.response?.data?.errors) {
            dispatch({
                type: MATERIAL_ERROR,
                payload: err.response.data,
            });
        }
        return false;
    }
};

export const addSparePart = (formData) => async (dispatch) => {
    dispatch(clearAlerts());

    const config = {
        headers: {
            'Content-Type': 'application/json',
        },
    };

    try {
        const res = await axios.post('/spare-parts/', formData, config);

        dispatch({
            type: ADD_SPARE_PART,
            payload: res.data,
        });

        dispatch(getSpareParts());
        dispatch(getSparepartOptions());
        dispatch(setAlert("Zapchast yaratildi!", 'success'));
    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));

        if (err?.response?.data?.errors) {
            dispatch({
                type: SPARE_PART_ERROR,
                payload: err.response.data,
            });
        } else {
            dispatch(setAlert("Ehtiyot qismi yaratilmadi !", 'danger'));
        }
    }
};


export const deleteSparePart = (deleteSparePartID, getPage) => async (dispatch) => {
    dispatch(clearAlerts());

    const config = {
        headers: {
            'Content-Type': 'application/json',
        },
    };

    try {
        const res = await axios.delete(`/spare-parts/${deleteSparePartID}`, config);

        dispatch({
            type: REMOVE_DELETE_SPARE_PART
        });

        dispatch(getSpareParts(getPage));
        dispatch(getSparepartOptions());
        dispatch(setAlert("Ehtiyot qismi o'chirildi !", 'success'));
        dispatch(clearErrors())
    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
    }
};

export const updateSparePart = (updateSparePartID, updateformData, getPage) => async (dispatch) => {
    dispatch(clearAlerts());

    const config = {
        headers: {
            'Content-Type': 'application/json',
        },
    };

    try {
        const res = await axios.put(`/spare-parts/${updateSparePartID}`, updateformData, config);

        dispatch({type: REMOVE_UPDATE_SPARE_PART});

        dispatch(getSpareParts(getPage));
        dispatch(getSparepartOptions());
        dispatch(setAlert("Zapchast tahrirlandi!", 'success'));
    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
        if (err?.response?.data?.errors) {
            dispatch({
                type: SPARE_PART_ERROR,
                payload: err.response.data,
            });
        } else {
            dispatch(setAlert("Zapchast yangilanmadi!", 'danger'));
        }
    }
};

export const setDeleteSparePartId = (spare_part_ID) => async (dispatch) => {

    try {
        if (spare_part_ID !== null) {
            dispatch({
                type: SET_DELETE_SPARE_PART_ID,
                payload: {
                    spare_part_ID
                }
            });

        } else {
            dispatch({
                type: REMOVE_DELETE_SPARE_PART
            });
        }

    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
    }
};

export const setUpdateSparePartId = (spare_part_ID) => async (dispatch) => {

    try {
        if (spare_part_ID !== null) {
            dispatch({
                type: SET_UPDATE_SPARE_PART_ID,
                payload: {
                    spare_part_ID
                }
            });
        } else dispatch({type: REMOVE_UPDATE_SPARE_PART});

    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
    }
};


export const clearErrors = () => async (dispatch) => {
    dispatch({
        type: CLEAR_ERRORS,
    });
};







