import React, {useEffect, useRef, useState} from 'react';
import Modal from "react-bootstrap/Modal";
import {useDispatch, useSelector} from "react-redux";
import {clearErrors, setUpdateTransportId, updateTransport, getAvailableDriverEmployees} from "../../actions/transport";
import Button from "react-bootstrap/Button";

const TransportUpdateForm = () => {

    const dispatch = useDispatch();

    const {update_transport, error, loading, currentPage, available_drivers} = useSelector(state => state.transport);
    useEffect(() => {
        getAvailableDriverEmployees()
    }, [])

    useEffect(() => {
        setInputs(update_transport)
    }, [update_transport])

    const formRef = useRef();

    const defaultValues = {
        name: '',
        plate: '',
        driver_id: '',
    };

    const [inputs, setInputs] = useState(defaultValues);
    const [errors, setErrors] = useState({});

    useEffect(() => {
        if (error && loading === false) {
            const errorsItems = error?.errors;
            let errorObject = {};
            if (error && error?.errors) {
                Object.keys(error?.errors).forEach((item, index) => errorObject[item] = errorsItems[item][0]);
            }
            setErrors(errorObject);

        }
    }, [error]);

    const handleChange = e => {
        setErrors({});
        e.target.oldValue = e.target.value;
        setInputs(prevState => ({...prevState, [e.target.name]: e.target.value}));
    };
    const onClose = () => {
        clearErrors();
        setInputs(defaultValues);
        setErrors({});
        closeModal();
    };
    const closeModal = () => {
        dispatch(setUpdateTransportId(null));
    };

    const handleSubmit = (event, value) => {
        event.preventDefault();
        dispatch(updateTransport(update_transport.id, inputs, currentPage));
    };

    let condition = false;
    if (update_transport !== null)
        condition = true;

    return (
        <div>
            <Modal show={condition} onHide={closeModal}>
                <Modal.Header closeButton className="bg-light">
                    <Modal.Title>Mashinani tahrirlash</Modal.Title>
                </Modal.Header>
                <Modal.Body className="pt-3 pb-4 mx-3">
                    <form onSubmit={handleSubmit} ref={formRef}>

                        <div className="mb-3">
                            <label htmlFor="name">Nomi</label>
                            <input
                                name="name"
                                className={'form-control'}
                                type="text"
                                value={inputs?.name || ''}
                                placeholder={'kiriting'}
                                onChange={handleChange}
                                onKeyUp={e => e.target.oldValue = e.target.value}
                            />

                            <div className="error">
                                {errors?.name}
                            </div>
                        </div>

                        <div className="mb-3">
                            <label htmlFor="plate">Mashina raqami</label>
                            <input
                                name="plate"
                                className={'form-control'}
                                type="text"
                                value={inputs?.plate || ''}
                                placeholder={'kiriting'}
                                onChange={handleChange}
                                onKeyUp={e => e.target.oldValue = e.target.value}
                            />

                            <div className="error">
                                {errors?.plate}
                            </div>
                        </div>

                        <div className="mb-3">
                            <label htmlFor="driver_id">Haydovchi</label>
                            <select name="driver_id"
                                    value={inputs?.driver_id || ''}
                                    id="driver_id"
                                    className="form-control"
                                    onChange={handleChange}
                                    onKeyUp={e => e.target.oldValue = e.target.value}
                            >
                                <option value="">Tanlang</option>
                                {
                                    available_drivers.map((item, index) => (
                                        <option key={index} value={item.id}>{item.name}</option>
                                    ))
                                }

                            </select>
                            <div className="error">
                                {errors?.driver_id}
                            </div>
                        </div>


                        <div className="d-flex gap-2">
                            <Button className="w-50" variant="secondary" type="button" onClick={onClose}>Bekor
                                qilish</Button>
                            <Button className="w-50" variant="primary" type="submit">Saqlash</Button>
                        </div>
                    </form>

                    {/*<AvForm model={update_transport} onValidSubmit={onSubmit}>*/}

                    {/*    <AvField*/}
                    {/*        name="name"*/}
                    {/*        label="Nomi"*/}
                    {/*        type="text"*/}
                    {/*        required*/}
                    {/*    />*/}

                    {/*    <AvField*/}
                    {/*        name="plate"*/}
                    {/*        label="Mashina raqami"*/}
                    {/*        type="text"*/}
                    {/*        required*/}
                    {/*    />*/}

                    {/*    <AvField*/}
                    {/*        type="select"*/}
                    {/*        name="driver_id"*/}
                    {/*        label="Haydovchi"*/}
                    {/*    >*/}
                    {/*        <option key={0} value={''}>Tanlang</option>*/}
                    {/*        {*/}
                    {/*            available_drivers.map((item, index) => (*/}
                    {/*                <option key={index} value={item.id}>{item.name}</option>*/}
                    {/*            ))*/}
                    {/*        }*/}
                    {/*    </AvField>*/}


                    {/*    <div className="d-flex gap-2">*/}
                    {/*        <Button className="w-50" onClick={() => closeModal()} variant="secondary" type="button">Bekor*/}
                    {/*            qilish</Button>*/}
                    {/*        <Button className="w-50" variant="primary" type="submit">Saqlash</Button>*/}
                    {/*    </div>*/}

                    {/*</AvForm>*/}

                </Modal.Body>
            </Modal>
        </div>
    );
};

export default TransportUpdateForm;