import React from 'react';
import Modal from "react-bootstrap/Modal";
import {useDispatch, useSelector} from "react-redux";
// import {AvField, AvForm} from "availity-reactstrap-validation";
import {setUpdateProductDeliveryId, updateProductDelivery} from "../../actions/product-delivery";

const ProductDeliveryUpdateForm = () => {

    const dispatch = useDispatch();

    const {update_product_delivery, currentPage, transport_ID_DATA, client_ID_DATA, driver_ID_DATA} = useSelector(state => state.product_delivery);

    const closeModal = () => {
        dispatch(setUpdateProductDeliveryId(null));
    };

    const onSubmit = (event, value) => {
        dispatch(updateProductDelivery(update_product_delivery.id, value, currentPage));
    };

    let condition = false;
    if (update_product_delivery !== null)
        condition = true;

    return (
        <div>
            <Modal show={condition} onHide={closeModal}>
                <Modal.Header closeButton className="bg-light">
                    <Modal.Title>Mahsulot yetkazib berishni tahrirlash </Modal.Title>
                </Modal.Header>
                <Modal.Body className="pt-3 pb-4 mx-3">

                    {/*<AvForm model={update_product_delivery} onValidSubmit={onSubmit}>*/}

                    {/*    <AvField*/}
                    {/*        type="select"*/}
                    {/*        name="transport_id"*/}
                    {/*        label="Transport"*/}
                    {/*    >*/}
                    {/*        {*/}
                    {/*            transport_ID_DATA.map((item, index) => (*/}
                    {/*                <option key={index} value={item.id}>{item.name}</option>*/}
                    {/*            ))*/}
                    {/*        }*/}
                    {/*    </AvField>*/}

                    {/*    <AvField*/}
                    {/*        type="select"*/}
                    {/*        name="client_id"*/}
                    {/*        label="Mijoz"*/}
                    {/*    >*/}
                    {/*        {*/}
                    {/*            client_ID_DATA.map((item, index) => (*/}
                    {/*                <option key={index} value={item.id}>{item.name}</option>*/}
                    {/*            ))*/}
                    {/*        }*/}
                    {/*    </AvField>*/}

                    {/*    <AvField*/}
                    {/*        type="select"*/}
                    {/*        name="driver_id"*/}
                    {/*        label="Haydovchi"*/}
                    {/*    >*/}
                    {/*        {*/}
                    {/*            driver_ID_DATA.map((item, index) => (*/}
                    {/*                <option key={index} value={item.id}>{item.first_name} {item.last_name}</option>*/}
                    {/*            ))*/}
                    {/*        }*/}
                    {/*    </AvField>*/}

                    {/*    <AvField*/}
                    {/*        name="round"*/}
                    {/*        label="Qatnov(reys)"*/}
                    {/*        type="number"*/}
                    {/*        required*/}
                    {/*    />*/}

                    {/*    <AvField*/}
                    {/*        name="price"*/}
                    {/*        label="Narxi"*/}
                    {/*        type="number"*/}
                    {/*        required*/}
                    {/*    />*/}


                    {/*    <div className="d-flex gap-2">*/}
                    {/*        <Button className="w-50" onClick={() => closeModal()} variant="secondary" type="button">Bekor*/}
                    {/*            qilish</Button>*/}
                    {/*        <Button className="w-50" variant="primary" type="submit">Saqlash</Button>*/}
                    {/*    </div>*/}

                    {/*</AvForm>*/}

                </Modal.Body>
            </Modal>
        </div>
    );
};

export default ProductDeliveryUpdateForm;