import React, {Fragment, useEffect, useState} from "react";
import { formatMoney} from "../../utils/hooks/getParams";
const SupplierMaterialDataCell = ({index, cellData}) => {
    const [itemData, setDateData] = useState(cellData);

    useEffect(() => {
        setDateData(cellData);
    }, [cellData])

    return (
        <Fragment key={`td${index}`}>
            <td
                className={'cell received_amount'}
                title={itemData.received_amount}
            >
                {formatMoney(parseFloat(itemData.received_amount)?.toFixed(2))}
            </td>
            <td
                className={'cell spent_amount'}
                title={itemData.received_sum}
            >
                {formatMoney(itemData.received_sum)}
            </td>
        </Fragment>
    )
}

export default SupplierMaterialDataCell;