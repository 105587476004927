import {Link} from "react-router-dom";
import React, { useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {formatMoney} from "../../utils/hooks/getParams";
import ProductDataCell from "./ProductDataCell";

const ProductDataRow = ({index, item, dates}) => {
    const dispatch = useDispatch();

    const [itemData, setItemData] = useState({
        transactions: []
    });
    const [rowData, setRowData] = useState([]);

    useEffect(() => {
        setItemData(item);
    }, [item])

    useEffect(() => {
        setRowData(itemData.transactions);
    }, [itemData]);

    const getCellData = (date) => {
        const foundDateItem = rowData.find(item => item.date === date);
        if (foundDateItem){
            return {
                sold_amount: foundDateItem.sold_amount,
                produced_amount: foundDateItem.produced_amount,
                date: foundDateItem.date,
            }
        }
        return {
            sold_amount: 0,
            produced_amount: 0,
            date,
        }
    }

    return (
        <tr>
            <th className="sticky-col">{index + 1}</th>
            <th className="sticky-col2">
                <Link to={`products/${itemData.id}`}>
                    {itemData.name}
                </Link>
            </th>
            {
                dates?.map((date, subindex) => (
                    <ProductDataCell
                        key={subindex}
                        date={date}
                        index={index}
                        cellData={getCellData(date)}
                    />
                ))
            }
            <th style={{textAlign: "center", minWidth: "100px", right: "400px"}}
                title={formatMoney(itemData.current_month_produced_amount)}
            >
                {formatMoney(itemData.current_month_produced_amount?.toFixed(2), ',')}
            </th>
            <th style={{textAlign: "center", minWidth: "100px", right: "300px"}}
                title={formatMoney(itemData.current_month_sold_amount)}
            >
                {formatMoney(itemData.current_month_sold_amount?.toFixed(2), ',')}
            </th>
            <th style={{textAlign: "center", minWidth: "100px", right: "200px"}}
                title={formatMoney(itemData.total_produced_amount, ',')}
            >
                {formatMoney(itemData.total_produced_amount?.toFixed(2), ',')}
            </th>
            <th style={{textAlign: "center", minWidth: "100px", right: "100px"}}
                title={formatMoney(itemData.total_sold_amount, ',')}
            >
                {formatMoney(itemData.total_sold_amount?.toFixed(2), ',')}
            </th>
            <th style={{textAlign: "center", minWidth: "100px", right: "0px"}}
                title={formatMoney(itemData.total_left_amount, ',')}
            >
                {formatMoney(itemData.total_left_amount?.toFixed(2))}
            </th>
        </tr>
    )
}

export default ProductDataRow;