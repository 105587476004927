import React, {Fragment, useEffect, useState} from "react";
import {getParams} from "../../utils/hooks/getParams";
import {useDispatch} from "react-redux";

const FilterByMonth = (props) => {

    const dispatch = useDispatch();

    const {getPageItems} = props;

    const [isFirst, setIsFirst] = useState(true);
    const [currentValue, setCurrentValue] = useState(false);
    useEffect(() => {
        setIsFirst(false);
        const params = getParams();

        if (Object.prototype.hasOwnProperty.call(params, 'is_minus')) {
            setCurrentValue(Boolean(params.is_minus));
        }
    }, []);

    useEffect(() => {
        if (!isFirst) {

            const params = getParams();
            params.is_minus = currentValue;
            const url = new URL(window.location);
            if (currentValue) {
                url.searchParams.set('is_minus', currentValue);
            } else {
                url.searchParams.delete('is_minus');
            }
            url.searchParams.set('page', 1);
            window.history.pushState({}, '', url);
            dispatch(getPageItems(1, params));
        }
    }, [currentValue])

    return (
        <Fragment>
            <div className="form-group align-items-middle">
                <input
                    className={'form-check-input'}
                    style={{marginRight: "0.3rem"}}
                    type={'checkbox'}
                    id={'debitors'}
                    checked={currentValue}
                    onChange={e => setCurrentValue(!currentValue)}
                />
                <label className={'pl-1 form-check-label'} htmlFor={'debitors'}>Faqat qarzdorlar</label>
            </div>
        </Fragment>
    )
}

export default FilterByMonth