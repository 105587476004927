import React, { useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import { useParams} from 'react-router-dom';
import GoBackButton from "../layout/GoBackButton";
import {
    getClient,
} from "../../actions/client";
import {Col, Nav, Row, Tab} from "react-bootstrap";
import ClientAbout from "./tabs/ClientAbout";
import ClientTransactions from "./tabs/ClientTransactions";
import ClientSales from "./tabs/ClientSales";

const ClientPage = () => {

    useEffect(() => {
        dispatch(getClient(id));
    }, [getClient]);

    const dispatch = useDispatch();
    const {
        client,
        client_sales,
        client_transactions,
    } = useSelector(state => state.client);
    const {id} = useParams();



    return (
        <div>
            <main className="content mt-0 pt-0">
                <div className="container-fluid p-0">

                    <div className="row mb-2">
                        <div className="col-md-12 col-lg-8">
                            <h1 className="h3 mb-3"><strong>Mijoz</strong> sahifasi </h1>
                        </div>
                        <div className="col-lg-4">
                            <div className="crud_actions text-end">
                                <GoBackButton/>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12 col-lg-12">
                            <div className="card px-4 py-3">
                                <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                                    <Row>
                                        <Col sm={3} md={3}>
                                            <Nav variant="pills" className="flex-column">
                                                <Nav.Item>
                                                    <Nav.Link eventKey="first"
                                                              className="text-center fw-bold">Haqida</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="second"
                                                              className="text-center fw-bold">Hisob-kitob</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="third" className="text-center fw-bold">
                                                        Sotuvlar
                                                    </Nav.Link>
                                                </Nav.Item>
                                            </Nav>
                                        </Col>
                                        <Col sm={9} md={9}>
                                            <Tab.Content>
                                                <Tab.Pane eventKey="first">

                                                    <ClientAbout client={client}/>

                                                </Tab.Pane>

                                                <Tab.Pane eventKey="second">

                                                    <ClientTransactions client={client}
                                                                        transactionsItems={client_transactions}/>

                                                </Tab.Pane>

                                                <Tab.Pane eventKey="third">

                                                    <ClientSales client={client} client_sales={client_sales}/>

                                                </Tab.Pane>
                                            </Tab.Content>
                                        </Col>
                                    </Row>
                                </Tab.Container>
                            </div>
                        </div>
                    </div>

                </div>

            </main>
        </div>
    );
};

export default ClientPage;