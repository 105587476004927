import {
    ADD_USER,
    CLEAR_ERRORS,
    GET_FILTERED_USERS,
    GET_STATISTICS,
    GET_USER,
    GET_USER_ACTIVITIES,
    GET_USER_ROL_ID_DATA,
    GET_USERS,
    REMOVE_DELETE_USER,
    REMOVE_UPDATE_USER,
    SET_DELETE_USER_ID,
    SET_SEARCH_QUERY,
    SET_UPDATE_USER_ID,
    USER_ERROR,
    GET_FIRM_ID_DATA,
    GET_INCOME_AND_EXPENSES
} from '../actions/types';

const initialState = {
    statistics: [],
    activities: [],
    users: [],
    firm_options: [],
    filtered_users: [],
    search_text: '',
    user_rol_id_data: [],
    update_user: null,
    delete_user: null,
    loading: true,
    numberOfPages: 1,
    currentPage: 1,
    total_users: 0,
    error: null
};

// Helper function to safely handle array data
const ensureArray = (data) => {
    if (!data) return [];
    return Array.isArray(data) ? data : [];
};

// Helper function to safely handle pagination meta
const handlePaginationMeta = (meta) => {
    if (!meta || typeof meta !== 'object') {
        return {
            currentPage: 1,
            lastPage: 1,
            total: 0
        };
    }
    return {
        currentPage: meta.current_page || 1,
        lastPage: meta.last_page || 1,
        total: meta.total || 0
    };
};

export default function userReducer(state = initialState, action) {
    const {type, payload} = action;

    switch (type) {
        case GET_FIRM_ID_DATA:
            return {
                ...state,
                firm_options: ensureArray(payload?.data),
                loading: false
            };

        case GET_STATISTICS:
            return {
                ...state,
                statistics: payload || [],
                loading: false
            };

        case GET_INCOME_AND_EXPENSES:
            return {
                ...state,
                incomes: payload?.incomes || [],
                expenses: payload?.expenses || [],
                loading: false
            };

        case GET_USER:
            return {
                ...state,
                user: payload?.data || null,
                loading: false
            };

        case GET_USERS: {
            const { currentPage, lastPage, total } = handlePaginationMeta(payload?.meta);
            return {
                ...state,
                users: ensureArray(payload?.data),
                numberOfPages: lastPage,
                currentPage: currentPage,
                total_users: total,
                loading: false,
                error: null
            };
        }

        case GET_USER_ROL_ID_DATA:
            return {
                ...state,
                user_rol_id_data: ensureArray(payload?.data),
                loading: false
            };

        case GET_FILTERED_USERS: {
            const { currentPage, lastPage } = handlePaginationMeta(payload?.meta);
            return {
                ...state,
                filtered_users: ensureArray(payload?.data),
                numberOfPages: lastPage,
                currentPage: currentPage,
                loading: false
            };
        }

        case GET_USER_ACTIVITIES:
            return {
                ...state,
                activities: ensureArray(payload?.data),
                loading: false
            };

        case SET_SEARCH_QUERY:
            return {
                ...state,
                search_text: payload || ''
            };

        case ADD_USER:
            return {
                ...state,
                error: null,
                loading: false
            };

        case SET_DELETE_USER_ID:
            return {
                ...state,
                delete_user: payload || null
            };

        case SET_UPDATE_USER_ID:
            return {
                ...state,
                update_user: payload || null
            };

        case REMOVE_DELETE_USER:
            return {
                ...state,
                delete_user: null
            };

        case REMOVE_UPDATE_USER:
            return {
                ...state,
                update_user: null
            };

        case USER_ERROR:
            return {
                ...state,
                error: payload,
                loading: false
            };

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            };

        default:
            return state;
    }
}
