import React, { } from 'react';
const TableSpinner = () => (
  <div style={loaderWrapperStyles} className="loader-wrapper">
    <div className="spinner-grow text-light" role="status" data-testid="spinner">
        <span className="visually-hidden">Loading...</span>
    </div>
  </div>
);
const loaderWrapperStyles = {
  backgroundColor: '#00000050',
  width: "100%",
  height: "100%",
  position: "absolute",
  top: 0,
  left: 0,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  zIndex: 0
};


export default TableSpinner;