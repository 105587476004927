import React, {Fragment, useEffect, useState} from "react";
import { formatMoney} from "../../utils/hooks/getParams";



const ProductMaterialDataCell = ({index, cellData}) => {
    const [itemData, setDateData] = useState(cellData);

    useEffect(() => {
        setDateData(cellData);
    }, [cellData])

    return (
        <Fragment key={`td${index}`}>
            <td
                className={'cell spent_amount'}
                title={itemData?.spent_amount}
            >
                {formatMoney(parseFloat(itemData?.spent_amount)?.toFixed(2))}
            </td>
            <td
                className={'cell sum'}
                title={itemData?.sum}
            >
                {formatMoney(itemData?.sum, ',')}
            </td>
        </Fragment>
    )
}

export default ProductMaterialDataCell;