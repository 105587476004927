import React, {Fragment, useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import {addFirm, clearErrors, getAvailableManagerUsers} from "../../actions/firms";


const FirmCreateForm = ({
                            firm: {available_manager_for_firms = [], error, loading, firms},
                            addFirm,
                            getAvailableManagerUsers,
                            handleShow,
                            handleClose,
                            show
                        }) => {

    useEffect(() => {
        getAvailableManagerUsers();
    }, [getAvailableManagerUsers]);

    const formRef = useRef();

    const defaultValues = {
        name: '',
        manager_id: '',
        address: '',
        phone: '',
        mfo: '',
        inn: '',
        bank_account: '',
    };

    const [inputs, setInputs] = useState(defaultValues);
    const [errors, setErrors] = useState({});

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = {
            name: inputs.name,
            manager_id: inputs.manager_id,
            address: inputs.address,
            phone: inputs.phone,
            mfo: inputs.mfo,
            inn: inputs.inn,
            bank_account: inputs.bank_account,
        };
        addFirm(data);
    };

    const handleChange = e => {
        setErrors({});
        e.target.oldValue = e.target.value;
        setInputs(prevState => ({...prevState, [e.target.name]: e.target.value}));
    };

    useEffect(() => {
        if (error && loading === false) {
            const errorsItems = error?.errors;
            let errorObject = {};
            if (error && error?.errors) {
                Object.keys(error?.errors).forEach((item, index) => errorObject[item] = errorsItems[item][0]);
            }
            setErrors(errorObject);
        }
    }, [error]);

    useEffect(() => {
        clearErrors();
        setErrors({});
        setInputs(defaultValues);
    }, [firms]);

    const onClose = () => {
        clearErrors();
        setInputs(defaultValues);
        setErrors({});
        handleClose();
    };

    return (
        <Fragment>
            <Modal show={show} onHide={handleClose} aria-label="Yangi firma qo'shish" data-testid="create-firm-modal">
                <Modal.Header closeButton>
                    <Modal.Title>Yangi firma qo'shish</Modal.Title>
                </Modal.Header>

                <Modal.Body className="pt-3 pb-4 mx-3">

                    <form onSubmit={handleSubmit} ref={formRef}>

                        <div className="mb-3">
                            <label htmlFor="name">Nomi</label>
                            <input
                                name="name"
                                className={'form-control'}
                                type="text"
                                value={inputs?.name || ''}
                                placeholder={'kiriting'}
                                onChange={handleChange}
                                onKeyUp={e => e.target.oldValue = e.target.value}
                                data-testid="name-input"
                            />

                            <div className="error" data-testid="name-error">
                                {errors?.name}
                            </div>
                        </div>

                        <div className="mb-3">
                            <label htmlFor="phone">Telefon</label>
                            <input
                                name="phone"
                                className={'form-control'}
                                type="text"
                                value={inputs?.phone || ''}
                                placeholder={'kiriting'}
                                onChange={handleChange}
                                onKeyUp={e => e.target.oldValue = e.target.value}
                                data-testid="phone-input"
                            />

                            <div className="error" data-testid="phone-error">
                                {errors?.phone}
                            </div>
                        </div>

                        <div className="mb-3">
                            <label htmlFor="mfo">MFO</label>
                            <input
                                name="mfo"
                                className={'form-control'}
                                type="text"
                                value={inputs?.mfo || ''}
                                placeholder={'kiriting'}
                                onChange={handleChange}
                                onKeyUp={e => e.target.oldValue = e.target.value}
                                data-testid="mfo-input"
                            />

                            <div className="error" data-testid="mfo-error">
                                {errors?.mfo}
                            </div>
                        </div>

                        <div className="mb-3">
                            <label htmlFor="inn">INN</label>
                            <input
                                name="inn"
                                className={'form-control'}
                                type="text"
                                value={inputs?.inn || ''}
                                placeholder={'kiriting'}
                                onChange={handleChange}
                                onKeyUp={e => e.target.oldValue = e.target.value}
                                data-testid="inn-input"
                            />

                            <div className="error" data-testid="inn-error">
                                {errors?.inn}
                            </div>
                        </div>

                        <div className="mb-3">
                            <label htmlFor="bank_account">Bank hisob(р.сч)</label>
                            <input
                                name="bank_account"
                                className={'form-control'}
                                type="text"
                                value={inputs?.bank_account || ''}
                                placeholder={'kiriting'}
                                onChange={handleChange}
                                onKeyUp={e => e.target.oldValue = e.target.value}
                                data-testid="bank-account-input"
                            />

                            <div className="error" data-testid="bank-account-error">
                                {errors?.bank_account}
                            </div>
                        </div>


                        <div className="mb-3">
                            <label htmlFor="manager_id">Menejer</label>
                            <select name="manager_id"
                                    value={inputs?.manager_id || ''}
                                    id="manager_id"
                                    className="form-control"
                                    onChange={handleChange}
                                    onKeyUp={e => e.target.oldValue = e.target.value}
                                    data-testid="manager-select"
                            >
                                <option value="">Tanlang</option>
                                {
                                    available_manager_for_firms?.map((item, index) => (
                                        <option key={index} value={item.id}>{item.name}</option>
                                    ))
                                }

                            </select>
                            <div className="error" data-testid="manager-error">
                                {errors?.manager_id}
                            </div>
                        </div>


                        <div className="mb-3">
                            <label htmlFor="address">Manzil</label>
                            <input
                                name="address"
                                className={'form-control'}
                                type="text"
                                value={inputs?.address || ''}
                                placeholder={'kiriting'}
                                onChange={handleChange}
                                onKeyUp={e => e.target.oldValue = e.target.value}
                                data-testid="address-input"
                            />

                            <div className="error" data-testid="address-error">
                                {errors?.address}
                            </div>
                        </div>


                        <div className="d-flex gap-2">
                            <Button
                                className="w-50"
                                variant="secondary"
                                type="button"
                                onClick={onClose}
                                data-testid="close-button"
                            >
                                Bekor qilish
                            </Button>
                            <Button
                                className="w-50"
                                variant="primary"
                                type="submit"
                                data-testid="submit-button"
                            >
                                Saqlash
                            </Button>
                        </div>
                    </form>


                </Modal.Body>

            </Modal>
        </Fragment>
    );
};

FirmCreateForm.propTypes = {
    addFirm: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    firm: state.firm,
});


export default connect(mapStateToProps, {addFirm, getAvailableManagerUsers, clearErrors})(FirmCreateForm);
