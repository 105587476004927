import {Link} from "react-router-dom";
import React, { useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {formatMoney} from "../../utils/hooks/getParams";
import {getSupplierTransactions} from "../../actions/suppliers_transaction";
import SupplierDataCell from "./SupplierDataCell";

const SupplierDataRow = ({index, item, dates}) => {
    const dispatch = useDispatch();

    const {
        auth
    } = useSelector(state => state);
    const {role_name} = auth?.user || {};

    const [itemData, setItemData] = useState({
        transactions: []
    });
    const [rowData, setRowData] = useState([]);

    useEffect(() => {
        setItemData(item);
    }, [item])

    useEffect(() => {
        setRowData(itemData.transactions);
    }, [itemData]);

    const refreshRow = (id) => {
        dispatch(getSupplierTransactions(id));
    }
    const getCellData = (date) => {
        const foundDateItem = rowData.find(item => item.date === date);
        if (foundDateItem){
            return {
                received_amount: foundDateItem.received_amount,
                paid_amount: foundDateItem.paid_amount,
                date: foundDateItem.date,
            }
        }
        return {
            received_amount: 0,
            paid_amount: 0,
            date,
        }
    }

    return (
        <tr>
            <th className="sticky-col">{index + 1}</th>
            <th className="sticky-col2">
                <Link to={`suppliers/${itemData.id}`}>
                    {itemData.name}
                </Link>
            </th>
            {
                dates?.map((date) => (
                    <SupplierDataCell
                        key={date}
                        object_id={item.id}
                        date={date}
                        cellData={getCellData(date)}
                        rowData={rowData}
                        refreshRow={refreshRow}
                        resetAllRows={() => console.log(2)}
                    />))
            }
            <th style={{textAlign: "center", minWidth: "120px", right: "360px"}}>
                {formatMoney(itemData.current_month_received_amount, ',')}
            </th>
            <th style={{textAlign: "center", minWidth: "120px", right: "240px"}}>
                {formatMoney(itemData.current_month_paid_amount, ',')}
            </th>
            <th style={{textAlign: "center", minWidth: "120px", right: "120px"}}>
                {formatMoney(itemData.total_received_amount, ',')}
            </th>
            <th style={{textAlign: "center", minWidth: "120px", right: "0px"}}>
                {formatMoney(itemData.total_paid_amount, ',')}
            </th>
        </tr>
    )
}

export default SupplierDataRow;