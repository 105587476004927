import React, {useEffect, useRef, useState} from 'react';
import Modal from "react-bootstrap/Modal";
import {useDispatch, useSelector} from "react-redux";
import Button from "react-bootstrap/Button";
import {
    clearErrors, getMaterialUnits, getWarehouseOptions, setUpdateMaterialId, updateMaterial
} from "../../actions/material";
import Select from "react-select";
import {getParams, getSelectedOption} from "../../utils/hooks/getParams";
import {getFirmOptions} from "../../actions/user";

const MaterialUpdateForm = () => {

    const dispatch = useDispatch();
    const {
        update_material, materials, material_warehouses_options, loading, material_units, error,
    } = useSelector(state => state.material);
    const {
        firm_options,
    } = useSelector(state => state.user);

    const closeModal = () => {
        // removing update_material from redux by sending null to setUpdateMaterialId
        dispatch(setUpdateMaterialId(null));
    };

    const formRef = useRef();
    useEffect(() => {
        if (update_material) {
            setInputs({
                name: update_material.name,
                unit_id: update_material.unit_id,
                firm_id: update_material.firm_id,
                warehouse_id: update_material.warehouse_id,
                unit_alternative_id: update_material.unit_alternative_id,
                multiplier: update_material.multiplier,
            });

            setMaterialName(update_material?.name);

            const unit = material_units.find(item => item.id === Number(update_material?.unit_id));
            setUnitName(unit?.name);

            const unit_alternative = material_units.find(item => item.id === Number(update_material?.unit_alternative_id));
            setUnitAlternativeName(unit_alternative?.name);

            setMultiplier(update_material?.multiplier);
        }
    }, [update_material]);

    useEffect(() => {
        dispatch(getMaterialUnits());
        dispatch(getFirmOptions());
        dispatch(getWarehouseOptions());
    }, [getMaterialUnits, getFirmOptions, getWarehouseOptions]);

    const [firmOptions, setFirmOptions] = useState([]);
    const [warehouseOptions, setWarehouseOptions] = useState([]);

    useEffect(() => {
        let items = [];
        if (firm_options && firm_options.length > 0)
            firm_options.forEach((item) => items.push({label: `${item.name}`, value: item.id}));
        setFirmOptions(items);
    }, [firm_options]);

    useEffect(() => {
        let options = [];
        material_warehouses_options.forEach((item) => options.push({
            label: item.name, value: item.id
        }));
        setWarehouseOptions(options);
    }, [material_warehouses_options]);

    const defaultValues = {
        name: '', unit_id: '', firm_id: '', warehouse_id: '', unit_alternative_id: '', multiplier: '', quantity: ''
    };

    const [inputs, setInputs] = useState(defaultValues);
    const [errors, setErrors] = useState({});

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = {
            name: inputs.name,
            firm_id: Number(inputs.firm_id),
            warehouse_id: Number(inputs.warehouse_id),
            unit_id: Number(inputs.unit_id),
            unit_alternative_id: Number(inputs.unit_alternative_id),
            multiplier: Number(inputs.multiplier),
            quantity: Number(inputs.quantity),
        };
        const params = getParams(true);
        // addMaterial(data, params);
        dispatch(updateMaterial(update_material.id, data, params));
    };

    const handleChange = e => {
        setErrors({});
        e.target.oldValue = e.target.value;
        setInputs(prevState => ({...prevState, [e.target.name]: e.target.value}));

        // bundan pastdagilar hamma formada kerak emas.
        if (e.target.name === 'name') {
            setMaterialName(e.target.value);
        }
        if (e.target.value && e.target.name === 'unit_id') {
            const unit = material_units.find(item => item.id === Number(e.target.value));
            setUnitName(unit.name);
        }
        if (e.target.value && e.target.name === 'unit_alternative_id') {
            const unit = material_units.find(item => item.id === Number(e.target.value));
            setUnitAlternativeName(unit.name);
        }
        if (e.target.name === 'multiplier') {
            setMultiplier(e.target.value);
        }
    };


    const handleSelect = (data, type) => {
        setErrors({});
        if (type.name === 'firm_id') {
            dispatch(getWarehouseOptions({firm_id: data.value}));
        }
        setInputs(prevState => ({...prevState, [type.name]: data.value}))
    };


    const [unit_name, setUnitName] = useState('');
    const [unit_alternative_name, setUnitAlternativeName] = useState('');
    const [material_name, setMaterialName] = useState('');
    const [multiplier, setMultiplier] = useState('');

    useEffect(() => {
        if (error && loading === false) {
            const errorsItems = error?.errors;
            let errorObject = {};
            if (error && error?.errors) {
                Object.keys(error?.errors).forEach((item) => errorObject[item] = errorsItems[item][0]);
            }
            setErrors(errorObject);
        }
    }, [error]);

    useEffect(() => {
        clearErrors();
        setErrors({});
        setInputs(defaultValues);

        setUnitName('')
        setUnitAlternativeName('')
        setMaterialName('')
        setMultiplier('')
    }, [materials]);

    useEffect(() => {

    }, [inputs]);

    const onClose = () => {
        clearErrors();
        setInputs(defaultValues);
        setErrors({});
        closeModal();
    }
    let condition = false;
    if (update_material !== null) condition = true;

    return (<div>
        <Modal show={condition} onHide={onClose}>
            <Modal.Header closeButton className="bg-light">
                <Modal.Title>Materialni tahrirlash</Modal.Title>
            </Modal.Header>
            <Modal.Body className="pt-3 pb-4 mx-3">

                <form onSubmit={handleSubmit} ref={formRef}>

                    <div className="mb-2">
                        <label htmlFor="name">Material nomi</label>
                        <input
                            name="name"
                            className={'form-control'}
                            type="text"
                            value={inputs?.name || ''}
                            placeholder={'kiriting'}
                            onChange={handleChange}
                            onKeyUp={e => e.target.oldValue = e.target.value}
                            required
                        />
                    </div>


                    <div className="mb-3">
                        <label htmlFor="firm_id">Firma</label>
                        <Select
                            name={'firm_id'}
                            options={firmOptions}
                            value={getSelectedOption(inputs?.firm_id, firmOptions)}
                            onChange={handleSelect}
                            defaultValue={{label: 'Tanlang', value: ''}}
                        />
                        <div className="error">
                            {errors?.firm_id}
                        </div>
                    </div>


                    <div className="mb-3">
                        <label htmlFor="firm_id">Sklad</label>
                        <Select
                            name={'warehouse_id'}
                            options={warehouseOptions}
                            value={getSelectedOption(inputs?.warehouse_id, warehouseOptions)}
                            onChange={handleSelect}
                            defaultValue={{label: 'Tanlang', value: ''}}
                        />
                        <div className="error">
                            {errors?.warehouse_id}
                        </div>
                    </div>


                    <div className="mb-2">
                        <label htmlFor="name">Kirim o'lchovi</label>
                        <select name="unit_id" id="unit_id"
                                className="form-control"
                                value={inputs?.unit_id || ''}
                                onChange={handleChange}
                                onKeyUp={e => e.target.oldValue = e.target.value}
                        >
                            <option value="">Tanlang</option>
                            {material_units.map((item, index) => (
                                <option key={index} value={item.id}>{item.name}</option>))}
                        </select>
                    </div>

                    <div className="mb-2">
                        <label htmlFor="unit_alternative_id">Chiqim o'lchovi</label>
                        <select name="unit_alternative_id"
                                value={inputs?.unit_alternative_id || ''}
                                id="unit_alternative_id"
                                className="form-control"
                                onChange={handleChange}
                                onKeyUp={e => e.target.oldValue = e.target.value}
                        >
                            <option value="">Tanlang</option>
                            {material_units.map((item, index) => (
                                <option key={index} value={item.id}>{item.name}</option>))}
                        </select>
                    </div>

                    <div className="mb-2">
                        <label htmlFor="multiplier">
                            {`1 ${unit_name} ${material_name} = ${multiplier} ${unit_alternative_name} ?`}
                        </label>
                        <input
                            name="multiplier"
                            className={'form-control'}
                            type="number"
                            value={inputs?.multiplier || ''}
                            placeholder={'kiriting'}
                            onChange={handleChange}
                            onKeyUp={e => e.target.oldValue = e.target.value}
                            required
                        />
                    </div>

                    <div className="d-flex gap-2">
                        <Button className="w-50" variant="secondary" type="button" onClick={onClose}>Bekor
                            qilish</Button>
                        <Button className="w-50" variant="primary" type="submit">Saqlash</Button>
                    </div>
                </form>

            </Modal.Body>
        </Modal>
    </div>);
};

export default MaterialUpdateForm;