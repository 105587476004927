import React from 'react';
import Modal from 'react-bootstrap/Modal'
import {useDispatch, useSelector} from "react-redux";
import {deleteMaterialImport, setDeleteMaterialImport} from "../../actions/material";
import {getParams} from "../../utils/hooks/getParams";

const MaterialImportDeleteForm = () => {


    const dispatch = useDispatch();

    const {delete_material_import} = useSelector(state => state.material);

    const closeModal = () => {
        dispatch(setDeleteMaterialImport(null))
    };

    const onConfirm = () => {
        const params = getParams();
        params.material_id = delete_material_import.material_id;
        dispatch(deleteMaterialImport(delete_material_import.id, params));
    };

    let condition = false;
    if (delete_material_import !== null)
        condition = true;

    return (
        <div>
            <Modal show={condition} onHide={closeModal}>
                <Modal.Header className="bg-danger" closeButton>
                    <Modal.Title className="text-white">Yetkazib berishni o'chirish</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Siz haqiqatdan ham ushbu material import ni bekor qilmoqchimisiz?
                </Modal.Body>
                <Modal.Footer>
                    <button onClick={closeModal} className="btn btn-secondary">YO'Q</button>
                    <button onClick={onConfirm} className="btn btn-primary">HA</button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default MaterialImportDeleteForm;