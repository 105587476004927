import {Link} from "react-router-dom";
import React, { useEffect, useState} from "react";
import {formatMoney} from "../../utils/hooks/getParams";
import ProductMaterialDataCell from "./ProductMaterialDataCell";

const ProductMaterialDataRow = ({index, item, dates}) => {

    const [itemData, setItemData] = useState({
        product_material_transactions: []
    });
    const [rowData, setRowData] = useState([]);

    useEffect(() => {
        setItemData(item);
    }, [item])

    useEffect(() => {
        if (item.hasOwnProperty('product_material_transactions')) {
            setRowData(itemData.product_material_transactions);
        }
    }, [itemData]);


    const getCellData = (date) => {
        const foundDateItem = rowData.find(item => item.date === date);
        if (foundDateItem){
            return {
                spent_amount: foundDateItem.spent_amount,
                sum: foundDateItem.sum,
                date: foundDateItem.date,
            }
        }
        return {
            spent_amount: 0,
            date,
        }
    }

    return (
        <tr>
            <th className="sticky-col">{index + 1}</th>
            <th className="sticky-col2">
                <Link to={`products/${item.id}`}>
                    {item.name}
                </Link>
            </th>
            {
                dates.map((date, subindex) => (
                    <ProductMaterialDataCell
                        key={subindex}
                        date={date}
                        index={index}
                        cellData={getCellData(date)}
                    />))
            }
            <th className={'sticky-col'} style={{textAlign: "center", minWidth: "100px", right: "300px"}} title={itemData?.total_current_month_amount}>
                {formatMoney(itemData.total_current_month_amount?.toFixed(2))}
            </th>

            <th className={'sticky-col'} style={{textAlign: "center", minWidth: "100px", right: "200px"}} title={itemData?.total_current_month_sum}>
                {formatMoney(itemData.total_current_month_sum, ',')}
            </th>
            <th className={'sticky-col'} style={{textAlign: "center", minWidth: "100px", right: "100px"}} title={itemData?.total_spent_amount}>
                {formatMoney(itemData.total_spent_amount?.toFixed(2))}
            </th>
            <th className={'sticky-col'} style={{textAlign: "center", minWidth: "100px", right: "0px"}} title={itemData?.total_spent_sum}>
                {formatMoney(itemData.total_spent_sum, ',')}
            </th>
        </tr>
    )
}

export default ProductMaterialDataRow;