import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {getUser, getUserRolIdData} from "../../actions/user";
import {useParams} from 'react-router-dom';
import {Col, Nav, Row, Tab} from 'react-bootstrap'
import GoBackButton from "../layout/GoBackButton";
import UserAbout from "./tabs/UserAbout";
import UserActivities from "./tabs/UserActivities";

const UserPage = () => {

    useEffect(() => {
        dispatch(getUser(id));
    }, [getUser]);

    useEffect(() => {
        dispatch(getUserRolIdData());
    }, [getUserRolIdData]);

    const dispatch = useDispatch();

    const {id} = useParams();

    const {user, currentPage, numberOfPages, activities} = useSelector(state => state.user);

    return (
        <div>
            <main className="content mt-0 pt-0">
                <div className="container-fluid p-0">

                    <div className="row mb-2">
                        <div className="col-md-12 col-lg-8">
                            <h1 className="h3 mb-3"><strong>Foydalanuvchi</strong> sahifasi </h1>
                        </div>
                        <div className="col-lg-4">
                            <div className="crud_actions text-end">
                                <GoBackButton/>
                            </div>
                        </div>
                    </div>

                    <div className="row">

                        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                            <Row>
                                <Col sm={3} md={3}>
                                    <div className="card">
                                        <Nav variant="pills" className="flex-column">
                                            <Nav.Item>
                                                <Nav.Link eventKey="first"
                                                          className="text-center fw-bold">Haqida</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="second"
                                                          className="text-center fw-bold">Faolliklar</Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                    </div>
                                </Col>
                                <Col sm={9} md={9}>
                                    <Tab.Content>
                                        <Tab.Pane eventKey="first">
                                            <UserAbout user={user}/>
                                        </Tab.Pane>

                                        <Tab.Pane eventKey="second">
                                            <UserActivities currentPage={currentPage}
                                                            numberOfPages={numberOfPages}
                                                            user={user}
                                                            activities={activities}/>
                                        </Tab.Pane>
                                    </Tab.Content>
                                </Col>
                            </Row>
                        </Tab.Container>

                    </div>

                </div>
            </main>
        </div>
    );
};

export default UserPage;