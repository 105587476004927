import {
    ADD_EXPENSE,
    EXPENSE_ERROR,
    EXPENSE_TEMPLATE_ID_ERROR,
    GET_EXPENSE,
    GET_EXPENSE_TEMPLATE_ID_DATA,
    GET_EXPENSES,
    REMOVE_DELETE_EXPENSE,
    REMOVE_UPDATE_EXPENSE,
    SET_DELETE_EXPENSE_ID, SET_LOADING,
    SET_UPDATE_EXPENSE_ID
} from '../actions/types';

const initialState = {
    expenses: [],
    expense: {},
    expense_template_id_data: [],
    update_expense: null,
    delete_expense: null,
    loading: true,
    numberOfPages: 1,
    total_expenses: null,
    error: null,
};

// eslint-disable-next-line

export default function (state = initialState, action) {
    const {type, payload} = action;

    switch (type) {

        case SET_LOADING:

            return {
                ...state,
                loading: payload
            };

        case GET_EXPENSE_TEMPLATE_ID_DATA:

            return {
                ...state,
                expense_template_id_data: payload.data,
                loading: false
            };

        case EXPENSE_TEMPLATE_ID_ERROR:
            return {
                ...state,
                error: payload,
                loading: false,
            };

        case GET_EXPENSE:
            return {
                ...state,
                expense: payload.data,
                error: null,
                loading: false,
            };

        case GET_EXPENSES:
            return {
                ...state,
                expenses: payload.data,
                numberOfPages: payload.meta.last_page,
                currentPage: payload.meta.current_page,
                total_expenses: payload.meta.total,
                error: null,
                loading: false,
            };

        case ADD_EXPENSE:
            return {
                ...state,
                expenses: [payload],
                error: null,
                loading: false,
            };

        case REMOVE_DELETE_EXPENSE:
            return {
                ...state,
                delete_expense: null,
            };

        case REMOVE_UPDATE_EXPENSE:
            return {
                ...state,
                update_expense: null,
            };

        case SET_DELETE_EXPENSE_ID:

            return {
                ...state,
                delete_expense: state.expenses.find((item) => item.id === action.payload.expenseID)
            };

        case SET_UPDATE_EXPENSE_ID:
            return {
                ...state,
                update_expense: state.expenses.find((item) => item.id === action.payload.expenseID)
            };

        case EXPENSE_ERROR:
            return {
                ...state,
                error: payload,
                loading: false,
            };

        default:
            return state;
    }
}
