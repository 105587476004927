import React, { useEffect, useRef, useState} from 'react';
import Chart from 'chart.js/auto';

const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
];

const SupplierTransactionLineChart = (props) => {

    const {metrics} = props;
    const chartRef = useRef();
    const [chartObject, setChartObject] = useState(null);
    const [labels, setLabels] = useState(months);

    useEffect(() => {

        const ctx = chartRef.current.getContext("2d");

        if (!chartObject) {
            setChartObject(new Chart(ctx, {
                type: "line",
                data: {
                },
                options: {
                    maintainAspectRatio: false,
                    legend: {
                        display: false
                    },
                    tooltips: {
                        intersect: false
                    },
                    hover: {
                        intersect: true
                    },
                    plugins: {
                        filler: {
                            propagate: false
                        }
                    },
                }
            }));
        }

    }, [])

    useEffect(() => {
        if (metrics) {
            const dataItems = props.metrics?.map((metric) => metric.total_received);
            const dataItems2 = props.metrics?.map((metric) => metric.total_sold);

            setLabels(months);
            if (chartObject) {
                chartObject.data = {
                    labels: labels,
                    datasets: [

                        {
                            data: dataItems,
                            label: 'Import material qiymati',
                            borderColor: 'rgb(82, 136, 255)',
                            lineTension: 0,
                            pointRadius: 2,
                            pointBackgroundColor: 'rgba(255,255,255,1)',
                            pointHoverBackgroundColor: 'rgba(255,255,255,0.6)',
                            borderWidth: 3
                        },
                        {
                            data: dataItems2,
                            label: "Yetkazuvchilarga to'langan pullar",
                            borderColor: 'rgb(255, 9, 15)',
                            lineTension: 0,
                            pointRadius: 2,
                            pointBackgroundColor: 'rgba(255,255,255,0)',
                            pointHoverBackgroundColor: 'rgba(255,255,255,0.6)',
                            borderWidth: 3
                        }
                    ]
                };
                chartObject.update();
            }
        }
    }, [metrics, chartObject])

    return (
        <div className="annual_statistics">
            <canvas
                style={styles}
                id="annual_statistics"
                ref={chartRef}
            />
        </div>
    )

}

const styles = {
    display: 'block', width: '450px', height: '350px'
}
export default SupplierTransactionLineChart;