import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {setDeleteExpenseId, setUpdateExpenseId} from "../../actions/expense";
import {useHistory} from 'react-router-dom'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'react-bootstrap';

const formatMoney = (value, seperator = ',') => {
    if (value) {
        return value.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, seperator);
    } else
        return 0;
}

const ExpenseItem = ({
                         auth,
                         setDeleteExpenseId,
                         setUpdateExpenseId,
                         number,
                         expense: {
                             id, price, quantity, description,
                             category,
                             name,
                             created_at: date
                         },
                         showActions,
                     }) => {

    const {role_name} = auth?.user || {};

    const history = useHistory();

    const setDeleteExpense = (id) => {
        setDeleteExpenseId(id);
    };

    const setUpdateExpense = (id) => {
        setUpdateExpenseId(id);
    };

    return (
        <tr>
            <th scope="row">{number}</th>
            <td>{name}</td>
            <td>{category}</td>
            <td>{quantity}</td>
            <td>{formatMoney(price)} so'm</td>
            <td>{formatMoney(price * quantity)} so'm</td>
            <td>{description}</td>
            <td>{moment(date).format("YYYY-MM-DD")}</td>
            {(role_name === 'superadmin' || role_name === 'director' || role_name === 'accountant') ? (
                <td>
                    <div className="d-flex gap-2">
                        <Button
                            variant="outline-primary"
                            size="sm"
                            className="d-flex align-items-center justify-content-center"
                            style={{ width: '32px', height: '32px', padding: 0 }}
                            onClick={() => setUpdateExpense(id)}
                        >
                            <FontAwesomeIcon icon={faEdit} />
                        </Button>
                        <Button
                            variant="outline-danger"
                            size="sm"
                            className="d-flex align-items-center justify-content-center"
                            style={{ width: '32px', height: '32px', padding: 0 }}
                            onClick={() => setDeleteExpense(id)}
                        >
                            <FontAwesomeIcon icon={faTrash} />
                        </Button>
                    </div>
                </td>
            ) : null}
        </tr>
    );
};

ExpenseItem.defaultProps = {
    showActions: true,
};

ExpenseItem.propTypes = {
    expense: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
});

export default connect(mapStateToProps, {setDeleteExpenseId, setUpdateExpenseId})(
    ExpenseItem
);
