import axios from 'axios';
import {clearAlerts, setAlert} from './alert';
import {
    ADD_MATERIAL,
    CLEAR_ERRORS,
    CLEAR_MATERIAL_TRANSACTIONS,
    GET_MATERIAL,
    GET_MATERIAL_IMPORTS,
    GET_MATERIAL_SPENDS,
    GET_MATERIAL_UNITS_DATA,
    GET_MATERIALS, GET_MATERIALS_OPTIONS, GET_SUPPLIER_OPTIONS, GET_WAREHOUSE_OPTIONS,
    MATERIAL_ERROR,
    REMOVE_DELETE_MATERIAL,
    REMOVE_UPDATE_MATERIAL,
    SET_DELETE_MATERIAL_ID,
    SET_DELETE_MATERIAL_IMPORT_ID_ON_MATERIAL_PAGE,
    SET_LOADING,
    SET_UPDATE_MATERILA_ID,
    TRANSPORT_ERROR
} from './types';
import {request} from "../utils/service";
import {setLoading, unsetLoading} from "./client";

export const getMaterialUnits = () => async (dispatch) => {

    const res = await request('/units?is_all=true');


    if (res.hasOwnProperty('data')) {
        dispatch({
            type: GET_MATERIAL_UNITS_DATA,
            payload: {
                material_units: res.data,
            },
        });

    } else {
        dispatch(setAlert(res.message, 'danger'));
        dispatch({
            type: MATERIAL_ERROR,
            payload: res
        });
    }

};

export const getMaterials = (page = 1, filters = {}) => async (dispatch) => {
    dispatch({type: SET_LOADING, payload: true});
    try {

        let queryParams = '';
        if (filters) {
            Object.keys(filters).map((key) => queryParams += `&${key}=${filters[key]}`);
        }
        const res = await axios.get(`/materials?page=${page}${queryParams}`);

        dispatch({
            type: GET_MATERIALS,
            payload: res.data,
        });
        dispatch({type: SET_LOADING, payload: false});
    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
        dispatch({
            type: MATERIAL_ERROR,
            payload: {msg: err.response.statusText, status: err.response.status},
        });
    }
};

export const getMaterialImports = (material_id, filters = {}) => async (dispatch) => {
    dispatch(setLoading());
    try {
        let queryParams = '';
        if (filters) {
            Object.keys(filters).map((key) => queryParams += `&${key}=${filters[key]}`);
        }
        const res = await axios.get(`/material-imports/?material_id=${material_id}${queryParams}`);
        dispatch({
            type: GET_MATERIAL_IMPORTS,
            payload: res.data,
        });
        dispatch(unsetLoading());
    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));

    }
};

export const getMaterialSpends = (material_id, filters = {}) => async (dispatch) => {
    let queryParams = '';
    if (filters) {
        Object.keys(filters).map((key) => queryParams += `&${key}=${filters[key]}`);
    }
    const res = await request(`/material-spends/?material_id=${material_id}&${queryParams}`, {method: 'GET'}, dispatch);
    if (res?.data?.length >= 0) {
        dispatch({
            type: GET_MATERIAL_SPENDS,
            payload: res
        });
    } else {

        // dispatch(setAlert("Qidiruv bo'yicha hech narsa topilmadi!", 'warning'));
    }

};

export const getMaterialOptions = (filters = {}) => async (dispatch) => {
    try {
        let queryParams = '';
        if (filters) {
            Object.keys(filters).map((key) => queryParams += `&${key}=${filters[key]}`);
        }
        const res = await axios.get(`/materials?is_all=true${queryParams}`);

        dispatch({
            type: GET_MATERIALS_OPTIONS,
            payload: res.data,
        });

    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
    }
};

export const getWarehouseOptions = (filters = {}) => async (dispatch) => {
    try {
        let queryParams = '';
        if (filters) {
            Object.keys(filters).map((key) => queryParams += `&${key}=${filters[key]}`);
        }
        const res = await axios.get(`/warehouses?is_all=true&type=material${queryParams}`);

        dispatch({
            type: GET_WAREHOUSE_OPTIONS,
            payload: res.data,
        });

    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
    }
};

export const getSupplierOptions = () => async (dispatch) => {
    try {

        const res = await axios.get(`/suppliers?is_all=true`);

        dispatch({
            type: GET_SUPPLIER_OPTIONS,
            payload: res.data,
        });

    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
    }
};

export const getMaterial = (materialID) => async (dispatch) => {
    try {
        const res = await axios.get(`/materials/${materialID}`);
        dispatch({
            type: GET_MATERIAL,
            payload: res.data,
        });
    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
        if (err.response) {
            dispatch({
                type: MATERIAL_ERROR,
                payload: err.response.data,
            });
        } else {
            dispatch({
                type: MATERIAL_ERROR,
                payload: {msg: err.response.statusText, status: err.response.status},
            });
        }
    }
};

export const addMaterial = (formData, filters = {}) => async (dispatch) => {
    dispatch(clearAlerts());

    const config = {
        headers: {
            'Content-Type': 'application/json',
        },
    };

    try {
        const res = await axios.post('/materials/', formData, config);


        dispatch({
            type: ADD_MATERIAL,
            payload: res.data,
        });

        dispatch(getMaterials(filters.page, filters));
        dispatch(getMaterialOptions());

        dispatch(setAlert('Material yaratildi !', 'success'));

    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));

        if (err?.response?.data?.errors) {
            dispatch({
                type: MATERIAL_ERROR,
                payload: err.response.data,
            });
        } else {
            dispatch(setAlert('Material yaratilmadi !', 'danger'));
        }
    }
};

export const addMaterialImport = (formData, filters = {}) => async (dispatch) => {

    dispatch({type: SET_LOADING, payload: true});
    dispatch(clearAlerts());

    const config = {
        headers: {
            'Content-Type': 'application/json',
        },
    };

    try {
        await axios.post(`/material-imports/`, formData, config);


        dispatch(getMaterials(filters.page, filters));

        dispatch(setAlert('Material import qilindi!', 'success'));

        return true;
    } catch (err) {
        dispatch({
            type: MATERIAL_ERROR,
            payload: err.response.data,
        });
        dispatch(setAlert('Material import amalga oshmadi!', 'danger'));
        return false;
    }
};


export const setDeleteMaterialImport = (material_import_id) => async (dispatch) => {

    try {
        if (material_import_id) {

            dispatch({
                type: SET_DELETE_MATERIAL_IMPORT_ID_ON_MATERIAL_PAGE,
                payload: material_import_id
            })

        } else dispatch({type: SET_DELETE_MATERIAL_IMPORT_ID_ON_MATERIAL_PAGE, payload: null});

    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
    }
};


export const deleteMaterialImport = (material_import_id, filters = {}) => async (dispatch) => {

    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        };
        await axios.delete(`/material-imports/${material_import_id}`, config);

        dispatch({
            type: SET_DELETE_MATERIAL_IMPORT_ID_ON_MATERIAL_PAGE,
            payload: null
        });

        dispatch(setAlert("Material import o'chirildi!", 'success'));

        dispatch(getMaterialImports(filters.material_id, filters));

        dispatch(clearErrors())

    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
        if (err?.response?.data?.errors) {
            dispatch({
                type: TRANSPORT_ERROR,
                payload: err.response.data,
            });
        } else {
            dispatch(setAlert("Yetkazib berish o'chirilmadi!", 'danger'));
        }
    }
};

export const clearErrors = () => async (dispatch) => {
    dispatch({
        type: CLEAR_ERRORS,
    });
};

export const clearMaterialTransactions = () => async (dispatch) => {
    dispatch({
        type: CLEAR_MATERIAL_TRANSACTIONS,
    });
};

export const deleteMaterial = (deleteMaterialID, getPage) => async (dispatch) => {
    dispatch(clearAlerts());

    const config = {
        headers: {
            'Content-Type': 'application/json',
        },
    };

    try {
        await axios.delete(`/materials/${deleteMaterialID}`, config);

        dispatch({
            type: REMOVE_DELETE_MATERIAL
        });

        dispatch(getMaterials(getPage));
        dispatch(getMaterialOptions());
        dispatch(setAlert("Material o'chirildi !", 'success'));
    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
    }
};

export const updateMaterial = (updateMaterialID, updateformData, getPage) => async (dispatch) => {
    dispatch(clearAlerts());

    const config = {
        headers: {
            'Content-Type': 'application/json',
        },
    };

    try {
        await axios.put(`/materials/${updateMaterialID}`, updateformData, config);

        dispatch({type: REMOVE_UPDATE_MATERIAL});

        dispatch(getMaterials(getPage));
        dispatch(getMaterialOptions());
        dispatch(setAlert('Material tahrirlandi !', 'success'));
    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
    }
};

export const setDeleteMaterialId = (materialID) => async (dispatch) => {

    try {
        if (materialID !== null) {
            dispatch({
                type: SET_DELETE_MATERIAL_ID,
                payload: {
                    materialID
                }
            });

        } else {
            dispatch({
                type: REMOVE_DELETE_MATERIAL
            });
        }

    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
    }
};

export const setUpdateMaterialId = (materialID) => async (dispatch) => {

    try {
        if (materialID !== null) {
            dispatch({
                type: SET_UPDATE_MATERILA_ID,
                payload: {
                    materialID
                }
            });
        } else dispatch({type: REMOVE_UPDATE_MATERIAL});

    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
    }
};


// download report
export const downloadSingleMaterialReport = (filters = {}) => async (dispatch) => {
    filters.object = 'material';
    dispatch(setLoading());
    dispatch(clearAlerts());
    let queryParams = `?`;
    if (filters) {
        Object.keys(filters).map((key, index) => index === 0 ? queryParams += `${key}=${filters[key]}` : queryParams += `&${key}=${filters[key]}`);
    }
    const res = await request(`/reports/${queryParams}`, {method: 'GET'}, dispatch);

    if (res?.hasOwnProperty('errors')) {
        const err = res.errors;
        dispatch(setAlert(err.response.statusText, 'danger'));
        dispatch(unsetLoading());
        return false;
    } else {
        dispatch(unsetLoading());
        return res?.link;
    }
};





