import axios from 'axios';
import {clearAlerts, setAlert} from './alert';
import {
    ADD_UNIT, CLEAR_ERRORS,
    GET_UNIT,
    GET_UNITS,
    REMOVE_DELETE_UNIT,
    REMOVE_UPDATE_UNIT,
    SET_DELETE_UNIT_ID, SET_LOADING,
    SET_UPDATE_UNIT_ID,
    UNIT_ERROR
} from './types';

export const getUnit = (unitID) => async (dispatch) => {
    try {
        const res = await axios.get(`/units/${unitID}`);
        dispatch({
            type: GET_UNIT,
            payload: res.data,
        });

    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
    }
};

export const getUnits = (page = 1, filters = {}) => async (dispatch) => {
    dispatch({type: SET_LOADING, payload: true});
    try {

        let queryParams = '';
        if (filters) {
            Object.keys(filters).map((key, index) => queryParams += `${key}=${filters[key]}`);
        }
        const res = await axios.get(`/units?${queryParams}`);

        dispatch({
            type: GET_UNITS,
            payload: res.data,
        });
        dispatch({type: SET_LOADING, payload: false});
    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
    }
};

export const addUnit = (formData) => async (dispatch) => {
    dispatch(clearAlerts());

    const config = {
        headers: {
            'Content-Type': 'application/json',
        },
    };

    try {
        const res = await axios.post('/units/', formData, config);

        dispatch(getUnits());

        dispatch({
            type: ADD_UNIT,
            payload: res.data,
        });

        dispatch(setAlert("O'lchov birligi yaratildi !", 'success'));

    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));

        if (err?.response?.data?.errors) {
            dispatch({
                type: UNIT_ERROR,
                payload: err.response.data,
            });
        } else {
            dispatch(setAlert("O'lchov birligi yaratilmadi !", 'danger'));
        }
    }
};

export const deleteUnit = (deleteUnitID, getPage) => async (dispatch) => {
    dispatch(clearAlerts());

    const config = {
        headers: {
            'Content-Type': 'application/json',
        },
    };

    try {
        const res = await axios.delete(`/units/${deleteUnitID}`, config);

        dispatch({
            type: REMOVE_DELETE_UNIT
        });

        dispatch(getUnits(getPage));

        dispatch(setAlert("O'lchov birligi o'chirildi !", 'success'));

        dispatch(clearErrors());

    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
    }
};

export const updateUnit = (updateUnitID, updateformData, getPage) => async (dispatch) => {
    dispatch(clearAlerts());

    const config = {
        headers: {
            'Content-Type': 'application/json',
        },
    };

    try {
        const res = await axios.put(`/units/${updateUnitID}`, updateformData, config);

        dispatch({type: REMOVE_UPDATE_UNIT});

        dispatch(getUnits(getPage));

        dispatch(setAlert("O'lchov birligi tahrirlandi !", 'success'));
    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
    }
};

export const setDeleteUnitId = (unitID) => async (dispatch) => {

    try {
        if (unitID !== null) {
            dispatch({
                type: SET_DELETE_UNIT_ID,
                payload: {
                    unitID
                }
            });

        } else {
            dispatch({
                type: REMOVE_DELETE_UNIT
            });
        }

    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
    }
};

export const setUpdateUnitId = (unitID) => async (dispatch) => {

    try {
        if (unitID !== null) {
            dispatch({
                type: SET_UPDATE_UNIT_ID,
                payload: {
                    unitID
                }
            });
        } else dispatch({type: REMOVE_UPDATE_UNIT});

    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
    }
};

export const clearErrors = () => async (dispatch) => {
    dispatch({
        type: CLEAR_ERRORS,
    });
};








