import {combineReducers} from 'redux';
import alert from './alert';
import auth from './auth';
import sidebar from './sidebar';
import navbar from './navbar';
import user from './user';
import employee from './employee';
import client from './client';
import supplier from './supplier';
import firm from './firm';
import warehouse from './warehouse';
import transport from './transport';
import unit from './unit';
import unitalternative from './unitalternative';
import product from './product';
import production from './production';
import material from './material';
import product_template from './product_template';
import salary from './salary'
import expense from "./expense";
import expense_template from "./expense_template";
import spare_part from "./spare_part";
import product_delivery from "./product_delivery";
import sale from "./sale";
import material_sale from "./material_sale";
import employees_transaction from "./employees_transaction";
import suppliers_transaction from "./suppliers_transaction";
import clients_transaction from "./clients_transaction";
import suppliers_materials_transaction from "./suppliers_materials_transaction";
import products_materials_transaction from "./products_materials_transaction";
import products_transaction from "./products_transaction";
import materials_transaction from "./materials_transaction";

export default combineReducers({
    alert,
    auth,
    // profile,
    // post,
    user,
    sidebar,
    navbar,
    employee,
    client,
    supplier,
    firm,
    warehouse,
    transport,
    unit,
    unitalternative,
    material,
    product,
    production,
    product_template,
    salary,
    expense,
    expense_template,
    spare_part,
    product_delivery,
    sale,
    material_sale,
    employees_transaction,
    suppliers_transaction,
    clients_transaction,
    suppliers_materials_transaction,
    products_materials_transaction,
    products_transaction,
    materials_transaction,
});




