import React from 'react';
import Modal from 'react-bootstrap/Modal'
import {useDispatch, useSelector} from "react-redux";
import {deleteExpense, setDeleteExpenseId} from "../../actions/expense";

const ExpenseDeleteForm = () => {

    const dispatch = useDispatch();

    const {delete_expense, currentPage, numberOfPages, expenses} = useSelector(state => state.expense);

    const closeModal = () =>{
        dispatch(setDeleteExpenseId(null))
    };

    let getPage = null;

    const onConfirm = () =>{

        if ((currentPage === numberOfPages) && (expenses.length === 1) ){
            getPage = (currentPage - 1);
        }
        else{
            getPage = currentPage;
        }
        dispatch(deleteExpense(delete_expense.id, getPage))
    };

    let condition = false;
    if(delete_expense !== null)
        condition = true;

    return (
        <div>
            <Modal show={condition} onHide={closeModal}>
                <Modal.Header className="bg-danger" closeButton>
                    <Modal.Title className="text-white" >Harajatni o'chirish</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Siz haqiqatdan ham ushbu harajatni o'chirmoqchimisiz ?
                </Modal.Body>
                <Modal.Footer>
                    <button onClick={closeModal} className="btn btn-secondary">YO'Q</button>
                    <button onClick={onConfirm} className="btn btn-primary">HA</button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default ExpenseDeleteForm;