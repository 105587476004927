import React, {Fragment, useState} from 'react';
import PropTypes from 'prop-types';
import {connect, useSelector} from 'react-redux';
import {getProductions} from "../../actions/production";
import ProductionUpdateForm from "./ProductionUpdateForm";
import ProductCreateForm from "./ProductCreateForm";
import SearchForm from "../layout/SearchForm";
import FilterByFirm from "../filters/FilterByFirm";
import CustomPaginationWithManyPages from "../layout/CustomPaginationWithManyPages";
import TableSpinner from "../layout/TableSpinner";
import ProductionItem from "./ProductionItem";
import FilterByDateRange from "../layout/FilterByDateRange";
import ProductionDeleteForm from "./ProductionDeleteForm";

const Productions = () => {

    const {productions, loading, numberOfPages, currentPage, total_productions} = useSelector(state => state.production);

    const [createFormShown, setCreateFormShown] = useState(false);
    const [updateFormShown, setUpdateFormShown] = useState(false);

    return (
        <Fragment>
            <div className="container-fluid p-0">

                <div className="row mt-4">
                    <div className="col-md-12 col-lg-8">
                        <h3 className="h4 mb-1"><strong>Mahsulot ishlab chiqarishlar</strong> ({total_productions}) </h3>
                    </div>
                </div>

                <div className="row mt-3">

                    <div className="col-3">
                        <FilterByFirm getPageItems={getProductions}/>
                    </div>

                    <div className="col-3">
                        <SearchForm getPageItems={getProductions}/>
                    </div>

                    <div className="col-3">
                        <FilterByDateRange getPageItems={getProductions}/>
                    </div>

                    {/*<div className="col-md-3">*/}
                    {/*    <div className="crud_actions text-end">*/}
                    {/*        {*/}
                    {/*            role_name === 'superadmin' || role_name === 'director' || role_name === 'manager' || role_name === 'storekeeper' ? (*/}
                    {/*                <button className="btn btn-primary" onClick={() => setCreateFormShown(true)}>*/}
                    {/*                    Mahsulot*/}
                    {/*                    <Plus/>*/}
                    {/*                </button>*/}
                    {/*            ) : ''*/}
                    {/*        }*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>

                <div className="row mt-2 position-relative">
                    <div className="col-xl-12 col-xxl-12 d-flex">

                        <div className="card w-100 table-responsive">
                            {loading && <TableSpinner/>}
                            <table className="table custom-table table-hover">
                                <thead className="table-light">
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Mahsulot</th>
                                    <th scope="col">Firm</th>
                                    <th scope="col">Sklad nomi</th>
                                    <th scope="col">Soni</th>
                                    <th scope="col">O'lchov</th>
                                    <th scope="col">Tan narxi</th>
                                    <th scope="col">Sana</th>
                                    <th scope="col">Yaratilgan</th>
                                    <th scope="col">Amallar</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    productions.map((item, index) => (
                                        <ProductionItem key={index} index={index} production={item}
                                                     handleShow={() => setUpdateFormShown(true)}
                                                     handleClose={() => setUpdateFormShown(false)}
                                        />
                                    ))
                                }

                                </tbody>
                            </table>

                        </div>

                    </div>

                </div>


                <div className="row">
                    <div className="col-xl-12 col-xxl-12 d-flex">
                        <CustomPaginationWithManyPages
                            numberOfPages={numberOfPages}
                            getPageItems={getProductions}
                            current_page={currentPage}
                        />
                    </div>
                </div>

            </div>

            {
                createFormShown ? (
                    <ProductCreateForm show={createFormShown}
                                       handleShow={() => setCreateFormShown(true)}
                                       handleClose={() => setCreateFormShown(false)}
                    />
                ) : ''
            }
            {
                updateFormShown ? (
                    <ProductionUpdateForm show={updateFormShown}
                                       handleShow={() => setUpdateFormShown(true)}
                                       handleClose={() => setUpdateFormShown(false)}
                    />
                ) : ''
            }

            <ProductionDeleteForm/>


        </Fragment>
    );
};


Productions.propTypes = {
    getProductions: PropTypes.func.isRequired,
    production: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    production: state.production,
});

export default connect(mapStateToProps, {getProductions})(Productions);
