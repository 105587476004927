import React, {Fragment, useEffect, useState} from "react";
import {getParams, getSelectedOption} from "../../utils/hooks/getParams";
import { clearMaterialTransactions} from "../../actions/material";

import {useDispatch, useSelector} from "react-redux";
import Select from "react-select";
import {getProductOptions} from "../../actions/product";

const FilterByProduct = (props) => {

    const dispatch = useDispatch();
    const {getPageItems, getMaterialOptions} = props;

    const {
        product_options,
        load = false
    } = useSelector(state => state.product);

    useEffect(() => {
        setIsFirst(false);
    }, [])

    useEffect(() => {
        if (getProductOptions)
            dispatch(getProductOptions())
    }, [getProductOptions])


    const [currentProduct, setCurrentProduct] = useState(Number(getParams().product_id) || '');
    const [isFirst, setIsFirst] = useState(true);
    useEffect(() => {
        const params = getParams();
        if (isFirst) {

            if (params.hasOwnProperty('product_id')) {
                setCurrentProduct(Number(params.product_id));
            }
        }
    }, [isFirst])

    const [productItems, setProductItems] = useState([
        {
            label: 'Tanlang',
            value: ''
        }
    ]);
    const handleSelect = (data, type) => {
        dispatch(clearMaterialTransactions())
        if (data.value !== '') {
            setCurrentProduct(data.value);
            const url = new URL(window.location);
            url.searchParams.set('product_id', data.value);
            url.searchParams.set('page', 1);
            window.history.pushState({}, '', url);
            const params = getParams();
            dispatch(getPageItems(1, params));

        } else {
            setCurrentProduct('');
            const url = new URL(window.location);
            url.searchParams.delete('product_id');
            url.searchParams.set('page', 1);
            window.history.pushState({}, '', url);
            const params = getParams();
            if (params['product_id']) delete params['product_id'];
            dispatch(getPageItems(1, params));
        }
    };
    useEffect(() => {
        if (product_options.length > 0) {
            let items = [
                {
                    label: 'Tanlang',
                    value: ''
                }
            ];
            product_options.map(item => items.push({
                label: item?.name,
                value: item?.id
            }))
            setProductItems(items);
        }
    }, [product_options])
    return (
        <Fragment>

            <Select styles={customStyles}
                    value={getSelectedOption(currentProduct, productItems)}
                    options={productItems}
                    onChange={handleSelect}
                    menuPortalTarget={document.body}
                    menuPosition={'fixed'}
            />
        </Fragment>
    )
}
const customStyles = {
    ///.....
    menuPortal: provided => ({...provided, zIndex: 9999}),
    menu: provided => ({...provided, zIndex: 9999})
    ///.....
}
export default FilterByProduct;