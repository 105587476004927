import React, {Fragment, useEffect, useRef} from "react";
import moment from 'moment'
import {useSelector} from "react-redux";
import SearchForm from "../layout/SearchForm";
import FilterByMonth from "../layout/FilterByMonth";
import {getParams} from "../../utils/hooks/getParams";
import FilterByMaterial from "../filters/FilterByMaterial";
import FilterByFirm from "./FilterByFirm";
import ProductMaterialDataRow from "./ProductMaterialDataRow";
import {getProductsMaterialsTransactions} from "../../actions/products_materials_transaction";
import {getMaterialOptions} from "../../actions/material";
import useDates from "../../utils/useDates";
import CustomPaginationWithManyPages from "../layout/CustomPaginationWithManyPages";

const ProductsMaterialCalculationsNew = () => {

    const {
        auth
    } = useSelector(state => state);
    const {role_name} = auth?.user || {};

    const params = getParams()
    const {dates, updateDates} = useDates(params.month);

    const {
        products,
        loading,
        numberOfPages,
        current_page,
    } = useSelector(state => state.products_materials_transaction);


    const {material_id} = getParams();

    const scrollableContainer = useRef();
    useEffect(() => {
        if (scrollableContainer.current) {
            scrollableContainer.current.scrollLeft += scrollableContainer.current.scrollHeight * 30;
        }
    }, [scrollableContainer])


    const onMonthChange = (newYearMonth) => {
        updateDates(newYearMonth);
    }
    return (
        <div className={'px-4'}>
            <div className="row mt-4 mb-2 justify-content-between">
                {
                    role_name === 'superadmin' || role_name === 'director' || role_name === 'accountant' ? (
                        <div className="col-md-3 align-self-start ">
                            <FilterByFirm getPageItems={getMaterialOptions} is_filter={true}/>
                        </div>
                    ) : (
                        <div className="col-md-0 align-self-start ">

                        </div>
                    )
                }
                <div className="col-md-3 align-self-start ">

                    {
                        role_name === 'manager' ? (
                            <FilterByMaterial getPageItems={getProductsMaterialsTransactions}
                                              getMaterialOptions={getMaterialOptions} auto={true}/>
                        ) : (
                            <FilterByMaterial getPageItems={getProductsMaterialsTransactions}
                                              getMaterialOptions={getMaterialOptions}/>
                        )
                    }

                </div>
                <div className="col-md-3">
                    <SearchForm getPageItems={getProductsMaterialsTransactions}/>
                </div>
                <div className="col-md-3 align-self-end ">
                    <FilterByMonth change={onMonthChange} getPageItems={getProductsMaterialsTransactions}/>
                </div>
            </div>

            <div className="row mt-3">
                <div className={`loader-container ${loading ? 'loading' : ''} `}></div>

                <div ref={scrollableContainer} className={`tableFixHead ${loading ? 'data_loader' : ''}`}>

                    {
                        material_id && products ? (
                            <table className={'table-hover custom-hover'}>
                                <thead>
                                <tr>
                                    <th className="sticky-col" style={{width: "2em"}}>#</th>
                                    <th className="sticky-col2">Mahsulot</th>
                                    {
                                        dates?.map((item, index, items) => (
                                            items.length - 1 === index ? (
                                                <th key={`date${index}`} className="sticky-col"
                                                    style={{textAlign: "center"}}
                                                    colSpan="2">
                                                    {item}
                                                    {moment(new Date()).format('YYYY-MM-DD') === item ? '(bugun)' : ''}
                                                </th>
                                            ) : (
                                                <th key={`date${index}`} className="sticky-col"
                                                    style={{textAlign: "center"}}
                                                    colSpan="2">{item}
                                                </th>
                                            )
                                        ))
                                    }
                                    <th className="sticky-col" style={{textAlign: "center", right: 0}}
                                        colSpan="4">Umumiy hisob
                                    </th>
                                </tr>
                                <tr>
                                    <th className="sticky-col">-</th>
                                    <th className="sticky-col2"></th>
                                    {
                                        dates?.map((subitem, index) => (
                                            <Fragment key={`th${index}`}>
                                                {/*<th className="sticky-col"*/}
                                                {/*    style={{textAlign: "center"}}>Ishlatilgan*/}
                                                {/*</th>*/}
                                                <th className="sticky-col"
                                                    style={{textAlign: "center"}}>
                                                    Material
                                                </th>
                                                <th className="sticky-col"
                                                    style={{textAlign: "center"}}>
                                                    Narxi
                                                </th>
                                            </Fragment>
                                        ))
                                    }
                                    <th className="sticky-col"
                                        style={{textAlign: "center", minWidth: "100px", right: "300px"}}>
                                        oy hajmi
                                    </th>
                                    <th className="sticky-col"
                                        style={{textAlign: "center", minWidth: "100px", right: "200px"}}>
                                        oy narxi
                                    </th>
                                    <th className="sticky-col"
                                        style={{textAlign: "center", minWidth: "100px", right: "100px"}}>
                                        Umumiy hajmi
                                    </th>
                                    <th className="sticky-col"
                                        style={{textAlign: "center", minWidth: "100px", right: "0px"}}>
                                        Umumiy narxi
                                    </th>

                                    {/*<th className="sticky-col"*/}
                                    {/*    style={{textAlign: "center", minWidth: "100px", right: "200px"}}>*/}
                                    {/*    O'tgan oy*/}
                                    {/*</th>*/}

                                    {/*<th className="sticky-col"*/}
                                    {/*    style={{textAlign: "center", minWidth: "100px", right: "100px"}}>*/}
                                    {/*Shu oy*/}
                                    {/*</th>*/}
                                    {/*<th className="sticky-col"*/}
                                    {/*    style={{textAlign: "center", minWidth: "100px", right: "0px"}}>*/}
                                    {/*    Umumiy sarf*/}
                                    {/*</th>*/}
                                </tr>
                                </thead>

                                <tbody>
                                {products?.map((item, index) =>
                                    (<ProductMaterialDataRow index={index} item={item} dates={dates} key={index}/>)
                                )}
                                </tbody>
                            </table>
                        ) : 'Firma va material tanlang!'
                    }

                </div>
            </div>
            <div className="row mt-3">
                <CustomPaginationWithManyPages
                    numberOfPages={numberOfPages}
                    getPageItems={getProductsMaterialsTransactions}
                    current_page={current_page}
                />
            </div>
        </div>

    )
}

export default ProductsMaterialCalculationsNew;

