import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {setDeleteSupplierId, setUpdateSupplierId} from "../../actions/supplier";
import {Link} from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash, faEye } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'react-bootstrap';

const SupplierItem = ({
                          auth,
                          setDeleteSupplierId,
                          setUpdateSupplierId,
                          number,
                          supplier: {
                              id, name, contractor,
                              inn, phone,
                              is_special_client,
                              bank_account, client_id,
                              client,
                              date: created_at
                          },
                          showActions,
                      }) => {

    const {role_name} = auth?.user || {};

    const setDeleteSupplier = (id) => {
        setDeleteSupplierId(id);
    };

    const setUpdateSupplier = (id) => {
        setUpdateSupplierId(id);
    };

    return (
        <Fragment>
            <tr>
                <th scope="row">{number}</th>
                <td>{name}</td>
                <td>{contractor}</td>
                <td>{inn}</td>
                <td>
                    <Link to={`clients/${client_id}`}>{client?.name}</Link>
                </td>
                <td>{phone}</td>
                <td>{bank_account}</td>
                <td>
                    <div className="d-flex gap-2">
                        <Link to={`suppliers/${id}`}>
                            <Button
                                variant="outline-secondary"
                                size="sm"
                                className="d-flex align-items-center justify-content-center"
                                style={{ width: '32px', height: '32px', padding: 0 }}
                            >
                                <FontAwesomeIcon icon={faEye} />
                            </Button>
                        </Link>
                        {
                            role_name === 'director' || role_name === 'superadmin' || role_name === 'manager' ? (
                                <Button
                                    variant="outline-primary"
                                    size="sm"
                                    className="d-flex align-items-center justify-content-center"
                                    style={{ width: '32px', height: '32px', padding: 0 }}
                                    onClick={() => setUpdateSupplier(id)}
                                >
                                    <FontAwesomeIcon icon={faEdit} />
                                </Button>
                            ) : ''
                        }
                        {
                            role_name === 'director' || role_name === 'superadmin' ? (
                                <Button
                                    variant="outline-danger"
                                    size="sm"
                                    className="d-flex align-items-center justify-content-center"
                                    style={{ width: '32px', height: '32px', padding: 0 }}
                                    onClick={() => setDeleteSupplier(id)}
                                >
                                    <FontAwesomeIcon icon={faTrash} />
                                </Button>
                            ) : ''
                        }
                    </div>
                </td>
            </tr>
        </Fragment>
    );
};

SupplierItem.defaultProps = {
    showActions: true,
};

SupplierItem.propTypes = {
    supplier: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
});

export default connect(mapStateToProps, {setDeleteSupplierId, setUpdateSupplierId})(
    SupplierItem
);
