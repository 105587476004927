import {
    ADD_SALARY,
    CLEAR_ERRORS,
    EMPLOYEES_ID_ERROR,
    GET_EMPLOYEES_ID_DATA,
    GET_SALARIES,
    GET_SALARIES_DATA,
    GET_SALARY,
    REMOVE_DELETE_SALARY,
    REMOVE_UPDATE_SALARY,
    SALARY_ERROR,
    SET_DELETE_SALARY_ID,
    SET_UPDATE_SALARY_ID,
    UPDATE_SALARY_TRANSACTION_ERROR,
    UPDATE_SALARY_TRANSACTION_SUCCESS,
} from '../actions/types';

const initialState = {
    salaries: [],
    employees: [],
    dates: [],
    employees_ID_data: [],
    salaries_data: {},
    update_salary: null,
    update_transaction_id: null,
    delete_salary: null,
    loading: false,
    numberOfPages: 1,
    total_salaries: null,
    error: null,
};

// eslint-disable-next-line

export default function (state = initialState, action) {

    const {type, payload} = action;

    switch (type) {

        case GET_EMPLOYEES_ID_DATA:

            return {
                ...state,
                employees_ID_data: payload.data,
                loading: false
            };

        case EMPLOYEES_ID_ERROR:
            return {
                ...state,
                error: payload,
                loading: false,
            };

        case GET_SALARY:
            return {
                ...state,
                current: payload,
                error: null,
                loading: false,
            };

        case GET_SALARIES:
            return {
                ...state,
                salaries: payload.data,
                numberOfPages: payload.meta.last_page,
                currentPage: payload.meta.current_page,
                total_salaries: payload.meta.total,
                error: null,
                loading: false,
            };

        case GET_SALARIES_DATA:
            return {
                ...state,
                salaries_data: payload,
                loading: false,
            };

        case ADD_SALARY:
            return {
                ...state,
                salaries: [payload],
                error: null,
                loading: false,
            };

        case UPDATE_SALARY_TRANSACTION_SUCCESS:
            return {
                ...state,
                update_transaction_id: true,
                error: null,
                loading: false,
            };

        case UPDATE_SALARY_TRANSACTION_ERROR:
            return {
                ...state,
                update_transaction_id: payload.transaction_id,
                error: {message: payload.message},
                loading: false,
            };

        case REMOVE_DELETE_SALARY:
            return {
                ...state,
                delete_salary: null,
            };

        case REMOVE_UPDATE_SALARY:
            return {
                ...state,
                update_salary: null,
            };

        case SET_DELETE_SALARY_ID:

            return {
                ...state,
                delete_salary: state.salaries.find((item) => item.id === action.payload.salaryID)
            };

        case SET_UPDATE_SALARY_ID:
            return {
                ...state,
                update_salary: state.salaries.find((item) => item.id === action.payload.salaryID)
            };

        case SALARY_ERROR:
            return {
                ...state,
                error: payload,
                loading: false,
            };

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
                loading: false,
            };

        default:
            return state;
    }
}
