import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {setDeleteUnitId, setUpdateUnitId} from "../../actions/unit";
import { useHistory} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'react-bootstrap';

const UnitItem = ({
                      auth,
                      setDeleteUnitId,
                      setUpdateUnitId,
                      unit: {
                          id, name, symbol, value,
                          date: created_at
                      },
                      showActions,
                      number
                  }) => {

    const history = useHistory();

    const {role_name} = auth?.user || {};

    const setDeleteUnit = (id) => {
        setDeleteUnitId(id);
    };

    const setUpdateUnit = (id) => {
        setUpdateUnitId(id);
    };

    return (
        <Fragment>
            <tr>
                <th scope="row">{number}</th>
                <td>{name}</td>
                <td>{symbol}</td>
                <td>{value}</td>
                <td>
                    <div className="d-flex gap-2">
                        <Button
                            variant="outline-primary"
                            size="sm"
                            className="d-flex align-items-center justify-content-center"
                            style={{ width: '32px', height: '32px', padding: 0 }}
                            onClick={() => setUpdateUnit(id)}
                        >
                            <FontAwesomeIcon icon={faEdit} />
                        </Button>
                        {
                            role_name === 'director' || role_name === 'superadmin' ? (
                                <Button
                                    variant="outline-danger"
                                    size="sm"
                                    className="d-flex align-items-center justify-content-center"
                                    style={{ width: '32px', height: '32px', padding: 0 }}
                                    onClick={() => setDeleteUnit(id)}
                                >
                                    <FontAwesomeIcon icon={faTrash} />
                                </Button>
                            ) : ''
                        }
                    </div>
                </td>
            </tr>
        </Fragment>
    );
};

UnitItem.defaultProps = {
    showActions: true,
};

UnitItem.propTypes = {
    unit: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
});

export default connect(mapStateToProps, {setDeleteUnitId, setUpdateUnitId})(
    UnitItem
);
