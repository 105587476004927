import React, {Fragment, useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import {addEmployee, clearErrors, getFirmIdData} from "../../actions/employee";

const EmployeeCreateForm = ({
                                auth,
                                employee: {firm_ID_DATA, employees, loading, error},
                                clearErrors,
                                addEmployee,
                                handleShow,
                                getFirmIdData,
                                handleClose,
                                show
                            }) => {


    const {role_name} = auth?.user || {};

    useEffect(() => {
        getFirmIdData();
    }, [getFirmIdData]);

    const formRef = useRef();

    const defaultValues = {
        first_name: '',
        last_name: '',
        inn: '',
        address: '',
        phone: '',
        firm_id: '',
        profession: '',
        status: ''
    };

    const [inputs, setInputs] = useState(defaultValues);
    const [errors, setErrors] = useState({});

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = {
            first_name: inputs.first_name,
            last_name: inputs.last_name,
            inn: inputs.inn,
            address: inputs.address,
            phone: inputs.phone,
            firm_id: inputs.firm_id,
            profession: inputs.profession,
            status: inputs.status,
        };
        addEmployee(data);
    };

    const handleChange = e => {
        setErrors({});
        e.target.oldValue = e.target.value;
        setInputs(prevState => ({...prevState, [e.target.name]: e.target.value}));
    };

    useEffect(() => {
        if (error && loading === false) {
            const errorsItems = error?.errors;
            let errorObject = {};
            if (error && error?.errors) {
                Object.keys(error?.errors).forEach((item, index) => errorObject[item] = errorsItems[item][0]);
            }
            setErrors(errorObject);

        }
    }, [error]);

    useEffect(() => {
        clearErrors();
        setErrors({});
        setInputs(defaultValues);
    }, [employees]);

    const onClose = () => {
        clearErrors();
        setInputs(defaultValues);
        setErrors({});
        handleClose();
    };


    return (
        <Fragment>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header className="py-4" closeButton>
                    <Modal.Title>Yangi xodim qo'shish</Modal.Title>
                </Modal.Header>

                <Modal.Body className="pt-3 pb-5 mx-3">

                    <form onSubmit={handleSubmit} ref={formRef}>

                        <div className="mb-3">
                            <label htmlFor="first_name">Ism</label>
                            <input
                                name="first_name"
                                className={'form-control'}
                                type="text"
                                value={inputs?.first_name || ''}
                                placeholder={'kiriting'}
                                onChange={handleChange}
                                onKeyUp={e => e.target.oldValue = e.target.value}
                            />

                            <div className="error">
                                {errors?.first_name}
                            </div>
                        </div>

                        <div className="mb-3">
                            <label htmlFor="last_name">Familiya</label>
                            <input
                                name="last_name"
                                className={'form-control'}
                                type="text"
                                value={inputs?.last_name || ''}
                                placeholder={'kiriting'}
                                onChange={handleChange}
                                onKeyUp={e => e.target.oldValue = e.target.value}
                            />

                            <div className="error">
                                {errors?.last_name}
                            </div>
                        </div>


                        <div className="mb-3">
                            <label htmlFor="inn">INN</label>
                            <input
                                name="inn"
                                className={'form-control'}
                                type="text"
                                value={inputs?.inn || ''}
                                placeholder={'kiriting'}
                                onChange={handleChange}
                                onKeyUp={e => e.target.oldValue = e.target.value}
                            />

                            <div className="error">
                                {errors?.inn}
                            </div>
                        </div>


                        <div className="mb-3">
                            <label htmlFor="address">Manzil</label>
                            <input
                                name="address"
                                className={'form-control'}
                                type="text"
                                value={inputs?.address || ''}
                                placeholder={'kiriting'}
                                onChange={handleChange}
                                onKeyUp={e => e.target.oldValue = e.target.value}
                            />

                            <div className="error">
                                {errors?.address}
                            </div>
                        </div>


                        <div className="mb-3">
                            <label htmlFor="phone">Telefon</label>
                            <input
                                name="phone"
                                className={'form-control'}
                                type="phone"
                                value={inputs?.phone || ''}
                                placeholder={'kiriting'}
                                onChange={handleChange}
                                onKeyUp={e => e.target.oldValue = e.target.value}
                            />

                            <div className="error">
                                {errors?.phone}
                            </div>
                        </div>

                        <div className="mb-3">
                            <label htmlFor="firm_id">Firma</label>
                            <select name="firm_id"
                                    value={inputs?.firm_id || ''}
                                    id="firm_id"
                                    className="form-control"
                                    onChange={handleChange}
                                    onKeyUp={e => e.target.oldValue = e.target.value}
                            >
                                <option value="">Tanlang</option>
                                {
                                    firm_ID_DATA.map((item, index) => (
                                        <option key={index} value={item.id}>{item.name}</option>
                                    ))
                                }
                            </select>
                            <div className="error">
                                {errors?.firm_id}
                            </div>
                        </div>

                        <div className="mb-3">
                            <label htmlFor="profession">Kasbi (
                                <u>Haydovchi</u>
                                )</label>
                            <input
                                name="profession"
                                className={'form-control'}
                                type="text"
                                value={inputs?.profession || ''}
                                placeholder={'kiriting'}
                                onChange={handleChange}
                                onKeyUp={e => e.target.oldValue = e.target.value}
                            />

                            <div className="error">
                                {errors?.profession}
                            </div>
                        </div>

                        <div className="mb-3">

                            <div className="d-flex">
                                <div className="d-flex align-items-center">
                                    <input
                                        name="status"
                                        id="name1"
                                        className={'me-2'}
                                        type="radio"
                                        value="hired"
                                        onChange={handleChange}
                                        onKeyUp={e => e.target.oldValue = e.target.value}
                                    />
                                    <label htmlFor="name1">Ishga olingan</label>
                                </div>

                                <div className="ms-4 d-flex align-items-center">
                                    <input
                                        name="status"
                                        id="name2"
                                        className={'me-2'}
                                        type="radio"
                                        value="fired"
                                        onChange={handleChange}
                                        onKeyUp={e => e.target.oldValue = e.target.value}
                                    />
                                    <label htmlFor="name2">Ishdan olingan</label>
                                </div>
                            </div>

                            <div className="error">
                                {errors?.status}
                            </div>
                        </div>


                        <div className="d-flex gap-2">
                            <Button className="w-50" variant="secondary" type="button" onClick={onClose}>
                                Bekor qilish
                            </Button>
                            <Button className="w-50" variant="primary" type="submit">Saqlash</Button>
                        </div>
                    </form>


                </Modal.Body>

            </Modal>
        </Fragment>
    );
};

EmployeeCreateForm.propTypes = {
    addEmployee: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    employee: state.employee,
    auth: state.auth,
});

export default connect(mapStateToProps, {addEmployee, clearErrors, getFirmIdData})(EmployeeCreateForm);
