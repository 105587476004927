import React from "react";
import MoneyFormat from "../../layout/MoneyFormat";
import SingleProductReport from "../SingleProductReport";

const ProductAbout = ({product}) => {
    const {id} = product;
    return (
        <>
            <div className="card">
                <div className="card-header">
                    Mahsulot ma'umotlari
                </div>
                <div
                    className="d-flex align-items-center">
                    <div
                        className="card-title m-2">Mahsulot
                        nomi:
                    </div>
                    <div
                        className="text-muted m-0">{product?.name}</div>
                </div>
                <div
                    className="d-flex align-items-center">
                    <div
                        className="card-title m-2">Mahsulot
                        ta'rifi:
                    </div>
                    <div
                        className="text-muted m-0">{product?.description}</div>
                </div>
                <div
                    className="d-flex align-items-center">
                    <div
                        className="card-title m-2">O'lchov
                        birligi:
                    </div>
                    <div
                        className="text-muted m-0">{product?.unit?.name}</div>
                </div>
                <div
                    className="d-flex align-items-center">
                    <div
                        className="card-title m-2">Hozirgi
                        tan narxi:
                    </div>
                    <div
                        className="text-muted m-0">
                        <MoneyFormat
                            value={product?.price}
                            currency={"so'm"}/>
                    </div>
                </div>
                <div
                    className="d-flex align-items-center">
                    <div
                        className="card-title m-2">Mahsulot
                        Skladdagi soni:
                    </div>
                    <div
                        className="text-muted m-0">
                        {product?.quantity} {` `} {product?.unit?.symbol}
                    </div>
                </div>

                <div className="card border-0 mb-3">
                    <div className="card-header">
                        <h5 className="card-title text-dark text-decoration-underline mb-0 text-left">Mahsulotlar</h5>
                    </div>
                    <div
                        className="card-body text-center">
                        {
                            product?.materials?.length > 0 ?
                                (
                                    product.materials.map((item, index, items) => (
                                        <div
                                            key={index}
                                            className="d-flex align-items-center">
                                            <div
                                                className="card-title m-2">
                                                <a href={`/materials/${item.material_id}`}
                                                   rel="noreferrer">{item.name}</a>:
                                            </div>
                                            <div
                                                className="text-muted  m-0">
                                                {item?.unit_value} {item?.unit_alternative?.symbol}

                                            </div>
                                        </div>
                                    ))
                                ) : ''
                        }
                    </div>
                </div>

            </div>
            <div className="card">
                <div className="card-header">Hisobot chiqarish(<b>ishlab chiqarish va sotuv</b>)</div>
                <div className="card-body">
                    <SingleProductReport id={id}/>
                </div>
            </div>
        </>

    )
}

export default ProductAbout;