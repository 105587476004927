import React, {Fragment, useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Button from 'react-bootstrap/Button';
import Select from 'react-select'
import {
    clearErrors,
    getClientOptions,
    getProductOptions,
    updateSale
} from "../../../actions/sale";
import {Accordion} from "react-bootstrap";
import {ArrowLeft, Plus, X} from "react-feather";
import {Multiselect} from "multiselect-react-dropdown";
import {getSelectedOption} from "../../../utils/hooks/getParams";
import moment from "moment";
import {getWarehouseOptions} from "../../../actions/product";
import {getFirmOptions} from "../../../actions/user";
import {useLocation} from "react-router-dom";
import ButtonLoader from "../../layout/ButtonLoader";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const SimpleSaleCreateForm = ({handleClose, hideLoader, showLoader}) => {
    const {sale, user, product} = useSelector(state => state);
    const dispatch = useDispatch();
    const {
        update_sale,
        client_options,
        product_options,
        sales,
        loading,
        error
    } = sale;
    const { firm_options } = user;
    const { warehouse_options } = product;


    useEffect(() => {
        showLoader();
        (() => {
            dispatch(getClientOptions())
            dispatch(getFirmOptions());
        })()
    }, []);

    const formRef = useRef();
    const defaultValues = {
        is_special_sale: '',
        client_id: '',
        seller_id: '',
        firm_id: '',
        warehouse_id: '',
        date: '',
        comment: '',
        products: []
    };
    const [selectedFirm, setSelectedFirm] = useState({label: 'Tanlang', value: ''});
    const [firmOptions, setFirmOptions] = useState([]);
    const [selectedWarehouse, setSelectedWarehouse] = useState({label: 'Tanlang', value: ''});
    const [warehouseOptions, setWarehouseOptions] = useState([]);

    const [productOptions, setProductOptions] = useState(product_options);
    const [selectedDynamicProductOptions, setSelectedDynamicProductOptions] = useState([]);
    const [selectedProductOptions, setSelectedProductOptions] = useState([]);
    const [clientOptions, setClientOptions] = useState([]);


    const [inputs, setInputs] = useState(defaultValues);
    const [errors, setErrors] = useState({});


    const handleChange = e => {
        setErrors({});
        e.target.oldValue = e.target.value;
        setInputs(prevState => ({...prevState, [e.target.name]: e.target.value}));
    };


    useEffect(() => {
        clearErrors();
        setErrors({});
        if (!update_sale) return;
        if (update_sale){
            const existing_items = update_sale.products.map(item => (
                    {id: item.product_id, name: item.name,
                        quantity: item?.quantity,
                        old_quantity: parseFloat(item?.quantity),
                        price: parseInt(item?.price),
                        in_stock: parseFloat(item?.in_stock),
                        unit: item?.unit,
                        net_price: item.net_price
                    }
                )
            );
            setInputs({
                id: update_sale.id,
                client_id: update_sale.client_id,
                firm_id: update_sale.firm_id,
                seller_id: update_sale.seller_id,
                warehouse_id: update_sale.warehouse_id,
                is_special_sale: update_sale.is_special_sale,
                products: [...existing_items],
                date: update_sale.date,
                comment: update_sale.comment,
            })
        }else{
            setInputs(defaultValues);
        }
        if (update_sale.firm_id){
            dispatch(getProductOptions({is_for_sale: true, firm_id: update_sale.firm_id}));
            dispatch(getWarehouseOptions({firm_id: update_sale.firm_id}));
        }
    }, [update_sale]);

    useEffect(() => {

        if (update_sale.hasOwnProperty('products')) {

            let ids = update_sale.products.map(item => item.product_id);
            let items = [];

            product_options.filter(item => {
                if (ids.includes(item.id)) {
                    const selectedSubitem = inputs.products.find(subItem => subItem.id === item.id);
                    if (selectedSubitem){
                        items.push(selectedSubitem);
                    }
                    return item;
                }
            });
            const leftItems = product_options.filter(item => !ids.includes(item.id))
            setProductOptions(leftItems);
            setSelectedProductOptions(items);
            hideLoader()
        }
    }, [product_options]);

    useEffect(() => {
        let options = [];
        client_options?.forEach((item) => options.push({
            label: item.name,
            value: item.id
        }));
        setClientOptions(options);
    }, [client_options]);

    useEffect(() => {
        if (update_sale.hasOwnProperty('firm_id')){
            setInputs(prevState => ({...prevState, firm_id:update_sale.firm_id}))
            setSelectedFirm(getSelectedOption(update_sale.firm_id, firmOptions))
        }
    }, [firmOptions]);

    useEffect(() => {
        if (update_sale.hasOwnProperty('warehouse_id')){
            setSelectedWarehouse(getSelectedOption(update_sale.warehouse_id, warehouseOptions))
        }
    }, [warehouseOptions]);



    useEffect(() => {
        let items = [];
        firm_options.forEach((item) => items.push({
            label: `${item.name}`,
            value: item.id
        }));
        setFirmOptions(items);

    }, [firm_options]);

    useEffect(() => {
        let options = [];
        warehouse_options?.forEach((item) => options.push({
            label: item.name,
            value: item.id
        }));
        setWarehouseOptions(options);
    }, [warehouse_options]);


    const onClose = () => {
        clearErrors();
        setInputs(defaultValues);
        setErrors({});
        handleClose();
        setProductOptions([]);
        setSelectedProductOptions([]);
    };



    const onSelectProduct = (data) => {
        setSelectedDynamicProductOptions(data);
    };


    const onRemoveProduct = (data) => {
        setSelectedDynamicProductOptions(data)
    };

    const onClickMoveSelectedMaterials = () => {
        if (selectedDynamicProductOptions.length > 0) {
            setSelectedProductOptions([...selectedProductOptions, ...selectedDynamicProductOptions]);
            let ids = selectedDynamicProductOptions.map(({id}) => id);
            const filteredMaterials = productOptions.filter((item) => !ids.includes(item.id));
            setProductOptions(filteredMaterials);
            setSelectedDynamicProductOptions([]);
            const inputProducts = [];
            selectedProductOptions.map(item => inputProducts.push({
                id: item.id,
                quantity: item?.quantity || 0,
                net_price: item?.net_price || 0,
            }));
            inputs.products = [...inputs.products, ...inputProducts];

            setInputs(inputs);
        }
    };

    const handleSelect = (data, type) => {
        setErrors({});
        if (type.name === 'firm_id') {
            dispatch(getWarehouseOptions({firm_id: data.value}));
            dispatch(getProductOptions({is_for_sale: true, firm_id: data.value}));
        }
        setInputs(prevState => ({...prevState, [type.name]: data.value}))
    };

    const onChangeInput = (e) => {
        let product_id = parseInt(e.target.dataset.id);
        const cleanItems = [];
        const items = selectedProductOptions.map((item) => {
            if (item.id === product_id) {
                if (e.target.getAttribute('name') === 'quantity') {
                    if (e.target.value !== '') {
                        if (Number(e.target.value) > 0) {
                            if (Number(e.target.value) <= (item?.in_stock + item?.old_quantity) && item?.in_stock !== 0) {
                                item[e.target.getAttribute('name')] = e.target.value;
                            }
                        } else {
                            item[e.target.getAttribute('name')] = 1;
                        }
                    } else {
                        item[e.target.getAttribute('name')] = e.target.value;
                    }
                } else {
                    item[e.target.getAttribute('name')] = e.target.value;
                }

            }
            // cleanItems.push({...item})
            cleanItems.push({
                id: item.id,
                price: item?.price || 0,
                quantity: item?.quantity || 1,
                product_id: item?.product_id,
            });
            return item;
        });

        setSelectedProductOptions(items);

        setErrors({});
        setInputs(prevState => ({...prevState, products: [...cleanItems]}));
    };

    const onClickRemoveFromSelectedMaterials = (id) => {
        const filteredSelectedMaterials = selectedProductOptions.filter(item => item.id !== Number(id));
        setSelectedProductOptions([...filteredSelectedMaterials]);
        let ids = filteredSelectedMaterials.map(({id}) => id);
        const filteredMaterials = product_options.filter((item) => !ids.includes(item.id));
        setProductOptions(filteredMaterials);
        setSelectedDynamicProductOptions([]);
    }
    const params = useQuery()

    const handleSubmit = async (event) => {
        event.preventDefault();

        const items = inputs.products.map((item) => ({id: item.id, product_id: item.product_id, quantity: item?.quantity, price: item?.price}));
        const data = {
            is_special_sale: inputs?.is_special_sale,
            firm_id: inputs?.firm_id,
            date: inputs?.date,
            client_id: inputs?.client_id,
            comment: inputs?.comment,
            products: items,
        };
        const page = params.get("page")
        const response = await dispatch(updateSale(update_sale.id, data, page ?? 1));
        if (response === true) {
            setInputs(defaultValues);
            setSelectedDynamicProductOptions([]);
            setSelectedProductOptions([]);
        }
    };

    useEffect(() => {
        getProductOptions({is_for_sale: true});
        if (error && error.hasOwnProperty('errors')) {
            const errorsItems = error.errors;
            let errorObject = {};
            if (error?.errors) {
                Object.keys(error.errors).forEach((item) => errorObject[item] = errorsItems[item][0]);
            }
            setErrors(errorObject);
        }
    }, [sales])

    return (
        <Fragment>
            <form onSubmit={handleSubmit} ref={formRef}>
                <div className="mb-3">
                    <label htmlFor="client_id" className="form-label">
                        Klient
                    </label>
                    <Select
                        name={'client_id'}
                        options={clientOptions}
                        value={getSelectedOption(inputs?.client_id, clientOptions)}
                        onChange={handleSelect}
                        defaultValue={{label: 'Tanlang', value: ''}}
                    />

                    <div className="error">
                        {errors?.client_id}
                    </div>
                </div>


                <div className="mb-3">
                    <label htmlFor="firm_id">Firma</label>
                    <Select
                        name={'firm_id'}
                        options={firmOptions}
                        value={selectedFirm}
                        onChange={handleSelect}
                        defaultValue={{label: 'Tanlang', value: ''}}
                    />
                    <div className="error">
                        {errors?.firm_id}
                    </div>
                </div>

                <div className="mb-3">
                    <label htmlFor="firm_id">Sklad</label>
                    <Select
                        name={'warehouse_id'}
                        options={warehouseOptions}
                        value={selectedWarehouse}
                        onChange={handleSelect}
                        defaultValue={{label: 'Tanlang', value: ''}}
                    />
                    <div className="error">
                        {errors?.warehouse_id}
                    </div>
                </div>


                <div className="mb-3">
                    <div className="row materials__mover">
                        <div className="col-lg-5 col-md-12">
                            <label className="form-label mb-2">Tarkibi</label>

                            <div className="card p-2">
                                <Accordion>
                                    {
                                        selectedProductOptions && selectedProductOptions.length > 0 ? selectedProductOptions.map((item, index) => (
                                                <Accordion.Item key={index} eventKey={item.id}>
                                                    <Accordion.Header>{item.name} ({item?.in_stock} {item?.unit?.symbol})</Accordion.Header>
                                                    <Accordion.Body>

                                                        <div className="form-group ">

                                                            <div
                                                                className="input-group mb-1 justify-content-between">
                                                                <label htmlFor="ID">Tan narxi</label>
                                                                <X className={'pointered-icon'}
                                                                   onClick={() => onClickRemoveFromSelectedMaterials(item.id)}
                                                                   color={'red'}
                                                                />
                                                            </div>
                                                            <input
                                                                className="form-control my-2 float-right"
                                                                type="text"
                                                                name="id"
                                                                id="ID"
                                                                value={`${item?.net_price} so'm`}
                                                                required
                                                                disabled
                                                            />
                                                            <div className="input-group mb-3">

                                                                <input type="text"
                                                                       className="form-control"
                                                                       placeholder="Miqdori"
                                                                       name='quantity'
                                                                       min={1}
                                                                       value={item?.quantity}
                                                                       data-id={item.id}
                                                                       id={`quantity${item.id}`}
                                                                       required
                                                                       onChange={onChangeInput}
                                                                />
                                                                <span className="input-group-text"
                                                                      id="integer">
                                                                                    {item.unit?.symbol}
                                                                                </span>
                                                            </div>
                                                            <div className="input-group mb-3">
                                                                <input type="text"
                                                                       className="form-control"
                                                                       placeholder="Narxi"
                                                                       name='price'
                                                                       min={1}
                                                                       value={item?.price}
                                                                       data-id={item.id}
                                                                       id={`price${item.id}`}
                                                                       required
                                                                       onChange={onChangeInput}
                                                                />
                                                                <span className="input-group-text"
                                                                      id="price">
                                                                            dan
                                                                        </span>
                                                            </div>
                                                        </div>

                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            ))
                                            :
                                            <h6 className="text-secondary">
                                                O'ng tomondan mahsulotlar tanlab qo'shing!
                                            </h6>
                                    }
                                </Accordion>
                            </div>

                        </div>

                        <div className="col-md-12 justify-content-center col-lg-2 text-center">
                            <br/>
                            <br/>
                            <button onClick={onClickMoveSelectedMaterials} type={'button'}
                                    className="btn-primary py-2 px-3">
                                <ArrowLeft/>
                            </button>
                        </div>

                        <div className="col-lg-5 col-md-12">

                            <div className="mt-2">
                                <label htmlFor="" className="mb-2">Mahsulotlar({productOptions?.length})</label>
                                <Multiselect
                                    className="form-control mt-2"
                                    options={productOptions}
                                    displayValue="name"
                                    onSelect={onSelectProduct}
                                    onRemove={onRemoveProduct}
                                    selectedValues={selectedDynamicProductOptions}
                                    placeholder="Mahsulotlarni tanlang"
                                />

                            </div>

                        </div>
                    </div>
                </div>
                O'ng tomondan mahsulotlar tanlab qo'shing!
                <div className="mb-3">
                    <label htmlFor="date">Sana</label>
                    <input
                        name="date"
                        className={'form-control'}
                        type="date"
                        value={inputs?.date || ''}
                        placeholder={'kiriting'}
                        onChange={handleChange}
                        onKeyUp={e => e.target.oldValue = e.target.value}
                        max={moment().format('YYYY-MM-DD')}
                    />

                    <div className="error">
                        {errors?.date}
                    </div>
                </div>


                <div className="mb-3">
                    <label htmlFor="date">Komment</label>
                    <textarea
                        name="comment"
                        className={'form-control'}
                        value={inputs?.comment || ''}
                        placeholder={'Sotuv uchun izoh'}
                        onChange={handleChange}
                        onKeyUp={e => e.target.oldValue = e.target.value}
                    ></textarea>

                    <div className="error">
                        {errors?.date}
                    </div>
                </div>


                <div className="d-flex gap-2">
                    <Button className="w-50" variant="secondary" type="button" onClick={onClose}>Bekor
                        qilish</Button>
                    <Button className="w-50" variant="primary" type="submit">
                        <Plus/> Sotish
                        {loading && <ButtonLoader/>}
                    </Button>
                </div>

            </form>
        </Fragment>
    );
};


export default SimpleSaleCreateForm;
