import {
    ADD_SPARE_PART, CLEAR_ERRORS,
    GET_SPARE_PART,
    GET_SPARE_PARTS,
    GET_SPARE_UNITS_WAREHOUSES_DATA,
    REMOVE_DELETE_SPARE_PART,
    REMOVE_UPDATE_SPARE_PART,
    SET_DELETE_SPARE_PART_ID,
    SET_UPDATE_SPARE_PART_ID,
    SPARE_PART_ERROR,
    GET_WAREHOUSE_OPTIONS,
    GET_SPARE_PART_OPTIONS, GET_UNIT_OPTIONS
} from '../actions/types';

const initialState = {
    spare_parts: [],
    spare_part_options: [],
    warehouse_options: [],
    spare_part: {},
    warehouse_ID_data: [],
    unit_options: [],
    update_spare_part: null,
    delete_spare_part: null,
    loading: false,
    numberOfPages: 1,
    total_spare_parts: null,
    error: null,
};

// eslint-disable-next-line

export default function (state = initialState, action) {
    const {type, payload} = action;

    switch (type) {

        case GET_SPARE_UNITS_WAREHOUSES_DATA:
            return {
                ...state,
                ...payload,
                loading: false
            };

        case GET_SPARE_PART:
            return {
                ...state,
                spare_part: payload.data,
                loading: false,
            };

        case GET_SPARE_PARTS:
            return {
                ...state,
                spare_parts: payload.data,
                numberOfPages: payload.meta.last_page,
                currentPage: payload.meta.current_page,
                total_spare_parts: payload.meta.total,
                error: null,
                loading: false,
            };

        case ADD_SPARE_PART:
            return {
                ...state,
                spare_parts: [payload],
                error: null,
                loading: false,
            };
        case GET_WAREHOUSE_OPTIONS:
            return {
                ...state,
                warehouse_options: payload.data,
                error: null,
            };

        case GET_UNIT_OPTIONS:
            return {
                ...state,
                unit_options: payload.data,
                error: null,
            };

        case GET_SPARE_PART_OPTIONS:
            return {
                ...state,
                spare_part_options: payload.data,
                error: null,
            };

        case REMOVE_DELETE_SPARE_PART:
            return {
                ...state,
                delete_spare_part: null,
            };

        case REMOVE_UPDATE_SPARE_PART:
            return {
                ...state,
                update_spare_part: null,
            };

        case SET_DELETE_SPARE_PART_ID:

            return {
                ...state,
                delete_spare_part: state.spare_parts.find((item) => item.id === action.payload.spare_part_ID)
            };

        case SET_UPDATE_SPARE_PART_ID:
            return {
                ...state,
                update_spare_part: state.spare_parts.find((item) => item.id === action.payload.spare_part_ID)
            };

        case SPARE_PART_ERROR:
            return {
                ...state,
                error: payload,
                loading: false,
            };

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
                loading: false,
            };

        default:
            return state;
    }
}
