import React, {Fragment, useEffect, useState} from 'react';
import UserForm from './UserCreateForm';
import UserItem from './UserItem';
import {useDispatch, useSelector} from 'react-redux';
import {getUsers} from '../../actions/user';
import UserDeleteForm from "./UserDeleteForm";
import UserUpdateForm from "./UserUpdateForm";
import SearchForm from "../layout/SearchForm";
import {Plus} from "react-feather";
import FilterByFirm from "../filters/FilterByFirm";
import CustomPaginationWithManyPages from "../layout/CustomPaginationWithManyPages";
import TableSpinner from "../layout/TableSpinner";

const Users = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getUsers());
    }, [dispatch]);

    const {auth} = useSelector(state => state);
    const {role_name} = auth?.user || {};

    const {
        users = [], loading, numberOfPages, currentPage, total_users, error
    } = useSelector(state => {
        console.log('Redux State:', state);
        return state.user;
    });

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const renderUserItems = () => {
        if (!Array.isArray(users)) {
            console.error('Users is not an array:', users);
            return (
                <tr>
                    <td colSpan="6" className="text-center">
                        Error: Invalid user data
                    </td>
                </tr>
            );
        }

        if (users.length === 0) {
            return (
                <tr>
                    <td colSpan="6" className="text-center">
                        No users found
                    </td>
                </tr>
            );
        }

        return users.map((item, index) => {
            console.log('User item:', item);
            if (!item || typeof item !== 'object') {
                console.error('Invalid user item:', item);
                return null;
            }
            return (
                <UserItem 
                    key={item.id ? `user${item.id}` : `user${index}`} 
                    number={index + 1} 
                    user={item}
                />
            );
        });
    };

    return (
        <Fragment>
            <div className="container-fluid p-0">
                <div className="row mb-2">
                    <div className="col-md-12 col-lg-8">
                        <h1 className="h3 mb-3">
                            <strong>Tizimdagi</strong> foydalanuvchilar ({total_users || 0})
                        </h1>
                    </div>
                </div>

                <div className="row">
                    <div className="col-3">
                        <FilterByFirm getPageItems={getUsers}/>
                    </div>
                    <div className="col-md-3">
                        <SearchForm getPageItems={getUsers}/>
                    </div>

                    <div className="col-md-6">
                        <div className="crud_actions text-end">
                            {(role_name === 'superadmin' || role_name === 'director' || role_name === 'manager') && (
                                <button className="btn btn-primary" onClick={handleShow}>
                                    Foydalanuvchi
                                    <Plus/>
                                </button>
                            )}
                        </div>
                    </div>
                </div>

                <div className="row mt-2 position-relative">
                    <div className="col-xl-12 col-xxl-12 d-flex">
                        {error ? (
                            <div className="alert alert-danger w-100" role="alert">
                                {error}
                            </div>
                        ) : (
                            <div className="card w-100 table-responsive">
                                {loading && <TableSpinner/>}
                                <table className="table custom-table table-hover">
                                    <thead className="table-light">
                                        <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">FIO</th>
                                            <th scope="col">Email</th>
                                            <th scope="col">Firma</th>
                                            <th scope="col">Lavozimi</th>
                                            <th scope="col">Amallar</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {renderUserItems()}
                                    </tbody>
                                </table>
                                {numberOfPages > 1 && (
                                    <CustomPaginationWithManyPages
                                        getPageItems={getUsers}
                                        numberOfPages={numberOfPages}
                                        currentPage={currentPage}
                                    />
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </div>

            {show && (
                <UserForm
                    show={show}
                    handleClose={handleClose}
                />
            )}

            <UserDeleteForm getPageItems={getUsers}/>
            <UserUpdateForm getPageItems={getUsers}/>
        </Fragment>
    );
};

export default Users;
