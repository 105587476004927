import React, {Fragment, useEffect, useState} from "react";
import {getParams, getSelectedOption} from "../../utils/hooks/getParams";

import {useDispatch, useSelector} from "react-redux";
import Select from "react-select";
import {getProfessionOptions} from "../../actions/employee";

const FilterByProfession = (props) => {

    const dispatch = useDispatch();
    const {getPageItems, is_filter = false} = props;
    const {
        profession_options
    } = useSelector(state => state.employee);

    useEffect(() => {
        dispatch(getProfessionOptions());
    }, [])

    const [currentProfession, setCurrentProfession] = useState(Number(getParams().profession) || '');
    const [isFirst, setIsFirst] = useState(true);

    useEffect(() => {
        const params = getParams();
        if (!isFirst) {
            if (is_filter) {
                dispatch(getPageItems({profession: currentProfession, without_relationship: true}))
            } else {
                dispatch(getPageItems(1, params))
            }
        }
    }, [currentProfession])

    useEffect(() => {
        setIsFirst(false);
    }, [])


    const [professionItems, setProfessionItems] = useState([
        {
            label: 'Tanlang',
            value: ''
        }
    ]);
    const handleSelect = (data, type) => {
        console.log(data, type)
        setCurrentProfession(data.label);
        if (data.value !== '') {
            const params = getParams();
            params.profession = data.label;
            const url = new URL(window.location);
            url.searchParams.set('page', 1);
            url.searchParams.set('profession', data.label);
            window.history.pushState({}, '', url);
        } else {
            const url = new URL(window.location);
            url.searchParams.delete('profession');
            window.history.pushState({}, '', url);
        }
    };
    useEffect(() => {
        if (profession_options.length > 0) {
            let items = [
                {
                    label: 'Tanlang',
                    value: ''
                }
            ];
            profession_options.map(item => items.push({
                label: item?.profession,
                value: item?.profession
            }))
            setProfessionItems(items);
        }
    }, [profession_options])
    return (
        <Fragment>

            <Select styles={customStyles}
                    value={getSelectedOption(currentProfession, professionItems)}
                    options={professionItems}
                    onChange={handleSelect}
                    menuPortalTarget={document.body}
                    menuPosition={'fixed'}
            />

        </Fragment>
    )
}
const customStyles = {
    ///.....
    menuPortal: provided => ({...provided, zIndex: 9999}),
    menu: provided => ({...provided, zIndex: 9999})
    ///.....
}
export default FilterByProfession;