import React, {Fragment, useEffect, useState} from "react";
import moment from "moment";
import {getParams} from "../../utils/hooks/getParams";
import {useDispatch} from "react-redux";
import DatePicker from "react-datepicker";
import {X} from "react-feather";

const FilterByDateRange = (props) => {

    const dispatch = useDispatch();

    const {getPageItems} = props;
    const [isFirst, setIsFirst] = useState(true);

    const [dateRange, setDateRange] = useState([
        getParams().from_date || null,
        getParams().to_date || null
    ]);
    const [startDate, endDate] = dateRange;
    useEffect(() => {
        if (isFirst){
            setIsFirst(false);
        }else{
            if (moment(startDate).isValid() && moment(endDate).isValid()){
                const formatted_start_date  = moment(startDate).format('YYYY-MM-DD');
                const formatted_end_date  = moment(endDate).format('YYYY-MM-DD');
                const url = new URL(window.location);
                url.searchParams.set('from_date', formatted_start_date);
                url.searchParams.set('to_date', formatted_end_date);
                url.searchParams.set('page', 1);
                window.history.pushState({}, '', url);
                const params = getParams();
                const new_params = {...params, from_date: formatted_start_date, to_date: formatted_end_date};
                dispatch(getPageItems(1, new_params));
            }
            else if (moment(startDate).isValid()){
                const formatted_start_date  = moment(startDate).format('YYYY-MM-DD');
                const url = new URL(window.location);
                url.searchParams.set('from_date', formatted_start_date);
                url.searchParams.set('page', 1);
                window.history.pushState({}, '', url);
                const params = getParams();
                const new_params = {...params, from_date: formatted_start_date};
                dispatch(getPageItems(1, new_params));
            }
            else if (moment(endDate).isValid()){
                const formatted_end_date  = moment(endDate).format('YYYY-MM-DD');
                const url = new URL(window.location);
                url.searchParams.set('to_date', formatted_end_date);
                url.searchParams.set('page', 1);
                window.history.pushState({}, '', url);
                const params = getParams();
                const new_params = {...params, to_date: formatted_end_date};
                dispatch(getPageItems(1, new_params));
            }else{
                handleReset()
            }

        }
    }, [startDate, endDate])

    const handleReset = () => {
        setDateRange([null, null])
        const url = new URL(window.location);
        url.searchParams.delete('from_date');
        url.searchParams.delete('to_date');
        url.searchParams.set('page', 1);
        window.history.pushState({}, '', url);
        const params = getParams();
        const new_params = {...params};
        dispatch(getPageItems(1, new_params));
    }
    return (
        <Fragment>
            <div className="input-group mb-3">
                <DatePicker
                    wrapperClassName={'form-control'}
                    className={'border-0 pe-0 w-100 focus-outline-0'}
                    placeholderText={"Dan-Gacha"}
                    selectsRange={true}
                    startDate={startDate}
                    endDate={endDate}
                    maxDate={moment()}
                    onChange={(update) => {
                        setDateRange(update);
                    }}
                />
                <span className="input-group-text cursor-pointer" onClick={handleReset}>
                    <X/>
                </span>
            </div>
        </Fragment>
    )
}

export default FilterByDateRange