import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {useHistory} from "react-router-dom";
import {setDeleteProductDeliveryId, setUpdateProductDeliveryId} from "../../actions/product-delivery";
import CurrencyFormat from "react-currency-format";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash, faEye } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'react-bootstrap';

const ProductDeliveryItem = ({
                      auth,
                      setDeleteProductDeliveryId,
                      setUpdateProductDeliveryId,
                      index,
                      product_delivery: {
                          id, transport, client, driver, round, price,
                          date: created_at
                      },
                      showActions,
                  }) => {

    const history = useHistory();

    const setDeleteFirm = (id) => {
        setDeleteProductDeliveryId(id);
    };

    const setUpdateFirm = (id) => {
        setUpdateProductDeliveryId(id);
    };

    return (
        <Fragment>
            <tr>
                <th scope="row">{id}</th>
                <td>{transport.name}</td>
                <td>{client.name}</td>
                <td>{driver.first_name} {driver.lastname}</td>
                <td>{round}</td>
                <td>
                    <CurrencyFormat
                        value={price}
                        displayType={'text'}
                        thousandSeparator={true}
                        suffix={"  so'm"}/>
                </td>
                <td>
                    <div className="d-flex gap-2">
                        <Button
                            variant="outline-secondary"
                            size="sm"
                            className="d-flex align-items-center justify-content-center"
                            style={{ width: '32px', height: '32px', padding: 0 }}
                            onClick={() => history.push(`product-deliveries/${id}`)}
                        >
                            <FontAwesomeIcon icon={faEye} />
                        </Button>
                        <Button
                            variant="outline-primary"
                            size="sm"
                            className="d-flex align-items-center justify-content-center"
                            style={{ width: '32px', height: '32px', padding: 0 }}
                            onClick={() => setUpdateFirm(id)}
                        >
                            <FontAwesomeIcon icon={faEdit} />
                        </Button>
                        <Button
                            variant="outline-danger"
                            size="sm"
                            className="d-flex align-items-center justify-content-center"
                            style={{ width: '32px', height: '32px', padding: 0 }}
                            onClick={() => setDeleteFirm(id)}
                        >
                            <FontAwesomeIcon icon={faTrash} />
                        </Button>
                    </div>
                </td>
            </tr>
        </Fragment>
    );
};

ProductDeliveryItem.defaultProps = {
    showActions: true,
};

ProductDeliveryItem.propTypes = {
    product_delivery: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
});

export default connect(mapStateToProps, {setDeleteProductDeliveryId, setUpdateProductDeliveryId})(
    ProductDeliveryItem
);
