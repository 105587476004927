import './wdyr';
import Axios from "axios";
import React, {Fragment, useEffect} from 'react';
import {Route, Switch} from 'react-router-dom';
import Routes from './components/routing/Routes';
import getApiUrl from './config/api';

// Components
import Sidebar from './components/layout/Sidebar';
import Landing from './components/layout/Landing';

import {loadUser} from './actions/auth';
import setAuthToken from './utils/setAuthToken';

import './app.css';
import './custom.css';
import './styles/overrides.css';

import 'bootstrap/dist/css/bootstrap.min.css';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Alert from './components/layout/Alert';
import PropTypes from "prop-types";
import {connect} from "react-redux";

// Set default axios configuration
Axios.defaults.baseURL = getApiUrl();
Axios.defaults.headers.common['Content-Type'] = 'application/json';
Axios.defaults.headers.common['Accept'] = 'application/json';

// Add response interceptor for better error handling
Axios.interceptors.response.use(
    response => response,
    error => {
        if (error.response) {
            // Log the error but don't expose it to the user
            console.error('API Error:', error.response);
            
            // Handle 401 unauthorized
            if (error.response.status === 401) {
                localStorage.removeItem('token');
                setAuthToken(null);
            }
        }
        return Promise.reject(error);
    }
);

if (localStorage.token) {
    setAuthToken(localStorage.token);
}

const App = ({loadUser}) => {
    useEffect(() => {
        loadUser();
    }, [loadUser]);

    return (
        <Fragment>
            <div className="wrapper">
                <Sidebar/>
                <div className="main">
                    <Switch>
                        <Route exact path="/" component={Landing}/>
                        <Route component={Routes}/>
                    </Switch>
                </div>
            </div>
            <Alert/>
            <ToastContainer autoClose={5000} limit={1}/>
        </Fragment>
    );
};

App.propTypes = {
    loadUser: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps, {loadUser})(App);
