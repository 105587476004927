import React, {Fragment, useEffect, useState} from "react";
import {getParams} from "../../utils/hooks/getParams";

import {useDispatch} from "react-redux";
import {getFirmOptions} from "../../actions/user";

const FilterBySaleType = (props) => {

    const dispatch = useDispatch();
    const {getPageItems} = props;

    useEffect(() => {
        dispatch(getFirmOptions());
    }, [])

    const [isSpecialSale, setIsSpecialSale] = useState(!!getParams().is_special_sale || false);
    const [isFirst, setIsFirst] = useState(true);

    useEffect(() => {
        const params = getParams();
        if (!isFirst) {
            dispatch(getPageItems(1, params))
        }
        setIsFirst(false);
    }, [isSpecialSale])

    const handleChange = () => {
        setIsSpecialSale(!isSpecialSale);

        if (!isSpecialSale) {
            const url = new URL(window.location);
            url.searchParams.set('page', 1);
            url.searchParams.set('is_special_sale', String(!isSpecialSale));
            window.history.pushState({}, '', url);
        } else {
            const url = new URL(window.location);
            url.searchParams.delete('is_special_sale');
            window.history.pushState({}, '', url);
        }
    };
    return (
        <Fragment>


            <div className="form-check form-switch">
                <input className="form-check-input"
                       type="checkbox"
                       id="flexSwitchCheckChecked"
                       onChange={handleChange}
                       checked={isSpecialSale}
                />
                <label className="form-check-label" htmlFor="flexSwitchCheckChecked">
                    Maxsus sotuv
                </label>
            </div>

        </Fragment>
    )
}

export default FilterBySaleType;