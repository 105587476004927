import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {setDeleteTransportId, setUpdateTransportId} from "../../actions/transport";
import {Link, useHistory} from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash, faEye } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'react-bootstrap';

const TransportItem = ({
                           auth,
                           setDeleteTransportId,
                           setUpdateTransportId,
                           index,
                           transport: {
                               id, name, driver_id, plate,
                               driver,
                               date: created_at
                           },
                           showActions,
                           number
                       }) => {

    const {role_name} = auth?.user || {};

    const history = useHistory();

    const setDeleteTransport = (id) => {
        setDeleteTransportId(id);
    };

    const setUpdateTransport = (id) => {
        setUpdateTransportId(id);
    };

    return (
        <Fragment>
            <tr>
                <th scope="row">{number}</th>
                <td>{name}</td>
                <td>{plate}</td>
                <td>{`${driver?.first_name || ''}  ${driver?.last_name || ''}`}</td>
                <td>
                    <div className="d-flex gap-2">
                        <Link to={`transports/${id}`}>
                            <Button
                                variant="outline-secondary"
                                size="sm"
                                className="d-flex align-items-center justify-content-center"
                                style={{ width: '32px', height: '32px', padding: 0 }}
                            >
                                <FontAwesomeIcon icon={faEye} />
                            </Button>
                        </Link>
                        {
                            role_name === 'director' || role_name === 'superadmin' || role_name === 'manager' || role_name === 'accountant' ? (
                                <Button
                                    variant="outline-primary"
                                    size="sm"
                                    className="d-flex align-items-center justify-content-center"
                                    style={{ width: '32px', height: '32px', padding: 0 }}
                                    onClick={() => setUpdateTransport(id)}
                                >
                                    <FontAwesomeIcon icon={faEdit} />
                                </Button>
                            ) : ''
                        }
                        {
                            role_name === 'director' || role_name === 'superadmin' ? (
                                <Button
                                    variant="outline-danger"
                                    size="sm"
                                    className="d-flex align-items-center justify-content-center"
                                    style={{ width: '32px', height: '32px', padding: 0 }}
                                    onClick={() => setDeleteTransport(id)}
                                >
                                    <FontAwesomeIcon icon={faTrash} />
                                </Button>
                            ) : ''
                        }
                    </div>
                </td>
            </tr>
        </Fragment>
    );
};

TransportItem.defaultProps = {
    showActions: true,
};

TransportItem.propTypes = {
    transport: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
});

export default connect(mapStateToProps, {setDeleteTransportId, setUpdateTransportId})(
    TransportItem
);
