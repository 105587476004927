import React, {useEffect, useRef, useState} from 'react';
import Modal from "react-bootstrap/Modal";
import {useDispatch, useSelector} from "react-redux";
// import {AvField, AvForm} from "availity-reactstrap-validation";
import Button from "react-bootstrap/Button";
import { getForTransportProductDeliveries,
    setUpdateTransportProductDeliveryId,
    updateForTransportProductDelivery
} from "../../actions/transport";
import Select from "react-select";
import {getSelectedOption} from "../../utils/hooks/getParams";
import {clearErrors} from "../../actions/sale";

const TransportProductDeliveryUpdateForm = ({transport_id}) => {

    const dispatch = useDispatch();

    const {transport_options, client_options, driver_options} = useSelector(state => state.product_delivery);

    const {
        update_transport_product_delivery,
        transport_product_deliveries,
        error, loading
    } = useSelector(state => state.transport);

    useEffect(() => {
        if (update_transport_product_delivery) {
            setInputs(update_transport_product_delivery);
        }
    }, [update_transport_product_delivery])

    const closeModal = () => {
        dispatch(setUpdateTransportProductDeliveryId(null));
    };

    const [clientOptions, setClientOptions] = useState([]);
    const [driverOptions, setDriverOptions] = useState([]);

    useEffect(() => {

        const items = [];
        if (client_options) {
            client_options.forEach((item) => items.push({
                label: `${item.name}`,
                value: item.id
            }));
        }
        setClientOptions(items);
    }, [client_options]);

    useEffect(() => {
        const items = [];
        if (driver_options) {
            driver_options.forEach((item) => items.push({
                label: `${item.name || " " + item?.first_name}`,
                value: item.id
            }));
        }
        setDriverOptions(items);
    }, [driver_options]);

    const formRef = useRef();

    const defaultValues = {
        transport_id: '',
        client_id: '',
        driver_id: '',
        round: '',
        price: ''
    };

    const [inputs, setInputs] = useState(defaultValues);
    const [errors, setErrors] = useState({});

    const handleSubmit = async (event) => {
        event.preventDefault();

        const res = await dispatch(updateForTransportProductDelivery(update_transport_product_delivery.id, transport_id, inputs));

        if (res) {
            dispatch(getForTransportProductDeliveries(transport_id))
            closeModal();
        }
    };
    const handleSelect = (data, type) => {
        setErrors({});
        setInputs(prevState => ({...prevState, [type.name]: data.value}))
    };
    const handleChange = e => {
        setErrors({});
        e.target.oldValue = e.target.value;
        setInputs(prevState => ({...prevState, [e.target.name]: e.target.value}));
    };

    useEffect(() => {
        if (error && loading === false) {
            const errorsItems = error?.errors;
            let errorObject = {};
            if (error && error?.errors) {
                Object.keys(error?.errors).forEach((item, index) => errorObject[item] = errorsItems[item][0]);
            }
            setErrors(errorObject);
        }
    }, [error]);

    useEffect(() => {
        clearErrors();
        setErrors({});
        setInputs(defaultValues);
    }, [transport_product_deliveries]);

    const onClose = () => {
        clearErrors();
        setInputs(defaultValues);
        setErrors({});
        closeModal();
    };


    let condition = false;
    if (update_transport_product_delivery !== null)
        condition = true;

    return (
        <div>
            <Modal show={condition} onHide={onClose}>
                <Modal.Header closeButton className="bg-light">
                    <Modal.Title>Mahsulot yetkazib berishni tahrirlash </Modal.Title>
                </Modal.Header>
                <Modal.Body className="pt-3 pb-4 mx-3">

                    <form onSubmit={handleSubmit} ref={formRef}>

                        <div className="mb-3">
                            <label htmlFor="client_id">Mijoz</label>
                            <Select
                                name={'client_id'}
                                options={clientOptions}
                                onChange={handleSelect}
                                value={getSelectedOption(inputs.client_id, clientOptions)}
                                defaultValue={{label: 'Tanlang', value: '-'}}
                            />

                            <div className="error">
                                {errors?.client_id}
                            </div>
                        </div>

                        <div className="mb-3">
                            <label htmlFor="driver_id">Haydovchi</label>
                            <Select
                                name={'driver_id'}
                                options={driverOptions}
                                onChange={handleSelect}
                                value={getSelectedOption(inputs.driver_id, driverOptions)}
                                defaultValue={{label: 'Tanlang', value: '-'}}
                            />

                            <div className="error">
                                {errors?.driver_id}
                            </div>
                        </div>


                        <div className="mb-3">
                            <label htmlFor="round">Qatnov(reys)</label>
                            <input
                                name="round"
                                className={'form-control'}
                                type="number"
                                value={inputs?.round || ''}
                                placeholder={'kiriting'}
                                onChange={handleChange}
                                onKeyUp={e => e.target.oldValue = e.target.value}
                            />

                            <div className="error">
                                {errors?.round}
                            </div>
                        </div>

                        <div className="mb-3">
                            <label htmlFor="price">Narxi</label>
                            <input
                                name="price"
                                className={'form-control'}
                                type="number"
                                value={inputs?.price || ''}
                                placeholder={'kiriting'}
                                onChange={handleChange}
                                onKeyUp={e => e.target.oldValue = e.target.value}
                            />

                            <div className="error">
                                {errors?.price}
                            </div>
                        </div>

                        <div className="d-flex gap-2">
                            <Button className="w-50" variant="secondary" type="button" onClick={onClose}>Bekor
                                qilish</Button>
                            <Button className="w-50" variant="primary" type="submit">Saqlash</Button>
                        </div>

                    </form>


                </Modal.Body>
            </Modal>
        </div>
    );
};

export default TransportProductDeliveryUpdateForm;