import React, {useEffect, useState} from 'react';
import {Pagination} from 'react-bootstrap';
import useQueryParams from "../../utils/hooks/useQueryParams";
import {getParams} from "../../utils/hooks/getParams";

const scrollToTop = (x = 0, y = 0) => {
    window.scroll(x, y);
};

const CustomPagination = props => {
    const {page} = useQueryParams();
    const params = getParams();
    const {getPageItems, numberOfPages, current_page, items, custom_params} = props;
    const [pageItems, setPageItems] = useState(items);
    const [innerCurrentPage, setInnerCurrentPage] = useState(current_page);
    const [firstRun, setFirstRun] = useState(true);

    const fetchPage = async (pageNum, params = {}) => {
        if (params['page']) delete params['page'];
        const new_params = {...custom_params, ...params};
        await getPageItems(pageNum, new_params);
    };

    useEffect(() => {
        const initializePage = async () => {
            setFirstRun(false);
            const params = getParams();
            if (page) {
                await fetchPage(page, params);
                setInnerCurrentPage(Number(page));
            } else {
                await fetchPage(1, params);
                setInnerCurrentPage(1);
            }
        };
        initializePage();
    }, []);

    useEffect(() => {
        if (Number(page) !== Number(innerCurrentPage)) {
            setInnerCurrentPage(Number(page));
        }
    }, [page]);

    const onPageClick = async (newPage) => {
        const url = new URL(window.location);
        url.searchParams.set('page', newPage);
        window.history.pushState({}, '', url);
        const params = getParams();
        setInnerCurrentPage(Number(newPage));
        await fetchPage(newPage, params);
        scrollToTop();
    };

    useEffect(() => {
        let items = [];
        const maxPagesToShow = 5;
        const totalPages = numberOfPages || 1;
        const currentPage = Number(innerCurrentPage) || 1;

        // Add first page
        items.push(
            <Pagination.Item
                key={1}
                active={1 === currentPage}
                onClick={() => onPageClick(1)}
            >
                1
            </Pagination.Item>
        );

        // Add ellipsis if needed
        if (currentPage > maxPagesToShow - 2) {
            items.push(<Pagination.Ellipsis key="ellipsis1"/>);
        }

        // Add pages around current page
        for (let i = Math.max(2, currentPage - 1); i <= Math.min(totalPages - 1, currentPage + 1); i++) {
            items.push(
                <Pagination.Item
                    key={i}
                    active={i === currentPage}
                    onClick={() => onPageClick(i)}
                >
                    {i}
                </Pagination.Item>
            );
        }

        // Add ellipsis if needed
        if (currentPage < totalPages - (maxPagesToShow - 3)) {
            items.push(<Pagination.Ellipsis key="ellipsis2"/>);
        }

        // Add last page
        if (totalPages > 1) {
            items.push(
                <Pagination.Item
                    key={totalPages}
                    active={totalPages === currentPage}
                    onClick={() => onPageClick(totalPages)}
                >
                    {totalPages}
                </Pagination.Item>
            );
        }

        setPageItems(items);
    }, [innerCurrentPage, numberOfPages]);

    return (
        <div className="d-flex justify-content-center mt-3">
            <Pagination>
                {pageItems}
            </Pagination>
        </div>
    );
};

export default CustomPagination;
