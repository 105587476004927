import React from 'react';
import Modal from 'react-bootstrap/Modal'
import {useDispatch, useSelector} from "react-redux";
import { deleteProduction, setDeleteProductProductionId} from "../../actions/product";
import {getParams} from "../../utils/hooks/getParams";

const ProductionDeleteForm = () => {

    const dispatch = useDispatch();

    const {
        delete_production,
    } = useSelector(state => state.production);

    const closeModal = () => {
        // removing product from redux state by sending null setDeleteProductId
        dispatch(setDeleteProductProductionId(null))
    };

    const onConfirm = () => {
        console.log(delete_production)
        dispatch(deleteProduction(delete_production.id, {...getParams(true)}))
    };

    let condition = false;
    if (delete_production !== null)
        condition = true;

    return (
        <div>
            <Modal show={condition} onHide={closeModal}>
                <Modal.Header className="bg-danger" closeButton>
                    <Modal.Title className="text-white">Ishlab chiqarishni bekor qilish </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Siz haqiqatdan ham ushbu ishlab chiqarishni o'chirmoqchimisiz?
                </Modal.Body>
                <Modal.Footer>
                    <button onClick={closeModal} className="btn btn-secondary">YO'Q</button>
                    <button onClick={onConfirm} className="btn btn-primary">HA</button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default ProductionDeleteForm;