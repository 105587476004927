import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {setDeleteClientId, setUpdateClientId} from "../../actions/client";
import {Link} from 'react-router-dom'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEdit, faTrash, faEye} from '@fortawesome/free-solid-svg-icons';
import {Button} from 'react-bootstrap';

const ClientItem = ({
                        auth,
                        setDeleteClientId,
                        setUpdateClientId,
                        client: {
                            id,
                            name,
                            contractor,
                            inn,
                            mfo,
                            phone,
                            type,
                            bank_account,
                            date: created_at,
                            supplier
                        },
                        number
                    }) => {

    const {role_name} = auth?.user || {};

    const setDeleteClient = (id) => {
        setDeleteClientId(id);
    };

    const setUpdateClient = (id) => {
        setUpdateClientId(id);
    };

    return (
        <Fragment>
            <tr>
                <th scope="row">{number}</th>
                <td>{name}</td>
                <td>
                    <Link to={`suppliers/${supplier?.id}`}>
                        {supplier?.name || ''}
                    </Link>
                </td>
                <td>{contractor}</td>
                <td>{inn}</td>
                <td>{mfo}</td>
                <td>{phone}</td>
                <td>{type}</td>
                <td>{bank_account}</td>
                <td>
                    <div className="d-flex gap-2">
                        <Link to={`clients/${id}`}>
                            <Button
                                variant="outline-secondary"
                                size="sm"
                                className="d-flex align-items-center justify-content-center"
                                style={{width: '32px', height: '32px', padding: 0}}
                            >
                                <FontAwesomeIcon icon={faEye} />
                            </Button>
                        </Link>
                        {
                            role_name === 'director' || role_name === 'superadmin' || role_name === 'accountant' || role_name === 'manager' ? (
                                <Button
                                    variant="outline-primary"
                                    size="sm"
                                    className="d-flex align-items-center justify-content-center"
                                    style={{width: '32px', height: '32px', padding: 0}}
                                    onClick={() => setUpdateClient(id)}
                                >
                                    <FontAwesomeIcon icon={faEdit} />
                                </Button>
                            ) : ''
                        }
                        {
                            role_name === 'director' || role_name === 'superadmin' ? (
                                <Button
                                    variant="outline-danger"
                                    size="sm"
                                    className="d-flex align-items-center justify-content-center"
                                    style={{width: '32px', height: '32px', padding: 0}}
                                    onClick={() => setDeleteClient(id)}
                                >
                                    <FontAwesomeIcon icon={faTrash} />
                                </Button>
                            ) : ''
                        }
                    </div>
                </td>
            </tr>
        </Fragment>
    );
};

ClientItem.defaultProps = {
    showActions: true,
};

ClientItem.propTypes = {
    client: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
});

export default connect(mapStateToProps, {setDeleteClientId, setUpdateClientId})(
    ClientItem
);
