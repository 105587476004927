import {
    ADD_MATERIAL,
    GET_MATERIALS_OPTIONS,
    MATERIAL_ERROR,
    CLEAR_ERRORS,
    CLEAR_MATERIAL_TRANSACTIONS,
    SET_LOADING,
    UNSET_LOADING,
    GET_PRODUCT_MATERIALS_TRANSACTIONS,
    GET_PRODUCTS_MATERIALS_TRANSACTIONS
} from '../actions/types';

const initialState = {
    products: [],
    material: {},
    material_options: [],
    loading: true,
    numberOfPages: 1,
    total_materials: null,
    error: null,
};

// eslint-disable-next-line
export default function (state = initialState, action) {
    const {type, payload} = action;

    switch (type) {
        case SET_LOADING:
            return {
                ...state,
                loading: true,
            }
        case UNSET_LOADING:
            return {
                ...state,
                loading: false,
            }
        case GET_MATERIALS_OPTIONS:

            return {
                ...state,
                material_options: payload.data,
                loading: false,
            };

        case CLEAR_MATERIAL_TRANSACTIONS:

            return {
                ...state,
                material_options: [],
                loading: false,
            };

        case GET_PRODUCTS_MATERIALS_TRANSACTIONS:
            const {dates, products} = payload;
            return {
                ...state,
                dates: dates,
                products: [...products.data],
                numberOfPages: products.last_page,
                current_page: products.current_page,
                products_data: payload,
                error: null,
                loading: false,
            };

        case GET_PRODUCT_MATERIALS_TRANSACTIONS:

            return {
                ...state,
                products: state.products.map((item) => item.id === payload.id ? payload : item),
                loading: false,
            };

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            };

        case ADD_MATERIAL:
            return {
                ...state,
                materials: [payload],
                error: null,
                loading: false,
            };

        case MATERIAL_ERROR:
            return {
                ...state,
                error: payload,
                loading: false,
            };

        default:
            return state;
    }
}


