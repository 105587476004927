import React, { } from 'react';
// import spinner from './spinner.gif';

const Spinner = () => (
  <div style={loaderWrapperStyles} className="loader-wrapper">
    <div className="spinner-grow text-light" role="status" data-testid="spinner">
        <span className="visually-hidden">Loading...</span>
    </div>
  </div>
);
const loaderWrapperStyles = {
  backgroundColor: '#808080',
  width: "calc(100vw - 300px)",
  height: "100vh",
  position: "absolute",
  top: 0,
  left: "280px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  zIndex: 1111
};

export default Spinner;