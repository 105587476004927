import React, {Fragment, useEffect, useState} from "react";
import CurrencyFormat from "react-currency-format";
import {useDispatch} from "react-redux";
import {Link} from "react-router-dom";
import MoneyFormat from "../../layout/MoneyFormat";
import {getClientSales} from "../../../actions/client";

const ClientSales = ({client, client_sales}) => {

    const dispatch = useDispatch();

    const {id} = client;
    useEffect(() => {
        if (id) {
            dispatch(getClientSales(id, {}));
        }
    }, [getClientSales, id]);


    const [from_date, setFromDate] = useState('');
    const [to_date, setToDate] = useState('');

    const onChangeFromDate = (event) => {
        setFromDate(state => event.target.value);
        const params = {};
        params.to_date = to_date;
        params.from_date = event.target.value;
        dispatch(getClientSales(id, params))
    };

    const onChangeToDate = (event) => {
        setToDate(state => event.target.value);
        const params = {};
        params.to_date = event.target.value;
        params.from_date = from_date;
        dispatch(getClientSales(id, params))
    };

    return (
        <div className="w-100 table-responsive px-2">

            <div className="row mb-3">
                <div className="col-md-6"></div>
                <div className="col-md-6">
                </div>
            </div>

            <div
                className="mb-2 align-items-center d-flex justify-content-between">

                <div className="mb-3">
                    <label htmlFor="date1"
                           className="mb-2">dan</label>
                    <input onChange={onChangeFromDate}
                           className="form-control"
                           id="date1" type="date"/>
                </div>

                <div className="mb-3">
                    <label htmlFor="date2"
                           className="mb-2">gacha</label>
                    <input onChange={onChangeToDate}
                           className="form-control"
                           id="date2" type="date"/>
                </div>
            </div>

            <table className="table custom-table table-hover">
                <thead className="table-dark">
                <tr>
                    <th scope="col">#</th>
                    <th scope="col">Sotuv ID</th>
                    <th scope="col">Sotuvchi</th>
                    <th scope="col">Mahsulotlar</th>
                    <th scope="col">Summa(so'm)</th>
                    <th scope="col">Sana</th>
                    {/*<th scope="col">Tahrirlash</th>*/}
                </tr>
                </thead>
                <tbody>
                {
                    client_sales?.map((item, index) => (
                        <tr key={index}>
                            <th scope="row">{index + 1}</th>
                            <th>
                                <Link
                                    to={`/sales/${item.id}`}>#{item.id}
                                </Link>
                            </th>
                            <th>
                                <Link to={`/users/${item?.seller?.id}`}>
                                    {item?.seller?.name}
                                </Link>
                            </th>
                            <th>
                                {
                                    item?.products?.map((item, sub_index, items) => (
                                        <Fragment key={sub_index}>
                                            <Link key={`product${sub_index}`} to={`/products/${item.id}`}>
                                                {item.name}
                                            </Link>
                                            {sub_index !== items.length - 1 ? '|' : ''}
                                        </Fragment>
                                    ))
                                }
                            </th>
                            <th>
                                <CurrencyFormat value={item?.sum}
                                                displayType={'text'}
                                                thousandSeparator={true}/>
                            </th>
                            <th>{item?.date}</th>
                        </tr>
                    ))
                }

                <tr key={"total_sales"}>
                    <th>
                        #
                    </th>
                    <td>
                        *
                    </td>
                    <td>
                        *
                    </td>
                    <td>
                        *
                    </td>
                    <td>
                        {
                            client_sales && client_sales.length > 0 ? (
                                <MoneyFormat
                                    value={client_sales.reduce((acc, cur) => acc + Number(cur.sum), 0) || 0}
                                    thousandSeparator={true}
                                />
                            ) : ''
                        }
                    </td>
                    <td>
                        Jami
                    </td>
                </tr>
                </tbody>
            </table>

        </div>
    )
}

export default ClientSales;