import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useParams} from 'react-router-dom';
import GoBackButton from "../layout/GoBackButton";
import {getExpense} from "../../actions/expense";

const ExpansePage = () => {

    useEffect(() => {
        dispatch(getExpense(id));
    }, [getExpense]);

    const dispatch = useDispatch();

    const {id} = useParams();

    const {expense} = useSelector(state => state.expense);

    return (
        <div>
            <main className="content mt-0 pt-0">
                <div className="container-fluid p-0">

                    <div className="row mb-2">
                        <div className="col-md-12 col-lg-8">
                            <h1 className="h3 mb-3"><strong>Harajat</strong> sahifasi </h1>
                        </div>
                        <div className="col-lg-4">
                            <div className="crud_actions text-end">
                                <GoBackButton/>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12 col-lg-12">
                            <div className="card px-4 py-3">
                                Harajat id:  {expense?.id}
                            </div>
                        </div>
                    </div>

                </div>
            </main>
        </div>
    );
};

export default ExpansePage;