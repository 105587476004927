import React, {useEffect, useRef, useState} from 'react';
import Modal from "react-bootstrap/Modal";
import {useDispatch, useSelector} from "react-redux";
import {setUpdateExpenseTemplateId, updateExpenseTemplate} from "../../actions/expense_template";
import Button from "react-bootstrap/Button";
import {getParams} from "../../utils/hooks/getParams";

const ExpenseTemplateUpdateForm = () => {

    const dispatch = useDispatch();

    const {update_expense_template, error, loading} = useSelector(state => state.expense_template);
    useEffect(() => {
        setInputs(update_expense_template)
    }, [update_expense_template])

    const handleChange = e => {
        setErrors({});
        e.target.oldValue = e.target.value;
        setInputs(prevState => ({...prevState, [e.target.name]: e.target.value}));
    }
    const defaultValues = {
        name: '',
        color: '',
        category: '',
        type: ''
    };
    const [inputs, setInputs] = useState(defaultValues);

    const [errors, setErrors] = useState();

    const formRef = useRef();
    const handleSubmit = (event) => {
        event.preventDefault();
        dispatch(updateExpenseTemplate(update_expense_template.id, inputs, getParams()));
    };

    const closeModal = () => {
        dispatch(setUpdateExpenseTemplateId(null));
    };
    useEffect(() => {
        if (error && loading === false) {
            const errorsItems = error?.errors;
            let errorObject = {};
            if (error && error?.errors) {
                Object.keys(error?.errors).forEach((item) => errorObject[item] = errorsItems[item][0]);
            }
            setErrors(errorObject);

        }
    }, [error]);

    let condition = false;
    if (update_expense_template !== null)
        condition = true;

    return (
        <div>
            <Modal show={condition} onHide={closeModal}>
                <Modal.Header closeButton className="bg-light">
                    <Modal.Title>Harajat shablonini tahrirlash</Modal.Title>
                </Modal.Header>
                <Modal.Body className="pt-3 pb-4 mx-3">
                    <form onSubmit={handleSubmit} ref={formRef}>

                        <div className="mb-3">
                            <label htmlFor="name">Nomi</label>
                            <input
                                name="name"
                                className={'form-control'}
                                type="text"
                                value={inputs?.name || ''}
                                placeholder={'kiriting'}
                                onChange={handleChange}
                                onKeyUp={e => e.target.oldValue = e.target.value}
                            />

                            <div className="error">
                                {errors?.name}
                            </div>
                        </div>

                        <div className="mb-3">
                            <label htmlFor="category">Qisqartmasi</label>
                            <input
                                name="category"
                                className={'form-control'}
                                type="text"
                                value={inputs?.category || ''}
                                placeholder={'kiriting'}
                                onChange={handleChange}
                                onKeyUp={e => e.target.oldValue = e.target.value}
                            />

                            <div className="error">
                                {errors?.category}
                            </div>
                        </div>

                        <div className="mb-3">
                            <label htmlFor="color">Grafikdagi rangi</label>
                            <input
                                name="color"
                                className={'form-control'}
                                type="color"
                                value={inputs?.color || ''}
                                placeholder={'kiriting'}
                                onChange={handleChange}
                                onKeyUp={e => e.target.oldValue = e.target.value}
                            />

                            <div className="error">
                                {errors?.color}
                            </div>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="type">Turi</label>
                            <select name="type"
                                    value={inputs?.type || ''}
                                    id="type"
                                    className="form-control"
                                    onChange={handleChange}
                                    onKeyUp={e => e.target.oldValue = e.target.value}
                            >
                                <option value="">Tanlang</option>
                                <option value="daily">Kunlik</option>
                                <option value="monthly">Oylik</option>
                                <option value="annual">Yillik</option>
                                <option value="one-time">Bir martalik</option>
                            </select>
                            <div className="error">
                                {errors?.type}
                            </div>
                        </div>


                        <div className="d-flex gap-2">
                            <Button className="w-50" variant="secondary" type="button" onClick={closeModal}>Bekor
                                qilish</Button>
                            <Button className="w-50" variant="primary" type="submit">Saqlash</Button>
                        </div>
                    </form>

                </Modal.Body>
            </Modal>
        </div>
    );
};

export default ExpenseTemplateUpdateForm;