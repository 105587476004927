import axios from 'axios';
import {clearAlerts, setAlert} from './alert';
import {
    CLEAR_ERRORS,
    GET_SUPPLIERS_MATERIALS_TRANSACTIONS,
    GET_SUPPLIER_MATERIALS_TRANSACTIONS,
    SET_LOADING,
    UNSET_LOADING
} from './types';
import {request} from "../utils/service";

export const setLoading = () => (dispatch) => {
    dispatch({
        type: SET_LOADING,
    });
};
export const unsetLoading = () => (dispatch) => {
    dispatch({
        type: UNSET_LOADING,
    });
};

export const getSuppliersMaterialsTransactions = (page = 1, filters = {}) => async (dispatch) => {
    dispatch(setLoading());
    let queryParams = '';
    if (filters) {
        Object.keys(filters).map((key, index) => queryParams += `&${key}=${filters[key]}`);
    }


    const res = await request(`/supplier-material-transactions/?page=${page}${queryParams}`, {method: 'GET'}, dispatch);


    if (res?.suppliers?.data?.length >= 0) {
        dispatch({
            type: GET_SUPPLIERS_MATERIALS_TRANSACTIONS,
            payload: res
        });
    } else {

        dispatch(setAlert("Qidiruv bo'yicha hech narsa topilmadi!", 'warning'));
        dispatch(unsetLoading());
    }

};


export const updateTransaction = (transaction, filters = {}) => async (dispatch) => {
    dispatch(clearAlerts());
    dispatch(setLoading());
    const config = {
        headers: {
            'Content-Type': 'application/json',
        },
    };

    try {
        const res = await axios.put(`/supplier-material-transactions/${transaction.id}`, JSON.stringify(transaction), config);

        dispatch(setAlert("Transaksiya tahrirlandi!", 'success'));

        return true;
    } catch (err) {
        dispatch(unsetLoading());
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
        return false;
    }
};


// export const updateTransaction = (transaction, filters = {}) => async (dispatch) => {
//
//     
//     dispatch(clearAlerts());
//
//     const config = {
//         headers: {
//             'Content-Type': 'application/json',
//         },
//     };
//
//     try {
//         const res = await axios.put(`/supplier-transactions/${transaction.id}`, JSON.stringify(transaction), config);
//
//         dispatch(setAlert("Transaksiya tahrirlandi!", 'success'));
//
//         return true;
//     } catch (err) {
//         dispatch(setAlert(err?.response?.data?.message, 'danger'));
//         dispatch(setAlert("To'lov tahrirlanmadi!", 'danger'));
//         dispatch({
//             type: UPDATE_TRANSACTION_ERROR,
//             payload: {
//                 transaction_id: transaction?.id,
//                 message: err?.message
//             },
//         });
//
//         return false;
//     }
// };


// getRowData after update in table
export const getSupplierMaterialsTransactions = (supplier_id, filters = {}) => async (dispatch) => {
    dispatch(setLoading());
    let queryParams = '';
    if (filters) {
        Object.keys(filters).map((key, index) => queryParams += `&${key}=${filters[key]}`);
    }


    const res = await request(`/single-supplier-material-transactions/${supplier_id}?${queryParams}`, {method: 'GET'}, dispatch);


    if (res && res.hasOwnProperty('errors')) {
        const err = res.errors;
        dispatch(setAlert(err.response.statusText, 'danger'));
        dispatch(unsetLoading());
    } else {
        dispatch({
            type: GET_SUPPLIER_MATERIALS_TRANSACTIONS,
            payload: res
        });
    }
};


export const clearErrors = () => async (dispatch) => {
    dispatch({
        type: CLEAR_ERRORS,
    });
};







