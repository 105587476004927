import axios from 'axios';
import {clearAlerts, setAlert} from './alert';
import {
    CLEAR_ERRORS, GET_MATERIALS_TRANSACTIONS, GET_PRODUCT_TRANSACTIONS,
    SET_LOADING,
    UNSET_LOADING
} from './types';
import {request} from "../utils/service";

export const setLoading = () => (dispatch) => {
    dispatch({
        type: SET_LOADING,
    });
};
export const unsetLoading = () => (dispatch) => {
    dispatch({
        type: UNSET_LOADING,
    });
};

export const updateTransaction = (transaction, filters = {}) => async (dispatch) => {
    dispatch(clearAlerts());
    dispatch(setLoading());
    const config = {
        headers: {
            'Content-Type': 'application/json',
        },
    };

    try {
        const res = await axios.put(`/material-transactions/${transaction.id}`, JSON.stringify(transaction), config);
        dispatch(setAlert("Transaksiya tahrirlandi!", 'success'));
        return true;
    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
        dispatch(unsetLoading());
        return false;
    }
};

export const getMaterialsTransactions = (page = 1, filters = {}) => async (dispatch) => {
    dispatch(setLoading());
    dispatch(clearAlerts());
    let queryParams = '';
    if (filters) {
        Object.keys(filters).map((key, index) => queryParams += `&${key}=${filters[key]}`);
    }
    const res = await request(`/material-transactions?page=${page}${queryParams}`, {method: 'GET'}, dispatch);
    if (res.hasOwnProperty('errors')) {
        const err = res.errors;
        dispatch(setAlert(err.response.statusText, 'danger'));
    } else {
        dispatch({
            type: GET_MATERIALS_TRANSACTIONS,
            payload: res
        });
        if (res.clients?.data?.length === 0) {
            dispatch(setAlert("Qidiruv bo'yicha hech narsa topilmadi!", 'warning'));
        }
    }
};

// getRowData after update in table
export const getMaterialTransactions = (material_id, filters = {}) => async (dispatch) => {
    dispatch(setLoading());
    dispatch(clearAlerts());
    let queryParams = '?';
    if (filters) {
        Object.keys(filters).map((key, index) => index === 0 ? queryParams += `${key}=${filters[key]}` : queryParams += `&${key}=${filters[key]}`);
    }
    const res = await request(`/single-material-transactions/${material_id}${queryParams}`, {method: 'GET'}, dispatch);

    if (res.hasOwnProperty('errors')) {
        const err = res.errors;
        dispatch(setAlert(err.response.statusText, 'danger'));
        dispatch(unsetLoading());
        return res;
    } else {
        dispatch({
            type: GET_PRODUCT_TRANSACTIONS,
            payload: res
        });
    }
};

// getRowData after update in table
export const downloadMaterialReport = (filters = {}) => async (dispatch) => {
    filters.object = 'material';
    dispatch(setLoading());
    dispatch(clearAlerts());
    let queryParams = '?';
    if (filters) {
        Object.keys(filters).map((key, index) => index === 0 ? queryParams += `${key}=${filters[key]}` : queryParams += `&${key}=${filters[key]}`);
    }
    const res = await request(`/reports/${queryParams}`, {method: 'GET'}, dispatch);

    if (res?.hasOwnProperty('errors')) {
        const err = res.errors;
        dispatch(setAlert(err.response.statusText, 'danger'));
        dispatch(unsetLoading());
        return false;
    } else {

        dispatch(unsetLoading());
        return res?.link;
        // dispatch({
        //     type: GET_PRODUCT_TRANSACTIONS,
        //     payload: res
        // });
    }
};


export const clearErrors = () => async (dispatch) => {
    dispatch({
        type: CLEAR_ERRORS,
    });
};







