import {
    ADD_PRODUCT,
    ADD_PRODUCT_PRODUCTION,
    ADD_PRODUCT_SALE,
    CLEAR_ERRORS,
    CLEAR_FILTERED_PRODUCTS,
    GET_FILTERED_PRODUCT_PRODUCTION,
    GET_FILTERED_PRODUCT_SALE,
    GET_FILTERED_PRODUCTS,
    GET_MATERIAL_OPTIONS,
    GET_PRODUCT,
    GET_PRODUCT_OPTIONS,
    GET_PRODUCT_PRODUCTIONS,
    GET_PRODUCT_SALES,
    GET_PRODUCT_TEMPLATES_ON_PRODUCTS,
    GET_PRODUCT_WAREHOUSES_ON_PRODUCTS,
    GET_PRODUCTS,
    GET_UNIT_OPTIONS,
    GET_WAREHOUSE_OPTIONS,
    GET_WAREHOUSES_DATA,
    PRODUCT_ERROR,
    PRODUCT_UNITS_DATA_ERROR,
    REMOVE_DELETE_PRODUCT,
    REMOVE_DELETE_PRODUCT_PRODUCTION, REMOVE_DELETE_PRODUCT_SALE,
    REMOVE_UPDATE_PRODUCT,
    REMOVE_UPDATE_PRODUCT_PRODUCTION,
    REMOVE_UPDATE_PRODUCT_SALE,
    SET_DELETE_PRODUCT_ID,
    SET_DELETE_PRODUCT_PRODUCTION_ID, SET_DELETE_PRODUCT_SALE_ID,
    SET_UPDATE_PRODUCT_ID,
    SET_UPDATE_PRODUCT_PRODUCTION_ID,
    SET_UPDATE_PRODUCT_SALE_ID,
    SET_LOADING, SET_PRODUCTION_ERROR, SET_SALE_ERROR
} from '../actions/types';

const initialState = {
    products: [],
    product: {},
    product_productions: [],
    product_sales: [],
    filtered_products: [],
    unit_options: [],
    material_options: [],
    warehouse_options: [],
    product_options: [],
    filtered_product_production_data_date: null,
    filtered_product_sale_data_date: null,
    product_units: [],
    warehouses_data: [],
    product_templates: [],
    product_warehouses: [],
    update_product: null,
    delete_product: null,
    update_product_production: null,
    update_product_sale: null,
    delete_product_production: null,
    delete_product_sale: null,
    loading: true,
    numberOfPages: 1,
    total_products: null,
    error: null,
};

// eslint-disable-next-line

export default function (state = initialState, action) {
    const {type, payload} = action;

    switch (type) {

        case SET_LOADING:
            return {
                ...state,
                loading: payload
            };
        case GET_UNIT_OPTIONS:

            return {
                ...state,
                unit_options: payload.data,
                loading: false
            };
        case GET_MATERIAL_OPTIONS:

            return {
                ...state,
                material_options: payload.data,
                loading: false
            };
        case GET_WAREHOUSE_OPTIONS:

            return {
                ...state,
                warehouse_options: payload.data,
                loading: false
            };

        case GET_WAREHOUSES_DATA:
            return {
                ...state,
                warehouses_data: payload.data,
                loading: false
            };

        case GET_PRODUCT_TEMPLATES_ON_PRODUCTS:

            return {
                ...state,
                product_templates: payload.data,
                loading: false
            };

        case GET_PRODUCT_WAREHOUSES_ON_PRODUCTS:

            return {
                ...state,
                product_warehouses: payload.data,
                loading: false
            };

        case PRODUCT_UNITS_DATA_ERROR:
            return {
                ...state,
                error: payload,
                loading: false,
            };

        case GET_PRODUCT:
            return {
                ...state,
                product: payload.data,
                loading: false,
            };

        case GET_PRODUCTS:
            return {
                ...state,
                products: payload.data,
                numberOfPages: payload.meta.last_page,
                currentPage: payload.meta.current_page,
                total_products: payload.meta.total,
                error: null,
                loading: false,
            };

        case GET_PRODUCT_PRODUCTIONS:
            return {
                ...state,
                product_productions: payload,
                error: null,
                loading: false,
            };

        case GET_PRODUCT_SALES:
            return {
                ...state,
                product_sales: payload,
                error: null,
                loading: false,
            };

        case GET_PRODUCT_OPTIONS:
            return {
                ...state,
                product_options: payload.data,
                loading: false,
            };

        case GET_FILTERED_PRODUCTS:
            return {
                ...state,
                filtered_products: payload.data,
                numberOfPages: payload.meta.last_page,
                currentPage: payload.meta.current_page,
                loading: false,
            };

        case GET_FILTERED_PRODUCT_PRODUCTION:
            return {
                ...state,
                filtered_product_production_data_date: payload.data,
                loading: false,
            };

        case GET_FILTERED_PRODUCT_SALE:
            return {
                ...state,
                filtered_product_sale_data_date: payload.data,
                loading: false,
            };

        case CLEAR_FILTERED_PRODUCTS:
            return {
                ...state,
                filtered_products: [],
                numberOfPages: state.products.length,
                loading: false,
            };

        case ADD_PRODUCT:
            return {
                ...state,
                products: [payload],
                error: null,
                loading: false,
            };

        case ADD_PRODUCT_PRODUCTION:
            return {
                ...state,
                product_productions: payload,
                error: null,
                loading: false,
            };

        case SET_PRODUCTION_ERROR:
            return {
                ...state,
                error: payload,
                loading: false,
            };

        case ADD_PRODUCT_SALE:
            return {
                ...state,
                product_sales: payload,
                error: null,
                loading: false,
            };
        case SET_SALE_ERROR:
            return {
                ...state,
                error: payload,
                loading: false,
            };

        case REMOVE_DELETE_PRODUCT:
            return {
                ...state,
                delete_product: null,
            };

        case REMOVE_UPDATE_PRODUCT:
            return {
                ...state,
                update_product: null,
            };

        case REMOVE_UPDATE_PRODUCT_PRODUCTION:
            return {
                ...state,
                update_product_production: null,
            };

        case REMOVE_UPDATE_PRODUCT_SALE:
            return {
                ...state,
                update_product_sale: null,
            };

        case REMOVE_DELETE_PRODUCT_PRODUCTION:
            return {
                ...state,
                delete_product_production: null,
            };

        case REMOVE_DELETE_PRODUCT_SALE:
            return {
                ...state,
                delete_product_sale: null,
            };

        case SET_DELETE_PRODUCT_ID:

            return {
                ...state,
                delete_product: state.products.find((item) => item.id === action.payload.productID)
            };

        case SET_UPDATE_PRODUCT_ID:
            return {
                ...state,
                update_product: action.payload.data
            };

        case SET_UPDATE_PRODUCT_PRODUCTION_ID:
            return {
                ...state,
                update_product_production: payload
            };

        case SET_UPDATE_PRODUCT_SALE_ID:
            return {
                ...state,
                update_product_sale: payload
            };

        case SET_DELETE_PRODUCT_PRODUCTION_ID:
            return {
                ...state,
                delete_product_production: payload
            };

        case SET_DELETE_PRODUCT_SALE_ID:
            return {
                ...state,
                delete_product_sale: payload
            };

        case PRODUCT_ERROR:
            return {
                ...state,
                error: payload,
                loading: false,
            };

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
                loading: false,
            };

        default:
            return state;
    }
}
