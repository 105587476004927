import React, {Fragment, useEffect, useRef, useState} from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from "react-bootstrap/Button";
import {Plus} from "react-feather";
import moment from "moment/moment";
import Select from "react-select";
import {getSelectedOption} from "../../utils/hooks/getParams";
import {addMaterialSale, clearErrors, getClientOptions, getMaterialOptions} from "../../actions/material_sale";
import {useDispatch, useSelector} from "react-redux";
import {getFirmOptions} from "../../actions/user";

const MaterialSaleCreateForm = ({handleClose, show}) => {

    const {material_sale, user} = useSelector(state => state);
    const { firm_options } = user;
    const dispatch = useDispatch();
    const {
        client_options,
        material_options,
        material_sales,
        error,
        loading,
    } = material_sale;

    useEffect(async () => {
        dispatch(getClientOptions())
        dispatch(getFirmOptions());
    }, []);


    const formRef = useRef();

    const defaultValues = {
        date: "",
        client_id: "",
        material_id: "",
        quantity: "",
        price: "",
        comment: "",
    };
    const [inputs, setInputs] = useState(defaultValues);

    const [materialOptions, setMaterialOptions] = useState([]);
    const [clientOptions, setClientOptions] = useState([]);
    const [firmOptions, setFirmOptions] = useState([]);

    const [errors, setErrors] = useState({});

    const handleChange = e => {
        setErrors({});
        e.target.oldValue = e.target.value;
        setInputs(prevState => ({...prevState, [e.target.name]: e.target.value}));
    };

    useEffect(() => {
        clearErrors();
        setErrors({});
        setInputs(defaultValues);

    }, [material_sales]);


    useEffect(() => {
        let items = [];
        firm_options.forEach((item) => items.push({
            label: `${item.name}`,
            value: item.id
        }));
        setFirmOptions(items);

    }, [firm_options]);

    useEffect(() => {
        let items = [];
        material_options.forEach((item) => items.push({
            label: `${item.name}(${item.quantity} - ${item.net_price}  so'm)`,
            value: item.id
        }));
        setMaterialOptions(items);
    }, [material_options]);

    useEffect(() => {
        let options = [];
        client_options?.forEach((item) => options.push({
            label: item.name,
            value: item.id
        }));
        setClientOptions(options);
    }, [client_options]);

    const defaultSelectedItem = {label: 'Tanlang', value: ''};
    const [selectedMaterial, setSelectedMaterial] = useState(defaultSelectedItem);
    const handleSelect = (data, type) => {
        if (type.name === 'firm_id') {
            dispatch(getMaterialOptions({is_for_sale: true, firm_id: data.value}));
        }
        if (type.name === 'material_id') {
            setSelectedMaterial(getSelectedOption(data.value, materialOptions))
        }
        setInputs(prevState => ({...prevState, [type.name]: data.value}))
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = {
            date: inputs?.date,
            client_id: inputs?.client_id,
            material_id: inputs?.material_id,
            quantity: inputs?.quantity,
            price: inputs?.price,
            comment: inputs?.comment,
        };
        console.log(data)
        const response = await dispatch(addMaterialSale(data));
        console.log(response)
        if (response === true) {
            setInputs(defaultValues);
            onClose()
            return;
        }
        console.log(error)
    };
    useEffect(() => {
        if (error && error.hasOwnProperty('errors')) {
            const errorsItems = error.errors;
            let errorObject = {};
            if (error?.errors) {
                Object.keys(error.errors).forEach((item) => errorObject[item] = errorsItems[item][0]);
            }
            setErrors(errorObject);
        }
    }, [error])



    const onClose = () => {
        setInputs(defaultValues);
        setSelectedMaterial(defaultSelectedItem);
        setMaterialOptions([]);
        handleClose();
    };

    return (
        <Fragment>
            <Modal show={show} onHide={onClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Yangi sotuv amalga oshirish</Modal.Title>
                </Modal.Header>

                <Modal.Body className="pt-3 pb-4 mx-3">

                    <form onSubmit={handleSubmit} ref={formRef}>
                        <div className="mb-3">
                            <label htmlFor="client_id" className="form-label">
                                Klient
                            </label>
                            <Select
                                name={'client_id'}
                                options={clientOptions}
                                value={getSelectedOption(inputs?.client_id, clientOptions)}
                                onChange={handleSelect}
                                defaultValue={{label: 'Tanlang', value: ''}}
                            />

                            <div className="error">
                                {errors?.client_id}
                            </div>
                        </div>


                        <div className="mb-3">
                            <label htmlFor="firm_id">Firma</label>
                            <Select
                                name={'firm_id'}
                                options={firmOptions}
                                value={getSelectedOption(inputs?.firm_id, firmOptions)}
                                onChange={handleSelect}
                                defaultValue={{label: 'Tanlang', value: ''}}
                            />
                            <div className="error">
                                {errors?.firm_id}
                            </div>
                        </div>

                        <div className="mb-3">
                            <label htmlFor="material_id">Material({materialOptions.length})</label>
                            <Select
                                name={'material_id'}
                                options={materialOptions}
                                value={selectedMaterial}
                                onChange={handleSelect}
                                defaultValue={defaultSelectedItem}
                            />
                            <div className="error">
                                {errors?.material_id}
                            </div>
                        </div>

                        <div className="mb-3">
                            <label htmlFor="quantity">Qancha?</label>
                            <input
                                name="quantity"
                                className={'form-control'}
                                type="quantity"
                                value={inputs?.quantity || ''}
                                placeholder={'kiriting'}
                                onChange={handleChange}
                                onKeyUp={e => e.target.oldValue = e.target.value}
                            />

                            <div className="error">
                                {errors?.quantity}
                            </div>
                        </div>

                        <div className="mb-3">
                            <label htmlFor="price">Narxi?</label>
                            <input
                                name="price"
                                className={'form-control'}
                                type="quantity"
                                value={inputs?.price || ''}
                                placeholder={'kiriting'}
                                onChange={handleChange}
                                onKeyUp={e => e.target.oldValue = e.target.value}
                            />

                            <div className="error">
                                {errors?.price}
                            </div>
                        </div>

                        <div className="mb-3">
                            <label htmlFor="date">Sana</label>
                            <input
                                name="date"
                                className={'form-control'}
                                type="date"
                                value={inputs?.date || ''}
                                placeholder={'kiriting'}
                                onChange={handleChange}
                                onKeyUp={e => e.target.oldValue = e.target.value}
                                max={moment().format('YYYY-MM-DD')}
                            />

                            <div className="error">
                                {errors?.date}
                            </div>
                        </div>


                        <div className="mb-3">
                            <label htmlFor="date">Komment</label>
                            <textarea
                                name="comment"
                                className={'form-control'}
                                value={inputs?.comment || ''}
                                placeholder={'Sotuv uchun izoh'}
                                onChange={handleChange}
                                onKeyUp={e => e.target.oldValue = e.target.value}
                            ></textarea>

                            <div className="error">
                                {errors?.date}
                            </div>
                        </div>

                        <div className="d-flex gap-2">
                            <Button className="w-50" variant="secondary" type="button" onClick={onClose}>Bekor
                                qilish</Button>
                            <Button className="w-50" variant="primary" type="submit">
                                <Plus/> Sotish
                                {loading &&
                                    <div className="spinner-border spinner-border-sm text-light" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                }
                            </Button>
                        </div>
                    </form>
                </Modal.Body>

            </Modal>
        </Fragment>
    );
};

export default MaterialSaleCreateForm;
