import {
    ADD_PRODUCT_TEMPLATE,
    GET_MATERIALS_ON_PRODUCT_TEMPLATES,
    GET_PRODUCT_TEMPLATE,
    GET_PRODUCT_TEMPLATE_UNIT_ALTERNATIVES_DATA,
    GET_PRODUCT_TEMPLATE_UNITS_DATA,
    GET_PRODUCT_TEMPLATES,
    GET_PRODUCTS_ON_PRODUCT_TEMPLATES,
    PRODUCT_TEMPLATE_ERROR,
    PRODUCT_TEMPLATE_UNITS_ERROR,
    REMOVE_DELETE_PRODUCT_TEMPLATE,
    REMOVE_UPDATE_PRODUCT_TEMPLATE,
    SET_DELETE_PRODUCT_TEMPLATE_ID,
    SET_UPDATE_PRODUCT_TEMPLATE_ID
} from '../actions/types';

const initialState = {
    product_templates: [],
    product_template: {},
    products: [],
    materials: [],
    product_template_units: [],
    product_template_unit_alternatives: [],
    update_product_template: null,
    delete_product_template: null,
    loading: false,
    numberOfPages: 1,
    currentPage: 1,
    error: {},
};

// eslint-disable-next-line

export default function (state = initialState, action) {
    const {type, payload} = action;

    switch (type) {

        case GET_PRODUCT_TEMPLATE_UNIT_ALTERNATIVES_DATA:

            return {
                ...state,
                product_template_unit_alternatives: payload.data,
                loading: false
            };

        case GET_PRODUCT_TEMPLATE_UNITS_DATA:

            return {
                ...state,
                product_template_units: payload.data,
                loading: false
            };

        case PRODUCT_TEMPLATE_UNITS_ERROR:
            return {
                ...state,
                error: payload,
                loading: false,
            };

        case GET_PRODUCT_TEMPLATE:
            return {
                ...state,
                product_template: payload.data,
                loading: false,
            };

        case GET_PRODUCT_TEMPLATES:
            return {
                ...state,
                product_templates: payload.data,
                numberOfPages: payload.meta.last_page,
                currentPage: payload.meta.current_page,
                loading: false,
            };
        case GET_PRODUCTS_ON_PRODUCT_TEMPLATES:
            return {
                ...state,
                products: payload.data,
                loading: false,
            };

        case GET_MATERIALS_ON_PRODUCT_TEMPLATES:
            return {
                ...state,
                materials: payload.data,
                loading: false,
            };

        case ADD_PRODUCT_TEMPLATE:
            return {
                ...state,
                products: [payload.data],
                loading: false,
            };

        case REMOVE_DELETE_PRODUCT_TEMPLATE:
            return {
                ...state,
                delete_product_template: null,
            };

        case REMOVE_UPDATE_PRODUCT_TEMPLATE:
            return {
                ...state,
                update_product_template: null,
            };

        case SET_DELETE_PRODUCT_TEMPLATE_ID:
            let item = null;
            if (action.payload !== null) {
                item = state.product_templates.find((item) => item.id === action.payload.product_templateID);
            }
            return {
                ...state,
                delete_product_template: item
            };

        case SET_UPDATE_PRODUCT_TEMPLATE_ID:

            let updateItem = null;
            if (action.payload !== null) {
                updateItem = state.product_templates.find((item) => item.id === action.payload.product_templateID);
            }
            return {
                ...state,
                update_product_template: updateItem
            };

        case PRODUCT_TEMPLATE_ERROR:
            return {
                ...state,
                error: payload,
                loading: false,
            };

        default:
            return state;
    }
}
