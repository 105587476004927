import {Link} from "react-router-dom";
import React, { useEffect, useState} from "react";
import { getClientTransactions} from "../../actions/clients_transaction";
import {useDispatch, useSelector} from "react-redux";
import ClientDataCell from "./ClientDataCell";
import {formatMoney} from "../../utils/hooks/getParams";

const ClientDataRow = ({index, item, dates}) => {
    const dispatch = useDispatch();

    const {
        auth
    } = useSelector(state => state);
    const {role_name} = auth?.user || {};

    const {
        updated_client_transaction
    } = useSelector(state => state.client);

    const [itemData, setItemData] = useState({
        transactions: []
    });
    const [rowData, setRowData] = useState([]);

    useEffect(() => {
        setItemData(item);
    }, [item])

    useEffect(() => {
        if (updated_client_transaction)
            setItemData(updated_client_transaction);
    }, [updated_client_transaction])


    useEffect(() => {
        setRowData(itemData.transactions);
    }, [itemData]);

    const refreshRow = (id) => {
        dispatch(getClientTransactions(id));
    }

    const getCellData = (date) => {
        const foundDateItem = rowData.find(item => item.date === date);
        if (foundDateItem){
            return {
                received_amount: foundDateItem.received_amount,
                paid_amount: foundDateItem.paid_amount,
                date: foundDateItem.date,
            }
        }
        return {
            earning_amount: 0,
            paid_amount: 0,
            date,
        }
    }
    const refreshPage = () => {
        window.location.reload();
    }

    return (
        <tr>
            <th className="sticky-col">{index + 1}</th>
            <th className="sticky-col2">
                <Link to={`clients/${itemData.id}`}>
                    {itemData.name}
                </Link>
            </th>

            {
                dates.map((date, subindex) => (
                    <ClientDataCell
                        key={subindex}
                        object_id={item.id}
                        date={date}
                        index={index}
                        cellData={getCellData(date)}
                        rowData={rowData}
                        refreshRow={refreshRow}
                        resetAllRows={() => console.log(2)}
                    />))
            }
            <th style={{textAlign: "center", minWidth: "100px", right: "200px"}}
                title={formatMoney(itemData.transactions_sum_received_amount, ',')}
            >
                {formatMoney(itemData.transactions_sum_received_amount, ',')}
            </th>
            <th style={{textAlign: "center", minWidth: "100px", right: "100px"}}
                title={formatMoney(itemData.transactions_sum_paid_amount, ',')}
            >
                {formatMoney(itemData.transactions_sum_paid_amount, ',')}
            </th>
            <th style={{textAlign: "center", minWidth: "100px", right: "0px"}}
                title={formatMoney(itemData.current_month_left_amount)}
            >
                {formatMoney(itemData.current_month_left_amount)}
            </th>
        </tr>
    )
}

export default ClientDataRow;