import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { deleteFirm, setDeleteFirmId } from '../../actions/firms';

const FirmDeleteModal = () => {
    const dispatch = useDispatch();
    const { delete_firm, currentPage, numberOfPages, firms } = useSelector(state => state.firm);

    const handleClose = () => {
        dispatch(setDeleteFirmId(null));
    };

    const handleDelete = () => {
        const getPage = (currentPage === numberOfPages && firms.length === 1) 
            ? currentPage - 1 
            : currentPage;
            
        if (delete_firm?.id) {
            dispatch(deleteFirm(delete_firm.id, getPage));
            handleClose();
        }
    };

    return (
        <Modal show={delete_firm !== null} onHide={handleClose} data-testid="delete-firm-modal">
            <Modal.Header closeButton className="bg-danger">
                <Modal.Title className="text-white">Firmani o'chirish</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Haqiqatan ham bu firmani o'chirmoqchimisiz?</p>
                <p><strong>{delete_firm?.name}</strong></p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose} data-testid="delete-firm-cancel">
                    Bekor qilish
                </Button>
                <Button variant="danger" onClick={handleDelete} data-testid="delete-firm-confirm">
                    O'chirish
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default FirmDeleteModal;
