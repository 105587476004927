import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {getEmployee, setDeleteEmployeeId, setUpdateEmployeeId} from "../../actions/employee";
import {Link, useHistory} from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash, faEye } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'react-bootstrap';

const EmployeeItem = ({
                          auth,
                          setDeleteEmployeeId,
                          setUpdateEmployeeId,
                          getEmployee,
                          employee: {
                              id,
                              firm,
                              profession,
                              status,
                              first_name,
                              last_name,
                              inn,
                              address,
                              phone,
                              balance,
                              date: created_at
                          },
                          showActions,
                          number
                      }) => {

    const history = useHistory();

    const {role_name} = auth?.user || {};

    const setDeleteEmployee = (id) => {
        setDeleteEmployeeId(id);
    };

    const setUpdateEmployee = (id) => {
        setUpdateEmployeeId(id);
    };

    return (
        <tr>
            <th scope="row">{number}</th>
            <td>{first_name}</td>
            <td>{last_name}</td>
            <td>{inn}</td>
            <td>{address}</td>
            <td>{phone}</td>
            <td>{firm?.name}</td>
            <td>{profession}</td>
            {/*<td>{balance}</td>*/}
            <td>{status === 'hired' ? 'Ishlamoqda' : 'Ishdan ketgan'}</td>
            <td>
                <div className="d-flex gap-2">
                    <Link to={`employees/${id}`}>
                        <Button
                            variant="outline-secondary"
                            size="sm"
                            className="d-flex align-items-center justify-content-center"
                            style={{ width: '32px', height: '32px', padding: 0 }}
                        >
                            <FontAwesomeIcon icon={faEye} />
                        </Button>
                    </Link>
                    {(role_name === 'director' || role_name === 'superadmin' || role_name === 'manager') && (
                        <Button
                            variant="outline-primary"
                            size="sm"
                            className="d-flex align-items-center justify-content-center"
                            style={{ width: '32px', height: '32px', padding: 0 }}
                            onClick={() => setUpdateEmployee(id)}
                        >
                            <FontAwesomeIcon icon={faEdit} />
                        </Button>
                    )}
                    {(role_name === 'director' || role_name === 'superadmin') && (
                        <Button
                            variant="outline-danger"
                            size="sm"
                            className="d-flex align-items-center justify-content-center"
                            style={{ width: '32px', height: '32px', padding: 0 }}
                            onClick={() => setDeleteEmployee(id)}
                        >
                            <FontAwesomeIcon icon={faTrash} />
                        </Button>
                    )}
                </div>
            </td>
        </tr>
    );
};

EmployeeItem.defaultProps = {
    showActions: true,
};

EmployeeItem.propTypes = {
    employee: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
});

export default connect(mapStateToProps, {setDeleteEmployeeId, getEmployee, setUpdateEmployeeId})(
    EmployeeItem
);
