import React, {Fragment, useEffect, useState} from "react";
import { formatMoney} from "../../utils/hooks/getParams";

const MaterialDataCell = ({index, cellData}) => {
    const [itemData, setDateData] = useState(cellData);
    useEffect(() => {
        setDateData(cellData);
    }, [cellData])

    return (
        <Fragment key={`td${index}`}>
            <td
                className={'cell received_amount'}
                data-old_value={itemData.received_amount}
            >
                {formatMoney(parseFloat(itemData.received_amount).toFixed(2))}
            </td>
            <td
                className={'cell spent_amount'}
                data-old_value={itemData.spent_amount}
            >
                {formatMoney(parseFloat(itemData?.spent_amount).toFixed(2))}
            </td>
        </Fragment>
    )
}

export default MaterialDataCell;