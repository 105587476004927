import moment from "moment";
import { useState} from "react";
import {useDispatch} from "react-redux";
import {downloadSingleClientReport, downloadSingleClientTransactionReport} from "../../actions/client";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const yearInputStyle = {padding: '6px 0', fontSize: '16px'};


const SingleClientReport = ({id}) => {
    const dispatch = useDispatch();
    const [reportType, setReportType] = useState('transactions');
    const [periodType, setPeriodType] = useState('');
    const [fromDate, setFromDate] = useState(moment().format('YYYY-MM-DD'));
    const [toDate, setToDate] = useState(moment().format('YYYY-MM-DD'));
    const [month, setMonth] = useState('');

    const [year, setYear] = useState(new Date().getFullYear());
    const [selectedYear, setSelectedYear] = useState(new Date());
    const handleYear = (date) => {
        setYear(date.getFullYear())
        setSelectedYear(date)
    }

    const [fileFormat, setFileFormat] = useState('pdf');

    const onDownload = async (e) => {
        e.preventDefault();
        if (reportType === 'products') {
            let data = {};
            switch (periodType) {
                case 'range':
                    data = {period_type: 'range', from_date: fromDate, to_date: toDate, file_format: fileFormat, report_type: reportType};
                    break;
                case 'monthly':
                    data = {period_type: 'monthly', month: month, file_format: fileFormat, report_type: reportType};
                    break;
                case 'yearly':
                    data = {period_type: 'yearly', year: year, file_format: fileFormat, report_type: reportType};
                    break;

            }
            data.client_id = id;
            const link = await dispatch(downloadSingleClientReport(data));

            if (link !== false && link !== undefined)
                window.open(link);
        }
        if (reportType === 'transactions') {
            const data = {file_format: fileFormat, report_type: reportType};
            data.client_id = id;
            const link = await dispatch(downloadSingleClientTransactionReport(data));
            if (link !== false && link !== undefined)
                window.open(link);
        }

    }
    return (
        <form className="row g-3">

            <div className="col-auto">
                <label htmlFor="to_date" className="visually-hidden">Hisobot turi</label>
                <select className={'form-control form-select'}
                        value={reportType}
                        onChange={e => setReportType(e.target.value)}
                >
                    <option value="">Hisobot turi</option>
                    <option value="transactions">Transaksiya</option>
                    <option value="products">Mahsulotlar</option>
                </select>
            </div>

            {
                reportType === 'products' && (

                    <>

                        <div className="col-auto">
                            <label htmlFor="to_date" className="visually-hidden">Hisobot turi</label>
                            <select className={'form-control form-select'}
                                    value={periodType}
                                    onChange={e => setPeriodType(e.target.value)}
                            >
                                <option value="">Muddat turi</option>
                                <option value="range">Dan-Gacha</option>
                                <option value="monthly">Oylik</option>
                                <option value="yearly">Yillik</option>
                            </select>
                        </div>

                        <>

                            {
                                'range' === periodType && (
                                    <>

                                        <div className="col-auto">
                                            <label htmlFor="from_date" className="visually-hidden">Dan</label>
                                            <input type="date" className="form-control" id="from_date"
                                                   onChange={e => setFromDate(e.target.value)}
                                                   max={moment(new Date()).format('YYYY-MM-DD')}
                                                   value={fromDate}
                                            />
                                        </div>
                                        <div className="col-auto">
                                            <label htmlFor="to_date" className="visually-hidden">Gacha</label>
                                            <input type="date" className="form-control" id="to_date"
                                                   onChange={e => setToDate(e.target.value)}
                                                   max={moment(new Date()).format('YYYY-MM-DD')}
                                                   value={toDate}
                                            />
                                        </div>
                                    </>
                                )
                            }
                            {
                                'monthly' === periodType && (
                                    <>
                                        <div className="col-auto">
                                            <label htmlFor="month" className="visually-hidden">Dan</label>
                                            <input type="month" className="form-control" id="month"
                                                   onChange={e => setMonth(e.target.value)}
                                                   max={moment(new Date()).format('YYYY-MM')}
                                                   value={month}
                                            />
                                        </div>
                                    </>
                                )
                            }
                            {
                                'yearly' === periodType && (
                                    <>
                                        <div className="col-auto">
                                            <label htmlFor="year" className="visually-hidden">Dan</label>
                                            <DatePicker
                                                className={'form-control'}
                                                type="year"
                                                selected={selectedYear}
                                                onChange={(date) => handleYear(date)}
                                                dateFormat="yyyy"
                                                maxDate={moment(new Date()).format('YYYY-MM-DD')}
                                                showYearPicker
                                                showYearDropdown={true}
                                                showFourColumnMonthYearPicker={true}
                                            />
                                        </div>
                                    </>
                                )
                            }
                        </>
                    </>
                )
            }


            <>
                <div className="col-auto">
                    <label htmlFor="to_date" className="visually-hidden">Fayl formati</label>
                    <select className={'form-control form-select'}
                            value={fileFormat}
                            onChange={e => setFileFormat(e.target.value)}
                    >
                        <option value="">Fayl formati</option>
                        <option value="pdf">pdf</option>
                        <option value="xls">xls</option>
                        <option value="xlsx">xlsx</option>
                    </select>
                </div>

            </>

            <div className="col-auto">
                <button type="button" className="btn btn-primary mb-3" onClick={onDownload}>Yuklash</button>
            </div>
        </form>
    )
}

export default SingleClientReport;