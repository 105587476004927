import React, {Fragment, useEffect, useRef, useState} from "react";
import moment from 'moment'
import { useSelector} from "react-redux";
import {
    getSuppliersTransactions,
} from "../../actions/suppliers_transaction";
import SearchForm from "../layout/SearchForm";
import FilterByMonth from "../layout/FilterByMonth";
import SupplierDataRow from "./SupplierDataRow";
import {Accordion} from "react-bootstrap";
import AddSupplierTransactionForm from "./AddSupplierTransactionForm";
import SupplierReport from "./SupplierReport";
import useDates from "../../utils/useDates";
import CustomPaginationWithManyPages from "../layout/CustomPaginationWithManyPages";
import {getParams} from "../../utils/hooks/getParams";

const SupplierCalculationsNew = () => {

    const params = getParams()
    const {dates, updateDates} = useDates(params.month);

    const {
        supplier_transactions,
        loading,
        numberOfPages,
        current_page
    } = useSelector(state => state.suppliers_transaction);

    const scrollableContainer = useRef();

    const [isFirst, setIsFirst] = useState(true);
    useEffect(() => {
        if (supplier_transactions && supplier_transactions.length > 0 && supplier_transactions[0].hasOwnProperty('transactions')) {
            if (isFirst === true && scrollableContainer.current) {
                scrollableContainer.current.scrollLeft += scrollableContainer.current.scrollHeight * 30;
                setIsFirst(false);
            }
        }
    }, [supplier_transactions])

    const onMonthChange = (newYearMonth) => {
        updateDates(newYearMonth);
    }
    return (
        <div className={'px-4'}>
            <div className="row">
                <Accordion>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>
                            <div className={'fs-4'}>Tezkor<strong> tranzaksiya</strong></div>
                        </Accordion.Header>
                        <Accordion.Body>
                            <AddSupplierTransactionForm/>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>
            <div className="row mt-4 mb-2 justify-content-between">
                <div className="col-md-6">
                    <SearchForm getPageItems={getSuppliersTransactions}/>
                </div>
                <div className="col-md-3 align-self-end ">
                    <FilterByMonth change={onMonthChange} getPageItems={getSuppliersTransactions}/>
                </div>
            </div>

            <div className="row mt-3">

                <div className={`loader-container ${loading ? 'loading' : ''} `}></div>

                <div ref={scrollableContainer} className={`tableFixHead  ${loading ? 'data_loader' : ''}`}>

                    {
                        supplier_transactions && supplier_transactions.length > 0 ? (
                            <table className={'table-hover custom-hover'}>
                                <thead>
                                <tr>
                                    <th className="sticky-col" style={{width: "2em"}}>#</th>
                                    <th className="sticky-col2">Yetkazuvchi</th>
                                    {dates.map((item, index, items) => (
                                        items.length - 1 === index ? (
                                            <th key={`date${index}`} className="sticky-col"
                                                style={{textAlign: "center"}}
                                                colSpan="2">
                                                {item}
                                                {moment(new Date()).format('YYYY-MM-DD') === item ? '(bugun)' : ''}
                                            </th>
                                        ) : (
                                            <th key={`date${index}`} className="sticky-col"
                                                style={{textAlign: "center"}}
                                                colSpan="2">{item}
                                            </th>
                                        )
                                    ))}
                                    <th className="sticky-col" style={{textAlign: "center", right: 0}}
                                        colSpan="4">Umumiy hisob
                                    </th>
                                </tr>
                                <tr>
                                    <th className="sticky-col">-</th>
                                    <th className="sticky-col2"></th>
                                    {
                                        dates.map((subitem, index) => (
                                            <Fragment key={`th${index}`}>
                                                <th className="sticky-col"
                                                    style={{textAlign: "center"}}>Kirim
                                                </th>
                                                <th className="sticky-col"
                                                    style={{textAlign: "center"}}>To'lov
                                                </th>
                                            </Fragment>
                                        ))
                                    }
                                    <th className="sticky-col"
                                        style={{textAlign: "center", minWidth: "120px", right: "360px"}}>
                                        Oyning kirimi
                                    </th>
                                    <th className="sticky-col"
                                        style={{textAlign: "center", minWidth: "120px", right: "240px"}}>
                                        Oyning to'lovi
                                    </th>
                                    <th className="sticky-col"
                                        style={{textAlign: "center", minWidth: "120px", right: "120px"}}>
                                        Umumiy kirim
                                    </th>
                                    <th className="sticky-col"
                                        style={{textAlign: "center", minWidth: "120px", right: "0px"}}>
                                        Umumiy to'lov
                                    </th>
                                </tr>
                                </thead>

                                <tbody>
                                {supplier_transactions?.map((item, index) => (
                                    <SupplierDataRow item={item}
                                                     key={item.id}
                                                     index={index}
                                                     dates={dates}
                                    />
                                ))}
                                </tbody>
                            </table>
                        ) : ''
                    }

                </div>
            </div>

            <div className="row mt-3">
                <CustomPaginationWithManyPages
                    numberOfPages={numberOfPages}
                    getPageItems={getSuppliersTransactions}
                    current_page={current_page}
                />
            </div>
            <SupplierReport/>
        </div>

    )
}


export default SupplierCalculationsNew;

