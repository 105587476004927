import React from 'react';
import {useHistory} from 'react-router-dom';
import Arrow from "../../img/arrow.svg"

const GoBackButton = ({path}) => {

    const history = useHistory();
    return (
        <>
            {
                path ? (
                    <button onClick={() => window.location.replace(path)}
                            className="btn text-center btn-primary -item-right">
                        <img
                            className="ms-1" src={Arrow} alt=""/></button>
                ) : (
                    <button onClick={() => history.goBack()} className="btn text-center btn-primary -item-right"><img
                        className="ms-1" src={Arrow} alt=""/></button>
                )
            }
        </>
    );
};

export default GoBackButton;