import React, {Fragment, useState} from 'react';
import Modal from 'react-bootstrap/Modal';
import SpecialSaleCreateForm from "./components/SpecialSaleCreateForm";
import SimpleSaleCreateForm from "./components/SimpleSaleCreateForm";

const SaleCreateForm = ({handleClose, show}) => {

    const onClose = () => {
        handleClose();
    };
    const [isSpecialSale, setIsSpecialSale] = useState(false);

    return (
        <Fragment>
            <Modal style={{ zIndex: 9999 }} size="lg" show={show} onHide={onClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Yangi sotuv amalga oshirish</Modal.Title>
                </Modal.Header>

                <Modal.Body className="pt-3 pb-4 mx-3">

                    <div className="form-check form-switch">
                        <input className="form-check-input"
                               type="checkbox"
                               id="flexSwitchCheckChecked"
                               onChange={() => setIsSpecialSale(!isSpecialSale)}
                               checked={isSpecialSale}
                        />
                        <label className="form-check-label" htmlFor="flexSwitchCheckChecked">
                            Maxsus sotuv
                        </label>
                    </div>

                    {
                        isSpecialSale ? (
                            <SpecialSaleCreateForm handleClose={onClose} />
                        ) : (
                            <SimpleSaleCreateForm handleClose={onClose}/>
                        )
                    }


                </Modal.Body>

            </Modal>
        </Fragment>
    );
};

export default SaleCreateForm;
