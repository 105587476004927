import axios from 'axios';
import {clearAlerts, setAlert} from './alert';
import {
    ADD_PRODUCT_DELIVERY,
    CLEAR_ERRORS,
    GET_CLIENT_AND_DRIVER_ID_DATA,
    GET_PRODUCT_DELIVERIES,
    GET_PRODUCT_DELIVERY,
    PRODUCT_DELIVERY_ERROR,
    REMOVE_DELETE_PRODUCT_DELIVERY,
    REMOVE_UPDATE_PRODUCT_DELIVERY,
    SET_DELETE_PRODUCT_DELIVERY_ID,
    SET_UPDATE_PRODUCT_DELIVERY_ID
} from './types';

export const getClientAndDriverIdData = () => async (dispatch) => {

    try {
        const res = await axios.get(`/clients?is_all=true`);
        const res2 = await axios.get(`/employees?is_all_driver=true`);
        const res3 = await axios.get(`/transports?is_all=true`);

        dispatch({
            type: GET_CLIENT_AND_DRIVER_ID_DATA,
            payload: {
                client_options: res.data.data,
                driver_options: res2.data.data,
                transport_options: res3.data.data
            },
        });

    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
    }
};

export const getProductDelivery = (productDeliveryID) => async (dispatch) => {

    try {
        const res = await axios.get(`/product-deliveries/${productDeliveryID}`);
        dispatch({
            type: GET_PRODUCT_DELIVERY,
            payload: res.data,
        });

    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
    }
};

export const getProductDeliveries = (page = 1, filters = {}) => async (dispatch) => {
    try {

        let queryParams = '';
        if (filters) {
            Object.keys(filters).map((key, index) => queryParams += `&${key}=${filters[key]}`);
        }
        const res = await axios.get(`/product-deliveries?page=${page}${queryParams}`);

        dispatch({
            type: GET_PRODUCT_DELIVERIES,
            payload: res.data,
        });

    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
    }
};

export const addProductDelivery = (formData) => async (dispatch) => {
    dispatch(clearAlerts());

    const config = {
        headers: {
            'Content-Type': 'application/json',
        },
    };

    try {
        const res = await axios.post('/product-deliveries/', formData, config);

        dispatch(getProductDeliveries());

        dispatch({
            type: ADD_PRODUCT_DELIVERY,
            payload: res.data,
        });

        dispatch(setAlert('Mahsulot yetkazib berish yaratildi !', 'success'));

    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));

        if (err?.response?.data?.errors) {
            dispatch({
                type: PRODUCT_DELIVERY_ERROR,
                payload: err.response.data,
            });
        } else {
            dispatch(setAlert("Mahsulot yetkazish yaratilmadi !", 'danger'));
        }
    }
};

export const deleteProductDelivery = (deleteProductDeliveryID, getPage) => async (dispatch) => {
    dispatch(clearAlerts());

    const config = {
        headers: {
            'Content-Type': 'application/json',
        },
    };

    try {
        const res = await axios.delete(`/product-deliveries/${deleteProductDeliveryID}`, config);

        dispatch({
            type: REMOVE_DELETE_PRODUCT_DELIVERY
        });

        dispatch(getProductDeliveries(getPage));

        dispatch(setAlert("Mahsulot yetkazib berish o'chirildi !", 'success'));
    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
    }
};

export const updateProductDelivery = (updateProductDeliveryID, updateformData, getPage) => async (dispatch) => {
    dispatch(clearAlerts());

    const config = {
        headers: {
            'Content-Type': 'application/json',
        },
    };

    try {
        const res = await axios.put(`/product-deliveries/${updateProductDeliveryID}`, updateformData, config);

        dispatch({type: REMOVE_UPDATE_PRODUCT_DELIVERY});

        dispatch(getProductDeliveries(getPage));

        dispatch(setAlert('Mahsulot yetkazib berish tahrirlandi !', 'success'));
    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
    }
};

export const setDeleteProductDeliveryId = (product_deliveryID) => async (dispatch) => {

    try {
        if (product_deliveryID !== null) {
            dispatch({
                type: SET_DELETE_PRODUCT_DELIVERY_ID,
                payload: {
                    product_deliveryID
                }
            });

        } else {
            dispatch({
                type: REMOVE_DELETE_PRODUCT_DELIVERY
            });
        }

    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
    }
};

export const setUpdateProductDeliveryId = (product_deliveryID) => async (dispatch) => {

    try {
        if (product_deliveryID !== null) {
            dispatch({
                type: SET_UPDATE_PRODUCT_DELIVERY_ID,
                payload: {
                    product_deliveryID
                }
            });
        } else dispatch({type: REMOVE_UPDATE_PRODUCT_DELIVERY});

    } catch (err) {
        dispatch(setAlert(err?.response?.data?.message, 'danger'));
    }
};

export const clearErrors = () => async (dispatch) => {
    dispatch({
        type: CLEAR_ERRORS,
    });
};














