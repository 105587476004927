import {
    ADD_SUPPLIER,
    AVAILABLE_CLIENT_ERROR,
    CLEAR_ERRORS,
    GET_AVAILABLE_MANAGERS, GET_AVAILABLE_SUPPLIERS,
    GET_MATERIALS_WAREHOUSES_ID_DATA,
    GET_SUPPLIER, GET_SUPPLIER_MATERIAL_IMPORTS,
    GET_SUPPLIER_TRANSACTIONS,
    GET_SUPPLIERS,
    GET_UPDATE_SUPPLIER,
    REMOVE_DELETE_SUPPLIER,
    REMOVE_DELETE_SUPPLIER_MATERIAL,
    REMOVE_UPDATE_SUPPLIER,
    REMOVE_UPDATE_SUPPLIER_MATERIAL, SET_DELETE_MATERIAL_IMPORT_ID,
    SET_DELETE_SUPPLIER_ID,
    SET_DELETE_SUPPLIER_MATERIAL_ID,
    SET_LOADING,
    SET_UPDATE_SUPPLIER_ID, SET_UPDATE_SUPPLIER_MATERIAL_ID,
    SUPPLIER_ERROR, UNSET_LOADING
} from '../actions/types';

const initialState = {
    suppliers: [],
    supplier: {},
    available_suppliers: [],
    available_managers: [],
    available_clients: [],
    update_supplier: null,
    delete_supplier: null,
    loading: true,
    numberOfPages: 1,
    total_suppliers: null,
    error: null,
    update_suppliers_transaction_for_supplier: null,
    filtered_supplier_material_data_date: null,
    supplier_transactions: [],
    supplier_material_imports: [],
    update_supplier_material: null,
    delete_supplier_material: null,
};

// eslint-disable-next-line
export default function (state = initialState, action) {
    const {type, payload} = action;

    switch (type) {
        case SET_LOADING:
            return {
                ...state,
                loading: payload,
            }
        case UNSET_LOADING:
            return {
                ...state,
                loading: false,
            }
        case GET_AVAILABLE_SUPPLIERS:

            return {
                ...state,
                available_suppliers: payload.data,
            };

        case GET_AVAILABLE_MANAGERS:

            return {
                ...state,
                available_managers: payload.data,
            };

        case AVAILABLE_CLIENT_ERROR:
            return {
                ...state,
                error: payload,
                loading: false,
            };

        case GET_SUPPLIER:
            return {
                ...state,
                supplier: payload.data,
                error: null,
                loading: false,
            };
        case GET_SUPPLIERS:
            return {
                ...state,
                suppliers: payload.data,
                numberOfPages: payload.meta.last_page,
                currentPage: payload.meta.current_page,
                total_suppliers: payload.meta.total,
                error: null,
                loading: false,
            };
        case SET_DELETE_MATERIAL_IMPORT_ID:
            let material_import = state.supplier_material_imports.find(item => item.id === payload);
            if (!payload) {
                material_import = null;
            }
            return {
                ...state,
                delete_material_import: material_import,
                error: null,
                loading: false,
            };
        case GET_SUPPLIER_TRANSACTIONS:
            return {
                ...state,
                supplier_transactions: payload.data,
                error: null,
                loading: false,
            };

        case GET_SUPPLIER_MATERIAL_IMPORTS:
            return {
                ...state,
                supplier_material_imports: payload.data,
                error: null,
                loading: false,
            };

        case ADD_SUPPLIER:
            return {
                ...state,
                suppliers: [payload],
                error: null,
            };

        case REMOVE_DELETE_SUPPLIER:
            return {
                ...state,
                delete_supplier: null,
            };

        case REMOVE_UPDATE_SUPPLIER:
            return {
                ...state,
                update_supplier: null,
            };

        case SET_DELETE_SUPPLIER_ID:

            return {
                ...state,
                delete_supplier: state.suppliers.find((item) => item.id === action.payload.supplierID)
            };

        case GET_UPDATE_SUPPLIER:
            return {
                ...state,
                update_supplier: payload.data,
                error: null,
            };


        case SET_UPDATE_SUPPLIER_ID:
            return {
                ...state,
                update_supplier: state.suppliers.find((item) => item.id === action.payload.supplierID)
            };

        case SUPPLIER_ERROR:
            return {
                ...state,
                error: payload,
                loading: false,
            };

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            };

        case GET_MATERIALS_WAREHOUSES_ID_DATA:
            return {
                ...state,
                ...payload,
                loading: false
            };

        case REMOVE_UPDATE_SUPPLIER_MATERIAL:
            return {
                ...state,
                update_supplier_material: null,
            };

        case REMOVE_DELETE_SUPPLIER_MATERIAL:
            return {
                ...state,
                delete_supplier_material: null,
            };

        case SET_UPDATE_SUPPLIER_MATERIAL_ID:
            return {
                ...state,
                update_supplier_material: payload
            };

        case SET_DELETE_SUPPLIER_MATERIAL_ID:
            return {
                ...state,
                delete_supplier_material: payload
            };


        default:
            return state;
    }
}
