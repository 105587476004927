import React, {Fragment, useEffect, useRef, useState} from "react";
import moment from 'moment'
import {useSelector} from "react-redux";
import {getClientsTransactions} from "../../actions/clients_transaction";
import SearchForm from "../layout/SearchForm";
import FilterByMonth from "../layout/FilterByMonth";
import FilterByMinusBalance from "../layout/FilterByMinusBalance";
import FilterByType from "../clients/FilterByType";
import ClientDataRow from "./ClientDataRow";
import {Accordion} from "react-bootstrap";
import AddClientTransactionForm from "./AddClientTransactionForm";
import ClientReport from "./ClientReport";
import useDates from "../../utils/useDates";
import CustomPaginationWithManyPages from "../layout/CustomPaginationWithManyPages";
import { useLocation } from 'react-router-dom';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

//create your forceUpdate hook
function useForceUpdate() {
    const [value, setValue] = useState(0); // integer state
    return () => setValue(value => value + 1); // update the state to force render
}

const ClientCalculationsNew = () => {

    const query = useQuery();
    const monthParam = query.get('month');
    const forceUpdate = useForceUpdate();

    const {dates, updateDates} = useDates(monthParam);
    const resetAllRows = (e) => {
        forceUpdate();
    }
    const {
        auth
    } = useSelector(state => state);
    const {role_name} = auth?.user || {};

    const {
        client_transactions,
        loading,
        numberOfPages,
        current_page,
    } = useSelector(state => state.clients_transaction);

    const scrollableContainer = useRef();
    const [isFirst, setIsFirst] = useState(true);

    useEffect(() => {
        if (client_transactions && client_transactions.length > 0 && client_transactions[0].hasOwnProperty('transactions')) {
            if (isFirst === true && scrollableContainer.current) {
                scrollableContainer.current.scrollLeft += scrollableContainer.current.scrollHeight * 30;
                setIsFirst(false);
            }
        }
    }, [client_transactions])


    const onMonthChange = (newYearMonth) => {
        updateDates(newYearMonth);
    }

    return (
        <div className={'px-4'}>
            <div className="row">
                <Accordion>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>
                            <div className={'fs-4'}>Tezkor<strong> tranzaksiya</strong></div>
                        </Accordion.Header>
                        <Accordion.Body>
                            <AddClientTransactionForm/>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>
            <div className="row mt-4 mb-2 justify-content-between">
                <div className="col-md-3">
                    <SearchForm getPageItems={getClientsTransactions}/>
                </div>
                <div className="col-md-2">
                    <FilterByType getPageItems={getClientsTransactions}/>
                </div>
                <div className="col-md-3 align-items-middle">
                    <FilterByMinusBalance getPageItems={getClientsTransactions}/>
                </div>
                <div className="col-md-3 align-self-end ">
                    <FilterByMonth change={onMonthChange} getPageItems={getClientsTransactions}/>
                </div>
            </div>

            <div className="row mt-3">

                <div className={`loader-container ${loading ? 'loading' : ''} `}></div>

                <div ref={scrollableContainer} className={`tableFixHead ${loading ? 'data_loader' : ''}`}>

                    {
                        client_transactions && client_transactions.length > 0 ? (
                            <table className={'table-hover custom-hover'}>
                                <thead>
                                <tr>
                                    <th className="sticky-col" style={{width: "2em"}}>#</th>
                                    <th className="sticky-col2">Klient</th>
                                    {dates && dates.length && dates.map((item, index, items) => 
                                        items.length - 1 === index ? (
                                            <th key={`date${index}`} className="sticky-col" style={{textAlign: "center"}} colSpan="2">
                                                {item}
                                                {moment(new Date()).format('YYYY-MM-DD') === item ? '(bugun)' : ''}
                                            </th>
                                        ) : (
                                            <th key={`date${index}`} className="sticky-col" style={{textAlign: "center"}} colSpan="2">
                                                {item}
                                            </th>
                                        )
                                    )}
                                    <th className="sticky-col" style={{textAlign: "center", right: 0}} colSpan="4">Oy bo'yicha umumiy hisob</th>
                                </tr>
                                <tr>
                                    <th className="sticky-col">-</th>
                                    <th className="sticky-col2"/>
                                    {dates.map((subitem, index) => (
                                        <Fragment key={`th${index}`}>
                                            <th className="sticky-col" style={{textAlign: "center"}}>Kirim</th>
                                            <th className="sticky-col" style={{textAlign: "center"}}>Sotuv</th>
                                        </Fragment>
                                    ))}
                                    <th className="sticky-col" style={{textAlign: "center", minWidth: "100px", right: "200px"}}>Umumiy kirim</th>
                                    <th className="sticky-col" style={{textAlign: "center", minWidth: "100px", right: "100px"}}>Umumiy sotuv</th>
                                    <th className="sticky-col" style={{textAlign: "center", minWidth: "100px", right: "0px"}}> Qoldiq puli</th>
                                </tr>
                                </thead>

                                <tbody>
                                {client_transactions?.map((item, index) => (
                                    <ClientDataRow item={item} key={item.id}
                                                   index={index}
                                                   dates={dates}
                                                   resetAllRows={resetAllRows}/>

                                ))}
                                </tbody>
                            </table>
                        ) : null
                    }

                </div>

            </div>

            <div className="row mt-3">
                <CustomPaginationWithManyPages
                    numberOfPages={numberOfPages}
                    getPageItems={getClientsTransactions}
                    current_page={current_page}
                />
            </div>
            <ClientReport/>
        </div>

    )
}

export default ClientCalculationsNew;
