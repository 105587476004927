import React, {Fragment, useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import {addProductDelivery, getClientAndDriverIdData} from "../../actions/product-delivery";
import {clearErrors} from "../../actions/sale";
import Select from "react-select";

const ProductDeliveryCreateForm = ({
                                       product_delivery: {client_ID_DATA, transport_ID_DATA, driver_ID_DATA},
                                       addProductDelivery,
                                       getClientAndDriverIdData,
                                       handleShow,
                                       handleClose,
                                       error,
                                       product_deliveries,
                                       loading,
                                       show
                                   }) => {

    useEffect(() => {
        getClientAndDriverIdData();
    }, [getClientAndDriverIdData]);

    const [client_id, setClientId] = useState(null);
    const [driver_id, setDriverId] = useState(null);
    const [transport_id, setTransportId] = useState(null);
    const [clientOptions, setClientOptions] = useState([]);
    const [driverOptions, setDriverOptions] = useState([]);
    const [transportOptions, setTransportOptions] = useState([]);

    useEffect(() => {
        const items = [];
        client_ID_DATA.forEach((item) => items.push({
            label: `${item.name}`,
            value: item.id
        }));
        setClientOptions(items);
    }, [client_ID_DATA]);

    useEffect(() => {


        const items = [];
        driver_ID_DATA.forEach((item) => items.push({
            label: `${item.first_name} ${item.last_name}`,
            value: item.id
        }));
        setDriverOptions(items);
    }, [driver_ID_DATA]);

    useEffect(() => {
        const items = [];
        transport_ID_DATA.forEach((item) => items.push({
            label: `${item.name}`,
            value: item.id
        }));
        setTransportOptions(items);
    }, [transport_ID_DATA]);

    const onSelectClient = (data) => {
        setClientId(data.value);
    };

    const onSelectDriver = (data) => {
        setDriverId(data.value);
    };

    const onSelectTransport = (data) => {
        setTransportId(data.value);
    };

    const formRef = useRef();

    const defaultValues = {
        transport_id: '',
        client_id: '',
        driver_id: '',
        round: '',
        price: ''
    };

    const [inputs, setInputs] = useState(defaultValues);
    const [errors, setErrors] = useState({});

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = {
            transport_id: transport_id,
            client_id: client_id,
            driver_id: driver_id,
            round: inputs.round,
            price: inputs.price,
        };
        addProductDelivery(data);
    };

    const handleChange = e => {
        setErrors({});
        e.target.oldValue = e.target.value;
        setInputs(prevState => ({...prevState, [e.target.name]: e.target.value}));
    };

    useEffect(() => {
        if (error && loading === false) {
            const errorsItems = error?.errors;
            let errorObject = {};
            if (error && error?.errors) {
                Object.keys(error?.errors).forEach((item, index) => errorObject[item] = errorsItems[item][0]);
            }
            setErrors(errorObject);

        }
    }, [error]);


    useEffect(() => {
        clearErrors();
        setErrors({});
        setInputs(defaultValues);
        setDriverId(driver_id => null);
        setClientId(client_id => null);
        setTransportId(transport_id => null);
    }, [product_deliveries]);

    const onClose = () => {
        clearErrors();
        setInputs(defaultValues);
        setErrors({});
        handleClose();
        setDriverId(prevState => (null));
        setClientId(prevState => (null));
        setTransportId(prevState => (null));
    };


    return (
        <Fragment>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Yangi mahsulot yetkazib berish qo'shish</Modal.Title>
                </Modal.Header>

                <Modal.Body className="pt-3 pb-4 mx-3">

                    <form onSubmit={handleSubmit} ref={formRef}>

                        <div className="mb-3">
                            <label htmlFor="transport_id">Transport</label>
                            <Select
                                name={'transport_id'}
                                options={transportOptions}
                                onChange={value => onSelectTransport(value)}
                                defaultValue={{label: 'Tanlang', value: '-'}}
                            />

                            <div className="error">
                                {errors?.transport_id}
                            </div>
                        </div>

                        <div className="mb-3">
                            <label htmlFor="client_id">Mijoz</label>
                            <Select
                                name={'client_id'}
                                options={clientOptions}
                                onChange={value => onSelectClient(value)}
                                defaultValue={{label: 'Tanlang', value: '-'}}
                            />

                            <div className="error">
                                {errors?.client_id}
                            </div>
                        </div>

                        <div className="mb-3">
                            <label htmlFor="driver_id">Haydovchi</label>
                            <Select
                                name={'driver_id'}
                                options={driverOptions}
                                onChange={value => onSelectDriver(value)}
                                defaultValue={{label: 'Tanlang', value: '-'}}
                            />

                            <div className="error">
                                {errors?.driver_id}
                            </div>
                        </div>


                        <div className="mb-3">
                            <label htmlFor="round">Qatnov(resy)</label>
                            <input
                                name="round"
                                className={'form-control'}
                                type="number"
                                value={inputs?.round || ''}
                                placeholder={'kiriting'}
                                onChange={handleChange}
                                onKeyUp={e => e.target.oldValue = e.target.value}
                            />

                            <div className="error">
                                {errors?.round}
                            </div>
                        </div>

                        <div className="mb-3">
                            <label htmlFor="price">Narxi</label>
                            <input
                                name="price"
                                className={'form-control'}
                                type="number"
                                value={inputs?.price || ''}
                                placeholder={'kiriting'}
                                onChange={handleChange}
                                onKeyUp={e => e.target.oldValue = e.target.value}
                            />

                            <div className="error">
                                {errors?.price}
                            </div>
                        </div>

                        <div className="d-flex gap-2">
                            <Button className="w-50" variant="secondary" type="button" onClick={onClose}>Bekor
                                qilish</Button>
                            <Button className="w-50" variant="primary" type="submit">Saqlash</Button>
                        </div>

                    </form>


                </Modal.Body>

            </Modal>
        </Fragment>
    );
};

ProductDeliveryCreateForm.propTypes = {
    addProductDelivery: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    product_delivery: state.product_delivery,
    error: state.product_delivery.error,
    loading: state.product_delivery.loading,
    product_deliveries: state.product_delivery.product_deliveries,
});


export default connect(mapStateToProps, {addProductDelivery, getClientAndDriverIdData})(ProductDeliveryCreateForm);
