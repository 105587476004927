// import axios from 'axios';
// import { setAlert } from './alert';
import {
    SHOW_SIDEBAR,
    HIDE_SIDEBAR,
    TOGGLE_SIDEBAR,
    SET_ACTIVE_MENU_ITEM,
    EXPAND_SIDEBAR,
    COLLAPSE_SIDEBAR
} from './types';

export const toggleSidebar = () => ({
    type: TOGGLE_SIDEBAR
});

export const expandSidebar = () => ({
    type: EXPAND_SIDEBAR
});

export const collapseSidebar = () => ({
    type: COLLAPSE_SIDEBAR
});

export const hideSidebar = () => ({
    type: HIDE_SIDEBAR
});

export const showSidebar = () => ({
    type: SHOW_SIDEBAR
});

export const setActiveItem = (route) => ({
    type: SET_ACTIVE_MENU_ITEM,
    payload: route
});
