import React from "react";
import moment from "moment";

export default function useDates(yearMonth = null) {
  let today = moment();
  let first = today.clone().startOf('month');
  
  if (yearMonth) {
    first = moment(yearMonth, 'YYYY-MM');
    today = first.clone().endOf('month'); // Set today to end of selected month for past months
  }
  
  let end = first.clone().endOf('month');
  const result = [];
  let current = first.clone();

  for (let i = 1; i <= end.daysInMonth(); i++) {
    // For current month, stop at today
    if (!yearMonth && current.isAfter(today)) {
      break;
    }
    
    // For selected month that is current month
    if (yearMonth === moment().format('YYYY-MM') && current.isAfter(today)) {
      break;
    }

    result.push(current.format('YYYY-MM-DD'));
    current.add(1, 'day');
  }

  const [dates, setDates] = React.useState(result);

  function updateDates(newYearAndMonth) {
    let selectedDate = moment(newYearAndMonth, 'YYYY-MM');
    let firstDay = selectedDate.clone().startOf('month');
    let lastDay = selectedDate.clone().endOf('month');
    let currentDay = firstDay.clone();
    const newDates = [];

    while (currentDay.isSameOrBefore(lastDay)) {
      // Only check against today if it's the current month
      if (newYearAndMonth === moment().format('YYYY-MM') && currentDay.isAfter(today)) {
        break;
      }
      newDates.push(currentDay.format('YYYY-MM-DD'));
      currentDay.add(1, 'day');
    }
    
    setDates(newDates);
  }

  return {dates, updateDates};
}