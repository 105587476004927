import React, {Fragment, useState} from 'react';
import {useSelector} from 'react-redux';
import SupplierDeleteForm from "./SupplierDeleteForm";
import SupplierUpdateForm from "./SupplierUpdateForm";
import {getSuppliers} from "../../actions/supplier";
import SupplierItem from "./SupplierItem";
import SupplierCreateForm from "./SupplierCreateForm";
import SearchForm from "../layout/SearchForm";
import {Plus} from "react-feather";
import TableSpinner from "../layout/TableSpinner";
import CustomPaginationWithManyPages from "../layout/CustomPaginationWithManyPages";

const Suppliers = () => {

    const {
        auth
    } = useSelector(state => state);
    const {role_name} = auth?.user || {};
    const {suppliers, loading, numberOfPages, currentPage, total_suppliers} = useSelector(state => state.supplier);

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <Fragment>
            <div className="container-fluid p-0">
                <div className={`row mb-2 `}>
                    <div className="col-md-12 col-lg-8">
                        <h1 className="h3 mb-3"><strong></strong> yetkazuvchilar ({total_suppliers}) </h1>
                    </div>
                </div>

                <div className="row">
                    <div className="col-6">
                        <SearchForm getPageItems={getSuppliers}/>
                    </div>

                    <div className="col-md-6">
                        <div className="crud_actions text-end">
                            <button className="btn btn-primary" onClick={handleShow}>
                                Yetkazib beruvchi <Plus/>
                            </button>
                        </div>
                    </div>
                </div>

                <div className="row mt-2">
                    <div className="col-xl-12 col-xxl-12 d-flex">

                        <div className="card w-100 table-responsive">
                            {loading && (
                                <TableSpinner/>
                            )}
                            <table className="table custom-table table-hover">
                                <thead className="table-light">
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Nomi</th>
                                    <th scope="col">Tashkilot xodimi</th>
                                    <th scope="col">INN</th>
                                    <th scope="col">Klient</th>
                                    <th scope="col">Telefon</th>
                                    <th scope="col">Bank raqami</th>
                                    <th scope="col">Amallar</th>
                                </tr>
                                </thead>
                                <tbody>
                                {suppliers.map((item, index) => (
                                    <SupplierItem number={index + 1} key={`supplier${item.id}`} supplier={item}/>
                                ))}
                                </tbody>
                            </table>

                        </div>

                    </div>

                </div>

                <div className="row">
                    <div className="col-xl-12 col-xxl-12 d-flex">
                        <CustomPaginationWithManyPages
                            numberOfPages={numberOfPages}
                            getPageItems={getSuppliers}
                            current_page={currentPage}
                        />
                    </div>
                </div>

            </div>


            <SupplierCreateForm show={show} handleShow={handleShow} handleClose={handleClose}/>

            <SupplierDeleteForm/>

            <SupplierUpdateForm/>


        </Fragment>
    );
};

export default Suppliers;
