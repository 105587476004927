import React, {Fragment, useEffect, useState} from "react";
import { formatMoney} from "../../utils/hooks/getParams";

const ProductDataCell = ({index, cellData, date}) => {
    const [itemData, setDateData] = useState(cellData);

    useEffect(() => {
        setDateData(cellData);
    }, [cellData])

    return (
        <Fragment key={`td${index}`}>
            <td
                className={'cell produced_amount'}
                data-old_value={itemData.produced_amount}
            >
                {formatMoney(itemData.produced_amount)}
            </td>
            <td
                className={'cell sold_amount'}
                data-old_value={itemData.sold_amount}
            >
                {formatMoney(itemData.sold_amount)}
            </td>
        </Fragment>
    )
}

export default ProductDataCell;