import React, {Fragment, useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import {addMaterial, clearErrors, getMaterialUnits, getWarehouseOptions} from "../../actions/material";
import Select from "react-select";
import {getFirmOptions} from "../../actions/user";
import {getParams, getSelectedOption} from "../../utils/hooks/getParams";


const MaterialCreateForm = ({
                                materials,
                                firm_options,
                                material_warehouses_options,
                                loading,
                                material_units,
                                error,
                                addMaterial,
                                getMaterialUnits,
                                getWarehouseOptions,
                                getFirmOptions,
                                clearErrors,
                                handleClose,
                                show
                            }) => {


    const formRef = useRef();

    useEffect(() => {
        getMaterialUnits();
        getFirmOptions();
    }, [getMaterialUnits, getFirmOptions]);

    const [firmOptions, setFirmOptions] = useState([]);
    const [warehouseOptions, setWarehouseOptions] = useState([]);

    useEffect(() => {
        let items = [];
        firm_options.forEach((item) => items.push({
            label: `${item.name}`,
            value: item.id
        }));
        setFirmOptions(items);

    }, [firm_options]);

    useEffect(() => {
        let options = [];
        material_warehouses_options.forEach((item) => options.push({
            label: item.name,
            value: item.id
        }));
        setWarehouseOptions(options);
    }, [material_warehouses_options]);

    const defaultValues = {
        name: '',
        unit_id: '',
        firm_id: '',
        warehouse_id: '',
        unit_alternative_id: '',
        multiplier: '',
    };

    const [inputs, setInputs] = useState(defaultValues);
    const [errors, setErrors] = useState({});

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = {
            name: inputs.name,
            firm_id: Number(inputs.firm_id),
            warehouse_id: Number(inputs.warehouse_id),
            unit_id: Number(inputs.unit_id),
            unit_alternative_id: Number(inputs.unit_alternative_id),
            multiplier: Number(inputs.multiplier),
        };
        const params = getParams(true);
        addMaterial(data, params);
    };

    const handleChange = e => {
        setErrors({});
        e.target.oldValue = e.target.value;
        setInputs(prevState => ({...prevState, [e.target.name]: e.target.value}));

        // bundan pastdagilar hamma formada kerak emas.
        if (e.target.name === 'name') {
            setMaterialName(e.target.value);
        }
        if (e.target.value && e.target.name === 'unit_id') {
            const unit = material_units.find(item => item.id === Number(e.target.value));
            setUnitName(unit.name);
        }
        if (e.target.value && e.target.name === 'unit_alternative_id') {
            const unit = material_units.find(item => item.id === Number(e.target.value));
            setUnitAlternativeName(unit.name);
        }
        if (e.target.name === 'multiplier') {
            setMultiplier(e.target.value);
        }
    };


    const handleSelect = (data, type) => {
        setErrors({});
        if (type.name === 'firm_id') {
            getWarehouseOptions({firm_id: data.value});
        }
        setInputs(prevState => ({...prevState, [type.name]: data.value}))
    };


    const [unit_name, setUnitName] = useState('');
    const [unit_alternative_name, setUnitAlternativeName] = useState('');
    const [material_name, setMaterialName] = useState('');
    const [multiplier, setMultiplier] = useState('');

    useEffect(() => {
        if (error && loading === false) {
            const errorsItems = error?.errors;
            let errorObject = {};
            if (error && error?.errors) {
                Object.keys(error?.errors).forEach((item) => errorObject[item] = errorsItems[item][0]);
            }
            setErrors(errorObject);
        }
    }, [error]);

    useEffect(() => {
        clearErrors();
        setErrors({});
        setInputs(defaultValues);

        setUnitName('')
        setUnitAlternativeName('')
        setMaterialName('')
        setMultiplier('')
    }, [materials]);

    const onClose = () => {
        clearErrors();
        setInputs(defaultValues);
        setErrors({});
        handleClose();
    }

    return (
        <Fragment>
            <Modal show={show} onHide={onClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Yangi material qo'shish</Modal.Title>
                </Modal.Header>

                <Modal.Body className="pt-3 pb-4 mx-3">
                    <form onSubmit={handleSubmit} ref={formRef}>


                        <div className="mb-2">
                            <label htmlFor="name">Material nomi</label>
                            <input
                                name="name"
                                className={'form-control'}
                                type="text"
                                value={inputs?.name || ''}
                                placeholder={'kiriting'}
                                onChange={handleChange}
                                onKeyUp={e => e.target.oldValue = e.target.value}
                                required
                            />

                            <div className="error">
                                {errors?.name}
                            </div>
                        </div>

                        <div className="mb-3">
                            <label htmlFor="firm_id">Firma</label>
                            <Select
                                name={'firm_id'}
                                options={firmOptions}
                                value={getSelectedOption(inputs?.firm_id, firmOptions)}
                                onChange={handleSelect}
                                defaultValue={{label: 'Tanlang', value: ''}}
                            />
                            <div className="error">
                                {errors?.firm_id}
                            </div>
                        </div>

                        <div className="mb-3">
                            <label htmlFor="firm_id">Sklad</label>
                            <Select
                                name={'warehouse_id'}
                                options={warehouseOptions}
                                value={getSelectedOption(inputs?.warehouse_id, warehouseOptions)}
                                onChange={handleSelect}
                                defaultValue={{label: 'Tanlang', value: ''}}
                            />
                            <div className="error">
                                {errors?.warehouse_id}
                            </div>
                        </div>


                        <div className="mb-2">
                            <label htmlFor="name">Kirim o'lchovi</label>
                            <select name="unit_id" id="unit_id"
                                    className="form-control"
                                    value={inputs?.unit_id || ''}
                                    onChange={handleChange}
                                    onKeyUp={e => e.target.oldValue = e.target.value}
                            >
                                <option value="">Tanlang</option>
                                {
                                    material_units.map((item, index) => (
                                        <option key={index} value={item.id}>{item.name}</option>
                                    ))
                                }
                            </select>

                            <div className="error">
                                {errors?.unit_id}
                            </div>
                        </div>

                        <div className="mb-2">
                            <label htmlFor="unit_alternative_id">Chiqim o'lchovi</label>
                            <select name="unit_alternative_id"
                                    value={inputs?.unit_alternative_id || ''}
                                    id="unit_alternative_id"
                                    className="form-control"
                                    onChange={handleChange}
                                    onKeyUp={e => e.target.oldValue = e.target.value}
                            >
                                <option value="">Tanlang</option>
                                {
                                    material_units.map((item, index) => (
                                        <option key={index} value={item.id}>{item.name}</option>
                                    ))
                                }

                            </select>
                            <div className="error">
                                {errors?.unit_alternative_id}
                            </div>
                        </div>


                        <div className="mb-2">
                            <label htmlFor="multiplier">
                                1 {`${unit_name} ${material_name} = ${multiplier} ${unit_alternative_name} ?`}
                            </label>
                            <input
                                name="multiplier"
                                className={'form-control'}
                                type="number"
                                value={inputs?.multiplier || ''}
                                placeholder={'kiriting'}
                                onChange={handleChange}
                                onKeyUp={e => e.target.oldValue = e.target.value}
                                required
                            />

                            <div className="error">
                                {errors?.multiplier}
                            </div>
                        </div>

                        <div className="d-flex gap-2">
                            <Button className="w-50" variant="secondary" type="button" onClick={onClose}>Bekor
                                qilish</Button>
                            <Button className="w-50" variant="primary" type="submit">Saqlash</Button>
                        </div>
                    </form>
                </Modal.Body>

            </Modal>
        </Fragment>
    );
};

MaterialCreateForm.propTypes = {
    addMaterial: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    material: state.material,
    loading: state.material.loading,
    materials: state.material.materials,
    firm_options: state.user.firm_options,
    material_warehouses_options: state.material.material_warehouses_options,
    error: state.material.error,
    material_units: state.material.material_units,
    material_unit_alternatives: state.material.material_unit_alternatives,
});


export default connect(mapStateToProps, {
    addMaterial,
    getMaterialUnits,
    clearErrors,
    getFirmOptions,
    getWarehouseOptions
})(MaterialCreateForm);
