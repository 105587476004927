import React, {Fragment, useEffect, useState} from 'react';
import Modal from "react-bootstrap/Modal";
import {useDispatch, useSelector} from "react-redux";
import {setUpdateSaleId} from "../../actions/sale";
import SpecialSaleUpdateForm from "./components/SpecialSaleUpdateForm";
import SimpleSaleUpdateForm from "./components/SimpleSaleUpdateForm";
import TableSpinner from "../layout/TableSpinner";

const SaleUpdateForm = ({show, handleClose, isLoading, showLoader, hideLoader}) => {

    const dispatch = useDispatch();

    const [isSpecialSale, setIsSpecialSale] = useState(false);
    const {update_sale} = useSelector(state => state.sale);


    useEffect(() => {
        if (update_sale) {
            setIsSpecialSale(update_sale.is_special_sale)
        }else{
            closeModal()
        }

    }, [update_sale]);

    const closeModal = () => {
        handleClose()
        dispatch(setUpdateSaleId(null));
    };


    return (
        <div>
            <Modal style={{ zIndex: 9999 }} size="lg" show={show} onHide={closeModal}>

                {isLoading && <TableSpinner/>}

                <Modal.Header closeButton className="bg-light">
                    <Modal.Title>Sotuvni tahrirlash</Modal.Title>
                </Modal.Header>

                <Modal.Body className="pt-3 pb-4 mx-3">

                    <div className="form-check form-switch">
                        <input className="form-check-input"
                               type="checkbox"
                               id="flexSwitchCheckChecked"
                               onChange={() => setIsSpecialSale(!isSpecialSale)}
                               checked={isSpecialSale}
                        />
                        <label className="form-check-label" htmlFor="flexSwitchCheckChecked">
                            Maxsus sotuv
                        </label>
                    </div>



                    {update_sale && <Fragment>
                        {
                            isSpecialSale ? (
                                <SpecialSaleUpdateForm show={show} handleClose={closeModal} hideLoader={hideLoader} showLoader={showLoader} />
                            ) : (
                                <SimpleSaleUpdateForm show={show} handleClose={closeModal} hideLoader={hideLoader} showLoader={showLoader}/>
                            )
                        }
                    </Fragment>}




                </Modal.Body>
            </Modal>
        </div>
    );
};

export default SaleUpdateForm;