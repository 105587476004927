import React, {Fragment, useEffect, useRef, useState} from "react";
import moment from 'moment'
import {useSelector} from "react-redux";
import SearchForm from "../layout/SearchForm";
import FilterByMonth from "../layout/FilterByMonth";
import FilterByFirm from "../filters/FilterByFirm";
import MaterialDataRow from "./MaterialDataRow";
import {getMaterialsTransactions} from "../../actions/materials_transaction";
import MaterialReport from "./MaterialReport";
import useDates from "../../utils/useDates";
import {getParams} from "../../utils/hooks/getParams";
import CustomPaginationWithManyPages from "../layout/CustomPaginationWithManyPages";

//create your forceUpdate hook
function useForceUpdate() {
    const [value, setValue] = useState(0); // integer state
    return () => setValue(val => val + 1); // update the state to force render
}

const MaterialCalculationsNew = () => {

    const forceUpdate = useForceUpdate();

    const {
        auth
    } = useSelector(state => state);

    const {role_name} = auth?.user || {};

    const {
        materials,
        loading,
        numberOfPages,
        current_page,
    } = useSelector(state => state.materials_transaction);

    const {dates, updateDates} = useDates(getParams().month);
    const scrollableContainer = useRef();
    const [isFirst, setIsFirst] = useState(true);

    useEffect(() => {
        if (materials && materials.length > 0 && materials[0].hasOwnProperty('transactions')) {
            if (isFirst === true && scrollableContainer.current) {
                scrollableContainer.current.scrollLeft += scrollableContainer.current.scrollHeight * 30;
                setIsFirst(false);
            }
        }
    }, [materials])

    const onMonthChange = (newYearMonth) => {
        updateDates(newYearMonth);
    }

    return (
        <div className={'px-4'}>
            <div className="row mt-4 mb-2 justify-content-between">
                <div className="col-md-3">
                    <SearchForm getPageItems={getMaterialsTransactions}/>
                </div>
                {
                    role_name !== 'manager' && role_name !== 'storekeeper' ? (
                        <div className="col-md-2">
                            <FilterByFirm getPageItems={getMaterialsTransactions}/>
                        </div>
                    ) : ''
                }
                <div className="col-md-3 align-self-end ">
                    <FilterByMonth change={onMonthChange} getPageItems={getMaterialsTransactions}/>
                </div>
            </div>

            <div className="row mt-3">

                <div className={`loader-container ${loading ? 'loading' : ''} `}></div>

                <div ref={scrollableContainer} className={`tableFixHead ${loading ? 'data_loader' : ''}`}>

                    {
                        materials && materials.length > 0 ? (
                            <table className={'table-hover custom-hover'}>
                                <thead>
                                <tr>
                                    <th className="sticky-col" style={{width: "2em"}}>#</th>
                                    <th className="sticky-col2">Material</th>
                                    {
                                        dates?.map((item, index, items) => (
                                            items.length - 1 === index ? (
                                                <th key={`date${index}`} className="sticky-col"
                                                    style={{textAlign: "center"}}
                                                    colSpan="2">
                                                    {item}
                                                    {moment(new Date()).format('YYYY-MM-DD') === item ? '(bugun)' : ''}
                                                </th>
                                            ) : (
                                                <th key={`date${index}`} className="sticky-col"
                                                    style={{textAlign: "center"}}
                                                    colSpan="2">{item}
                                                </th>
                                            )
                                        ))
                                    }
                                    <th className="sticky-col" style={{textAlign: "center", right: 0}}
                                        colSpan="5">
                                        Umumiy hisob
                                    </th>
                                </tr>
                                <tr>
                                    <th className="sticky-col">-</th>
                                    <th className="sticky-col2"></th>
                                    {
                                        dates.map((subitem, index) => (
                                            <Fragment key={`th${index}`}>
                                                <th className="sticky-col"
                                                    style={{textAlign: "center"}}>Import
                                                </th>
                                                <th className="sticky-col"
                                                    style={{textAlign: "center"}}>Sarf
                                                </th>
                                            </Fragment>
                                        ))
                                    }
                                    <th className="sticky-col"
                                        style={{textAlign: "center", minWidth: "100px", right: "400px"}}>
                                        Oy importi
                                    </th>
                                    <th className="sticky-col"
                                        style={{textAlign: "center", minWidth: "100px", right: "300px"}}>
                                        Oy sarfi
                                    </th>
                                    <th className="sticky-col"
                                        style={{textAlign: "center", minWidth: "100px", right: "200px"}}>
                                        Import
                                    </th>
                                    <th className="sticky-col"
                                        style={{textAlign: "center", minWidth: "100px", right: "100px"}}>
                                        Sarflangan
                                    </th>
                                    <th className="sticky-col"
                                        style={{textAlign: "center", minWidth: "100px", right: "0px"}}>
                                        Qoldiq
                                    </th>
                                </tr>
                                </thead>


                                <tbody>
                                {materials?.map((item, index) => (
                                    <MaterialDataRow item={item}
                                                     key={item.id}
                                                     index={index}
                                                     dates={dates}
                                                     resetAllRows={forceUpdate}/>
                                ))}
                                </tbody>
                            </table>
                        ) : ''
                    }

                </div>

            </div>

            <div className="row mt-3">
                <CustomPaginationWithManyPages
                    numberOfPages={numberOfPages}
                    getPageItems={getMaterialsTransactions}
                    current_page={current_page}
                />
            </div>
            <MaterialReport/>
        </div>

    )
}

export default MaterialCalculationsNew;

