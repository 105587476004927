import {
    ADD_UNIT, CLEAR_ERRORS,
    GET_UNIT,
    GET_UNITS,
    REMOVE_DELETE_UNIT,
    REMOVE_UPDATE_UNIT,
    SET_DELETE_UNIT_ID,
    SET_UPDATE_UNIT_ID,
    UNIT_ERROR,
    SET_LOADING
} from '../actions/types';

const initialState = {
    units: [],
    unit: {},
    update_unit: null,
    delete_unit: null,
    loading: true,
    error: null,
};

// eslint-disable-next-line
export default function (state = initialState, action) {
    const {type, payload} = action;

    switch (type) {

        case SET_LOADING:
            return {
                ...state,
                loading: payload,
            };
        case GET_UNIT:
            return {
                ...state,
                unit: payload.data,
                loading: false,
            };

        case GET_UNITS:
            return {
                ...state,
                units: payload.data,
                error: null,
                loading: false,
            };

        case ADD_UNIT:
            return {
                ...state,
                units: [payload],
                error: null,
                loading: false,
            };

        case REMOVE_DELETE_UNIT:
            return {
                ...state,
                delete_unit: null,
            };

        case REMOVE_UPDATE_UNIT:
            return {
                ...state,
                update_unit: null,
            };

        case SET_DELETE_UNIT_ID:

            return {
                ...state,
                delete_unit: state.units.find((item) => item.id === action.payload.unitID)
            };

        case SET_UPDATE_UNIT_ID:
            return {
                ...state,
                update_unit: state.units.find((item) => item.id === action.payload.unitID)
            };

        case UNIT_ERROR:
            return {
                ...state,
                error: payload,
                loading: false,
            };

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            };

        default:
            return state;
    }
}
