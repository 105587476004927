import React, {Fragment, useState} from 'react';
import PropTypes from 'prop-types';
import {connect, useSelector} from 'react-redux';
import {getProducts} from "../../actions/product";
import ProductDeleteForm from "./ProductDeleteForm";
import ProductUpdateForm from "./ProductUpdateForm";
import ProductItem from "./ProductItem";
import ProductCreateForm from "./ProductCreateForm";
import SearchForm from "../layout/SearchForm";
import ProductProducingForm from "./ProductProducingForm";
import FilterByStock from "../materials/FilterByStock";
import {Plus} from "react-feather";
import FilterByFirm from "../filters/FilterByFirm";
import {Accordion} from "react-bootstrap";
import CustomPaginationWithManyPages from "../layout/CustomPaginationWithManyPages";
import TableSpinner from "../layout/TableSpinner";

const Products = () => {

    const {
        auth
    } = useSelector(state => state);
    const {role_name} = auth?.user || {};

    const {products, loading, numberOfPages, currentPage, total_products} = useSelector(state => state.product);

    const [createFormShown, setCreateFormShown] = useState(false);
    const [updateFormShown, setUpdateFormShown] = useState(false);

    return (
        <Fragment>
            <div className="container-fluid p-0">

                <Accordion>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>
                            <div className={'fs-4'}>Mahsulot<strong> ishlab chiqarish</strong></div>
                        </Accordion.Header>
                        <Accordion.Body>
                            <ProductProducingForm/>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>

                <div className="row mt-4">
                    <div className="col-md-12 col-lg-8">
                        <h3 className="h4 mb-1"><strong>Mahsulotlar</strong> ({total_products}) </h3>
                    </div>
                </div>

                <div className="row mt-3">

                    <div className="col-3">
                        <FilterByFirm getPageItems={getProducts}/>
                    </div>

                    <div className="col-3">
                        <SearchForm getPageItems={getProducts}/>
                    </div>

                    <div className="col-3">
                        <FilterByStock getPageItems={getProducts}/>
                    </div>

                    <div className="col-md-3">
                        <div className="crud_actions text-end">
                            {
                                role_name === 'superadmin' || role_name === 'director' || role_name === 'manager' || role_name === 'storekeeper' ? (
                                    <button className="btn btn-primary" onClick={() => setCreateFormShown(true)}>
                                        Mahsulot
                                        <Plus/>
                                    </button>
                                ) : ''
                            }
                        </div>
                    </div>
                </div>

                <div className="row mt-2 position-relative">
                    <div className="col-xl-12 col-xxl-12 d-flex">

                        <div className="card w-100 table-responsive">
                            {loading && <TableSpinner/>}
                            <table className="table custom-table table-hover">
                                <thead className="table-light">
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Nomi</th>
                                    <th scope="col">Firma</th>
                                    <th scope="col">Sklad nomi</th>
                                    <th scope="col">Skladda(qoldiq)</th>
                                    <th scope="col">O'lchov birligi</th>
                                    <th scope="col">Tan narxi(oxirgi)</th>
                                    <th scope="col">Amallar</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    products.map((item, index) => (
                                        <ProductItem key={index} index={index} product={item}
                                                     handleShow={() => setUpdateFormShown(true)}
                                                     handleClose={() => setUpdateFormShown(false)}
                                        />
                                    ))
                                }

                                </tbody>
                            </table>

                        </div>

                    </div>

                </div>

                <div className="row">
                    <div className="col-xl-12 col-xxl-12 d-flex">
                        <CustomPaginationWithManyPages
                            numberOfPages={numberOfPages}
                            getPageItems={getProducts}
                            current_page={currentPage}
                        />
                    </div>
                </div>

            </div>

            {
                createFormShown ? (
                    <ProductCreateForm show={createFormShown}
                                       handleShow={() => setCreateFormShown(true)}
                                       handleClose={() => setCreateFormShown(false)}
                    />
                ) : ''
            }
            {
                updateFormShown ? (
                    <ProductUpdateForm show={updateFormShown}
                                       handleShow={() => setUpdateFormShown(true)}
                                       handleClose={() => setUpdateFormShown(false)}
                    />
                ) : ''
            }

            <ProductDeleteForm/>


        </Fragment>
    );
};


Products.propTypes = {
    getProducts: PropTypes.func.isRequired,
    product: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    product: state.product,
});

export default connect(mapStateToProps, {getProducts})(Products);
