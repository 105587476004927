import React, {Fragment, useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import Spinner from '../layout/Spinner';
import Table from 'react-bootstrap/Table';
import CustomPagination from "../layout/CustomPagination";
import WarehouseDeleteForm from "./WarehouseDeleteForm";
import WarehouseUpdateForm from "./WarehouseUpdateForm";
import WarehouseItem from "./WarehouseItem";
import WarehouseCreateForm from "./WarehouseCreateForm";
import { getWarehouses} from "../../actions/warehouse";
import SearchForm from "../layout/SearchForm";
// import { useHistory, useLocation } from "react-router-dom";

const MyWarehouses = (props) => {
    const dispatch = useDispatch();
    const {
        warehouses, loading, numberOfPages, currentPage, total_warehouses
    } = useSelector(state => state.warehouse);
    const {
        auth
    } = useSelector(state => state);
    const {role_name} = auth?.user || {};

    const [show, setShow] = useState(false);

    useEffect(() => {
        dispatch(getWarehouses());
    }, [getWarehouses]);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    return loading ? (
        <Spinner/>
    ) : (
        <Fragment>
            <div className="container-fluid p-0">

                <div className="row mb-2">
                    <div className="col-md-12 col-lg-8">
                        <h1 className="h3 mb-3"><strong></strong> skladlar ({total_warehouses}) </h1>
                    </div>
                </div>

                <div className="row">
                    <SearchForm getPageItems={getWarehouses}/>
                    <div className="col-md-6">
                        <div className="crud_actions text-end">
                            <button className="btn btn-primary" onClick={handleShow}>
                                Sklad
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                     fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                     strokeLinejoin="round" className="feather feather-plus align-middle mr-3">
                                    <line x1="12" y1="5" x2="12" y2="19"></line>
                                    <line x1="5" y1="12" x2="19" y2="12"></line>
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>

                <div className="row mt-2">
                    <div className="col-xl-12 col-xxl-12 d-flex">

                        <div className="card w-100 table-responsive">
                            <Table responsive="sm">

                            </Table>
                            <table className="table custom-table table-hover">
                                <thead className="table-light">
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Nomi</th>
                                    <th scope="col">Firma</th>
                                    <th scope="col">Skladchi</th>
                                    <th scope="col">Sklad turi</th>
                                    <th scope="col">Amallar</th>
                                </tr>
                                </thead>
                                <tbody>
                                {warehouses.map((item, index) => (
                                    <WarehouseItem key={`warehouse${item.id}`} warehouse={item}/>
                                ))}
                                </tbody>
                            </table>

                        </div>

                    </div>

                </div>

                <div className="row">
                    <div className="col-xl-12 col-xxl-12 d-flex">
                        <CustomPagination
                            numberOfPages={numberOfPages}
                            getPageItems={getWarehouses}
                            current_page={currentPage}
                        />
                    </div>
                </div>

            </div>


            <WarehouseCreateForm show={show} handleShow={handleShow} handleClose={handleClose}/>

            <WarehouseDeleteForm/>

            <WarehouseUpdateForm/>


        </Fragment>
    );
};

export default MyWarehouses;
