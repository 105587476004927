import React from 'react';
import Modal from "react-bootstrap/Modal";
import {useDispatch, useSelector} from "react-redux";
// import {AvField, AvForm} from "availity-reactstrap-validation";
import {setUpdateUnitAlternativeId, updateUnitAlternative} from "../../actions/unitalternative";

const UnitAlternativeUpdateForm = () => {

    const dispatch = useDispatch();

    const {update_unitAlternative, unitsData} = useSelector(state => state.unitalternative);

    const closeModal = () => {
        // removing update_unitAlternative from redux by sending null to setUpdateUnitAlternativeId
        dispatch(setUpdateUnitAlternativeId(null));
    };

    const onSubmit = (event, value) => {
        dispatch(updateUnitAlternative(update_unitAlternative.id, value));
    };

    let condition = false;
    if (update_unitAlternative !== null)
        condition = true;

    return (
        <div>
            <Modal show={condition} onHide={closeModal}>
                <Modal.Header closeButton className="bg-light">
                    <Modal.Title>O'lchov birligini tahrirlash</Modal.Title>
                </Modal.Header>
                <Modal.Body className="pt-3 pb-4 mx-3">

                    {/*<AvForm model={update_unitAlternative} onValidSubmit={onSubmit}>*/}

                    {/*    <AvField*/}
                    {/*        type="select"*/}
                    {/*        name="unit_id"*/}
                    {/*        label="O'lchov birligi"*/}
                    {/*    >*/}
                    {/*        {*/}
                    {/*            unitsData.map((item, index) => (*/}
                    {/*                <option key={index} value={item.id}>{item.name}</option>*/}
                    {/*            ))*/}
                    {/*        }*/}
                    {/*    </AvField>*/}

                    {/*    <AvField*/}
                    {/*        name="multiplier"*/}
                    {/*        label="Chiqim o'lchov farqi"*/}
                    {/*        type="number"*/}
                    {/*        required*/}
                    {/*    />*/}

                    {/*    <div className="d-flex gap-2">*/}
                    {/*        <Button className="w-50" onClick={() => closeModal()} variant="secondary" type="button">Bekor qilish</Button>*/}
                    {/*        <Button className="w-50" variant="primary" type="submit">Saqlash</Button>*/}
                    {/*    </div>*/}

                    {/*</AvForm>*/}

                </Modal.Body>
            </Modal>
        </div>
    );
};

export default UnitAlternativeUpdateForm;