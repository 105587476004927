import {v4 as uuidv4} from 'uuid';
import {SET_ALERT, CLEAR_ALERTS} from './types';

export const setAlert = (msg, type, timeout = 5000) => (dispatch) => {
    const id = uuidv4();
    dispatch({
        type: SET_ALERT,
        payload: {msg, type, id},
    });

};

export const clearAlerts = () => (dispatch) => {
    dispatch({
        type: CLEAR_ALERTS,
    });
};
