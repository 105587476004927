import React, {Fragment, useEffect, useState} from "react";
import {getParams, formatMoney, unformatMoney} from "../../utils/hooks/getParams";
import {getEmployeeTransactions, updateTransaction} from "../../actions/employees_transaction";
import {useDispatch, useSelector} from "react-redux";

const EmployeeDataCell = ({index, date, object_id, cellData, refreshRow, rowData}) => {

    const dispatch = useDispatch();
    const [itemData, setDateData] = useState(cellData);

    useEffect(() => {
        setDateData(cellData);
    }, [cellData])

    const {
        auth
    } = useSelector(state => state);
    const {role_name} = auth?.user || {};

    // useEffect(() => item ? setItemData(item) : setItemData({}), [item]);
    // useEffect(() =>

    function setEndOfContenteditable(contentEditableElement)
    {
        let range,selection;
        if(document.createRange)//Firefox, Chrome, Opera, Safari, IE 9+
        {
            range = document.createRange();//Create a range (a range is a like the selection but invisible)
            range.selectNodeContents(contentEditableElement);//Select the entire contents of the element with the range
            range.collapse(false);//collapse the range to the end point. false means collapse to end rather than the start
            selection = window.getSelection();//get the selection object (allows you to change selection)
            selection.removeAllRanges();//remove any selections already made
            selection.addRange(range);//make the range you have just created the visible selection
        }
        else if(document.selection)//IE 8 and lower
        {
            range = document.body.createTextRange();//Create a range (a range is a like the selection but invisible)
            range.moveToElementText(contentEditableElement);//Select the entire contents of the element with the range
            range.collapse(false);//collapse the range to the end point. false means collapse to end rather than the start
            range.select();//Select the range (make it the visible selection
        }
    }

    const updateOrCreateTransaction = async (transaction) => {
        console.log(object_id);
        return await dispatch(
            updateTransaction(
                object_id,
                transaction, getParams(true)
            )
        );
    };

    const handleKeyDown = async (e, updatedValue) => {
        console.log(e);
        console.log(updatedValue)

        // if (parseFloat(updatedValue) < 0) {
        //     e.target.classList.add('invalid_cell_value');
        //     return false;
        // } else {
        //     e.target.classList.remove('invalid_cell_value');
        // }
        //
        if (e.key === 'Enter' || e.key === 'Tab' || e.nativeEvent.key === "NumpadEnter") {
            e.preventDefault();
            if (e.target.getAttribute('contenteditable') === 'plaintext-only') {
                const cell = rowData.find((item) => item === date);
                let edited_cell = cell ?? {date};
                if (e.target.classList.contains('payment_amount')) {
                    edited_cell.payment_amount = unformatMoney(updatedValue);
                } else {
                    edited_cell.earning_amount = unformatMoney(updatedValue);
                }
                if (await updateOrCreateTransaction(edited_cell)) {
                    const params = getParams();
                    dispatch(getEmployeeTransactions(object_id, params));
                    e.target.setAttribute('contenteditable', false);
                    e.target.classList.remove('editing_cell');
                    e.target.classList.remove('invalid_cell');
                } else {
                    e.target.setAttribute('contenteditable', 'plaintext-only');
                    e.target.classList.add('invalid_cell');
                    e.target.classList.remove('editing_cell');
                    setTimeout(function () {
                        e.target.focus();
                    }, 500);
                }
            } else {
                e.target.setAttribute('contenteditable', 'plaintext-only');
                e.target.classList.remove('invalid_cell');
                e.target.classList.add('editing_cell');
            }
        }
        if (e.key === 'Escape') {
            e.preventDefault();
            if (e.target.getAttribute('contenteditable') === 'plaintext-only') {
                e.target.setAttribute('contenteditable', false);
                e.target.classList.remove('editing_cell');
                e.target.classList.remove('invalid_cell');
                const cell = rowData.find((item) => item.id === e.target.dataset.cell_id);
                e.target.textContent = formatMoney(e.target.dataset.old_value)
            }
        }
    }

    const onDoubleClick = async (e, updatedValue) => {
        console.log(e, updatedValue);
        e.target.parentElement.querySelectorAll('td').forEach(item => {
            if (item.classList.contains('editing_cell')){
                item.classList.remove('editing_cell');
            }
        })
        if (e.target.getAttribute('contenteditable') === 'plaintext-only') {
            const cell = rowData.find((item) => item === date);
            let edited_cell = cell ?? {date};
            if (e.target.classList.contains('payment_amount')) {
                edited_cell.payment_amount = unformatMoney(updatedValue);
            } else {
                edited_cell.earning_amount = unformatMoney(updatedValue);
            }
            if (await updateOrCreateTransaction(edited_cell)) {
                const params = getParams();
                dispatch(getEmployeeTransactions(object_id, params));
                e.target.setAttribute('contenteditable', false);
                e.target.classList.remove('editing_cell');
                e.target.classList.remove('invalid_cell');
            } else {
                e.target.setAttribute('contenteditable', 'plaintext-only');
                e.target.classList.add('invalid_cell');
                e.target.classList.remove('editing_cell');
                setTimeout(function () {
                    e.target.focus();
                }, 500);
            }
        } else {
            e.target.setAttribute('contenteditable', 'plaintext-only');
            e.target.classList.remove('invalid_cell');
            e.target.classList.add('editing_cell');
        }
        setEndOfContenteditable(e.target);
    }

    return (
        <Fragment key={`td${index}`}>
                <td onDoubleClick={(e) => onDoubleClick(e, e.target.textContent)}
                    onKeyDown={(e) => handleKeyDown(e, e.target.textContent)}
                    className={'cell earning_amount'}
                    data-old_value={itemData.earning_amount}
                >
                    {formatMoney(itemData.earning_amount)}
                </td>
                <td onDoubleClick={(e) => onDoubleClick(e, e.target.textContent)}
                    onKeyDown={(e) => handleKeyDown(e, e.target.textContent)}
                    className={'cell payment_amount'}
                    data-old_value={itemData.payment_amount}
                >
                    {formatMoney(itemData.payment_amount)}
                </td>
        </Fragment>
    )
}

export default EmployeeDataCell;