import React, {useEffect, useRef, useState} from 'react';
import Modal from "react-bootstrap/Modal";
import {useDispatch, useSelector} from "react-redux";
// import {AvField, AvForm, AvRadio, AvRadioGroup} from "availity-reactstrap-validation";
import Button from "react-bootstrap/Button";
import {setUpdateEmployeeId, updateEmployee} from "../../actions/employee";
import {getParams} from "../../utils/hooks/getParams";

const EmployeeUpdateForm = () => {

    const dispatch = useDispatch();

    const {update_employee, currentPage, firm_ID_DATA, error} = useSelector(state => state.employee);
    useEffect(() => {
        setInputs(update_employee)
    }, [update_employee])
    const closeModal = () => {
        setInputs(defaultValues);
        setErrors({});
        // removing update_employee from redux by sending null to setUpdateEmployeeId
        dispatch(setUpdateEmployeeId(null));
    };

    const formRef = useRef();

    const defaultValues = {
        first_name: '',
        last_name: '',
        inn: '',
        address: '',
        phone: '',
        firm_id: '',
        profession: '',
        status: ''
    };

    const [inputs, setInputs] = useState(defaultValues);
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        if (error && loading === false) {
            const errorsItems = error?.errors;
            let errorObject = {};
            if (error && error?.errors) {
                Object.keys(error?.errors).forEach((item, index) => errorObject[item] = errorsItems[item][0]);
            }
            setErrors(errorObject);
        }
    }, [error]);
    const handleChange = e => {
        setErrors({});
        e.target.oldValue = e.target.value;
        setInputs(prevState => ({...prevState, [e.target.name]: e.target.value}));
    };

    const handleSubmit = (event, value) => {
        event.preventDefault();
        dispatch(updateEmployee(update_employee.id, inputs, getParams()));
    };

    let condition = false;
    if (update_employee !== null)
        condition = true;

    return (
        <div>
            <Modal show={condition} onHide={closeModal}>
                <Modal.Header closeButton className="bg-light">
                    <Modal.Title className="text-black">Xodimni tahrirlash</Modal.Title>
                </Modal.Header>
                <Modal.Body className="pt-3 pb-4 mx-3">
                    <form onSubmit={handleSubmit} ref={formRef}>

                        <div className="mb-3">
                            <label htmlFor="first_name">Ism</label>
                            <input
                                name="first_name"
                                className={'form-control'}
                                type="text"
                                value={inputs?.first_name || ''}
                                placeholder={'kiriting'}
                                onChange={handleChange}
                                onKeyUp={e => e.target.oldValue = e.target.value}
                            />

                            <div className="error">
                                {errors?.first_name}
                            </div>
                        </div>

                        <div className="mb-3">
                            <label htmlFor="last_name">Familiya</label>
                            <input
                                name="last_name"
                                className={'form-control'}
                                type="text"
                                value={inputs?.last_name || ''}
                                placeholder={'kiriting'}
                                onChange={handleChange}
                                onKeyUp={e => e.target.oldValue = e.target.value}
                            />

                            <div className="error">
                                {errors?.last_name}
                            </div>
                        </div>


                        <div className="mb-3">
                            <label htmlFor="inn">INN</label>
                            <input
                                name="inn"
                                className={'form-control'}
                                type="text"
                                value={inputs?.inn || ''}
                                placeholder={'kiriting'}
                                onChange={handleChange}
                                onKeyUp={e => e.target.oldValue = e.target.value}
                            />

                            <div className="error">
                                {errors?.inn}
                            </div>
                        </div>


                        <div className="mb-3">
                            <label htmlFor="address">Manzil</label>
                            <input
                                name="address"
                                className={'form-control'}
                                type="text"
                                value={inputs?.address || ''}
                                placeholder={'kiriting'}
                                onChange={handleChange}
                                onKeyUp={e => e.target.oldValue = e.target.value}
                            />

                            <div className="error">
                                {errors?.address}
                            </div>
                        </div>


                        <div className="mb-3">
                            <label htmlFor="phone">Telefon</label>
                            <input
                                name="phone"
                                className={'form-control'}
                                type="phone"
                                value={inputs?.phone || ''}
                                placeholder={'kiriting'}
                                onChange={handleChange}
                                onKeyUp={e => e.target.oldValue = e.target.value}
                            />

                            <div className="error">
                                {errors?.phone}
                            </div>
                        </div>

                        <div className="mb-3">
                            <label htmlFor="firm_id">Firma</label>
                            <select name="firm_id"
                                    value={inputs?.firm_id || ''}
                                    id="firm_id"
                                    className="form-control"
                                    onChange={handleChange}
                                    onKeyUp={e => e.target.oldValue = e.target.value}
                            >
                                <option value="">Tanlang</option>
                                {
                                    firm_ID_DATA.map((item, index) => (
                                        <option key={index} value={item.id}>{item.name}</option>
                                    ))
                                }
                            </select>
                            <div className="error">
                                {errors?.firm_id}
                            </div>
                        </div>

                        <div className="mb-3">
                            <label htmlFor="profession">Kasbi (
                                <u>Haydovchi</u>
                                )</label>
                            <input
                                name="profession"
                                className={'form-control'}
                                type="text"
                                value={inputs?.profession || ''}
                                placeholder={'kiriting'}
                                onChange={handleChange}
                                onKeyUp={e => e.target.oldValue = e.target.value}
                            />

                            <div className="error">
                                {errors?.profession}
                            </div>
                        </div>

                        <div className="mb-3">

                            <div className="d-flex">
                                <div className="d-flex align-items-center">
                                    <input
                                        name="status"
                                        id="name1"
                                        className={'me-2'}
                                        type="radio"
                                        value="hired"
                                        checked={inputs?.status === 'hired'}
                                        onChange={handleChange}
                                        onKeyUp={e => e.target.oldValue = e.target.value}
                                    />
                                    <label htmlFor="name1">Ishga olingan</label>
                                </div>

                                <div className="ms-4 d-flex align-items-center">
                                    <input
                                        name="status"
                                        id="name2"
                                        className={'me-2'}
                                        type="radio"
                                        value="fired"
                                        checked={inputs?.status === 'fired'}
                                        onChange={handleChange}
                                        onKeyUp={e => e.target.oldValue = e.target.value}
                                    />
                                    <label htmlFor="name2">Ishdan olingan</label>
                                </div>
                            </div>

                            <div className="error">
                                {errors?.status}
                            </div>
                        </div>


                        <div className="d-flex gap-2">
                            <Button className="w-50" variant="secondary" type="button" onClick={closeModal}>
                                Bekor qilish
                            </Button>
                            <Button className="w-50" variant="primary" type="submit">Saqlash</Button>
                        </div>
                    </form>
                    {/*<AvForm model={update_employee} onValidSubmit={onSubmit}>*/}

                    {/*    <AvField*/}
                    {/*        name="first_name"*/}
                    {/*        label="Ism"*/}
                    {/*        type="text"*/}
                    {/*        required*/}
                    {/*    />*/}

                    {/*    <AvField*/}
                    {/*        name="last_name"*/}
                    {/*        label="Familiya"*/}
                    {/*        type="text"*/}
                    {/*        required*/}
                    {/*    />*/}

                    {/*    <AvField*/}
                    {/*        name="inn"*/}
                    {/*        label="INN"*/}
                    {/*        type="text"*/}
                    {/*        required*/}
                    {/*    />*/}

                    {/*    <AvField*/}
                    {/*        name="address"*/}
                    {/*        label="Manzil"*/}
                    {/*        type="text"*/}
                    {/*        required*/}
                    {/*    />*/}

                    {/*    <AvField*/}
                    {/*        name="phone"*/}
                    {/*        label="Telefon"*/}
                    {/*        type="text"*/}
                    {/*        required*/}
                    {/*    />*/}

                    {/*    <AvField*/}
                    {/*        type="select"*/}
                    {/*        name="firm_id"*/}
                    {/*        label="Firma"*/}
                    {/*    >*/}
                    {/*        {*/}
                    {/*            firm_ID_DATA.map((item, index) => (*/}
                    {/*                <option key={index} value={item.id}>{item.name}</option>*/}
                    {/*            ))*/}
                    {/*        }*/}
                    {/*    </AvField>*/}

                    {/*    <AvField*/}
                    {/*        name="profession"*/}
                    {/*        label="Kasbi"*/}
                    {/*        type="text"*/}
                    {/*        required*/}
                    {/*    />*/}


                    {/*    <label htmlFor="radio" className="mb-2">Holati</label>*/}

                    {/*    <AvRadioGroup id="radio" inline name="status" required errorMessage="Holatni tanlang !">*/}
                    {/*        <AvRadio label="Ishga olingan" value="hired"/>*/}
                    {/*        <AvRadio label="Ishdan olingan" value="fired"/>*/}
                    {/*    </AvRadioGroup>*/}

                    {/*    <div className="d-flex gap-2">*/}
                    {/*        <Button className="w-50" variant="secondary" type="button" onClick={closeModal}>Bekor*/}
                    {/*            qilish</Button>*/}
                    {/*        <Button className="w-50" variant="primary" type="submit">Saqlash</Button>*/}
                    {/*    </div>*/}
                    {/*</AvForm>*/}

                </Modal.Body>
            </Modal>
        </div>
    );
};

export default EmployeeUpdateForm;