export const hasPermission = (role, permission) => {
    const permissions = {
        superadmin: ['all'],
        director: ['view_firms', 'edit_firms', 'delete_firms', 'create_firms'],
        manager: ['view_firms'],
        storekeeper: ['view_profile'],
        user: []
    };

    if (!role || !permission) return false;
    
    const rolePermissions = permissions[role] || [];
    return rolePermissions.includes('all') || rolePermissions.includes(permission);
};
